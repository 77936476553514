<template>
	<div class="modal modal-add-client">
		<h1 class="text-2xl">Slanje grupnog email-a</h1>
		<hr />
		<div class="modal-content text-lg">
			<div class="flex flex-row content">
				<div class="w-1/2 mr-4">
					<h3 class="font-semibold text-xl mb-4">Izaberite primaoce</h3>
					<div class="flex text-sm mb-2">
						<button title="Izaberi sve" class="mr-4" @click="selectAll">Izaberi sve</button>
						<button title="Ponisti izbor" @click="clearSelection">Poništi izbor</button>
					</div>
					<vuetable
						v-if="!loading"
						ref="vuetableEmail"
						:api-mode="false"
						:fields="fieldsEmail"
						:css="css.table"
						:per-page="perPage"
						noDataTemplate="Nisu pronađeni podaci"
						pagination-path="pagination"
						@vuetable:pagination-data="onPaginationDataEmail"
						@vuetable:checkbox-toggled="onCheckboxToggled"
						@vuetable:checkbox-toggled-all="onCheckboxToggled"
						:data-manager="dataManagerEmail"
						class="dashboard-table"
					>
						<div class="slot flex flex-row" slot="name-slot" slot-scope="props">
							<span :title="props.rowData.name">
								{{ props.rowData.name }}
							</span>
						</div>
					</vuetable>
					<vuetable-pagination
						ref="paginationEmail"
						class="pull-right"
						@vuetable-pagination:change-page="onChangePageEmail"
					></vuetable-pagination>
					<div class="font-semibold text-m mb-4 mt-4 text-right">
						Izabrano {{ selectedRows.length }}/{{ emailTableData.length }}
					</div>
				</div>
				<div class="w-1/2">
					<h3 class="font-semibold text-xl mb-4">Unesite naslov email-a</h3>
					<input type="text" class="focus:ring-primary focus:border-primary mb-2 w-full" v-model="emailSubject" />
					<h3 class="font-semibold text-xl mb-4">Unesite tekst email-a</h3>
					<vue-editor class="w-full wysiwyg" v-model="emailContent" :editor-toolbar="customToolbar" />
				</div>
			</div>
			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button
					class="flex-grow ml-2"
					@click="sendEmails"
					:disabled="
						loading || selectedRows.length == 0 || emailSubject.trim().length == 0 || emailContent.trim().length == 0
					"
				>
					Pošalji email-ove
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';
import VuetableCssConfig from '@/VuetableCssConfig';
import Vuetable from 'vuetable-2';
import VuetablePagination from './VuetablePaginationBootstrap';
import { VueEditor } from 'vue2-editor';

export default {
	components: { Vuetable, VuetablePagination, VueEditor },
	data() {
		return {
			loading: false,
			filterStatus: null,
			css: VuetableCssConfig,
			fieldsEmail: [
				{
					name: 'name-slot',
					title: 'Naziv',
					sortField: 'name'
				},
				{
					name: '__checkbox',
					titleClass: 'center-checkbox',
					dataClass: 'center-checkbox'
				}
			],
			perPage: 5,
			emailTableData: [],
			selectedRows: [],
			emailContent: '',
			emailSubject: '',
			customToolbar: [
				[{ header: [false, 1, 2, 3, 4, 5, 6] }],
				['bold', 'italic', 'underline'],
				[{ list: 'ordered' }, { list: 'bullet' }],
				['clean']
			]
		};
	},

	props: {},

	computed: {
		...mapState({
			loggedUser: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		})
	},

	async created() {
		this.loading = true;
		if (this.loggedUser.role == 'SUPERADMIN') {
			const response = await axios.get('user/partners/all');
			this.emailTableData = response.data;
			if (this.$refs.vuetableEmail) this.$refs.vuetableEmail.reload();
		}
		setTimeout(() => {
			this.loading = false;
		}, 200);
	},

	methods: {
		onPaginationDataEmail(paginationData) {
			this.$refs.paginationEmail.setPaginationData(paginationData);
		},
		dataManagerEmail(sortOrder, pagination) {
			let data = this.emailTableData;
			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}
			pagination = this.$refs.vuetableEmail.makePagination(data.length); // if you don't want to use pagination component, you can just
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},
		onChangePageEmail(page) {
			this.$refs.vuetableEmail.changePage(page);
		},
		onCheckboxToggled() {
			this.computeSelectedRows();
		},
		computeSelectedRows() {
			if (!this.$refs.vuetableEmail) return;

			this.selectedRows = this.emailTableData.filter(row => this.$refs.vuetableEmail.selectedTo.indexOf(row.id) > -1);
		},
		clearSelection() {
			this.$refs.vuetableEmail.selectedTo = [];
			this.computeSelectedRows();
		},
		selectAll() {
			this.$refs.vuetableEmail.selectedTo = [];
			for (const data of this.emailTableData) {
				this.$refs.vuetableEmail.selectedTo.push(data.id);
			}
			this.computeSelectedRows();
		},
		async sendEmails() {
			this.loading = true;
			try {
				const requestBody = {
					subject: this.emailSubject,
					body: this.emailContent,
					to: this.selectedRows.map(row => {
						return row.email;
					})
				};
				await axios.post('chat/bulk-email', requestBody);
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Email-ovi su uspešno poslati.',
					type: 'success',
					timer: 3500,
					showConfirmButton: false
				});
			} catch (error) {
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške u slanju.',
					type: 'error',
					timer: 3500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.dashboard-table {
	font-size: 15;
	width: 100%;
}
.wysiwyg {
	height: 306px;
}
.content {
	min-height: 500px;
}
</style>
