<template>
	<div class="archive-tab">
		<div>
			<modal :draggable="true" :adaptive="true" height="auto" :width="600" name="action-history-archive">
				<div class="flex justify-end m-2 absolute right-1 top-1">
					<button
						class="bg-secondary hover:bg-secondary-light"
						style="width: 34px; height: 34px; line-height: 0"
						@click="$modal.hide('action-history-archive')"
					>
						<i class="fas fa-times" />
					</button>
				</div>
				<modal-licence-action-history
					@close="closeModal('action-history-archive')"
					:lpfr="this.selectedLpfr"
					@refresh="refreshData"
			/></modal>
		</div>
		<div v-if="loading" style="height: 200px" class="flex flex-col justify-center">
			<spinner-component :size="50" />
		</div>
		<div class="content" v-if="!loading">
			<div class="button-row">
				<div class="flex flex-row items-center">
					<div class="filter-container">
						<div>
							<input
								type="text"
								class="focus:ring-primary focus:border-primary"
								v-model="searchInput"
								:placeholder="searchPlaceholder"
								style="width: 420px"
								v-on:keyup.enter="search"
							/>
							<button title="Pretraži" @click="search()" style="width: 42px">
								<i class="fas fa-search" />
							</button>
							<button
								title="Poništi filter i osveži tabelu"
								class="cancel-filter-button"
								@click="cancelFilter()"
								style="width: 42px"
							>
								<i class="fas fa-times" />
							</button>
						</div>
					</div>
					<spinner-component class="ml-12" v-if="loadingTableData" :size="45" />
				</div>
				<button title="Osveži tabelu" @click="getDataThroughFilter()">
					Osveži
					<i class="fas fa-redo ml-2"></i>
				</button>
			</div>

			<div>
				<vuetable
					ref="vuetable"
					:api-mode="false"
					:fields="fields"
					:css="css.table"
					:per-page="perPage"
					noDataTemplate="Nisu pronađeni arhivirani LPFR"
					:data-manager="dataManager"
					pagination-path="pagination"
					@vuetable:pagination-data="onPaginationData"
					@vuetable:loaded="onLoaded"
					class="archive-table"
				>
					<div class="slot flex flex-row" slot="distributor-slot" slot-scope="props">
						<span
							:title="props.rowData.distributor.name"
							class="text-xs text-ellipsis distributor-name"
							v-if="props.rowData.distributor"
							style="width: 100%"
						>
							<div style="width: 100%">
								{{ props.rowData.distributor.name }}
							</div>
						</span>
						<span v-else class="text-xs text-center">/</span>
					</div>
					<div class="slot flex flex-row" slot="partner-slot" slot-scope="props">
						<span
							:title="props.rowData.partner.name"
							class="text-xs text-ellipsis distributor-name"
							v-if="props.rowData.partner"
							style="width: 100%"
						>
							<div style="width: 100%">
								{{ props.rowData.partner.name }}
							</div>
						</span>
						<span v-else class="text-xs text-center">/</span>
					</div>
					<div class="slot flex flex-row items-center" slot="client-slot" slot-scope="props" style="width: 100%">
						<div
							:title="props.rowData.client.name"
							class="font-semibold text-ellipsis partner-client-txt"
							style="width: 100%"
						>
							{{ props.rowData.client.name }}
						</div>
					</div>
					<div class="slot" slot="jid-slot" slot-scope="props">
						<div style="display: flex; flex-direction: row; align-items: center; max-width: 500px">
							<span
								v-if="props.rowData.jidNickname"
								:title="props.rowData.jid + ' (' + props.rowData.jidNickname + ')'"
								>{{ props.rowData.jid + ' (' + props.rowData.jidNickname + ')' }}</span
							>
							<span v-else>{{ props.rowData.jid }}</span>
						</div>
					</div>
					<div class="slot" slot="last-update-slot" slot-scope="props">
						<span>{{ props.rowData.lastUpdate | formatDate }}</span>
					</div>
					<div class="slot" slot="expiration-date-slot" slot-scope="props">
						<span>{{ props.rowData.expirationDate | formatDateNoTime }}</span>
					</div>
					<div class="slot" slot="licence-actions-slot" slot-scope="props">
						<div class="flex flex-row justify-center">
							<button
								class="logs-btn"
								title="Prikaži akcije"
								@click="showActionHistory(props.rowData)"
								style="width: 26px; height: 26px; padding: 0"
							>
								<i class="fas fa-list"></i>
							</button>
						</div>
					</div>
					<div slot="unarchive-slot" slot-scope="props" class="text-center text-sm slot">
						<button
							v-if="props.rowData.archived"
							style="width: 26px; height: 26px; padding: 0"
							@click="unarchive(props.rowData)"
							class="btn btn-sm"
							title="Vrati iz arhive"
						>
							<i class="fas fa-undo" />
						</button>
					</div>
				</vuetable>
				<div class="flex flex-row justify-end items-center">
					<div class="mr-6">
						{{ numOfDisplayed }}
						/
						{{ numOfTotal }}
					</div>
					<div class="flex flex-row per-page-div">
						<input type="radio" id="10-per-page-monitor" :value="10" v-model="perPage" /><label
							:class="{ active: perPage == 10 }"
							for="10-per-page-monitor"
							>10</label
						>
						<input type="radio" id="20-per-page-monitor" :value="20" v-model="perPage" /><label
							:class="{ active: perPage == 20 }"
							for="20-per-page-monitor"
							>20</label
						>
						<input type="radio" id="50-per-page-monitor" :value="50" v-model="perPage" /><label
							:class="{ active: perPage == 50 }"
							for="50-per-page-monitor"
							>50</label
						>
						<input type="radio" id="100-per-page-monitor" :value="100" v-model="perPage" /><label
							:class="{ active: perPage == 100 }"
							for="100-per-page-monitor"
							>100</label
						>
					</div>
					<vuetable-pagination
						ref="pagination"
						class="pull-right"
						@vuetable-pagination:change-page="onChangePage"
					></vuetable-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from './VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import axios from '@/axios/axios.js';
const semver = require('semver');
import ModalLicenceActionHistory from './ModalLicenceActionHistory.vue';

export default {
	components: {
		Vuetable,
		VuetablePagination,
		ModalLicenceActionHistory
	},

	data() {
		return {
			searchInput: '',
			searchFor: '',
			localData: [],
			css: VuetableCssConfig,
			fields: [],
			refreshInterval: 60000,
			lpfrRow: null,
			logs: null,
			selectedUser: null,
			selectedLpfr: null,
			loadingTableData: false,
			perPage: 20,
			numOfDisplayed: 0,
			numOfTotal: 0
		};
	},

	async created() {
		this.setupTableFields();
	},

	computed: {
		...mapState({
			loading: state => {
				return state.loadingMonitor;
			}
		}),
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		}),
		searchPlaceholder() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'Naziv distributera/partnera/klijenta, PIB, JID, nadimak, ...';
				case 'DISTRIBUTOR':
					return 'Naziv partnera/klijenta, PIB, JID, nadimak, ...';
				default:
					return 'Naziv klijenta, PIB, JID, nadimak, ...';
			}
		},
		clientFieldTitle() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'Partner / Klijent';
				case 'DISTRIBUTOR':
					return 'Partner / Klijent';
				case 'PARTNER':
					return 'Klijent';
				default:
					return 'Klijent';
			}
		}
	},

	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		}
	},
	methods: {
		...mapMutations(['loadingStartedMonitor', 'loadingFinishedMonitor', 'setLpfrs']),

		onLoaded() {
			this.numOfDisplayed = this.$refs.vuetable.countTableData;
			this.numOfTotal = this.$refs.pagination.tablePagination.total;
		},
		showActionHistory(rowData) {
			this.selectedLpfr = rowData;
			this.$modal.show('action-history-archive');
			event.stopPropagation();
		},

		cancelFilter() {
			this.searchFor = '';
			this.searchInput = '';
			this.refreshData();
		},

		async refreshData() {
			this.loadingTableData = true;
			await this.getArchivedLPFRs();
			this.loadingTableData = false;
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		},

		async getDataThroughFilter() {
			this.loadingTableData = true;
			await this.refreshData();
			this.loadingTableData = false;
		},

		async getDataIfNeeded() {
			if (!this.localData || this.localData == null || this.localData.length == 0) {
				this.loadingTableData = true;
				await this.refreshData();
				this.loadingTableData = false;
			}
		},

		async getArchivedLPFRs() {
			try {
				const response = await axios.get('client/archived-lpfrs');

				this.localData = response.data;
				//* sort by versions to assign correct order
				this.localData.sort((a, b) => {
					if (semver.valid(b.version) && semver.valid(a.version)) {
						return semver.compare(a.version, b.version);
					} else if (semver.valid(b.version)) {
						return -1;
					} else if (semver.valid(a.version)) {
						return 1;
					}
				});

				//* Assigning order by version and setting up unknown status when needed
				for (const row of this.localData) {
					row.versionOrder = this.localData.indexOf(row);
					if (!row.version) row.version = '/';
					if (row.lastUpdate) {
						row.lastUpdateSort = row.lastUpdate;
					} else {
						row.lastUpdateSort = new Date(0);
					}
					if (row.type && row.type.toLowerCase() == 'production') {
						row.type = 'PROD';
					} else if (row.type && row.type.toLowerCase() == 'sandbox') {
						row.type = 'DEV';
					} else if (row.type && row.type.toLowerCase() == 'print') {
						row.type = 'PRINT';
					} else {
						row.type = 'UNKWN';
					}
					row.clientName = row.client.name;

					// Setting up a special indicator if the LPFR is under direct ownership of the logged in user
					let ownedByLoggedUser = false;
					if (row.admin && row.admin.id == this.user.id) {
						ownedByLoggedUser = true;
					} else if (row.distributor && !row.partner && row.distributor.id == this.user.id) {
						ownedByLoggedUser = true;
					} else if (row.partner && row.partner.id == this.user.id) {
						ownedByLoggedUser = true;
					}
					row.ownedByLoggedUser = ownedByLoggedUser;
				}

				//* Sorting by last update (default sort for table)
				this.localData.sort((a, b) => {
					return new Date(b.lastUpdateSort) - new Date(a.lastUpdateSort);
				});

				// //* Filter by environment (DEV/PROD)
				// let formattedType = '';
				// if (this.filterType == 'SANDBOX') formattedType = 'DEV';
				// else if (this.filterType == 'PRODUCTION') formattedType = 'PROD';
				// else formattedType = 'PRINT';
				// this.localData = this.localData.filter((lpfr) => {
				// 	return lpfr.type == formattedType;
				// });

				// //* Filter by LPFR status
				// if (this.filterStatus) {
				// 	this.localData = this.localData.filter(
				// 		(lpfr) => lpfr.status == +this.filterStatus
				// 	);
				// }
			} catch (error) {}
		},

		unarchive(rowData) {
			this.$fire({
				title: 'Vraćanje LPFR',
				html: `Da li ste sigurni da želite da vratite<br><b>LPFR - ${rowData.jid} ${
					rowData.jidNickname ? '(' + rowData.jidNickname + ')' : ''
				}</b><br>iz arhive nazad u aktivno posmatranje?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Vrati LPFR',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					axios
						.post(`lpfr/${rowData.id}/archive`, { archived: false })
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								html: `LPFR je uspešno vraćen iz arhive.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							this.loadingFinishedMonitor();
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom vraćanja LPFR.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
		},

		setupTableFields() {
			this.fields = [
				{
					name: 'client-slot',
					title: 'Klijent',
					sortField: 'clientName'
				},
				{
					name: 'pib',
					title: 'PIB klijenta'
				},
				{
					name: 'jid-slot',
					title: 'JID'
				},
				{
					name: 'last-update-slot',
					title: 'Poslednje javljanje',
					sortField: 'lastUpdateSort'
				},
				{
					name: 'expiration-date-slot',
					title: 'Datum isteka licence',
					sortField: 'expirationDate'
				},
				{
					name: 'licence-actions-slot',
					title: `Istorijat akcija`
				},

				{ name: 'type', title: 'Env.', sortField: 'type' },
				{ name: 'version', title: 'Ver.', sortField: 'versionOrder' },
				{ name: 'unarchive-slot', title: 'Akcije' }
			];

			if (this.user.role != 'PARTNER' && this.user.role != 'ONPREMADMIN') {
				this.fields.unshift({
					name: 'partner-slot',
					title: 'Partner',
					sortField: `partner.name`
				});
			}

			if (this.user.role == 'SUPERADMIN') {
				this.fields.unshift({
					name: 'distributor-slot',
					title: 'Distributer',
					sortField: `distributor.name`
				});
			}
		},

		search() {
			this.searchFor = this.searchInput;
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},

		closeModal(name) {
			this.$modal.hide(name);
		},

		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.client.name.search(txt) >= 0 ||
						item.pib.search(txt) >= 0 ||
						item.jid.search(txt) >= 0 ||
						(item.jidNickname != null && item.jidNickname.search(txt) >= 0) ||
						(item.partner ? item.partner.name.search(txt) >= 0 : false) ||
						(item.distributor ? item.distributor.name.search(txt) >= 0 : false)
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.monitor-container {
	display: flex;
	flex-direction: column;
}

.status-slot img {
	object-fit: contain;
	width: 24px;
	height: 24px;
}

.status-slot {
	// min-width: 95px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.archive-table {
	font-size: 15px;
}
.log-text {
	max-width: 100%;
}
.text-ellipsis {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.edit-nick-btn {
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	i {
		font-size: 13px;
	}
}
.logs-btn {
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
}
.num-of-invoices {
	font-weight: bold;
	white-space: nowrap;
}
.distributor-name {
	max-width: 100px;
}
.partner-client-txt {
	max-width: 150px;
}
.jid-txt {
	max-width: 150px;
}
.button-row button {
	margin-top: 0px;
	font-size: 0.9rem;
	margin-bottom: 0px;
}
</style>

<style lang="scss">
.vuetable-th-slot-unarchive-slot {
	text-align: center;
	width: 65px;
}
</style>
