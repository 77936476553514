<template>
	<div class="modal">
		<h1>Detalji o nacrtu</h1>
		<hr class="mb-2" />
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div class="modal-content" v-if="!loading">
			<div>
				<h2>Obračunski period</h2>
				<h1>{{ group.accountingPeriodFormatted }}</h1>
			</div>
			<div class="flex flex-row justify-between">
				<div class="w-1/2">
					<h2>Status</h2>
					<h1>{{ group.statusFormatted }}</h1>
				</div>
			</div>

			<h2>Obveznici</h2>
			<div class="flex flex-row flex-wrap">
				<div v-for="client in chosenClients" :key="client.index">
					<div class="flex flex-row items-center mr-2 mt-2 bg-blue-200 p-1 rounded">
						<h2>
							{{ client }}
						</h2>
					</div>
				</div>
			</div>
			<hr class="mt-2 mb-2" />
			<div class="flex flex-row justify-between">
				<div class="w-1/2">
					<h2>Datum prometa faktura</h2>
					<h1>{{ group.dateTurnover | formatDateNoTime }}</h1>
				</div>
				<div class="w-1/2">
					<h2>Srednji kurs (1€ u RSD)</h2>
					<h1>{{ moneyFormatter.format(this.group.exchangeRateEURtoRSD) }}</h1>
				</div>
			</div>
			<div class="flex flex-row justify-between" v-if="group.dateValue">
				<div class="w-1/2">
					<h2>Datum valute</h2>
					<h1>{{ group.dateValue | formatDateNoTime }}</h1>
				</div>
			</div>

			<div class="flex flex-row justify-between">
				<div class="w-1/2">
					<h2>Datum poslednje izmene nacrta</h2>
					<h1>{{ this.group.dateDrafted | formatDateNoTime }}</h1>
				</div>
				<div class="w-1/2" v-if="group.status != 'DRAFT'">
					<h2>Datum izdavanja faktura</h2>
					<h1>{{ group.dateCreated | formatDateNoTime }}</h1>
				</div>
			</div>
			<h2>Fakturisanje samo direktnih LPFR-ova obveznika</h2>
			<h1>
				{{ group.onlyDirect ? 'Da' : 'Ne (obračunavaju se svi LPFR-ovi)' }}
			</h1>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	props: {
		invoiceGroupId: {
			type: Number,
			required: true
		}
	},

	data() {
		return {
			loading: false,
			group: {},
			clients: [],
			chosenClients: [],
			months: [
				'Januar',
				'Februar',
				'Mart',
				'April',
				'Maj',
				'Jun',
				'Jul',
				'Avgust',
				'Septembar',
				'Oktobar',
				'Novembar',
				'Decembar'
			],
			moneyFormatter: new Intl.NumberFormat('de-DE', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 4
			})
		};
	},

	async created() {
		await this.loadData();
	},

	methods: {
		async loadData() {
			this.loading = true;
			try {
				const response = await axios.get(`finances/group/${this.invoiceGroupId}`);
				this.group = response.data;
				const clientsResponse = await axios.get('/finances/valid-clients');
				this.clients = clientsResponse.data.map(client => {
					switch (client.type) {
						case 'CLIENT': {
							client.typeFormatted = 'Klijent';
							break;
						}
						case 'DISTRIBUTOR': {
							client.typeFormatted = 'Distributer';
							break;
						}
						case 'PARTNER': {
							client.typeFormatted = 'Partner';
							break;
						}
						default: {
							client.typeFormatted = 'Nepoznato';
						}
					}
					return client;
				});
				this.formatChosenClients();
				this.formatGroup();
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},

		formatChosenClients() {
			const chosenClients = [];
			if (this.group) {
				if (this.group.clientIds && this.group.clientIds.length > 0) {
					const ids = this.group.clientIds.split(',');
					for (const id of ids) {
						const client = this.clients.find(c => c.id == id && c.type == 'CLIENT');
						if (client) {
							const clientFormatted = `${client.name} (${client.typeFormatted}), ${client.pib}`;
							chosenClients.push(clientFormatted);
						}
					}
				}
				if (this.group.userIds && this.group.userIds.length > 0) {
					const ids = this.group.userIds.split(',');
					for (const id of ids) {
						const client = this.clients.find(c => c.id == id && c.type != 'CLIENT');
						if (client) {
							const clientFormatted = `${client.name} (${client.typeFormatted}), ${client.pib}`;
							chosenClients.push(clientFormatted);
						}
					}
				}

				this.chosenClients = chosenClients;
			}
		},

		formatGroup() {
			if (this.group) {
				if (this.group.accountingPeriod == 'MONTHLY') {
					this.group.accountingPeriodFormatted = `${this.months[this.group.month]} ${this.group.year}.`;
				} else if (this.group.accountingPeriod == 'YEARLY') {
					this.group.accountingPeriodFormatted = `${this.group.year}. (Godišnji)`;
				} else if (this.group.accountingPeriod == 'PERIOD') {
					let periodFormatted = '';
					for (const period of this.group.months) {
						periodFormatted += `${this.months[period.month]} ${period.year}, `;
					}
					periodFormatted = periodFormatted.slice(0, -2);
					periodFormatted += '.';
					this.group.accountingPeriodFormatted = periodFormatted;
				}

				switch (this.group.status) {
					case 'DRAFT': {
						this.group.statusFormatted = 'Nacrt';
						break;
					}
					case 'CREATED': {
						this.group.statusFormatted = 'Izdate fakture';
						break;
					}
					case 'SENT': {
						this.group.statusFormatted = 'Izdate fakture i poslati mejlovi';
						break;
					}
					default: {
						this.group.statusFormatted = 'Nepoznato';
					}
				}
			}
		}
	}
};
</script>

<style></style>
