<template>
	<div class="modal modal-user-details">
		<h1 class="text-2xl">Detalji o nalogu</h1>
		<hr />
		<div class="flex flex-row justify-center mt-4" v-if="loading">
			<spinner-component :size="50" />
		</div>
		<div class="modal-content text-lg" v-if="!loading">
			<div class="form-item">
				<div class="flex">
					<h2 for="name">Naziv</h2>
					<span v-if="underEdit" class="ml-1 font-semibold text-red-600">*</span>
				</div>
				<div class="font-semibold text-lg" v-if="!underEdit">{{ name }}</div>
				<input
					v-if="underEdit"
					:disabled="!underEdit"
					type="text"
					id="name"
					v-model="name"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<div class="flex">
					<h2 for="pib">PIB</h2>
				</div>

				<div class="font-semibold text-lg">{{ pib }}</div>
			</div>

			<div class="form-item">
				<div class="flex" v-if="email || underEdit">
					<h2 for="email">E-mail</h2>

					<span v-if="underEdit && user.role" class="ml-1 font-semibold text-red-600">*</span>
				</div>

				<a class="font-semibold text-lg hover:text-primary" v-if="!underEdit" :href="`mailto:${email}`">{{ email }}</a>

				<input
					v-if="underEdit"
					:disabled="!underEdit"
					type="text"
					id="email"
					v-model="email"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<h2 v-if="phoneRaw || underEdit" for="phone">Kontakt telefon</h2>

				<a v-if="!underEdit" :href="`tel:${phoneRaw}`" class="font-semibold text-lg hover:text-primary">
					{{ phoneRaw }}
				</a>

				<vue-phone-number-input
					v-if="underEdit"
					:disabled="!underEdit"
					id="phone"
					color="#005ca9"
					valid-color="#00796b"
					default-country-code="RS"
					v-model="phoneRaw"
					:translations="{
						countrySelectorLabel: 'Država',
						countrySelectorError: 'Greska',
						phoneNumberLabel: 'Broj telefona',
						example: 'Primer:'
					}"
					:no-example="true"
					:preferred-countries="['RS', 'ME', 'BA', 'HR', 'MK', 'HU', 'RO', 'BG']"
					:show-code-on-list="true"
					size="lg"
					:countries-height="25"
					:no-country-selector="false"
					@update="updatePhoneNumber($event)"
				/>
			</div>

			<div class="flex flex-row mt-3">
				<button v-if="!underEdit" class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Izađi</button>
				<button v-if="underEdit" class="w-4/12 bg-red-600 hover:bg-red-800" @click="cancelEdit()">Otkaži</button>
				<button v-if="!underEdit" :disabled="loading" class="flex-grow ml-2" @click="startEdit()">
					Izmeni detalje
				</button>
				<button
					v-if="underEdit"
					:disabled="
						!isDifferent ||
						loading ||
						name == '' ||
						pib == '' ||
						pib.match(/^[0-9]{9}$/) == null ||
						((this.user.role == 'DISTRIBUTOR' || this.user.role == 'PARTNER') && !this.email.match(this.emailRegex)) ||
						(this.email && !this.email.match(this.emailRegex)) ||
						(this.phoneRaw && !this.phone.isValid)
					"
					class="flex-grow ml-2"
					@click="editUser()"
				>
					Potvrdi izmene
				</button>
			</div>
			<div>
				<p
					class="float-right text-base underline text-gray-600 mt-2 text-right cursor-pointer hover:text-primary"
					@click="resetPassword()"
				>
					Resetuj šifru naloga
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import store from '../store/store';

export default {
	props: {
		user: {
			type: Object,
			required: true
		}
	},

	components: {
		VuePhoneNumberInput
	},

	computed: {
		isDifferent() {
			return (
				this.user.name != this.name ||
				this.user.pib != this.pib ||
				(this.user.email && this.user.email != this.email) ||
				(!this.user.email && this.email != '') ||
				(this.user.phone && this.user.phone != this.phone.number) ||
				(!this.user.phone && this.phone.number != null)
			);
		}
	},

	created() {
		// Filling data
		this.name = this.user.name;
		this.pib = this.user.pib;
		if (this.user.email) this.email = this.user.email;
		if (this.user.phone) this.phoneRaw = this.user.phone;

		if (this.user.role) {
			switch (this.user.role) {
				case 'SUPERADMIN':
					this.userType = 'administratoru';
					break;
				case 'ONPREMADMIN':
					this.userType = 'administratoru';
					break;
				case 'DISTRIBUTOR':
					this.userType = 'distributeru';
					break;
				case 'PARTNER':
					this.userType = 'partneru';
					break;
				default:
					this.userType = 'klijentu';
					break;
			}
		} else {
			this.userType = 'klijentu';
		}
	},

	data() {
		return {
			name: '',
			pib: '',
			loading: false,
			email: '',
			phoneRaw: null,
			phone: {
				isValid: null,
				number: null
			},
			userType: null,
			emailRegex:
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			underEdit: false
		};
	},

	methods: {
		updatePhoneNumber($event) {
			this.phone = {
				isValid: $event.isValid,
				number: $event.formattedNumber
			};
		},

		startEdit() {
			this.underEdit = true;
		},

		cancelEdit() {
			this.underEdit = false;
			this.name = this.user.name;
			this.pib = this.user.pib;
			if (this.user.email) this.email = this.user.email;
			else {
				this.email = '';
			}
			if (this.user.phone) this.phoneRaw = this.user.phone;
			else {
				this.phoneRaw = null;
			}
		},

		resetPassword() {
			this.$fire({
				title: 'Resetovanje šifre',
				html: `Da li ste sigurni da želite da resetujete šifru svog naloga?<br><br> <span class="text-sm">Bićete odjavljeni sa platforme, a nova šifra će biti poslata na Vašu e-mail adresu.</span>`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Resetuj šifru',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					let data = {
						id: this.user.id
					};
					if (this.user.alternateId) {
						data.alternateId = +this.user.alternateId;
					}
					// console.log(data);
					axios
						.post(`user/${this.user.id}/reset-password`, data)
						.then(response => {
							if (response.data.message && response.data.message == 'Email not sent') {
								this.$fire({
									title: 'Uspešna akcija',
									html: `Šifra je uspešno resetovana. <br/> <br/> Nova lozinka: <b>${response.data.password}<br/><br/>Sačuvajte novu lozinku!</b>`,
									type: 'success',
									showConfirmButton: true
								});
							} else {
								this.$fire({
									title: 'Uspešna akcija',
									html: `Šifra je uspešno resetovana. Podaci su poslati na sledeću e-mail adresu: <br><br> <span class="font-semibold">${this.user.email}</span>`,
									type: 'success',
									showConfirmButton: true
								});
							}
							this.loading = false;
							axios.post('auth/logout-all', { user: { id: this.user.id } }).then(response => {
								this.$router.push('/logout');
							});
							this.$emit('refresh');
						})
						.catch(error => {
							this.loading = false;
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom resetovanja lozinke.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
		},

		async editUser() {
			this.loading = true;
			try {
				const requestBody = {
					name: this.name,
					email: this.email ? this.email : null
				};

				if (this.phoneRaw && this.phone.isValid) {
					requestBody.phone = this.phone.number;
				} else {
					requestBody.phone = '';
				}

				if (this.user.alternateId) {
					requestBody.alternateId = +this.user.alternateId;
				}

				const response = await axios.post(`user/edit/${this.user.id}`, requestBody);

				this.$emit('close');
				localStorage.setItem('user', JSON.stringify(response.data));
				store.commit('setUser', response.data);
				this.$fire({
					title: 'Uspešna akcija',
					html: `Podaci o nalogu su uspešno izmenjeni`,
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				if (error.response.status == 409) {
					this.$fire({
						title: 'Neispravna e-mail adresa',
						text: 'Uneta e-mail adresa već postoji.',
						type: 'error',
						showConfirmButton: true
					});
				} else {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom izmene podataka.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
					this.$emit('close');
				}
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style></style>
