<template>
	<div class="modal">
		<h1>Potvrda lokalnog vremena</h1>
		<hr class="mb-4" />
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-else>
			<h2>LPFR</h2>
			<h1>
				{{ lpfr.jid }}
				{{ lpfr.jidNickname ? ` (${lpfr.jidNickname})` : '' }}
			</h1>
			<div class="mt-2">
				<h2>Informacije od poslednjeg javljanja LPFR-a</h2>
				<hr class="mb-2" />
			</div>
			<div class="flex flex-row justify-between">
				<div class="w-1/2">
					<h2>Lokalno vreme LPFR-a u trenutku javljanja</h2>
					<h1 v-if="lpfr.lastLocalTime">
						{{ lpfr.lastLocalTime | formatDate }}
					</h1>
					<h1 v-else>Nepoznato</h1>
				</div>
				<div class="w-1/2">
					<h2>Vreme na monitoring platformi u trenutku javljanja</h2>
					<h1 v-if="lpfr.lastUpdate">{{ lpfr.lastUpdate | formatDate }}</h1>
					<h1 v-else>Nepoznato</h1>
				</div>
			</div>
			<div class="flex items-center p-2 bg-primary mt-4 bg-opacity-10 rounded-md">
				<input type="checkbox" id="checkbox" v-model="confirm" class="mr-2" /><label class="text-xs" for="checkbox"
					>Potvrđujem da je lokalno vreme LPFR-a tačno i poklapa se sa vremenom na monitoring platformi.<br />NAPOMENA:
					Razlika između prikazanih vremena ne bi smela biti veća od 3 minuta.</label
				>
			</div>
			<div class="flex flex-row mt-3">
				<button :disabled="loading" class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button class="ml-2 flex-grow-2" :disabled="!confirm" @click="confirmLocalTime">Potvrdi lokalno vreme</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	props: {
		lpfr: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			loading: false,
			confirm: false
		};
	},
	methods: {
		async confirmLocalTime() {
			try {
				this.loading = true;
				await axios.post(`/lpfr/${this.lpfr.id}/confirmLocalTime`, {
					confirmLocalTime: true
				});
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Lokalno vreme je uspešno potvrđeno.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				this.$emit('close');
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom potvrde lokalnog vremena.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style></style>
