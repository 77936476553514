<template>
	<div>
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-show="!loading">
			<div class="button-row">
				<div class="filter-container">
					<div class="flex flex-row flex-nowrap">
						<input
							type="text"
							class="focus:ring-primary focus:border-primary"
							style="width: 420px"
							placeholder="Br. fakture, naziv/PIB obveznika"
							v-on:keyup.enter="search"
							v-model="searchInput"
						/>
						<button title="Pretraži" @click="search()" style="width: 42px">
							<i class="fas fa-search" />
						</button>
						<button title="Poništi filter" class="cancel-filter-button" @click="cancelFilter()" style="width: 42px">
							<i class="fas fa-times" />
						</button>
					</div>
				</div>
			</div>
			<vuetable
				ref="vuetable"
				:api-mode="false"
				:fields="fields"
				:css="css.table"
				:per-page="perPage"
				noDataTemplate="Nisu pronađene fakture"
				:data-manager="dataManager"
				pagination-path="pagination"
				@vuetable:pagination-data="onPaginationData"
				@vuetable:row-clicked="onRowClicked"
				@vuetable:loaded="onLoaded"
				class="invoices-table"
				track-by="id"
			>
				<div class="slot" slot="obligor-slot" slot-scope="props">
					<span :title="props.rowData.obligor.name">{{ props.rowData.obligorFormatted }}</span>
				</div>
				<div class="slot" slot="total-slot" slot-scope="props">
					{{ props.rowData.totalFormatted }}
				</div>
				<div class="slot" slot="status-slot" slot-scope="props">
					{{ props.rowData.statusFormatted }}
				</div>
				<div class="slot" slot="email-slot" slot-scope="props">
					{{ props.rowData.mailSent ? 'Poslat' : 'Nije poslat' }}
				</div>
				<div class="slot" slot="date-of-issue-slot" slot-scope="props">
					{{ props.rowData.dateOfIssue | formatDateNoTime }}
				</div>
				<div class="slot" slot="accounting-period-slot" slot-scope="props">
					{{ props.rowData.accountingPeriodFormatted }}
				</div>
				<div class="slot" slot="licences-slot" slot-scope="props">
					{{ props.rowData.itemsQuantity }}
				</div>
				<div class="slot flex flex-row justify-center" slot="actions-slot" slot-scope="props">
					<button
						@click="downloadPdf(props.rowData)"
						class="btn btn-sm ml-1"
						title="Preuzmi PDF fakture"
						style="width: 26px; height: 26px; padding: 0px"
						:id="`pdf-btn-${props.rowData.id}`"
					>
						<i class="fas fa-file-pdf" :id="`pdf-icon-${props.rowData.id}`"></i>
					</button>
					<button
						v-if="props.rowData.status == 'UNPAID'"
						@click="setInvoiceStatus(props.rowData, 'PAID')"
						class="btn btn-sm ml-1"
						title="Obeleži kao plaćenu"
						style="width: 26px; height: 26px; padding: 0px"
					>
						<i class="fas fa-check"></i>
					</button>
					<button
						v-if="props.rowData.status != 'CANCELLED'"
						@click="setInvoiceStatus(props.rowData, 'CANCELLED')"
						class="btn btn-sm bg-red-600 ml-1"
						title="Storniraj fakturu"
						style="width: 26px; height: 26px; padding: 0px"
					>
						<i class="fas fa-ban"></i>
					</button>
					<button
						v-if="props.rowData.archived && props.rowData.status == 'CANCELLED'"
						style="height: 26px; width: 26px; padding: 0"
						class="reset-lpfr-btn ml-1"
						title="Vrati fakturu iz arhive"
						@click="unarchiveLpfr(props.rowData.id)"
					>
						<i class="fas fa-undo"></i>
					</button>
				</div>
			</vuetable>
			<div class="flex flex-row justify-end items-center">
				<div class="mr-4">{{ numOfDisplayed }} / {{ numOfTotal }}</div>
				<div class="flex flex-row per-page-div">
					<input type="radio" id="10-per-page-monitor" :value="10" v-model="perPage" /><label
						:class="{ active: perPage == 10 }"
						for="10-per-page-monitor"
						>10</label
					>
					<input type="radio" id="20-per-page-monitor" :value="20" v-model="perPage" /><label
						:class="{ active: perPage == 20 }"
						for="20-per-page-monitor"
						>20</label
					>
					<input type="radio" id="50-per-page-monitor" :value="50" v-model="perPage" /><label
						:class="{ active: perPage == 50 }"
						for="50-per-page-monitor"
						>50</label
					>
					<input type="radio" id="100-per-page-monitor" :value="100" v-model="perPage" /><label
						:class="{ active: perPage == 100 }"
						for="100-per-page-monitor"
						>100</label
					>
				</div>
				<vuetable-pagination ref="pagination" class="pull-right" @vuetable-pagination:change-page="onChangePage">
				</vuetable-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from '../components/VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';

export default {
	components: {
		Vuetable,
		VuetablePagination
	},
	computed: {
		...mapState({
			user: state => {
				return {
					id: state.user.user.id,
					name: state.user.user.name,
					phone: state.user.user.phone,
					email: state.user.user.email,
					role: state.user.user.role,
					pib: state.user.user.pib
				};
			}
		})
	},
	data() {
		return {
			fields: [],
			localData: [],
			originalData: [],
			loading: false,
			css: VuetableCssConfig,
			perPage: 20,
			filterStatus: null,
			months: [
				'Januar',
				'Februar',
				'Mart',
				'April',
				'Maj',
				'Jun',
				'Jul',
				'Avgust',
				'Septembar',
				'Oktobar',
				'Novembar',
				'Decembar'
			],
			searchInput: '',
			searchFor: '',
			numOfDisplayed: 0,
			numOfTotal: 0
		};
	},
	async created() {
		try {
			this.loading = true;
			this.setupTableFields();
			await this.loadData();
		} catch (error) {
			console.log(error);
		} finally {
			this.loading = false;
		}
	},
	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		},
		filterStatus() {
			if (this.filterStatus) {
				this.localData = this.originalData.filter(invoice => invoice.status == this.filterStatus);
			} else {
				return;
			}
		}
	},

	methods: {
		search() {
			this.searchFor = this.searchInput;
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},
		cancelFilter() {
			this.searchFor = '';
			this.searchInput = '';
			this.filterStatus = null;
			this.localData = this.originalData;
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},
		onLoaded() {
			this.numOfDisplayed = this.$refs.vuetable.countTableData;
			this.numOfTotal = this.$refs.pagination.tablePagination.total;
		},
		unarchiveLpfr(id) {
			this.$fire({
				title: 'Vraćanje fakture iz arhive',
				html: `Da li ste sigurni da želite da vratite fakturu iz arhive?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Vrati fakturu',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`finances/archive-invoice/${id}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								html: `Faktura je uspešno vraćena.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							this.loadingFinishedLicences();
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom vraćanja fakture.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});

			event.stopPropagation();
		},
		async setInvoiceStatus(row, status) {
			event.stopPropagation();
			let text = '';
			if (status == 'CANCELLED') {
				text = 'Da li ste sigurni da želite da stornirate fakturu?';
			} else if (status == 'PAID') {
				text = 'Da li ste sigurni da želite da obeležite fakturu kao plaćenu?';
			}
			this.$fire({
				title: 'Promena statusa fakture',
				text,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Promeni status fakture',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`finances/invoice/${row.id}/status/${status}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								text: `Uspešno promenjen status fakture.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							console.log(error);
							this.$fire({
								title: 'Greška',
								text: `Greška prilikom promene statusa fakture.`,
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
							this.loading = false;
						});
				}
			});
		},

		async sendEmails(row) {
			event.stopPropagation();
			this.$fire({
				title: 'Slanje mejla',
				html: `Da li ste sigurni da želite da pošaljete mejl sa pdf-om fakture na imejl adrese obveznika?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Pošalji mejl',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`finances/invoice-emails/invoice/${row.id}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								text: `Uspešno poslat mejl.`,
								type: 'success',
								showConfirmButton: true
							});
							this.refreshData();
						})
						.catch(error => {
							console.error(error);
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom slanja mejla.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});
		},

		async refreshData() {
			await this.loadData();
		},

		async downloadPdf(row) {
			event.stopPropagation();
			const pdfIconElement = document.getElementById(`pdf-icon-${row.id}`);
			const pdfBtnElement = document.getElementById(`pdf-btn-${row.id}`);

			pdfIconElement.classList.add('fa-spinner');
			pdfIconElement.classList.add('animation-rotate');
			pdfIconElement.classList.remove('fa-file-pdf');
			pdfBtnElement.setAttribute('disabled', 'true');

			try {
				const response = await axios.get(`finances/generate-invoice-pdf/${row.id}`, { responseType: 'blob' });
				// console.log(response);

				// console.log(typeof response.data);
				const blob = new Blob([response.data], {
					type: 'application/pdf'
				});
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${row.number}.pdf`);
				document.body.appendChild(link);
				link.click();
				setTimeout(function () {
					// For Firefox it is necessary to delay revoking the ObjectURL
					window.URL.revokeObjectURL(url);
				}, 100);
			} catch (error) {
				console.log(error);
			} finally {
				pdfIconElement.classList.remove('fa-spinner');
				pdfIconElement.classList.remove('animation-rotate');
				pdfIconElement.classList.add('fa-file-pdf');
				pdfBtnElement.removeAttribute('disabled');
			}
		},

		onRowClicked(row) {
			this.$router.push({
				name: 'invoice-details',
				params: {
					id: row.data.id
				}
			});
		},
		async loadData() {
			this.loading = true;
			const response = await axios.get('finances/invoices/archived');
			this.formatData(response.data);
			if (this.filterStatus) {
				this.localData = this.originalData.filter(invoice => invoice.status == this.filterStatus);
			} else {
				return;
			}
			this.loading = false;
		},
		formatData(data) {
			const moneyFormatter = new Intl.NumberFormat('de-DE', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 4
			});

			const formattedData = data.map(row => {
				row.totalFormatted = moneyFormatter.format(row.total) + ' RSD';
				switch (row.status) {
					case 'UNPAID':
						row.statusFormatted = 'Nije plaćena';
						break;
					case 'PAID':
						row.statusFormatted = 'Plaćena';
						break;
					case 'CANCELLED':
						row.statusFormatted = 'Stornirana';
						break;
					default:
						row.statusFormattted = 'Nepoznato';
						break;
				}
				switch (row.accountingPeriod) {
					case 'YEARLY':
						row.accountingPeriodFormatted = `${row.year}. (Godišnji)`;
						row.accountingPeriodSort = `${row.year}-12`;
						break;
					case 'MONTHLY':
						row.accountingPeriodFormatted = `${this.months[row.month]} ${row.year}.`;
						row.accountingPeriodSort = `${row.year}-${row.month}`;
						break;
					default:
						row.accountingPeriodFormatted = 'Nepoznato';
						row.accountingPeriodSort = null;
						break;
				}

				let total = 0;
				for (const item of row.items) {
					if (item.type == 'LICENCE') {
						total += item.quantity;
					}
				}

				row.itemsQuantity = total;

				let role = '';
				switch (row.obligor.role) {
					case 'DISTRIBUTOR':
						role = 'Distributer';
						break;
					case 'PARTNER':
						role = 'Partner';
						break;
					case 'CLIENT':
						role = 'Klijent';
						break;
					default:
						role = 'Nepoznato';
						break;
				}

				if (row.obligor.name.length > 20) {
					row.obligorFormatted =
						row.obligor.name.substring(0, 20) + '...' + ` (${row.obligor.role}), ${row.obligor.pib}`;
				} else {
					row.obligorFormatted = row.obligor.name + ` (${role}), ${row.obligor.pib}`;
				}
				return row;
			});

			this.localData = formattedData;
			this.originalData = formattedData;
		},
		setupTableFields() {
			this.fields = [
				{
					name: 'number',
					title: 'Br. fakture',
					sortField: 'number'
				},
				{
					name: 'obligor-slot',
					title: 'Obveznik',
					sortField: 'obligor.name'
				},
				{
					name: 'accounting-period-slot',
					title: 'Obrаčunski period',
					sortField: 'accountingPeriodSort'
				},
				{
					name: 'licences-slot',
					title: 'Br. licenci',
					sortField: 'itemsQuantity'
				},
				{
					name: 'total-slot',
					title: 'Ukupno (bez PDV)',
					sortField: 'total'
				},

				{
					name: 'date-of-issue-slot',
					title: 'Datum izdavanja',
					sortField: 'dateOfIssue'
				},
				{
					name: 'status-slot',
					title: 'Status',
					sortField: 'status'
				},
				{
					name: 'email-slot',
					title: 'E-mail fakture',
					sortField: 'mailSent'
				},

				{
					name: 'actions-slot',
					title: 'Akcije',
					sortable: false
				}
			];
		},

		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.obligor.pib.search(txt) >= 0 || item.obligor.name.search(txt) >= 0 || item.number.search(txt) >= 0
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		}
	}
};
</script>

<style lang="scss">
.invoices-table tbody tr:hover {
	background-color: var(--primary-color-hover);
	cursor: pointer;
}
</style>

<style lang="scss" scoped>
.animation-rotate {
	animation: rotation 1.5s linear infinite;
}
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
</style>
