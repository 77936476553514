<template>
	<div class="modal">
		<h1>Postavljanje TaxCore ZIP fajla</h1>
		<hr class="mb-4" />
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-else>
			<h2>Proizvod</h2>
			<h1>
				{{ lpfr.jid }}
				{{ lpfr.jidNickname ? ` (${lpfr.jidNickname})` : '' }}
			</h1>
			<h2>Fajl</h2>
			<label
				class="file-upload-label mt-1"
				for="taxCoreFile"
				v-if="!file"
				@dragover.prevent
				@dragleave.prevent
				@drop="onDrop"
				>Izaberi ili prevuci .zip fajl</label
			>
			<div v-else class="flex flex-row items-center">
				<h1>{{ fileName }}</h1>
				<button class="ml-4" style="height: 28px; width: 28px; padding: 0" @click="removeFile()">
					<i class="fas fa-times"></i>
				</button>
			</div>

			<input type="file" name="taxCoreFile" id="taxCoreFile" @change="onFileChange" style="display: none" />
		</div>
		<div class="flex flex-row mt-3">
			<button :disabled="loading" class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
			<button class="ml-2 flex-grow-2" :disabled="!file" @click="uploadFile">Postavi fajl</button>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	data() {
		return {
			file: null,
			loading: false
		};
	},
	props: {
		lpfr: {
			type: Object,
			required: true
		}
	},
	methods: {
		onDrop(e) {
			e.stopPropagation();
			e.preventDefault();
			var file = e.dataTransfer.files[0];

			this.setFile(file);
		},

		onFileChange(e) {
			const file = e.target.files[0];

			this.setFile(file);
		},

		setFile(file) {
			if (this.validateInput(file.name)) {
				this.file = file;
			} else {
				this.$fire({
					title: 'Neispravan fajl',
					text: 'Izabrani fajl je neispravan. Molimo izaberite validan .zip fajl.',
					type: 'error',
					showConfirmButton: true
				});
			}
		},

		validateInput(name) {
			let allowedExtensions = ['zip'];
			let extension = name.substring(name.lastIndexOf('.') + 1, name.length);
			return allowedExtensions.indexOf(extension) > -1;
		},

		removeFile() {
			this.file = null;
		},

		//get base64 from file
		getBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
					resolve(base64String);
				};
				reader.onerror = function (error) {
					reject(error);
				};
			});
		},

		async uploadFile() {
			try {
				const fileBase64 = await this.getBase64(this.file);
				await axios.post(`/lpfr/${this.lpfr.id}/tax-core-file`, {
					content: fileBase64
				});
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Fajl je uspešno postavljen.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				this.$emit('close');
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom postavke fajla.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			}
		}
	},

	computed: {
		fileName() {
			return this.file ? this.file.name : '';
		}
	}
};
</script>

<style lang="scss" scoped>
.file-upload-label {
	display: block;
	margin-bottom: 16px;
	cursor: pointer;
	border: 1px solid #ccc;
	padding: 16px 12px;
	border-radius: 4px;
	transition: all 0.2s ease-in-out;
}
</style>
