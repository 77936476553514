<template>
	<div class="modal modal-add-client">
		<h1 class="text-2xl">Produžavanje licenci</h1>
		<hr />
		<div class="modal-content text-lg">
			<div class="flex flex-row">
				<div class="left-column">
					<h2 class="mb-4">Odabrani proizvod:</h2>
					<ul class="overflow-y-auto max-h-80 lpfr-list p-2">
						<li class="mb-4" v-for="row in rows" :key="row.id">
							<span class="font-semibold">{{ row.jid }}</span>
							<span v-if="row.jidNickname"> ({{ row.jidNickname }})</span>
							<p class="text-sm">Klijent: {{ row.client.name }}</p>
							<p>
								Licenca:<span
									class="font-bold text-green-600"
									v-if="new Date(row.expirationDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)"
								>
									Aktivna</span
								><span class="font-bold text-red-600" v-else> Neaktivna</span>
							</p>
							<p v-if="new Date(row.expirationDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)">
								Trenutni datum isteka:
								<span class="font-bold">{{ row.expirationDate | formatDateNoTime }}</span>
							</p>
							<p v-if="monthsToAdd">
								Nov datum isteka:
								<span class="font-bold">{{ computeNewExpiryDate(row, monthsToAdd) | formatDateNoTime }}</span>
							</p>
							<p v-if="yearlyLicence">
								Nov datum isteka:
								<span class="font-bold">{{ computeYearlyExpiryDate() | formatDateNoTime }} (Godišnja licenca)</span>
							</p>
						</li>
					</ul>
				</div>
				<div class="right-column ml-6">
					<h2 class="mb-4">Produžavanje licenci</h2>
					<hr class="mb-2" />
					<h3>Mesečno produženje:</h3>
					<p class="mb-1 text-sm text-gray-400">Naplaćuje se na kraju svakog meseca</p>
					<div class="mb-4 text-red-600 text-sm" v-if="!monthsBetweenValid(1)">
						<p>Izabrane licence nije moguće produžiti.</p>
						<p>(Maksimalno produženje je 24 meseca od tekućeg meseca)</p>
					</div>

					<div v-else>
						<div class="mb-4">
							<v-select
								placeholder="Odaberite broj meseci"
								class="renew-licence-select-style select-style mb-1"
								v-model="selectedMonthlyOption"
								:options="monthlyOptions"
							></v-select>
						</div>
					</div>
					<div>
						<h3>Godišnje produženje:</h3>
						<p class="mb-1 text-sm text-gray-400">Naplaćuje se na početku perioda važenja godišnje licence</p>
					</div>
					<ul class="renew-licence-length">
						<li
							v-if="monthsBetweenValid(12) && allLicencesExpireThisMonthOrEarlier"
							:class="{ 'li-selected': yearlyLicence }"
						>
							<input type="radio" name="months-to-add" id="yearly-renew" :value="true" v-model="yearlyLicence" /><label
								for="yearly-renew"
								>{{ yearlyLicence ? 'GODIŠNJA LICENCA' : 'Izaberi godišnju licencu' }}</label
							>
						</li>
						<div class="text-red-600 text-sm" v-else>
							<p>
								Nije moguće podesiti godišnju licencu za
								{{ this.lpfrs.length === 1 ? 'izabrani proizvod' : 'izabrane proizvode' }}.
							</p>
							<p>
								(Godišnje licence za naredni period se podešavaju poslednjeg meseca pred istek licence, ili nakon što
								licenca istekne.)
							</p>
						</div>
					</ul>
				</div>
			</div>
			<div class="flex items-center p-2 bg-primary mt-4 bg-opacity-10 rounded-md">
				<input type="checkbox" id="checkbox" v-model="confirm" class="mr-2" /><label class="text-xs" for="checkbox"
					>Saglasan sam da se izvrši produženje odabranih licenci iz liste. Nakon produženja licenci, svaki proizvod će
					povući novu licencu i potvrditi validnost.</label
				>
			</div>
			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button
					:disabled="!confirm || loading || (!monthsToAdd && !yearlyLicence)"
					class="flex-grow ml-2"
					@click="renewLicences"
				>
					Produži licence
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';

export default {
	data() {
		return {
			confirm: false,
			loading: false,
			monthsToAdd: null,
			lpfrs: [],
			monthlyOptions: [],
			selectedMonthlyOption: null,
			yearlyLicence: false
		};
	},

	props: {
		rows: {
			type: Array,
			required: true
		}
	},

	created() {
		this.lpfrs = this.rows;

		this.generateValidMonthlyOptions();
	},

	computed: {
		activeYearlyLicencesIncluded() {
			return this.lpfrs.some(lpfr => {
				const yStartDate = new Date(lpfr.yearlyLicenceStartDate);
				const yEndDate = new Date(lpfr.yearlyLicenceEndDate);

				return yStartDate && yEndDate;
			});
		},

		yearlyLicencesExpireThisMonthOrEarlier() {
			return this.lpfrs.every(lpfr => {
				const yStartDate = new Date(lpfr.yearlyLicenceStartDate);
				const yEndDate = new Date(lpfr.yearlyLicenceEndDate);
				const today = new Date();

				if (yStartDate && yEndDate) {
					return (
						yEndDate.getFullYear() < today.getFullYear() ||
						(yEndDate.getMonth() <= today.getMonth() && yEndDate.getFullYear() <= today.getFullYear())
					);
				}
			});
		},

		allLicencesExpireThisMonthOrEarlier() {
			return this.lpfrs.every(lpfr => {
				const endDate = new Date(lpfr.expirationDate);
				const today = new Date();

				if (endDate) {
					return (
						endDate.getFullYear() < today.getFullYear() ||
						(endDate.getMonth() <= today.getMonth() && endDate.getFullYear() <= today.getFullYear())
					);
				}
			});
		},

		lastExpirationDate() {
			let max = new Date();

			for (const lpfr of this.lpfrs) {
				const expirationDate = new Date(lpfr.expirationDate);
				if (expirationDate > max) {
					max = expirationDate;
				}
			}

			return max;
		},
		...mapState({
			user: state => {
				return {
					id: state.user.user.id,
					name: state.user.user.name,
					phone: state.user.user.phone,
					email: state.user.user.email,
					role: state.user.user.role,
					pib: state.user.user.pib,
					alternateId: state.user.user.alternateId ? state.user.user.alternateId : null
				};
			}
		})
	},

	watch: {
		selectedMonthlyOption() {
			if (this.selectedMonthlyOption) {
				this.yearlyLicence = false;
				this.monthsToAdd = this.selectedMonthlyOption.value;
			}
		},

		yearlyLicence() {
			if (this.yearlyLicence) {
				this.monthsToAdd = null;
				this.selectedMonthlyOption = null;
			}
		}
	},

	methods: {
		async renewLicences() {
			this.loading = true;
			try {
				// get list of jids
				const jids = this.lpfrs.map(lpfr => lpfr.jid);
				let data = {
					jids,
					alternateLoginId: this.user.alternateId ? this.user.alternateId : null
				};

				if (this.yearlyLicence) {
					data.yearlyLicence = true;
					data.monthsToAdd = 12;
				} else {
					data.monthsToAdd = +this.monthsToAdd;
				}
				await axios.post(`licence/renew`, data);
				this.$emit('refresh');
				this.$emit('clearSelection');
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Licence se uspešno produžene.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
			} catch (error) {
				// console.log(error);
				this.$emit('close');
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom produžavanja licenci.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		},

		generateValidMonthlyOptions() {
			const options = [];
			for (let i = 1; i <= 12; i++) {
				if (this.monthsBetweenValid(i)) {
					options.push({
						value: i,
						label: i + (i === 1 ? ' mesec' : i < 5 ? ' meseca' : ' meseci'),
						type: 'monthly'
					});
				}
			}
			this.monthlyOptions = options;
		},

		monthsBetweenValid(monthsToAdd) {
			const date1 = new Date();
			const date2 = new Date(this.lastExpirationDate);

			date1.setHours(0, 0, 0, 0);
			date1.setDate(1);
			date2.setHours(0, 0, 0, 0);
			date2.setDate(1);
			date2.setMonth(date2.getMonth() + monthsToAdd);
			const diff = date2.getTime() - date1.getTime();
			const diffInMonths = diff / 1000 / 60 / 60 / 24 / 30;

			if (diffInMonths > 25) return false;
			return true;
		},

		computeNewExpiryDate(row, monthsToAdd) {
			const expirationDate = new Date(row.expirationDate);
			let date = new Date(Math.max(expirationDate, new Date()));

			monthsToAdd++;

			if (new Date(row.expirationDate) < new Date().setHours(0, 0, 0, 0)) {
				const today = new Date();
				const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
				const daysUntilEndOfMonth = endOfMonth.getDate() - date.getDate();
				if (daysUntilEndOfMonth >= 4) monthsToAdd--;
			}

			date.setMonth(date.getMonth() + +monthsToAdd, 0);

			return date;
		},
		computeYearlyExpiryDate() {
			const today = new Date();
			const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
			// console.log(nextMonth);
			const yearlyLicenceEndDate = new Date(nextMonth);
			yearlyLicenceEndDate.setMonth(yearlyLicenceEndDate.getMonth() + 12, 1);
			// console.log(yearlyLicenceEndDate);
			yearlyLicenceEndDate.setDate(yearlyLicenceEndDate.getDate() - 1);
			// console.log(yearlyLicenceEndDate);
			return yearlyLicenceEndDate;
		}
	}
};
</script>

<style lang="scss">
.renew-licence-select-style {
	min-width: 280px !important;
	font-size: 1rem !important;
}
.renew-licence-select-style .vs__dropdown-menu {
	max-height: 250px !important;
	font-size: 1rem !important;
}
</style>

<style lang="scss" scoped>
h1 {
	font-size: 1.5rem !important;
	margin-bottom: 12px;
}

h2 {
	color: rgb(90, 90, 90) !important;
}

.left-column {
	width: 60%;
}

.right-column {
	width: 40%;
}

.lpfr-list {
	border: 1px solid rgb(230, 230, 230);
}

.renew-licence-length {
	li {
		text-align: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 4px;
		// padding-left: 8px;
		width: 100%;
		color: white;
		margin-bottom: 12px;
		background-color: rgb(139, 172, 199);
		border-radius: 4px;
	}
	li input {
		display: none;
	}
	li:hover {
		background-color: rgb(85, 142, 175);
	}
	label {
		width: 100%;
		// padding-left: 6px;
		font-weight: 600;
	}

	.li-selected {
		background-color: #08a570;
	}

	.li-selected:hover {
		background-color: #08a570;
	}
}
</style>
