<template>
	<div class="finances-container">
		<div class="content">
			<h1 class="font-semibold text-3xl">Sistem za fakturisanje</h1>
			<div class="flex flex-col items-center">
				<div class="flex flex-row justify-between finances-menu mt-4">
					<router-link to="/finances/dashboard">Dashboard</router-link>
					<router-link to="/finances/clients">Lista korisnika</router-link>
					<router-link v-if="user.role == 'SUPERADMIN'" to="/finances/ignored-lpfrs">Izuzeti LPFR-ovi</router-link>
					<router-link to="/finances/pricelists">Cenovnici</router-link>
					<router-link to="/finances/invoices">Fakture</router-link>
					<router-link to="/finances/drafts">Nacrti</router-link>
					<router-link to="/finances/exchange-rates">Kursna lista</router-link>
					<router-link to="/finances/settings">Globalna podešavanja</router-link>
					<router-link to="/finances/archived-invoices">Arhiva faktura</router-link>
				</div>
				<hr class="finances-menu mb-6" />
			</div>

			<router-view />
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	computed: {
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		})
	}
};
</script>

<style lang="scss" scoped>
.router-link-exact-active {
	color: #2563eb !important;
}

.finances-menu a:hover {
	color: #60a5fa !important;
}

.finances-menu a {
	padding: 6px;
}

.finances-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.content {
	margin: 16px 12px 0 12px;
}

.finances-menu {
	width: 1080px;
}

.content {
	width: 100%;
	padding: 16px;
}
</style>
