<template>
	<div class="header-container">
		<div>
			<modal :adaptive="true" height="auto" name="version-details">
				<modal-version-details @close="$modal.hide('version-details')"></modal-version-details>
			</modal>
		</div>
		<div>
			<modal :adaptive="true" height="auto" name="init-commands">
				<modal-upload-init-commands-file @close="$modal.hide('init-commands')"></modal-upload-init-commands-file>
			</modal>
		</div>
		<div>
			<modal :adaptive="true" height="auto" name="reports-modal">
				<modal-generate-report @close="$modal.hide('reports-modal')"></modal-generate-report>
			</modal>
		</div>
		<div>
			<modal :adaptive="true" height="auto" name="upload-update-details">
				<modal-upload-update-details @close="$modal.hide('upload-update-details')"></modal-upload-update-details>
			</modal>
		</div>
		<div>
			<modal :adaptive="true" height="auto" name="logged-user-details">
				<modal-edit-logged-user
					@close="closeModal('logged-user-details')"
					@refresh="refreshData"
					:user="user"
				></modal-edit-logged-user>
			</modal>
		</div>

		<div id="nav" :class="{ paused: refreshingStopped }">
			<div class="nav-left">
				<div class="flex flex-row items-center">
					<h1 class="text-2xl font-bold">{{ user.name }}</h1>

					<div
						class="flex flex-col justify-center ml-3"
						v-if="(user.role == 'SUPERADMIN' || user.role == 'ONPREMADMIN') && !this.$route.path.includes('/finances')"
					>
						<button
							style="padding: 3px 6px"
							v-if="!refreshingStopped"
							class="text-yellow-300"
							@click="stopRefreshing()"
						>
							Pauziraj ažuriranje
						</button>
						<button style="padding: 3px 6px" v-else class="text-red-600 font-bold" @click="startRefreshing()">
							Nastavi ažuriranje
						</button>
					</div>
				</div>
				<h3 class="text-lg">{{ user.email }}</h3>
				<h2 class="text-xl font-light">{{ role }}</h2>
			</div>
			<div class="nav-right flex flex-row mt-2 align-center">
				<div
					class="nav-right-element"
					v-if="
						((!this.$route.path.includes('/finances') && this.user.role == 'SUPERADMIN') ||
							(!this.$route.path.includes('/finances') && this.user.role == 'ONPREMADMIN')) &&
						this.user.email != 'budimir1991@gmail.com'
					"
				>
					<router-link class="route-link" to="/finances/dashboard">Sistem za fakturisanje</router-link>
				</div>
				<div class="nav-right-element" v-if="this.$route.path != '/'">
					<router-link class="route-link" to="/">Monitoring platforma</router-link>
				</div>
				<div class="relative nav-right-element" v-click-outside="closeActionsDropdown">
					<button @click="toggleActionsDropdown">Akcije</button>
					<transition name="fade">
						<div v-if="actionsDropdownActive" class="flex flex-col absolute dropdown-content actions-dropdown">
							<a @click="openUserDetails">Izmena detalja naloga</a>
							<a v-if="user.role == 'SUPERADMIN' || user.role == 'ONPREMADMIN'" @click="openVersionsModal"
								>Upravljanje verzijama</a
							>
							<a v-if="downloadUrl" :href="downloadUrl" target="_blank" rel="noopener"
								>Preuzimanje eFiskalizator 2022 aplikacije</a
							>
							<a @click="openInitCommandsModal">Komande za inicijalna podešavanja</a>
							<a v-if="user.role == 'ONPREMADMIN'" @click="openUploadUpdateDetailsModal"
								>Podešavanja za update lpfr softvera</a
							>
							<a @click="openReportsModal">Generisanje izveštaja o aktivacijama</a>
						</div>
					</transition>
				</div>
				<div
					class="relative nav-right-element"
					v-if="monitorGuideUrl || lpfrInstallGuideUrl"
					v-click-outside="closeManualsDropdown"
				>
					<button @click="toggleManualsDropdown">Uputstva</button>
					<transition name="fade">
						<div v-if="manualsDropdownActive" class="flex flex-col absolute dropdown-content">
							<a v-if="monitorGuideUrl" :href="monitorGuideUrl" target="_blank" rel="noopener"
								>Uputstvo za korišćenje Monitor aplikacije</a
							>
							<a v-if="logExplanationsUrl" :href="logExplanationsUrl" target="_blank" rel="noopener"
								>eFiskalizator 2022 - Mogući statusi i rešenja</a
							>

							<a v-if="lpfrInstallGuideUrl" :href="lpfrInstallGuideUrl" target="_blank" rel="noopener"
								>Uputstvo za instalaciju LPFR</a
							>
							<a v-if="manualInvoiceGuideUrl" :href="manualInvoiceGuideUrl" target="_blank" rel="noopener"
								>Uputstvo za ručno slanje računa i zamenu kartica</a
							>
							<a v-if="endOfShiftStatisticsUrl" :href="endOfShiftStatisticsUrl" target="_blank" rel="noopener"
								>Dobijanje statistike prometa po smenama</a
							>
							<a v-if="productionGuideUrl" :href="productionGuideUrl" target="_blank" rel="noopener"
								>Uputstvo za puštanje produkcije</a
							>
						</div>
					</transition>
				</div>

				<div class="nav-right-element">
					<button class="logout-button" @click="logout">
						<i class="fas fa-sign-out-alt" style="margin-right: 6px"></i><span>Odjavi se</span>
					</button>
				</div>
			</div>
		</div>
		<hr class="border-b-2 opacity-25" style="border-color: purple" />
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import vClickOutside from 'v-click-outside';
import axios from '@/axios/axios.js';
import ModalVersionDetails from './ModalVersionDetails.vue';
import ModalEditLoggedUser from './ModalEditLoggedUser.vue';
import ModalUploadInitCommandsFile from './ModalUploadInitCommandsFile.vue';
import ModalUploadUpdateDetails from './ModalUploadUpdateDetails.vue';
import ModalGenerateReport from './ModalGenerateReport.vue';

export default {
	components: {
		ModalVersionDetails,
		ModalEditLoggedUser,
		ModalUploadInitCommandsFile,
		ModalUploadUpdateDetails,
		ModalGenerateReport
	},
	directives: {
		clickOutside: vClickOutside.directive
	},

	methods: {
		...mapMutations(['stopRefreshing', 'startRefreshing']),
		toggleManualsDropdown() {
			this.manualsDropdownActive = !this.manualsDropdownActive;
		},
		closeManualsDropdown() {
			this.manualsDropdownActive = false;
		},
		toggleActionsDropdown() {
			this.actionsDropdownActive = !this.actionsDropdownActive;
		},
		closeActionsDropdown() {
			this.actionsDropdownActive = false;
		},
		openInitCommandsModal() {
			this.$modal.show('init-commands');
		},
		openUploadUpdateDetailsModal() {
			this.$modal.show('upload-update-details');
		},
		openVersionsModal() {
			this.$modal.show('version-details');
		},
		openUserDetails() {
			this.$modal.show('logged-user-details');
		},
		refreshData() {},
		closeModal(name) {
			this.$modal.hide(name);
		},
		openReportsModal() {
			this.$modal.show('reports-modal');
		},
		logout() {
			this.$fire({
				title: 'Odjava',
				html: `Da li ste sigurni da želite da se odjavite sa Monitor eFiskalizator platforme?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Odjavi se',
				confirmButtonColor: '#EF4444',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.$router.push('/logout');
				}
			});
		}
	},

	async created() {
		const guideResponse = await axios.get('guides');
		const guides = guideResponse.data;
		const monitorGuide = guides.find(guide => guide.type === 'MONITOR_APP_GUIDE');
		const lpfrInstallGuide = guides.find(guide => guide.type === 'LPFR_INSTALL_GUIDE');
		const manualInvoiceGuide = guides.find(guide => guide.type === 'MANUAL_INVOICE_GUIDE');
		const productionGuide = guides.find(guide => guide.type === 'PRODUCTION_GUIDE');
		const logExplanations = guides.find(guide => guide.type === 'LOG_EXPLANATIONS');

		const endOfShiftStatistics = guides.find(guide => guide.type === 'END_OF_SHIFT_STATISTICS');

		if (monitorGuide) this.monitorGuideUrl = monitorGuide.link;

		if (lpfrInstallGuide) this.lpfrInstallGuideUrl = lpfrInstallGuide.link;

		if (manualInvoiceGuide) this.manualInvoiceGuideUrl = manualInvoiceGuide.link;

		if (productionGuide) this.productionGuideUrl = productionGuide.link;

		if (logExplanations) this.logExplanationsUrl = logExplanations.link;

		if (endOfShiftStatistics) this.endOfShiftStatisticsUrl = endOfShiftStatistics.link;

		const downloadUrlResponse = await axios.get('download-link');
		if (downloadUrlResponse.data) {
			this.downloadUrl = downloadUrlResponse.data;
		}
	},
	computed: {
		...mapState({
			user: state => {
				return {
					id: state.user.user.id,
					name: state.user.user.name,
					phone: state.user.user.phone,
					email: state.user.user.email,
					role: state.user.user.role,
					pib: state.user.user.pib,
					alternateId: state.user.user.alternateId ? state.user.user.alternateId : null
				};
			},
			refreshingStopped: state => {
				return state.refreshingStopped;
			}
		}),

		role() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'SUPER ADMINISTRATOR';
				case 'ONPREMADMIN':
					return 'ADMINISTRATOR - ON PREMISE SYSTEM';
				case 'DISTRIBUTOR':
					return 'DISTRIBUTER';
				case 'PARTNER':
					return 'PARTNER';
				default:
					return 'Nepoznato';
			}
		}
	},

	data() {
		return {
			manualsDropdownActive: false,
			actionsDropdownActive: false,
			monitorGuideUrl: null,
			lpfrInstallGuideUrl: null,
			manualInvoiceGuideUrl: null,
			productionGuideUrl: null,
			downloadUrl: null,
			endOfShiftStatisticsUrl: null,
			logExplanationsUrl: null
		};
	}
};
</script>

<style lang="scss" scoped>
button {
	background-color: unset;
}

.paused {
	background: linear-gradient(97deg, rgb(51, 50, 59) 0%, rgb(36, 42, 65) 41%, rgb(19, 27, 41) 100%) !important;
}

button:hover,
.route-link:hover {
	background-color: unset;
	background-color: rgba(250, 250, 250, 0.315);
	border-radius: unset;
	border-radius: 12px;
}
.route-link {
	padding: 9px;
	display: block;
}

.nav-right a {
	color: white;
}

.nav-right-element {
	margin-left: 12px;
}

.nav-right {
	margin-right: 12px;
}

.nav-left {
	text-align: left;
	color: white;
}

// .logout-button {
// 	background-color: transparent;
// }
// .logout-button:hover {
// 	color: var(--primary-color-hover);
// }

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.actions-dropdown {
	width: 300px !important;
	min-width: unset !important;
}

.dropdown-content {
	border-radius: 4px;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.185);
	background-color: white;
	z-index: 100;
	min-width: 360px;
	right: 0px;
	top: 44px;
	a {
		color: black;
		text-align: left;
		padding: 8px;
		padding-left: 12px;
	}
	a:hover {
		background-color: var(--primary-color-dark);
		color: white;
		cursor: pointer;
	}
}
</style>

<style>
#nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: rgb(10, 64, 146);
	background: linear-gradient(97deg, rgba(10, 64, 146, 1) 0%, rgba(0, 92, 169, 1) 41%, rgba(0, 145, 204, 1) 100%);
}

.nav-left {
	padding: 16px;
}

#nav a.router-link-exact-active {
	color: var(--primary-color-light);
}
</style>
