export default {
	table: {
		tableClass: 'table table-striped table-bordered',
		loadingClass: 'loading',
		ascendingIcon: 'fas fa-chevron-up',
		descendingIcon: 'fas fa-chevron-down',
		handleIcon: 'fas fa-hamburger',
		sortableIcon: 'fas fa-sort',
		renderIcon: function (classes) {
			return '<span class="' + classes.join(' ') + '"></span>';
		}
	}
};
