<template>
	<div class="modal modal-add-client">
		<h1 class="text-2xl">Registracija proizvoda</h1>

		<hr />
		<div class="px-4 py-1 bg-red-600 bg-opacity-10 rounded-md my-2">
			<label class="text-xs text-red-600"
				><b>NAPOMENA</b>: Ovu opciju je potrebno koristiti <b>samo ako proizvod nema dostupnu internet konekciju</b>. U
				suprotnom će registracija proizvoda biti automatski izvršena prilikom pokretanja.</label
			>
		</div>
		<div class="modal-content text-lg">
			<div class="form-item">
				<div class="flex">
					<h2 for="productType">Tip proizvoda klijenta</h2>

					<span class="ml-1 font-semibold text-red-600">*</span>
				</div>
				<v-select
					:placeholder="`Izaberite tip proizvoda`"
					class="select-style mb-1"
					v-model="selectedProductType"
					:options="user.productTypes"
				></v-select>
			</div>
			<div class="form-item" v-if="selectedProductType">
				<h2>Izaberi klijenta</h2>
				<v-select
					placeholder="Pretražite i izaberite klijenta"
					class="select-style client-select mb-1"
					v-model="selectedClient"
					:options="clients"
				></v-select>
			</div>
			<div class="form-item">
				<h2 for="jid">JID</h2>
				<p class="text-sm text-gray-200 mt-0">Osam karaktera, npr. ABCD1234</p>
				<input
					type="text"
					id="jid"
					v-model="jid"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<h2 for="jid">Nadimak JID-a</h2>
				<p class="text-sm text-gray-200 mt-0">
					Opciono polje, naziv za lakšu identifikaciju proizvoda sa izabranim JID-om
				</p>
				<input
					type="text"
					id="jidNickname"
					v-model="jidNickname"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<h2 for="type">Okruženje</h2>
				<select id="type" v-model="type">
					<option value="SANDBOX">Razvojno</option>
					<option value="PRODUCTION">Produkciono</option>
				</select>
			</div>
			<div class="flex items-center p-2 bg-primary bg-opacity-10 rounded-md">
				<input type="checkbox" id="checkbox" v-model="confirm" class="mr-2" /><label class="text-xs" for="checkbox"
					>Saglasan sam da se izvrši registracija proizvoda. Nakon kreiranja, biće generisana probna licenca za dati
					proizvod.</label
				>
			</div>
			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button
					:disabled="loading || !selectedClient || jid == '' || !confirm || jid.length != 8"
					class="flex-grow ml-2"
					@click="registerLPFR()"
				>
					Registruj proizvod
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';

export default {
	data() {
		return {
			clients: [],
			selectedClient: null,
			selectedProductType: 'LPFR',
			jid: '',
			jidNickname: '',
			type: 'PRODUCTION',
			loading: false,
			confirm: false
		};
	},

	computed: {
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id,
					productTypes: state.user.user.productTypes
				};
			}
		})
	},

	async created() {
		await this.fetchClients();
	},

	watch: {
		async selectedProductType() {
			await this.fetchClients();
		}
	},

	methods: {
		async fetchClients() {
			this.response = await axios.get('/client/allowed', {
				params: {
					productType: this.selectedProductType
				}
			});
			this.clients = this.response.data.map(client => {
				client.label = `${client.name} (PIB: ${client.pib})`;
				return client;
			});
		},
		async registerLPFR() {
			this.loading = true;
			const body = {
				imeFirme: this.selectedClient.name,
				PIB: this.selectedClient.pib,
				JID: this.jid,
				type: this.type
			};
			if (this.jidNickname && this.jidNickname.length > 0) {
				body.JIDNickname = this.jidNickname;
			}
			try {
				await axios.post('licence/create', body);
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Licenca je uspešno kreirana.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				this.$emit('close');
				if (error.response.status == 400) {
					this.$fire({
						title: 'Greška',
						html: 'Dati LPFR je već registrovan.<br/>Nije potrebna ručna registracija.',
						type: 'error',
						showConfirmButton: true
					});
				} else if (error.response.status == 404) {
					this.$fire({
						title: 'Greška',
						html: 'Klijent nije pronađen.',
						type: 'error',
						showConfirmButton: true
					});
				} else if (error.response.status == 403) {
					this.$fire({
						title: 'Greška',
						html: 'Klijent nije pod nadzorom ovog naloga.',
						type: 'error',
						showConfirmButton: true
					});
				} else {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom kreiranja licence.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
				}
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss">
.select-style.client-select .vs__dropdown-menu {
	max-height: 250px !important;
}
</style>
>
