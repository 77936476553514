export default {
	state: {
		lpfrs: null,
		licences: null,
		archived: null
	},
	mutations: {
		setLpfrs(state, payload) {
			state.lpfrs = payload;
		},

		unsetLpfrs(state) {
			state.lpfrs = null;
		},

		setArchived(state, payload) {
			state.archived = payload;
		},

		unsetArchived(state) {
			state.archived = null;
		},

		setLicences(state, payload) {
			state.licences = payload;
		},
		unsetLicences(state) {
			state.licences = null;
		}
	},
	getters: {
		getLpfrs(state) {
			return state.lpfrs;
		},

		getArchived(state) {
			return state.archived;
		},

		getLicences(state) {
			return state.licences;
		}
	}
};
