<template>
	<div class="modal modal-add-client">
		<h1 class="text-2xl">Dodaj novog klijenta</h1>
		<hr />
		<div class="modal-content text-lg">
			<div class="form-item">
				<div class="flex">
					<h2 for="name">Naziv klijenta</h2>
					<span class="ml-1 font-semibold text-red-600">*</span>
				</div>
				<input
					type="text"
					id="name"
					v-model="name"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<div class="flex">
					<h2 for="productType">Tip proizvoda klijenta</h2>

					<span class="ml-1 font-semibold text-red-600">*</span>
				</div>
				<v-select
					:placeholder="`Izaberite tip proizvoda`"
					class="select-style mb-1"
					v-model="selectedProductType"
					:options="user.productTypes"
				></v-select>
			</div>
			<div class="form-item">
				<div class="flex">
					<h2 for="pib">PIB</h2>

					<span class="ml-1 font-semibold text-red-600">*</span>
				</div>
				<p class="text-sm text-gray-200 mt-0">Poreski identifikacioni broj, primer: 123456789</p>
				<input
					type="text"
					id="pib"
					v-model="pib"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>

			<div class="form-item">
				<h2 for="email">E-mail</h2>

				<input
					type="text"
					id="email"
					v-model="email"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<h2 for="phone">Kontakt telefon</h2>

				<vue-phone-number-input
					style="z-index: 500"
					id="phone"
					color="#005ca9"
					valid-color="#00796b"
					default-country-code="RS"
					v-model="phoneRaw"
					:translations="{
						countrySelectorLabel: 'Država',
						countrySelectorError: 'Greska',
						phoneNumberLabel: 'Broj telefona',
						example: 'Primer:'
					}"
					:no-example="true"
					:preferred-countries="['RS', 'ME', 'BA', 'HR', 'MK', 'HU', 'RO', 'BG']"
					:show-code-on-list="true"
					size="lg"
					:countries-height="25"
					:no-country-selector="false"
					@update="updatePhoneNumber($event)"
				/>
			</div>

			<div
				class="form-item"
				v-if="this.user.role != 'PARTNER' && this.user.role != 'ONPREMADMIN' && this.selectedProductType"
			>
				<h2 for="email">
					{{ user.role == 'SUPERADMIN' ? 'Dodela klijenta partneru/distributeru' : 'Dodela klijenta partneru' }}
				</h2>
				<p class="text-sm text-gray-200 mt-0">
					<span>Opciono, ukoliko ne izaberete partnera</span><span v-if="user.role == 'SUPERADMIN'">/distributera</span
					><span>, klijent će biti dodeljen Vama</span>
				</p>
				<v-select
					:placeholder="`Pretražite i izaberite partnera${user.role == 'SUPERADMIN' ? '/distributera' : ''}`"
					class="select-style mb-1"
					v-model="selectedPartner"
					:options="partners"
				></v-select>
			</div>

			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button
					:disabled="
						loading ||
						name == '' ||
						pib == '' ||
						!selectedProductType ||
						pib.match(/^[0-9,A-Z,a-z]{9,13}$/) == null ||
						(this.email && !this.email.match(this.emailRegex)) ||
						(this.phoneRaw && !this.phone.isValid)
					"
					class="flex-grow ml-2"
					@click="addClient()"
				>
					Dodaj
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { mapState } from 'vuex';

export default {
	components: {
		VuePhoneNumberInput
	},

	data() {
		return {
			name: '',
			pib: '',
			loading: false,
			email: '',
			phoneRaw: null,
			phone: {
				isValid: null,
				number: null
			},
			partners: [],
			selectedPartner: null,
			selectedProductType: 'LPFR',
			emailRegex:
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		};
	},

	computed: {
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id,
					productTypes: state.user.user.productTypes
				};
			}
		})
	},

	watch: {
		async selectedProductType() {
			await this.fetchPartnersAndDistributors();
		}
	},

	async created() {
		await this.fetchPartnersAndDistributors();
	},

	methods: {
		async fetchPartnersAndDistributors() {
			if (this.user.role == 'DISTRIBUTOR') {
				const response = await axios.get('user/distributor/partners', {
					params: {
						productType: this.selectedProductType
					}
				});
				this.partners = response.data.map(partner => {
					partner.label = `${partner.name} (PIB: ${partner.pib})`;
					return partner;
				});
			} else if (this.user.role == 'SUPERADMIN') {
				const response = await axios.get('user/partners/all', {
					params: {
						productType: this.selectedProductType
					}
				});
				this.partners = response.data.map(partner => {
					partner.label = `${partner.name} (PIB: ${partner.pib})`;
					return partner;
				});
			}
		},

		updatePhoneNumber($event) {
			this.phone = {
				isValid: $event.isValid,
				number: $event.formattedNumber
			};
		},

		async addClient() {
			this.loading = true;
			try {
				const requestBody = {
					name: this.name,
					pib: this.pib,
					productType: this.selectedProductType
				};

				if (this.email) {
					requestBody.email = this.email;
				}

				if (this.phoneRaw && this.phone.isValid) {
					requestBody.phone = this.phone.number;
				}

				if (this.selectedPartner) {
					requestBody.partnerId = this.selectedPartner.id;
				}

				await axios.post('client', requestBody);
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Klijent je uspešno dodat.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				this.$emit('close');
				console.log(error.response.status);
				if (error.response.status == 400) {
					this.$fire({
						title: 'Klijent već registrovan',
						text: 'Korisnik je već registrovan.',
						type: 'error',
						showConfirmButton: true
					});
				} else if (error.response.status == 409) {
					this.$fire({
						title: 'Klijent već registrovan',
						text: 'Korisnik je već registrovan kod drugog partnera ili distributera.',
						type: 'error',
						showConfirmButton: true
					});
				} else {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom dodavanja klijenta.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
				}
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style></style>
