<template>
	<div class="tab-container">
		<tabs :options="{ useUrlFragment: false }" @changed="tabChanged">
			<tab name="Dashboard" id="dashboard"
				><dashboard-tab
					@refreshMonitorData="refreshMonitorData"
					@refreshLicenceData="refreshLicenceData"
					ref="dashboard"
			/></tab>
			<tab name="Monitor" id="monitor"><monitor-tab ref="monitor" /></tab>
			<tab name="Licence" id="licence"><licence-tab ref="licences" /></tab>
			<tab name="Klijenti na čekanju" id="waiting"><clients-on-hold-tab ref="clientsOnHold" /></tab>
			<tab name="Arhiva" id="archived"><archived-tab ref="archive" /></tab>
			<tab name="Log fajlovi" id="logfiles"><log-files-tab ref="logfiles" /></tab>
			<tab name="Podrška" id="chat" :suffix="suffix"
				><admin-chat-tab ref="chat" v-if="user.role == 'SUPERADMIN'" />
				<user-chat-tab ref="chatUser" v-if="user.role != 'SUPERADMIN' && user.role != 'ONPREMADMIN'" />
			</tab>
		</tabs>
	</div>
</template>

<script>
import { Tabs, Tab } from 'vue-tabs-component';
import DashboardTab from './DashboardTab.vue';
import LicenceTab from './LicenceTab.vue';
import MonitorTab from './MonitorTab.vue';
import ArchivedTab from './ArchivedTab.vue';
import ClientsOnHoldTab from './ClientsOnHoldTab.vue';
import LogFilesTab from './LogFilesTab.vue';
import AdminChatTab from './AdminChatTab.vue';
import UserChatTab from './UserChatTab.vue';
import { mapState } from 'vuex';
import axios from '@/axios/axios.js';

export default {
	components: {
		Tabs,
		Tab,
		MonitorTab,
		LicenceTab,
		LogFilesTab,
		DashboardTab,
		ArchivedTab,
		ClientsOnHoldTab,
		AdminChatTab,
		UserChatTab
	},
	methods: {
		tabChanged(e) {
			this.activeTab = e;
			this.removeAllIntervals();

			if (!this.refreshingStopped) {
				if (e.tab.$options.propsData.id == 'dashboard') {
					this.$refs.dashboard.resetIntervals();
				} else if (e.tab.$options.propsData.id == 'monitor') {
					// this.$refs.monitor.getDataIfNeeded();
				} else if (e.tab.$options.propsData.id == 'archived') {
					this.$refs.archive.getDataIfNeeded();
				} else if (e.tab.$options.propsData.id == 'licence') {
					// this.$refs.licences.getDataIfNeeded();
				} else if (e.tab.$options.propsData.id == 'waiting') {
					// this.$refs.clientsOnHold.getDataIfNeeded();
				} else if (e.tab.$options.propsData.id == 'logfiles') {
					// this.$refs.logfiles.getDataIfNeeded();
				} else if (e.tab.$options.propsData.id == 'chat') {
					this.suffix = '';
					if (this.user.role == 'SUPERADMIN') {
						this.$refs.chat.resetIntervals();
					} else {
						this.$refs.chatUser.resetIntervals();
					}
				}
			}
		},

		refreshMonitorData() {
			this.$refs.monitor.refreshData();
		},
		refreshLicenceData() {
			this.$refs.licences.refreshData();
		},

		removeAllIntervals() {
			this.$refs.dashboard.removeInterval();
			this.$refs.monitor.removeInterval();
			this.$refs.licences.removeInterval();
			this.$refs.clientsOnHold.removeInterval();
			if (this.$refs.logfiles) this.$refs.logfiles.removeInterval();
			if (this.$refs.chat) this.$refs.chat.removeInterval();
			if (this.$refs.chatUser) this.$refs.chatUser.removeInterval();
		}
	},
	data() {
		return {
			activeTab: null,
			intervalId: null,
			suffix: ''
		};
	},
	created() {
		this.intervalId = setInterval(async () => {
			if (this.$router.history.current.name == 'home') {
				const response = await axios.get('chat');
				const hasUnread = response.data.findIndex(chat => chat.hasUnread) != -1;
				if (hasUnread) {
					this.suffix = "<span class='badge'></span>";
					this.$toasted.show('Imate novu poruku!');
				} else {
					this.suffix = '';
				}
			}
		}, 5000);
	},
	beforeDestroy() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
			this.intervalId = null;
		}
	},
	watch: {
		refreshingStopped() {
			if (this.refreshingStopped) {
				this.removeAllIntervals();
			} else {
				if (this.activeTab) {
					this.tabChanged(this.activeTab);
				}
			}
		}
	},
	computed: {
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			},
			refreshingStopped: state => {
				return state.refreshingStopped;
			}
		})
	}
};
</script>

<style lang="scss" scoped></style>

<style>
.badge {
	height: 8px;
	width: 8px;
	background: red;
	margin-left: 8px;
	border-radius: 50%;
}
.tab-container {
	margin: 12px;
}
.tabs-component {
	/* margin: 4em 0; */
	margin: 0 0;
}

.tabs-component-tabs {
	border: solid 1px #ddd;
	border-radius: 6px;
}

@media (min-width: 700px) {
	.tabs-component-tabs {
		border: 0;
		align-items: stretch;
		display: flex;
		justify-content: flex-start;
		margin-bottom: -1px;
	}
}

.tabs-component-tab {
	color: #999;
	font-size: 16px;
	font-weight: 600;
	margin-right: 0;
	padding: 0 16px;
	list-style: none;
}

/* .tabs-component-tab:not(:last-child) {
	border-bottom: dotted 1px #ddd;
} */

.tabs-component-tab:hover {
	color: #666;
}

.tabs-component-tab.is-active {
	color: var(--primary-color);
}

.tabs-component-tab.is-disabled * {
	color: #cdcdcd;
	cursor: not-allowed !important;
}

@media (min-width: 700px) {
	.tabs-component-tab {
		background-color: #fff;
		border: solid 1px #ddd;
		border-radius: 3px 3px 0 0;
		margin-right: 0.5em;
		transform: translateY(2px);
		transition: transform 0.3s ease;
	}

	.tabs-component-tab.is-active {
		border-bottom: solid 1px #fff;
		z-index: 2;
		transform: translateY(0);
	}
}

.tabs-component-tab-a {
	align-items: center;
	color: inherit;
	display: flex;
	padding: 0.75em 1em;
	text-decoration: none;
}

.tabs-component-panels {
	padding: 2em 0;
}

@media (min-width: 700px) {
	.tabs-component-panels {
		border-top-left-radius: 0;
		background-color: #fff;
		border: solid 1px #ddd;
		border-radius: 0 6px 6px 6px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
		padding: 1em 2em;
	}
}
</style>
