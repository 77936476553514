<template>
	<div class="modal">
		<div class="flex flex-row justify-between items-center">
			<h1>Kreiranje nacrta faktura</h1>
			<div class="flex flex-row text-gray-400 relative">
				<div class="absolute w-32 top-2 left-16 z-0">
					<hr
						:class="{
							'border-gray-100': step === 1,
							'border-blue-600': step > 1
						}"
						class="border-t-8 borber-b-0 border-dotted transition ease-in"
					/>
				</div>
				<div class="absolute w-32 top-2 right-16 z-0">
					<hr
						:class="{
							'border-gray-100': step < 3,
							'border-blue-600': step === 3
						}"
						class="border-t-8 borber-b-0 border-dotted transition ease-in"
					/>
				</div>
				<div class="flex flex-col items-center w-32 mb-2 z-10">
					<div id="step-1" class="w-6 h-6 rounded-full bg-blue-600 transition ease-in"></div>
					<p :class="{ 'active-step': step === 1 }">Izbor obveznika</p>
				</div>
				<div class="flex flex-col items-center w-32 z-10">
					<div
						id="step-2"
						:class="{ 'bg-blue-600': step >= 2, 'bg-gray-200': step < 2 }"
						class="w-6 h-6 rounded-full transition"
					></div>
					<p :class="{ 'active-step': step === 2 }">Period obračuna</p>
				</div>
				<div class="flex flex-col items-center w-32 z-10">
					<div
						id="step-3"
						:class="{ 'bg-blue-600': step === 3, 'bg-gray-200': step !== 3 }"
						class="w-6 h-6 rounded-full transition"
					></div>
					<p :class="{ 'active-step': step === 3 }">Podešavanja</p>
				</div>
			</div>
		</div>
		<hr class="mb-4" />
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-show="!loading">
			<div v-show="step === 1" style="min-height: 700px">
				<h2 class="text-2xl">Izbor obveznika</h2>
				<hr />
				<div class="bg-white-600 rounded p-1">
					<label class="text-gray-400 text-sm mt-1"
						>Lista prikazuje isključivo validne obveznike. Izostavljeni su oni obveznici za koje nisu podešeni svi
						podaci potrebni za fakturisanje. Ovi podaci se mogu podesiti u okviru
						<router-link class="text-blue-600" to="/finances/clients">liste korisnika</router-link>. Cenovnike je moguće
						kreirati i izmeniti <router-link class="text-blue-600" to="/finances/pricelists">ovde</router-link>.</label
					>
				</div>
				<hr />
				<div class="flex flex-row justify-between mt-2">
					<div class="flex flex-row">
						<div class="flex flex-row flex-nowrap">
							<input
								type="text"
								class="focus:ring-primary focus:border-primary"
								v-model="searchInput"
								:placeholder="searchPlaceholder"
								style="width: 420px"
								v-on:keyup.enter="searchValidClients"
							/>
							<button
								class="ml-2"
								title="Pretraži"
								@click="searchValidClients()"
								:disabled="!searchInput || searchInput.length === 0"
								style="width: 42px"
							>
								<i class="fas fa-search" />
							</button>
							<button
								v-if="searchDone"
								title="Poništi filter i osveži tabelu"
								class="cancel-filter-button ml-2"
								@click="cancelSearch()"
								style="width: 42px"
							>
								<i class="fas fa-times" />
							</button>
						</div>
					</div>
					<div class="flex flex-row">
						<button class="mr-2" @click="selectAll('DISTRIBUTOR')" v-if="user.role == 'SUPERADMIN'">
							Svi distributeri
						</button>
						<button class="mr-2" @click="selectAll('PARTNER')" v-if="user.role == 'SUPERADMIN'">Svi partneri</button>
						<button class="mr-2" @click="selectAll('CLIENT')">Svi klijenti</button>
						<button @click="$refs.vuetable.selectedTo = []">Ukloni sve</button>
					</div>
				</div>
				<div class="mt-2">
					<vuetable
						ref="vuetable"
						:api-mode="false"
						:fields="fields"
						:css="css.table"
						:per-page="perPage"
						noDataTemplate="Nisu pronađeni validni obveznici"
						:data-manager="dataManager"
						pagination-path="pagination"
						@vuetable:pagination-data="onPaginationData"
						@vuetable:loaded="onLoaded"
						@vuetable:row-clicked="onRowClicked"
						class="invoice-drafts-table"
						track-by="index"
					>
						<div class="slot" slot="name-slot" slot-scope="props">
							{{ props.rowData.nameFormatted }}
						</div>
						<div class="slot" slot="user-type-slot" slot-scope="props">
							{{ props.rowData.typeFormatted }}
						</div></vuetable
					>
					<div class="flex flex-row justify-between">
						<div class="flex flex-col">
							<div class="flex flex-row items-center">
								<input type="checkbox" name="onlyDirect" id="only-direct-clients" v-model="onlyDirect" />
								<label for="only-direct-clients" class="pl-1 whitespace-nowrap flex-nowrap pr-2"
									>Samo "direktni" klijenti obveznika</label
								>
							</div>
							<label class="text-gray-400 text-sm mt-0"
								>Biranjem ove opcije fakturišu se isključivo LPFR-ovi klijenata za koje su izabrani obveznici direktno
								nadležni, tj. LPFR-ovi klijenata onih partnera za koje je zadužen izabrani obveznik se ne uzimaju u
								obzir.</label
							>
						</div>
						<vuetable-pagination
							ref="pagination"
							class="pull-right"
							@vuetable-pagination:change-page="onChangePage"
						></vuetable-pagination>
					</div>
				</div>
				<div v-if="selectedClients.length > 0" class="mt-2">
					<h2>Izabrani obveznici</h2>

					<div class="flex flex-row flex-wrap">
						<div v-for="client in selectedClients" :key="client.index">
							<div
								:class="{
									'bg-blue-200': client.type === 'PARTNER',
									'bg-purple-200': client.type === 'DISTRIBUTOR',
									'bg-red-200': client.type === 'CLIENT'
								}"
								class="flex flex-row items-center mr-1 mt-1 p-1 rounded"
							>
								<h2 class="text-sm cursor-help" :title="`${client.name} - ${client.type} (${client.pib})`">
									{{ client.name }}
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="step === 2" style="min-height: 700px" class="flex flex-col justify-start">
				<div>
					<h2 class="text-2xl">Period obračuna</h2>
					<hr />
					<div class="bg-white-600 rounded p-1">
						<label class="text-gray-400 text-sm mt-1"
							>Definiše period koji će biti naplaćen izabranim obveznicima. Za svaki izabrani mesec, moguće je
							definisati broj besplatnih (gratis) licenci ili dodatan broj licenci za naplatu.
						</label>
					</div>
					<hr />
				</div>
				<div class="rounded-xl bg-white-100 pl-4 pr-4 pb-4 mt-2 border">
					<div class="flex flex-row items-end pb-0">
						<h2 class="w-1/12 p-2 pb-0">Godina</h2>
						<div class="w-1/12 p-2 pb-0">
							<h2>Mesec</h2>
						</div>
						<h2 class="flex flex-grow p-2 pb-0">Podešavanja</h2>
					</div>
					<div class="border-gray-600 border"></div>
					<div class="flex flex-row">
						<div class="flex flex-col w-1/12">
							<div v-for="year of availableYears" :key="year" class="border-l-2 border-gray-600">
								<div
									@click="selectedYear = year"
									:class="{
										'bg-blue-600': year === selectedYear,
										'text-white-100': year === selectedYear
									}"
									class="cursor-pointer hover:bg-blue-800 hover:text-white-100 text-3xl pt-2 pb-2 w-full pr-5 flex flex-row items-center justify-end"
								>
									<i v-if="year === selectedYear" class="fas fa-caret-right"></i>
									<p class="pl-2 pr-2 font-semibold">{{ year }}</p>
								</div>
								<!-- <hr v-if="index != availableYears.length - 1" /> -->
							</div>
							<div class="border-gray-600 border"></div>
						</div>
						<div class="flex flex-col w-1/12">
							<div v-for="period of selectedYearPeriods" :key="period.month" class="border-gray-600 border-l-2">
								<div class="flex flex-row items-center hover:bg-blue-200">
									<input
										class="ml-2"
										type="checkbox"
										:id="`${period.year}-${period.month}`"
										v-model="period.selected"
									/>
									<label
										class="p-2 w-full"
										:class="{ 'text-blue-800': period.selected }"
										:for="`${period.year}-${period.month}`"
										>{{ months[period.month].label }}</label
									>
								</div>
								<hr />
							</div>
							<div class="border-gray-600 border-l-2">
								<p
									class="m-0 p-1 w-full cursor-pointer text-center bg-blue-200 hover:bg-blue-600 hover:text-white-100"
									@click="selectAllMonthsOfSelectedYear(true)"
								>
									Izaberi sve
								</p>
								<p
									class="m-0 p-1 w-full cursor-pointer text-center bg-red-200 hover:bg-red-600 hover:text-white-100"
									@click="selectAllMonthsOfSelectedYear(false)"
								>
									Poništi izbor
								</p>
							</div>
							<div class="border-gray-600 border"></div>
						</div>
						<div class="border-gray-600 border"></div>
						<div class="flex flex-col flex-grow">
							<div v-for="period of selectedYearPeriods" :key="period.month">
								<div class="flex flex-row items-center p-2">
									<div v-if="period.selected" class="flex flex-row items-center">
										<p class="mr-2">Br. gratis mesеčnih prod. licenci</p>
										<money
											class="m-0 p-0 pl-2 w-16"
											style="height: 22px; border-color: #ccc"
											v-model="period.numOfFreeLicences"
											v-bind="{ precision: 0, thousands: '' }"
										>
										</money>
										<a
											title="Podesi istu vrednost za sve izabrane mesece u godini"
											class="p-1 ml-1 hover:bg-blue-600 cursor-pointer rounded-xl flex flex-row justify-center hover:text-white-100"
											style="width: 24px"
											@click="setNumOfFreeLicences(period.numOfFreeLicences)"
											><i class="fas fa-paste"></i
										></a>
										<p class="mr-2 ml-4">Br. gratis godišnjih prod. licenci</p>
										<money
											class="m-0 p-0 pl-2 w-16"
											style="height: 22px; border-color: #ccc"
											v-model="period.numOfFreeYearlyLicences"
											v-bind="{ precision: 0, thousands: '' }"
										>
										</money>
										<a
											title="Podesi istu vrednost za sve izabrane mesece u godini"
											class="p-1 ml-1 hover:bg-blue-600 cursor-pointer rounded-xl flex flex-row justify-center hover:text-white-100"
											style="width: 24px"
											@click="setNumOfFreeYearlyLicences(period.numOfFreeYearlyLicences)"
											><i class="fas fa-paste"></i
										></a>
									</div>
									<div v-else style="height: 24px"></div>
								</div>
								<hr />
							</div>
							<div style="min-height: 64px" class="flex flex-col pt-1 pl-2 mb-1">
								<h2 v-if="atLeastOnePeriodSelected">Izabrano:</h2>
								<div class="flex flex-row flex-wrap">
									<div v-for="(period, index) in periods" :key="index">
										<div v-if="period.selected" class="flex flex-row items-center mr-1 mt-1 p-1 rounded bg-blue-200">
											<h2 class="text-sm cursor-help whitespace-nowrap">
												{{ period.year }} - {{ months[period.month].label }}
											</h2>
										</div>
									</div>
								</div>
							</div>
							<div class="border-gray-600 border"></div>
						</div>
						<div class="border-gray-600 border"></div>
					</div>
				</div>
			</div>
			<div v-if="step === 3">
				<div class="flex flex-row" style="min-height: 640px">
					<div class="mt-2 step-3-input w-1/2 border-r mr-4 pr-4">
						<h2 class="text-2xl">Opšta podešavanja</h2>
						<hr class="mb-4" />
						<div class="mb-2">
							<h2 class="flex flex-row items-center">Opis nacrta faktura</h2>
							<input class="w-full" type="text" v-model="description" />
						</div>

						<div class="mb-2">
							<h2>Naziv fakture</h2>
							<p class="text-gray-400 text-sm">
								Prikazuje se u okviru PDF dokumenta fakture. Ukoliko nije definisan, biće automatski generisan na osnovu
								izabranog perioda.
							</p>
							<input class="w-full" type="text" v-model="descriptionForPDF" />
						</div>
						<hr class="mb-2" />
						<div class="flex flex-row">
							<div>
								<h2 class="flex flex-row items-center">
									Datum prometa<i class="text-red-600 fas fa-star-of-life text-xs ml-1"></i>
								</h2>
								<vue-datetime
									type="date"
									input-class="form-control form-control"
									placeholder="Izaberite datum prometa"
									hidden-name="webstorm"
									format="dd.MM.yyyy."
									:phrases="{
										ok: 'U redu',
										cancel: 'Otkaži'
									}"
									v-model="turnoverDate"
								></vue-datetime>
							</div>
							<div class="ml-2" v-show="turnoverDate">
								<h2 class="flex flex-row items-center">
									Srednji kurs (1€ u RSD)<i class="text-red-600 fas fa-asterisk text-xs ml-1"></i>
								</h2>
								<money
									v-if="!exchangeRateExists"
									class="m-0 p-0 pl-2 w-36"
									style="height: 26px; border-color: #ccc"
									v-model="exchangeRate"
									v-bind="{ precision: 4 }"
								></money>
								<input type="text" v-else v-model="exchangeRate" disabled />
							</div>
						</div>
						<div class="flex flex-row">
							<div>
								<h2 class="mt-2">Datum valute</h2>
								<div class="flex flex-row items-center">
									<vue-datetime
										type="date"
										input-class="form-control form-control"
										placeholder="Izaberite datum valute"
										hidden-name="webstorm"
										format="dd.MM.yyyy."
										:phrases="{
											ok: 'U redu',
											cancel: 'Otkaži'
										}"
										v-model="valueDate"
									></vue-datetime>
									<a class="cursor-pointer ml-2" v-if="valueDate" @click="valueDate = null"
										><i class="text-red-600 fas fa-minus-circle"></i
									></a>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-2 step-3-input w-1/2">
						<div class="right-col">
							<h2 class="text-2xl">Prilagođene stavke</h2>
							<hr />
							<div class="bg-white-600 rounded p-1">
								<label class="text-gray-400 text-sm mt-1"
									>Svaka prilagođena stavka dodaje se jednom za svaku kreiranu fakturu, nezavisno od izbora perioda
									obračuna. Porez će biti uzet u obzir za svaku dodatu stavku. Rabat iz cenovnika obveznika se ne uzima
									u obzir i ne uvažava se.</label
								>
							</div>
							<hr class="mb-4" />
							<div v-if="user.role == 'SUPERADMIN'"></div>
							<div class="flex flex-col">
								<div>
									<h2>Opis stavke</h2>
									<input type="text" v-model="customItemDescription" style="width: 100%" />
								</div>
								<div class="flex flex-row mt-2 justify-between items-end">
									<div class="flex flex-row">
										<div class="mr-2">
											<h2>Količina</h2>
											<money
												class="m-0 p-0 pl-2"
												style="height: 26px; border-color: #ccc"
												v-model="customItemQuantity"
												v-bind="{ precision: 0, thousands: '' }"
											></money>
										</div>
										<div>
											<h2>Cena po komadu</h2>
											<money
												class="m-0 p-0 pl-2"
												style="height: 26px; border-color: #ccc"
												v-model="customItemPrice"
												v-bind="{
													precision: 2,
													thousands: ' ',
													suffix: ' RSD'
												}"
											></money>
										</div>
									</div>

									<button
										class="whitespace-nowrap pt-1 pb-1 w-48"
										:disabled="
											!customItemDescription ||
											customItemDescription == '' ||
											customItemPrice <= 0 ||
											customItemQuantity <= 0
										"
										@click="addCustomItem()"
									>
										Dodaj stavku
									</button>
								</div>
							</div>
							<hr class="mt-2" />
							<div class="list-of-custom-items mt-2">
								<div v-for="(item, index) of customItems" :key="index" class="mb-2 mr-2 bg-white-600 p-2 rounded">
									<div class="flex flex-row justify-between items-center">
										<div class="flex flex-row flex-grow mr-2 items-center">
											<div>
												<p class="custom-item-index mr-2">{{ index + 1 }} -</p>
											</div>
											<div class="w-3/4">
												<h2>
													'{{ item.description }}'
													<span class="whitespace-nowrap">x {{ item.quantity }},&nbsp; </span>
													<span class="whitespace-nowrap"
														>jedinična cena: {{ formatMoney(item.pricePerUnit) }} RSD</span
													>
												</h2>
											</div>
										</div>

										<button
											class="bg-secondary hover:bg-red-800 btn-sm bg-red-600"
											style="width: 28px; height: 28px; line-height: 0; padding: 0"
											@click="removeItem(index)"
										>
											<i class="fas fa-minus"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr class="mt-4" />
			<div class="flex flex-row justify-between mt-3">
				<button class="w-1/12 bg-red-600 hover:bg-red-800 mr-2" @click="$emit('close')">Otkaži</button>
				<div class="flex flex-row">
					<button class="mr-2 w-32" v-if="step > 1" @click="step--">Nazad</button>
					<button
						class="mr-2 w-32"
						:disabled="(step === 1 && selectedClients.length < 1) || (step === 2 && chosenPeriods.length < 1)"
						v-if="step < 3"
						@click="step++"
					>
						Dalje
					</button>
					<button
						:disabled="!invoiceGroupValid"
						class="mr-2 w-48"
						v-if="step === 3"
						@click="downloadInvoiceDetails('preview')"
					>
						Preuzmi izveštaj
					</button>
					<button
						:disabled="!invoiceGroupValid"
						class="mr-2 w-48"
						v-if="step === 3"
						@click="downloadPreviewPDFs('preview')"
					>
						Preuzmi PDF-ove
					</button>
					<button
						:disabled="!invoiceGroupValid"
						class="mr-2 w-32"
						v-if="step === 3"
						@click="confirmInvoiceGroupAction('draft')"
					>
						Sačuvaj nacrt
					</button>
					<button
						:disabled="!invoiceGroupValid"
						class="mr-2 w-32"
						v-if="step === 3"
						@click="confirmInvoiceGroupAction('publish')"
					>
						Izdaj fakture
					</button>
					<button
						:disabled="!invoiceGroupValid"
						class="mr-2 w-64"
						v-if="step === 3"
						@click="confirmInvoiceGroupAction('publish_with_mail')"
					>
						Izdaj fakture i pošalji mejlove
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from '../components/VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';
import * as XLSX from 'xlsx-js-style';
import moment from 'moment';

export default {
	components: {
		Vuetable,
		VuetablePagination
		// Money
	},
	props: {
		invoiceGroupId: {
			type: Number,
			required: false
		}
	},
	computed: {
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		}),
		currentYear() {
			return new Date().getFullYear();
		},
		selectedYearPeriods() {
			return this.periods.filter(period => period.year === this.selectedYear);
		},
		chosenPeriods() {
			return this.periods.filter(period => period.selected);
		},
		atLeastOnePeriodSelected() {
			return this.periods.some(period => period.selected);
		},
		invoiceGroupValid() {
			const periodsChosen = this.chosenPeriods && this.chosenPeriods.length > 0;
			const clientsSelected = this.selectedClients && this.selectedClients.length > 0;

			const dataValid = this.turnoverDate && this.exchangeRate && this.exchangeRate > 0;

			return periodsChosen && clientsSelected && dataValid;
		}
	},
	data() {
		return {
			step: 1,
			groupId: null,
			onlyDirect: false,
			loading: false,
			css: VuetableCssConfig,
			perPage: 12,
			localData: [],
			originalData: [],
			fields: [],
			turnoverDate: null,
			valueDate: null,
			exchangeRate: '',
			exchangeRateExists: false,
			selectedClients: [],
			months: [
				{ label: 'Januar', value: 0 },
				{ label: 'Februar', value: 1 },
				{ label: 'Mart', value: 2 },
				{ label: 'April', value: 3 },
				{ label: 'Maj', value: 4 },
				{ label: 'Jun', value: 5 },
				{ label: 'Jul', value: 6 },
				{ label: 'Avgust', value: 7 },
				{ label: 'Septembar', value: 8 },
				{ label: 'Oktobar', value: 9 },
				{ label: 'Novembar', value: 10 },
				{ label: 'Decembar', value: 11 }
			],
			searchPlaceholder: 'Pretražite obveznike po nazivu ili PIB-u',
			description: '',
			descriptionForPDF: '',
			customItemQuantity: 1,
			customItemPrice: 0,
			customItemDescription: '',
			customItems: [],
			searchInput: '',
			searchDone: false,
			availableYears: [],
			selectedYear: null,
			periods: []
		};
	},

	async created() {
		this.loading = true;
		this.setupTableFields();
		this.calculateAvailableYears();
		this.selectedYear = this.currentYear;
		try {
			await this.loadData();
			if (this.invoiceGroupId) {
				await this.loadInvoiceGroup();
			}
		} catch (error) {
			console.log(error);
		} finally {
			this.loading = false;
		}
	},

	mounted() {
		this.$watch(
			() => this.$refs.vuetable.selectedTo,
			val => {
				this.selectedClients = this.$refs.vuetable.selectedTo.map(index => {
					return this.originalData.find(item => item.index === index);
				});
			}
		);
	},

	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		},

		async turnoverDate() {
			if (!this.turnoverDate) return;
			try {
				const response = await axios.get(`finances/exchange-rate-by-date/${this.turnoverDate}`);

				this.exchangeRate = response.data;
				this.exchangeRateExists = true;
			} catch (error) {
				this.exchangeRate = '0';
				this.exchangeRateExists = false;
			}
		}
	},

	methods: {
		calculateAvailableYears() {
			// get available years from 2021 to current year
			let currentYear = new Date().getFullYear();
			for (let i = 2021; i <= currentYear; i++) {
				this.availableYears.push(i);

				for (let j = 0; j < 12; j++) {
					const currentDate = new Date();

					if (currentDate.getFullYear() === i && j > currentDate.getMonth()) {
						continue;
					}

					this.periods.push({
						year: i,
						month: j,
						numOfFreeLicences: 0,
						numOfFreeYearlyLicences: 0,
						selected: false
					});
				}
			}
		},
		selectAllMonthsOfSelectedYear(selected) {
			this.periods.forEach(period => {
				if (period.year === this.selectedYear) {
					period.selected = selected;
				}
			});
		},
		setNumOfFreeLicences(numOfFreeLicences) {
			this.selectedYearPeriods.forEach(period => {
				period.numOfFreeLicences = numOfFreeLicences;
			});
		},
		setNumOfFreeYearlyLicences(numOfFreeLicences) {
			this.selectedYearPeriods.forEach(period => {
				period.numOfFreeYearlyLicences = numOfFreeLicences;
			});
		},
		setupTableFields() {
			this.fields = [
				{
					name: 'name-slot',
					title: 'Obveznik',
					sortField: 'name'
				},
				{
					name: 'user-type-slot',
					title: 'Tip obveznika',
					sortField: 'type'
				},
				{
					name: 'pib',
					title: 'PIB',
					sortField: 'pib'
				},
				{
					name: 'pricelistProdName',
					title: 'Cenovnik (prod. i god.)',
					sortField: 'pricelistProdName'
				},
				{
					name: 'pricelistIpsName',
					title: 'Cenovnik (IPS)',
					sortField: 'pricelistIpsName'
				},
				{
					name: 'pricelistEftName',
					title: 'Cenovnik (EFT)',
					sortField: 'pricelistEftName'
				},
				{
					name: 'pricelistPrintName',
					title: 'Cenovnik (print)',
					sortField: 'pricelistPrintName'
				},
				{
					name: '__checkbox',
					titleClass: 'center-checkbox',
					dataClass: 'center-checkbox'
				}
			];
		},
		async loadData() {
			const response = await axios.get('/finances/valid-clients');

			this.formatTableData(response.data);
		},
		formatTableData(data) {
			this.localData = data.map(row => {
				switch (row.type) {
					case 'DISTRIBUTOR':
						row.typeFormatted = 'Distributer';
						break;
					case 'PARTNER':
						row.typeFormatted = 'Partner';
						break;
					case 'CLIENT':
						row.typeFormatted = 'Klijent';
						break;
				}

				row.nameFormatted = row.name;
				if (row.name.length > 35) {
					row.nameFormatted = row.nameFormatted.substr(0, 35) + '...';
				}

				if (!row.pricelistPrintName) {
					row.pricelistPrintName = '/';
				}

				if (!row.pricelistProdName) {
					row.pricelistProdName = '/';
				}

				if (!row.pricelistIpsName) {
					row.pricelistIpsName = '/';
				}

				if (!row.pricelistEftName) {
					row.pricelistEftName = '/';
				}

				return row;
			});

			this.originalData = this.localData;
		},
		addCustomItem() {
			this.customItems.push({
				quantity: this.customItemQuantity,
				pricePerUnit: this.customItemPrice,
				description: this.customItemDescription
			});
			this.customItemQuantity = 1;
			this.customItemPrice = 0;
			this.customItemDescription = '';
		},

		removeItem(index) {
			this.customItems.splice(index, 1);
		},
		async loadInvoiceGroup() {
			const response = await axios.get(`finances/group/${this.invoiceGroupId}`);
			const group = response.data;

			this.groupId = group.id;

			this.description = group.description;
			this.descriptionForPDF = group.descriptionForPDF;

			this.turnoverDate = new Date(group.dateTurnover).toISOString();
			if (group.dateValue) {
				this.valueDate = new Date(group.dateValue).toISOString();
			}
			this.exchangeRate = group.exchangeRateEURtoRSD;
			this.onlyDirect = group.onlyDirect;

			for (const item of group.customItems) {
				// console.log(item);
				// console.log(item.pricePerUnit);
				// console.log(parseFloat(item.pricePerUnit));
				this.customItems.push({
					description: item.description,
					quantity: parseInt(item.quantity),
					pricePerUnit: parseFloat(item.pricePerUnit)
				});
			}

			for (const groupPeriod of group.months) {
				const period = this.periods.find(p => {
					return p.year === groupPeriod.year && p.month === groupPeriod.month;
				});

				period.selected = true;
				period.numOfFreeLicences = groupPeriod.numOfFreeLicences;
				period.numOfFreeYearlyLicences = groupPeriod.numOfFreeYearlyLicences;
			}

			this.$refs.vuetable.selectedTo = [];

			if (group.clientIds && group.clientIds.length > 0) {
				const ids = group.clientIds.split(',');
				for (const id of ids) {
					const client = this.originalData.find(c => c.id == id && c.type == 'CLIENT');
					if (client) {
						this.$refs.vuetable.selectedTo.push(client.index);
					}
				}
			}

			if (group.userIds && group.userIds.length > 0) {
				const ids = group.userIds.split(',');
				for (const id of ids) {
					const user = this.originalData.find(u => u.id == id && u.type != 'CLIENT');
					if (user) {
						this.$refs.vuetable.selectedTo.push(user.index);
					}
				}
			}
		},

		formRequestObject(status) {
			const requestObject = {};

			switch (status) {
				case 'preview':
					requestObject.saveToDb = false;
					requestObject.status = 'CREATE INVOICES';
					break;
				case 'draft':
					requestObject.status = 'SAVE AS DRAFT';
					break;
				case 'publish':
					requestObject.status = 'CREATE INVOICES';
					break;
				case 'publish_with_mail':
					requestObject.status = 'CREATE AND SEND INVOICES';
					break;
			}

			const userIds = this.selectedClients.filter(x => x.type != 'CLIENT').map(x => x.id);
			const clientIds = this.selectedClients.filter(x => x.type == 'CLIENT').map(x => x.id);

			if (this.description && this.description != '') requestObject.description = this.description;
			if (this.descriptionForPDF && this.descriptionForPDF != '')
				requestObject.descriptionForPDF = this.descriptionForPDF;
			if (this.groupId) requestObject.id = this.groupId;

			requestObject.dateTurnover = this.turnoverDate;
			requestObject.exchangeRate = this.exchangeRate;
			requestObject.dateValue = this.valueDate ? this.valueDate : null;
			requestObject.includeNested = !this.onlyDirect;
			requestObject.userIds = userIds;
			requestObject.clientIds = clientIds;
			requestObject.months = this.chosenPeriods.map(period => {
				return {
					year: period.year,
					month: period.month,
					numOfFreeLicences: period.numOfFreeLicences,
					numOfFreeYearlyLicences: period.numOfFreeYearlyLicences
				};
			});

			if (this.customItems && this.customItems.length > 0) {
				requestObject.customItems = this.customItems;
			}

			requestObject.accountingPeriod = 'PERIOD';

			// console.log('REQUEST OBJECT', requestObject);

			return requestObject;
		},

		async downloadInvoiceDetails(status) {
			const requestObject = this.formRequestObject(status);
			try {
				this.loading = true;
				const response = await axios.post('/finances/invoice', requestObject);

				let invoices = response.data.invoices;
				const invoiceGroup = response.data;
				delete invoiceGroup.invoices;

				invoices = invoices.map(invoice => {
					invoice.invoiceGroup = invoiceGroup;
					return invoice;
				});

				if (!invoices || invoices.length === 0) {
					this.$fire({
						title: 'Nisu kreirani excel izveštaji',
						text: 'Nije pronаđeno ništa za naplatu za izabrani period i izabrane obveznike.',
						type: 'warn',
						showConfirmButton: true
					});
					return;
				}

				for (const invoice of invoices) {
					const invoiceDetails = invoice.invoiceDetails;
					// console.log(invoiceDetails);

					const wb = XLSX.utils.book_new();

					for (const year in invoiceDetails) {
						for (const month in invoiceDetails[year]) {
							// console.log(month);
							const data = invoiceDetails[year][month];
							const dataArray = [];
							const eventsArray = [];
							for (const jid in data) {
								for (const item of data[jid]) {
									// console.log('item', item);
									dataArray.push({
										JID: jid,
										ENV: item.lpfrType,
										TYPE: item.productType,
										'INVOICING REASON':
											item.invoiceType === 'YEARLY LICENCE'
												? 'Yearly licence'
												: item.numberOfDaysActive === true
												? 'Licence transferred to this LPFR'
												: 'Num. of days active',
										'NUM. OF DAYS ACTIVE': item.numberOfDaysActive === true ? '' : item.numberOfDaysActive,
										CLIENT: item.client?.name ?? '/',
										'CL. PIB': item.client?.pib ?? '/',
										PARTNER: item.client?.user?.name ?? '/',
										'P. PIB': item.client?.user?.pib ?? '/'
									});

									if (item.events) {
										for (const event of item.events) {
											eventsArray.push({
												JID: jid,
												TIMESTAMP: moment(event.timestamp, moment.ISO_8601).format('DD.MM.YYYY - HH:mm:ss'),
												EVENT: event.event,
												'TRIGGERED BY': event.triggeredBy
											});
										}
									}
								}
							}

							// console.log(dataArray);

							const ws = XLSX.utils.json_to_sheet(dataArray);
							const eventWs = XLSX.utils.json_to_sheet(eventsArray);

							for (let i = 0; i < 9; i++) {
								const addr = XLSX.utils.encode_cell({
									c: i,
									r: 0
								});

								const styleSetings = {
									font: {
										bold: true,
										color: {
											rgb: 'ffffff'
										}
									},
									fill: { fgColor: { rgb: '0066CC' } }
								};

								if (i < 4) {
									eventWs[addr].s = styleSetings;
								}

								ws[addr].s = styleSetings;
							}

							let oldJID = '';
							for (let i = 1; i <= eventsArray.length; i++) {
								const addr = XLSX.utils.encode_cell({
									c: 0,
									r: i
								});
								if (eventWs[addr].v != oldJID) {
									for (let j = 0; j < 4; j++) {
										const editAddr = XLSX.utils.encode_cell({
											c: j,
											r: i
										});

										eventWs[editAddr].s = {
											border: {
												top: { style: 'medium', color: { auto: 1 } }
											}
										};
									}
								}
								oldJID = eventWs[addr].v;
							}

							ws['!cols'] = this.fitToColumn(dataArray);

							eventWs['!cols'] = this.fitToColumn(eventsArray);

							XLSX.utils.book_append_sheet(wb, ws, `${year}-${+month + 1}`);
							XLSX.utils.book_append_sheet(wb, eventWs, `Events - ${year}-${+month + 1}`);
						}
					}

					let invoiceOwnerName = invoice.user ? invoice.user.name : invoice.client.name;
					invoiceOwnerName = invoiceOwnerName.substring(0, 8);
					//remove spaces from name
					invoiceOwnerName = invoiceOwnerName.replace(/\s/g, '');

					const filename = 'izvestaj-' + invoiceOwnerName + '-' + invoice.number + '.xls';
					XLSX.writeFile(wb, filename);
				}
			} catch (error) {
				console.log(error);
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom kreiranja excel izveštaja na osnovu nacrta.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		},
		fitToColumn(data) {
			// get maximum character of each column
			//get number of properties
			let numberOfProperties = Object.keys(data[0]).length;
			const columnWidths = [];

			for (const row of data) {
				for (let i = 0; i < numberOfProperties; i++) {
					let column = Object.keys(row)[i];
					if (!row[column]) continue;
					let columnLength = row[column].toString().length;
					if (!columnWidths[i] || columnLength > columnWidths[i]) {
						if (columnLength > 100) {
							columnWidths[i] = 100;
						} else {
							columnWidths[i] = columnLength + 5;
						}
					}
					if (Object.keys(row)[i].length > columnWidths[i]) {
						columnWidths[i] = Object.keys(row)[i].length + 5;
					}
				}
			}

			//create array of column widths
			const cols = [];
			for (let i = 0; i < numberOfProperties; i++) {
				cols.push({
					wch: columnWidths[i]
				});
			}

			return cols;
		},

		async downloadPreviewPDFs(status) {
			const requestObject = this.formRequestObject(status);

			try {
				this.loading = true;
				const response = await axios.post('/finances/invoice', requestObject);

				let invoices = response.data.invoices;
				const invoiceGroup = response.data;
				delete invoiceGroup.invoices;

				invoices = invoices.map(invoice => {
					invoice.invoiceGroup = invoiceGroup;
					return invoice;
				});

				if (!invoices || invoices.length === 0) {
					this.$fire({
						title: 'Nisu kreirani PDF dokumenti',
						text: 'Nije pronаđeno ništa za naplatu za izabrani period i izabrane obveznike.',
						type: 'warn',
						showConfirmButton: true
					});
					return;
				}

				for (const invoice of invoices) {
					const response = await axios({
						method: 'post',
						url: 'finances/generate-preview-invoice-pdf',
						data: invoice,
						responseType: 'blob'
					});
					// console.log(response);
					// console.log(typeof response.data);
					const blob = new Blob([response.data], {
						type: 'application/pdf'
					});

					// console.log(blob);
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${invoice.number}.pdf`);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(url);
					}, 100);
				}
			} catch (error) {
				console.log(error);
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom kreiranja PDF-ova na osnovu nacrta.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		},

		async confirmInvoiceGroupAction(status) {
			let title = '';
			let html = '';

			switch (status) {
				case 'draft':
					title = 'Sačuvaj nacrt';
					html = 'Da li želite da sačuvate nacrt grupa faktura?';
					break;
				case 'publish':
					title = 'Izdaj fakture';
					html = 'Da li želite da izdate fakture grupe, bez slanja mejlova?';
					break;
				case 'publish_with_mail':
					title = 'Izdaj fakture sa mejlovima';
					html = 'Da li želite da izdate fakture grupe, <b>sa slanjem mejlova?</b>';
			}

			this.$fire({
				title,
				html,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Potvrdi',
				confirmButtonColor: '#0091cc',
				reverseButtons: true
			}).then(async result => {
				if (result.value) {
					await this.saveInvoiceGroup(status);
				}
			});
		},

		async saveInvoiceGroup(status) {
			const requestObject = this.formRequestObject(status);
			try {
				this.loading = true;
				const response = await axios.post('/finances/invoice', requestObject);

				if (requestObject.status !== 'SAVE AS DRAFT') {
					let invoices = response.data.invoices;
					const invoiceGroup = response.data;
					delete invoiceGroup.invoices;

					invoices = invoices.map(invoice => {
						invoice.invoiceGroup = invoiceGroup;
						return invoice;
					});

					if (!invoices || invoices.length === 0) {
						this.$fire({
							title: 'Neuspela akcija',
							text: 'Nije pronаđeno ništa za naplatu za izabrani period i izabrane obveznike.',
							type: 'warn',
							showConfirmButton: true
						});
						return;
					}
				}

				this.$emit('close');
				let text = ``;
				if (status == 'draft') {
					text = `Nacrt faktura je uspešno sačuvan.`;
				} else if (status == 'publish') {
					text = `Grupa faktura je uspešno kreirana i fakture su uspešno izdate.`;
				} else if (status == 'publish_with_mail') {
					text = `Grupa faktura je uspešno kreirana i fakture su uspešno izdate. Mejlovi se šalju.`;
				}
				this.$fire({
					title: 'Uspešna akcija',
					text: `${text}`,
					type: 'success',
					showConfirmButton: true
				});
				this.$emit('refresh');
			} catch (error) {
				console.error(error);
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom obrade nacrta fakture.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		},

		formatMoney(value) {
			const moneyFormatter = new Intl.NumberFormat('de-DE', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 4
			});

			return moneyFormatter.format(value);
		},

		searchValidClients() {
			if (!this.searchInput || this.searchInput.length === 0) return;

			this.localData = this.originalData.filter(row => {
				return (
					row.name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
					row.pib.toLowerCase().includes(this.searchInput.toLowerCase())
				);
			});
			this.searchDone = true;
		},

		cancelSearch() {
			this.searchInput = '';
			this.searchDone = false;
			this.localData = this.originalData;
		},

		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.name.search(txt) >= 0 ||
						item.pib.search(txt) >= 0 ||
						(item.legalEntityName && item.legalEntityName.search(txt) >= 0) ||
						(item.legalEntityEmails && item.legalEntityEmails.search(txt) >= 0) ||
						(item.legalEntityAddress && item.legalEntityAddress.search(txt) >= 0)
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onLoaded() {},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		onRowClicked(row) {
			if (this.$refs.vuetable.selectedTo.includes(row.data.index)) {
				this.$refs.vuetable.selectedTo.splice(this.$refs.vuetable.selectedTo.indexOf(row.data.index), 1);
			} else {
				this.$refs.vuetable.selectedTo.push(row.data.index);
			}
		},

		selectAll(type) {
			let rows = [];

			if (type == 'DISTRIBUTOR') {
				const distributors = this.originalData.filter(x => x.type == 'DISTRIBUTOR');
				rows = distributors;
			} else if (type == 'PARTNER') {
				const partners = this.originalData.filter(x => x.type == 'PARTNER');
				rows = partners;
			} else if (type == 'CLIENT') {
				const clients = this.originalData.filter(x => x.type == 'CLIENT');
				rows = clients;
			}

			rows = rows.map(x => x.index);

			const allSelected = new Set([...this.$refs.vuetable.selectedTo, ...rows]);
			this.$refs.vuetable.selectedTo = Array.from(allSelected);
		}
	}
};
</script>

<style lang="scss">
.active-step {
	color: var(--primary-color) !important;
}
.step-3-input {
	input {
		padding: 0;
		margin: 0;
		padding-left: 6px;
	}
}
</style>
