<template>
	<div class="clients-on-hold-tab">
		<div>
			<modal :adaptive="true" height="auto" name="user-details-from-clients-on-hold">
				<modal-user-details
					@close="closeModal('user-details-from-clients-on-hold')"
					@refresh="refreshData"
					:user="selectedUser"
				></modal-user-details>
			</modal>
		</div>
		<div>
			<modal :draggable="true" :adaptive="true" :height="'auto'" :focusTrap="true" name="add-client">
				<modal-add-client @close="closeModal('add-client')" @refresh="refreshData" />
			</modal>
		</div>
		<div v-if="loading" style="height: 200px" class="flex flex-col justify-center">
			<spinner-component :size="50" />
		</div>
		<div class="content" v-if="!loading">
			<div class="button-row">
				<div class="filter-container">
					<div>
						<input
							type="text"
							class="focus:ring-primary focus:border-primary"
							v-model="searchInput"
							:placeholder="searchPlaceholder"
							style="width: 420px"
							v-on:keyup.enter="getDataThroughFilter"
						/>
						<button title="Pretraži" @click="getDataThroughFilter()" style="width: 42px">
							<i class="fas fa-search" />
						</button>
						<button
							v-if="initialSearchDone"
							title="Poništi filter i osveži tabelu"
							class="cancel-filter-button"
							@click="cancelFilter()"
							style="width: 42px"
						>
							<i class="fas fa-times" />
						</button>
					</div>
				</div>
				<spinner-component v-if="loadingTableData" :size="45" />
				<div class="flex flex-row">
					<button @click="addClient">Kreiraj klijenta</button>
					<div style="margin-left: 24px; margin-right: 12px; border-left: 1px solid #d0d0d0"></div>
					<button title="Osveži tabelu" @click="getDataThroughFilter()">
						Osveži
						<i class="fas fa-redo ml-2"></i>
					</button>
				</div>
			</div>

			<div v-if="initialSearchDone">
				<vuetable
					ref="vuetable"
					:api-mode="false"
					:fields="fields"
					:css="css.table"
					:per-page="perPage"
					@vuetable:loaded="onLoaded"
					noDataTemplate="Nisu pronađeni klijenti na čekanju"
					:data-manager="dataManager"
					pagination-path="pagination"
					@vuetable:pagination-data="onPaginationData"
					class="clients-on-hold-table"
				>
					<div class="slot flex flex-row" slot="distributor-slot" slot-scope="props">
						<span
							:title="props.rowData.distributor.name"
							class="text-xs text-ellipsis distributor-name"
							v-if="props.rowData.distributor"
							style="width: 100%"
						>
							<div
								style="width: 100%"
								@click="showUserDetails(props.rowData.distributor)"
								class="cursor-pointer hover:text-primary"
							>
								{{ props.rowData.distributor.name }}
							</div>
						</span>
						<span v-else class="text-xs text-center">/</span>
					</div>
					<div class="slot flex flex-row" slot="partner-slot" slot-scope="props">
						<span
							:title="props.rowData.partner.name"
							class="text-xs text-ellipsis distributor-name"
							v-if="props.rowData.partner"
							style="width: 100%"
						>
							<div
								style="width: 100%"
								@click="showUserDetails(props.rowData.partner)"
								class="cursor-pointer hover:text-primary"
							>
								{{ props.rowData.partner.name }}
							</div>
						</span>
						<span v-else class="text-xs text-center">/</span>
					</div>
					<div class="slot flex flex-row items-center" slot="client-slot" slot-scope="props" style="width: 100%">
						<div
							:title="props.rowData.name"
							class="font-semibold text-ellipsis partner-client-txt cursor-pointer hover:text-primary"
							style="width: 100%"
							@click="showUserDetails(props.rowData)"
						>
							{{ props.rowData.name }}
						</div>
					</div>
					<div class="slot" slot="email-slot" slot-scope="props">
						<a :href="`mailto:${props.rowData.email}`" class="text-ellipsis hover:text-primary">
							{{ props.rowData.email }}</a
						>
					</div>
					<div class="slot" slot="phone-slot" slot-scope="props">
						<a :href="`tel:${props.rowData.phone}`" class="text-ellipsis hover:text-primary">
							{{ props.rowData.phone }}</a
						>
					</div>
					<div slot="actions-slot" slot-scope="props" class="text-center text-sm slot">
						<button
							style="width: 26px; height: 26px; padding: 0"
							@click="deleteClient(props.rowData)"
							class="btn btn-sm bg-red-600 hover:bg-red-800"
							title="Izbriši klijenta"
						>
							<i class="fas fa-trash" />
						</button>
					</div>
				</vuetable>
				<div class="flex flex-row justify-end items-center">
					<div class="mr-6">
						{{ numOfDisplayed }}
						/
						{{ numOfTotal }}
					</div>
					<div class="flex flex-row per-page-div">
						<input type="radio" id="10-per-page-monitor" :value="10" v-model="perPage" /><label
							:class="{ active: perPage == 10 }"
							for="10-per-page-monitor"
							>10</label
						>
						<input type="radio" id="20-per-page-monitor" :value="20" v-model="perPage" /><label
							:class="{ active: perPage == 20 }"
							for="20-per-page-monitor"
							>20</label
						>
						<input type="radio" id="50-per-page-monitor" :value="50" v-model="perPage" /><label
							:class="{ active: perPage == 50 }"
							for="50-per-page-monitor"
							>50</label
						>
						<input type="radio" id="100-per-page-monitor" :value="100" v-model="perPage" /><label
							:class="{ active: perPage == 100 }"
							for="100-per-page-monitor"
							>100</label
						>
					</div>
					<vuetable-pagination
						ref="pagination"
						class="pull-right"
						@vuetable-pagination:change-page="onChangePage"
					></vuetable-pagination>
				</div>
			</div>
			<div v-else class="mt-24 mb-36">
				<h1 class="text-2xl">Izvršite pretragu iznad ili osvežite tabelu, kako bi učitali podatke</h1>
			</div>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from './VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import axios from '@/axios/axios.js';
import ModalUserDetails from './ModalUserDetails.vue';
import ModalAddClient from './ModalAddClient.vue';

export default {
	components: {
		Vuetable,
		VuetablePagination,
		ModalUserDetails,
		ModalAddClient
	},

	data() {
		return {
			loadingTableData: false,
			intervalId: null,
			searchInput: '',
			searchFor: '',
			localData: [],
			css: VuetableCssConfig,
			fields: [],
			refreshInterval: 60000,
			lpfrRow: null,
			logs: null,
			selectedUser: null,
			initialSearchDone: false,
			perPage: 20,
			numOfDisplayed: 0,
			numOfTotal: 0
		};
	},

	async created() {
		this.setupTableFields();
	},

	computed: {
		...mapState({
			loading: state => {
				return state.loadingClientsOnHold;
			}
		}),
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		}),
		searchPlaceholder() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'Naziv distributera/partnera/klijenta, PIB';
				case 'DISTRIBUTOR':
					return 'Naziv partnera/klijenta, PIB';
				default:
					return 'Naziv klijenta, PIB';
			}
		},
		clientFieldTitle() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'Partner / Klijent';
				case 'DISTRIBUTOR':
					return 'Partner / Klijent';
				case 'PARTNER':
					return 'Klijent';
				default:
					return 'Klijent';
			}
		}
	},

	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		}
	},
	methods: {
		...mapMutations(['loadingStartedOnHold', 'loadingFinishedOnHold']),

		onLoaded() {
			this.numOfDisplayed = this.$refs.vuetable.countTableData;
			this.numOfTotal = this.$refs.pagination.tablePagination.total;
		},

		cancelFilter() {
			this.searchFor = '';
			this.searchInput = '';
			this.localData = [];
			this.initialSearchDone = false;
		},

		deleteClient(client) {
			this.$fire({
				title: 'Brisanje klijenta',
				html: `Da li ste sigurni da želite da obrišete klijenta<br><b>${client.name} (${client.pib})</b> ?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Obriši',
				confirmButtonColor: '#e74c3c',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					axios
						.delete(`client/${client.id}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								html: `Klijent je uspešno obrisan.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom brisanja klijenta.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
		},

		setupTableFields() {
			this.fields = [
				{
					name: 'client-slot',
					title: 'Klijent',
					sortField: 'clientName'
				},
				{
					name: 'pib',
					title: 'PIB klijenta'
				},
				{ name: 'email-slot', title: 'E-mail' },
				{ name: 'phone-slot', title: 'Telefon' },
				{ name: 'actions-slot', title: 'Akcije' }
			];

			if (this.user.role != 'PARTNER' && this.user.role != 'ONPREMADMIN') {
				this.fields.unshift({
					name: 'partner-slot',
					title: 'Partner',
					sortField: `partner.name`
				});
			}

			if (this.user.role == 'SUPERADMIN') {
				this.fields.unshift({
					name: 'distributor-slot',
					title: 'Distributer',
					sortField: `distributor.name`
				});
			}
		},

		showUserDetails(user) {
			this.selectedUser = user;
			this.$modal.show('user-details-from-clients-on-hold');
		},

		async getDataThroughFilter() {
			this.loadingTableData = true;
			await this.refreshData();
			this.initialSearchDone = true;
			this.loadingTableData = false;
		},

		async refreshData() {
			this.loadingTableData = true;
			await this.getOnHold();
			this.loadingTableData = false;
		},

		async getOnHold() {
			// console.log('getting clients on hold');
			try {
				const response = await axios.get(`client/clients-on-hold`, {
					params: {
						search: this.searchInput
					}
				});
				this.localData = response.data;
			} catch (error) {}
		},

		async resetIntervals() {
			this.loadingStartedOnHold();
			await this.refreshData();
			this.loadingFinishedOnHold();

			this.removeInterval();
			// this.setInterval();

			// console.log('resetIntervals on clients on hold');
		},

		async getDataIfNeeded() {
			if (!this.localData || this.localData == null || this.localData.length == 0) {
				this.loadingStartedOnHold();
				await this.refreshData();
				this.loadingFinishedOnHold();
			}
		},

		addClient() {
			this.$modal.show('add-client');
		},

		setInterval() {
			this.intervalId = setInterval(() => {
				if (this.$router.history.current.name == 'home') {
					this.getOnHold();
				}
			}, this.refreshInterval);
		},

		removeInterval() {
			// console.log('removed interval on hold');
			if (this.intervalId) {
				clearInterval(this.intervalId);
				this.intervalId = null;
			}
		},

		search() {
			this.searchFor = this.searchInput;
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},

		closeModal(name) {
			this.$modal.hide(name);
		},

		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.name.search(txt) >= 0 ||
						item.pib.search(txt) >= 0 ||
						(item.partner ? item.partner.name.search(txt) >= 0 : false) ||
						(item.distributor ? item.distributor.name.search(txt) >= 0 : false)
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.monitor-container {
	display: flex;
	flex-direction: column;
}

.status-slot img {
	object-fit: contain;
	width: 24px;
	height: 24px;
}

.status-slot {
	// min-width: 95px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.clients-on-hold-table {
	font-size: 15px;
}
.log-text {
	max-width: 100%;
}
.text-ellipsis {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.edit-nick-btn {
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	i {
		font-size: 13px;
	}
}
.logs-btn {
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
}
.num-of-invoices {
	font-weight: bold;
	white-space: nowrap;
}
.distributor-name {
	max-width: 250px;
}
.partner-client-txt {
	max-width: 150px;
}
.jid-txt {
	max-width: 150px;
}
.button-row button {
	margin-top: 0px;
	font-size: 0.9rem;
	margin-bottom: 0px;
}
</style>

<style lang="scss">
.clients-on-hold-table .vuetable-th-slot-actions-slot {
	width: 65px;
}
</style>
