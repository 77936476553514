<template>
	<div class="login-view-body">
		<div class="login-container">
			<form class="login-form bg-white shadow-lg text-left rounded-lg px-8 pt-6 pb-6 mb-4" @submit.prevent="login">
				<div class="flex flex-row mb-6 justify-center items-center">
					<i alt="eFiskalizator 2022" class="mr-3 far fa-credit-card text-2xl text-gray-600" />
					<h1 class="text-4xl text-center text-primary font-bold mr-2">eFiskalizator 2022</h1>
				</div>
				<div class="form-container">
					<input
						type="text"
						id="email"
						v-model="email"
						placeholder="E-mail adresa"
						class="focus:ring-primary focus:border-primary"
						:class="{ 'error-input': emailError }"
					/>

					<input
						:type="passwordInputType"
						id="password"
						v-model.trim="password"
						placeholder="Lozinka"
						class="focus:ring-primary focus:border-primary"
						:class="{ 'error-input': passwordError }"
					/>
					<div class="flex flex-row items-center" id="reveal-password-container">
						<input type="checkbox" style="margin-bottom: 0" id="reveal-password" v-model="revealPassword" />
						<label for="reveal-password" class="pl-2 text-sm">Prikaži lozinku</label>
					</div>
					<button
						:disabled="
							loading ||
							password == '' ||
							email == '' ||
							!this.email.match(this.emailRegex) ||
							this.password.length != 16
						"
						:title="helpInfo"
						type="submit"
						class="mt-4"
						id="login-btn"
					>
						Prijavi se
					</button>
				</div>
			</form>
			<div class="text-center text-xs flex flex-col items-center mt-6 pt-2 pb-2 text-gray-600">
				<p>
					Powered by
					<a target="_blank" rel="noopener noreferrer" href="https://proudsourceit.com/" class="text-red-800"
						>ProudSourceIT</a
					>
				</p>
				<p>Proud of our code!</p>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import { mapMutations } from 'vuex';

export default {
	data() {
		return {
			revealPassword: false,
			passwordInputType: 'password',
			email: '',
			password: '',
			emailError: false,
			passwordError: false,
			loading: false,
			emailRegex:
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		};
	},
	methods: {
		...mapMutations(['setUser']),
		async login() {
			if (!this.validateFields()) return;

			try {
				this.loading = true;
				const response = await axios.post('auth/login', {
					email: this.email,
					password: this.password
				});

				if (response.status == 200) {
					this.setUser(response.data);
					localStorage.setItem('token', response.headers['authorization']);
					localStorage.setItem('refresh-token', response.headers['refresh-token']);
					localStorage.setItem('user', JSON.stringify(response.data));
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.headers['authorization'];
					this.$router.push('/');
				}
			} catch (e) {
				let errorMessage = 'Dоšlo je do greške prilikom prijave. Molimo pokušajte kasnije';
				if (e.response.status === 403) {
					if (e.response.data.message === 'Invalid password') {
						errorMessage = 'Uneli ste netačnu šifru za dati nalog';
					} else {
						errorMessage = 'Korisnik sa datom e-mail adresom nije pronađen.';
					}
				}

				this.$fire({
					title: 'Greška',
					text: errorMessage,
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
				// console.log('e', e.response);
			} finally {
				this.loading = false;
			}
		},

		validateFields() {
			this.emailError = false;
			this.passwordError = false;

			let isValid = true;

			if (
				!this.email ||
				!this.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ||
				this.email == ''
			) {
				this.emailError = true;
				isValid = false;
			}

			if (!this.password || this.password == '') {
				this.passwordError = true;
				isValid = false;
			}
			return isValid;
		}
	},

	watch: {
		revealPassword() {
			if (this.revealPassword) {
				this.passwordInputType = 'text';
			} else {
				this.passwordInputType = 'password';
			}
		}
	},
	computed: {
		helpInfo() {
			let text = '';

			if (!this.email) {
				text = text + 'Potrebno je uneti e-mail adresu.\n';
			} else if (!this.email.match(this.emailRegex)) {
				text = text + 'E-mail adresa nije validna.\n';
			}
			if (!this.password) {
				text = text + 'Potrebno je uneti lozinku.';
			} else if (this.password.length != 16) {
				text = text + 'Lozinka mora imati tačno 16 karaktera.';
			}

			return text;
		}
	}
};
</script>

<style lang="scss" scoped>
#reveal-password-container {
	color: rgb(109, 109, 109);
	input {
		border-color: rgb(184, 184, 184);
	}
	input:checked {
		border-color: rgba(0, 0, 0, 0);
	}
}
#login-btn:disabled {
	cursor: help;
}
.error-input {
	background-color: rgb(235, 36, 36);
}
.login-view-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.login-container {
	width: 500px;
	margin: auto;
}
.login-form {
	background-color: rgb(250, 249, 252);
}

.form-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	text-align: left;
	margin: auto;
}

.form-container input {
	margin-bottom: 10px;
}
</style>
