import Vue from 'vue';
import Vuex from 'vuex';
import userModule from './modules/userModule';
import lpfrModule from './modules/lpfrModule';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		loadingMonitor: false,
		loadingLicences: false,
		loadingDashboard: false,
		loadingClientsOnHold: false,
		loadingLogfiles: false,
		refreshingStopped: false
	},

	mutations: {
		loadingStartedDashboard(state) {
			state.loadingDashboard = true;
		},
		loadingFinishedDashboard(state) {
			state.loadingDashboard = false;
		},
		loadingStartedMonitor(state) {
			state.loadingMonitor = true;
		},
		loadingFinishedMonitor(state) {
			state.loadingMonitor = false;
		},
		loadingStartedLicences(state) {
			state.loadingLicences = true;
		},
		loadingFinishedLicences(state) {
			state.loadingLicences = false;
		},
		loadingStartedOnHold(state) {
			state.loadingClientsOnHold = true;
		},
		loadingFinishedOnHold(state) {
			state.loadingClientsOnHold = false;
		},
		loadingStartedLogfiles(state) {
			state.loadingLogfiles = true;
		},
		loadingFinishedLogfiles(state) {
			state.loadingLogfiles = false;
		},
		stopRefreshing(state) {
			state.refreshingStopped = true;
		},
		startRefreshing(state) {
			state.refreshingStopped = false;
		}
	},
	modules: {
		user: userModule,
		lpfr: lpfrModule
	}
});
