<template>
	<div class="monitor-container">
		<div v-if="loading" style="height: 200px" class="flex flex-col justify-center">
			<spinner-component :size="50" />
		</div>
		<div>
			<modal :adaptive="true" height="auto" name="confirm-local-time">
				<modal-confirm-local-time
					@close="closeModal('confirm-local-time')"
					@refresh="refreshData"
					:lpfr="confirmLocalTimeModal.selectedLpfr"
				>
				</modal-confirm-local-time>
			</modal>
		</div>
		<div>
			<modal :adaptive="true" height="auto" name="force-update">
				<modal-force-update
					@close="closeModal('force-update')"
					@refresh="refreshData"
					:lpfr="forceUpdateModal.selectedLpfr"
					:lpfrs="forceUpdateModal.selectedLpfrs"
					:updateAll="forceUpdateModal.updateAll"
				>
				</modal-force-update>
			</modal>
		</div>
		<div>
			<modal :adaptive="true" height="auto" name="tax-core-file">
				<modal-upload-tax-core-file
					@close="closeModal('tax-core-file')"
					@refresh="refreshData"
					:lpfr="taxCoreFileModal.selectedLpfr"
				>
				</modal-upload-tax-core-file>
			</modal>
		</div>
		<div>
			<modal :adaptive="true" :width="1240" height="auto" name="recovery-invoices-modal">
				<div class="flex justify-end m-2 absolute right-1 top-1">
					<button
						class="bg-secondary hover:bg-secondary-light"
						style="width: 34px; height: 34px; line-height: 0"
						@click="$modal.hide('recovery-invoices-modal')"
					>
						<i class="fas fa-times" />
					</button>
				</div>
				<modal-recovery-invoices
					@close="closeModal('recovery-invoices-modal')"
					:lpfr="recoveryInvoicesModal.selectedLpfr"
				>
				</modal-recovery-invoices>
			</modal>
		</div>

		<div>
			<modal :adaptive="true" height="auto" name="additional-info">
				<div class="flex flex-row justify-end m-2 absolute right-1 top-1">
					<button
						class="bg-secondary hover:bg-secondary-light"
						style="width: 34px; height: 34px; line-height: 0"
						@click="$modal.hide('additional-info')"
					>
						<i class="fas fa-times" />
					</button>
				</div>
				<modal-lpfr-additional-info
					@close="closeModal('additional-info')"
					@edit-client="showUserDetails"
					:info="selectedInfo"
				>
				</modal-lpfr-additional-info>
			</modal>
		</div>

		<modal :adaptive="true" height="auto" name="user-details">
			<modal-user-details
				@close="closeModal('user-details')"
				@refresh="refreshData"
				:user="selectedUser"
			></modal-user-details>
		</modal>
		<modal :draggable="true" :adaptive="true" height="auto" name="register-lpfr">
			<modal-register-lpfr @close="closeModal('register-lpfr')" @refresh="refreshData"
		/></modal>
		<modal :draggable="true" :adaptive="true" :height="'auto'" :focusTrap="true" name="add-client">
			<modal-add-client @close="closeModal('add-client')" @refresh="refreshData" />
		</modal>
		<modal :draggable="true" :adaptive="true" :height="'auto'" :focusTrap="true" name="add-partner">
			<modal-add-partner @close="closeModal('add-partner')" @refresh="refreshData" />
		</modal>
		<modal :draggable="true" :adaptive="true" :height="'auto'" :focusTrap="true" name="add-distributor">
			<modal-add-distributor @close="closeModal('add-distributor')" @refresh="refreshData" />
		</modal>
		<modal :draggable="true" :adaptive="true" :focusTrap="true" :height="'auto'" name="edit-nickname">
			<modal-edit-nickname :row="lpfrRow" @close="closeModal('edit-nickname')" @refresh="refreshData" />
		</modal>
		<modal :draggable="true" :focusTrap="true" :height="'auto'" name="logs">
			<div class="flex justify-end m-2 absolute right-1 top-1">
				<button
					class="bg-secondary hover:bg-secondary-light"
					style="width: 34px; height: 34px; line-height: 0"
					@click="$modal.hide('logs')"
				>
					<i class="fas fa-times" />
				</button>
			</div>
			<modal-list-of-logs :lpfr="listOfLogsModal.selectedLpfr" />
		</modal>
		<div class="content" v-if="!loading">
			<div class="button-row">
				<div class="filter-container flex justify-start" style="align-items: stretch">
					<div class="status-container">
						<h1 class="text-xl font-semibold status-filter-header">Env.</h1>
						<div class="status-options flex items-center licence-tab-filter-options px2">
							<div class="flex flex-row">
								<input type="radio" id="type-prod-licence" value="PRODUCTION" v-model="filterType" />
								<label for="type-prod-licence">PROD</label>
							</div>
							<div class="flex flex-row">
								<input type="radio" id="type-dev-licence" value="SANDBOX" v-model="filterType" />
								<label for="type-dev-licence">DEV</label>
							</div>
							<div v-if="user.canSeePrintLPFR" class="flex flex-row">
								<input type="radio" id="type-print-licence" value="PRINT" v-model="filterType" />
								<label for="type-print-licence">PRINT</label>
							</div>
						</div>
					</div>
					<div class="status-container">
						<h1 class="text-xl font-semibold status-filter-header">Status</h1>
						<div class="status-options flex-col flex justify-start items-start px-2">
							<div class="flex flex-row">
								<input type="radio" id="status-1" value="1" v-model="filterStatus" />
								<label for="status-1">Operativan</label>
							</div>
							<div class="flex flex-row">
								<input type="radio" id="status-2" value="2" v-model="filterStatus" />
								<label for="status-2">Upozorenje</label>
							</div>
							<div class="flex flex-row">
								<input type="radio" id="status-3" value="3" v-model="filterStatus" />
								<label for="status-3">Van funkcije</label>
							</div>
							<div class="flex flex-row">
								<input type="radio" id="status-0" value="0" v-model="filterStatus" />
								<label for="status-0">Nepoznat</label>
							</div>
						</div>
					</div>
					<div v-if="user.productTypes != null && user.productTypes.length > 0" class="status-container">
						<h1 class="text-xl font-semibold status-filter-header">Tip</h1>
						<div class="status-options flex-col flex items-start justify-start px-2">
							<div class="flex flex-row">
								<input type="checkbox" id="product-type-1" v-model="filterProductType.filterAddLpfr" />
								<label for="product-type-1">LPFR</label>
							</div>
							<div class="flex flex-row">
								<div v-if="user.productTypes.includes('EFT')">
									<input type="checkbox" id="product-type-2" v-model="filterProductType.filterAddEft" />
									<label for="product-type-2">EFT</label>
								</div>
							</div>
							<div class="flex flex-row">
								<div v-if="user.productTypes.includes('IPS')">
									<input type="checkbox" id="product-type-3" v-model="filterProductType.filterAddIps" />
									<label for="product-type-3">IPS</label>
								</div>
							</div>
						</div>
					</div>
					<div class="status-container">
						<h1 class="text-xl font-semibold status-filter-header">Računi</h1>
						<div class="status-options flex items-center px-2">
							<input
								type="checkbox"
								name="onlyWithRecoveryInvoicesFilter"
								id="recovery-invoices-exist-monitor"
								v-model="onlyWithRecoveryInvoicesFilter"
							/>
							<label for="recovery-invoices-exist-monitor">Postoje računi za oporavak</label>
						</div>
					</div>
					<div class="flex flex-row flex-nowrap justify-center items-center ml-4">
						<input
							type="text"
							class="focus:ring-primary focus:border-primary"
							v-model="searchInput"
							:placeholder="searchPlaceholder"
							style="width: 420px"
							v-on:keyup.enter="getDataThroughFilter"
						/>
						<button
							title="Pretraži"
							@click="getDataThroughFilter()"
							style="width: 38px; height: 38px; margin-bottom: 0px"
						>
							<i class="fas fa-search" />
						</button>
						<button
							v-if="initialSearchDone"
							title="Poništi filter i osveži tabelu"
							class="cancel-filter-button"
							@click="cancelFilter()"
							style="width: 38px; height: 38px; margin-bottom: 0px"
						>
							<i class="fas fa-times" />
						</button>
					</div>
				</div>
				<spinner-component v-if="loadingTableData" :size="45" />
				<div class="flex flex-col">
					<div class="flex flex-row justify-between flex-wrap button-group">
						<div v-click-outside="closeCreateDropdown" style="z-index: 1" class="relative">
							<button class="flex flex-row items-center" @click="toggleCreateDropdown">
								Kreiraj <i class="fas fa-plus ml-2 text-sm"></i>
							</button>

							<transition name="fade">
								<div v-if="createDropdownActive" class="flex flex-col absolute dropdown-content create-dropdown">
									<a v-if="user.role == 'SUPERADMIN'" @click="addDistributor">Distributera</a>
									<a v-if="user.role != 'PARTNER' && user.role != 'ONPREMADMIN'" @click="addPartner">Partnera</a>
									<a @click="addClient">Klijenta</a>
									<a @click="registerLpfr">Proizvod</a>
								</div>
							</transition>
						</div>
						<div style="margin-left: 24px; border-left: 1px solid #d0d0d0"></div>
						<button style="margin-left: 24px" title="Osveži tabelu" @click="getDataThroughFilter()">
							Osveži
							<i class="fas fa-redo ml-2"></i>
						</button>
					</div>
				</div>
			</div>

			<div v-if="initialSearchDone">
				<vuetable
					ref="vuetable"
					:api-mode="false"
					:fields="fields"
					:css="css.table"
					:per-page="perPage"
					noDataTemplate="Nisu pronađeni LPFR-ovi"
					:data-manager="dataManager"
					pagination-path="pagination"
					@vuetable:pagination-data="onPaginationData"
					@vuetable:loaded="onLoaded"
					class="monitor-table"
				>
					<div class="slot flex flex-row" slot="distributor-slot" slot-scope="props">
						<span
							:title="props.rowData.distributor.name"
							class="text-xs text-ellipsis distributor-name"
							v-if="props.rowData.distributor"
							style="width: 100%"
						>
							<div
								@click="showUserDetails(props.rowData.distributor)"
								style="width: 100%"
								class="cursor-pointer hover:text-primary"
							>
								{{ props.rowData.distributor.name }}
							</div>
						</span>
						<span v-else class="text-xs text-center">/</span>
					</div>
					<div class="slot flex flex-row" slot="ou-slot" slot-scope="props">
						<span
							:title="props.rowData.additionalInfo.OU"
							v-if="props.rowData.additionalInfo && props.rowData.additionalInfo.OU"
						>
							{{ computeOU(props.rowData.additionalInfo.OU) }}
						</span>
						<span v-else>/</span>
					</div>
					<div class="slot flex flex-row" slot="partner-slot" slot-scope="props">
						<span
							:title="props.rowData.partner.name"
							class="text-xs text-ellipsis distributor-name"
							v-if="props.rowData.partner"
							style="width: 100%"
						>
							<div
								@click="showUserDetails(props.rowData.partner)"
								style="width: 100%"
								class="cursor-pointer hover:text-primary"
							>
								{{ props.rowData.partner.name }}
							</div>
						</span>
						<span v-else class="text-xs text-center">/</span>
					</div>
					<div class="slot flex flex-row items-center" slot="client-slot" slot-scope="props" style="width: 100%">
						<div
							:title="props.rowData.client.name"
							class="font-semibold text-ellipsis partner-client-txt cursor-pointer hover:text-primary"
							@click="showUserDetails(props.rowData.client)"
							style="width: 100%"
						>
							{{ props.rowData.client.name }}
						</div>
					</div>
					<div class="slot" slot="jid-slot" slot-scope="props">
						<div style="display: flex; flex-direction: row; align-items: center; max-width: 300px">
							<span style="margin-right: 12px">
								<button
									title="Dodatne informacije o proizvodu"
									@click="viewAdditionalInfo(props.rowData)"
									class="edit-nick-btn"
								>
									<i class="fas fa-info"></i></button
								><button title="Dodaj nadimak" @click="editNickname(props.rowData)" class="edit-nick-btn ml-1">
									<i class="fas fa-pen"></i></button
							></span>
							<span
								v-if="props.rowData.jidNickname"
								:title="props.rowData.jid + ' (' + props.rowData.jidNickname + ')'"
								>{{ props.rowData.jid + ' (' + props.rowData.jidNickname + ')' }}</span
							>
							<span v-else>{{ props.rowData.jid }}</span>
						</div>
					</div>
					<div class="slot status-slot" slot="status-slot" slot-scope="props">
						<span v-if="props.rowData.status == 1">
							<img title="Operativan" src="@/assets/Green.png" />
							<!-- <p>Operativan</p> -->
						</span>
						<span v-else-if="props.rowData.status == 2">
							<img title="Upozorenje" src="@/assets/Orange.png" />
							<!-- <p>Upozorenje</p> -->
						</span>
						<span v-else-if="props.rowData.status == 3">
							<img title="Van funkcije" src="@/assets/Red.png" />
							<!-- <p>Van funkcije</p> -->
						</span>
						<span v-else>
							<img title="Nepoznat" src="@/assets/Gray.png" />
							<!-- <p>Nepoznat</p> -->
						</span>
					</div>
					<div class="slot flex flex-row justify-center" slot="reset-lpfr-slot" slot-scope="props">
						<!-- Reset LPFR button section -->
						<div class="flex flex-row justify-center">
							<button
								v-if="!props.rowData.resetLpfr"
								style="height: 26px; width: 26px; padding: 2px 0px 0px 0px"
								@click="resetLPFR(props.rowData)"
								class="reset-lpfr-btn"
								title="Resetuj proizvod"
							>
								<i class="fas fa-redo"></i>
							</button>
							<button
								v-else
								title="Poništi resetovanje proizvoda"
								style="height: 26px; width: 26px; padding: 2px 0px 0px 0px"
								class="bg-red-600 hover:bg-red-800"
								@click="cancelResetLPFR(props.rowData)"
							>
								<i class="fas fa-redo"></i>
							</button>
							<div
								class="flex flex-row"
								v-if="user.role == 'SUPERADMIN' || user.role == 'ONPREMADMIN' || props.rowData.ownedByLoggedUser"
							>
								<button
									v-if="!props.rowData.doLocalAudit"
									style="height: 26px; width: 26px; padding: 2px 0px 0px 0px"
									@click="doLocalAudit(props.rowData, true)"
									class="reset-lpfr-btn ml-1"
									title="Odradi lokalnu reviziju"
								>
									<i class="fas fa-clipboard-list"></i>
								</button>
								<button
									v-else
									title="Poništi rađenje lokalne revizije"
									style="height: 26px; width: 26px; padding: 2px 0px 0px 0px"
									class="bg-red-600 hover:bg-red-800 ml-1"
									@click="doLocalAudit(props.rowData, false)"
								>
									<i class="fas fa-clipboard-list"></i>
								</button>
								<button
									v-if="
										!props.rowData.confirmLocalTime &&
										props.rowData.lastNotifyLog &&
										(props.rowData.lastNotifyLog.includes('NTP') ||
											props.rowData.lastNotifyLog.includes('Sinhronizacija'))
									"
									title="Potvrdi lokalno vreme"
									style="height: 26px; width: 26px; padding: 2px 0px 0px 0px"
									class="ml-1"
									@click="confirmLocalTime(props.rowData)"
								>
									<i class="fas fa-clock"></i>
								</button>
							</div>
							<div>
								<button
									v-if="!props.rowData.hasTaxCoreFile"
									style="height: 26px; width: 26px; padding: 2px 0px 0px 0px"
									@click="openTaxCoreFileModal(props.rowData)"
									class="reset-lpfr-btn ml-1"
									title="Postavi TaxCore ZIP fajl"
								>
									<i class="fas fa-qrcode"></i>
								</button>
							</div>
						</div>
					</div>
					<div class="slot" slot="last-update-slot" slot-scope="props">
						<span>{{ props.rowData.lastUpdate | formatDate }}</span>
					</div>
					<div class="slot flex flex-row justify-between items-center" slot="log-slot" slot-scope="props">
						<span title="Ne postoji log" v-if="!props.rowData.lastNotifyLog"
							>Ne postoji log&nbsp;
							<span
								v-if="
									!props.rowData.lastNotifyLog == 0 &&
									props.rowData.numOfUnconfirmedSentInvoices != null &&
									props.rowData.numOfUnregisteredInvoices != null
								"
								class="num-of-invoices"
								>({{ props.rowData.numOfUnconfirmedSentInvoices }} /
								{{ props.rowData.numOfUnregisteredInvoices }})</span
							></span
						>
						<div
							class="log-slot-container"
							@click="showLogs(props.rowData)"
							v-else
							@mouseover="computeLogExplanation(props.rowData.lastNotifyLog)"
						>
							<span :title="selectedLogExplanation ? selectedLogExplanation : props.rowData.lastNotifyLog" class=""
								>{{ computeLogText(props.rowData.lastNotifyLog) }} &nbsp;<span
									v-if="
										props.rowData.numOfUnconfirmedSentInvoices ||
										props.rowData.numOfUnregisteredInvoices ||
										props.rowData.numOfRecoveryInvoices
									"
									class="num-of-invoices"
									>({{ props.rowData.numOfUnconfirmedSentInvoices ?? 0 }} /
									{{ props.rowData.numOfUnregisteredInvoices ?? 0 }} / {{ props.rowData.numOfRecoveryInvoices ?? 0 }})
								</span></span
							>
							<!-- <button
								class="ml-2 logs-btn"
								title="Prikaži logove"
								@click="showLogs(props.rowData)"
							>
								<i class="fas fa-list"></i>
							</button> -->
						</div>
						<button
							v-if="props.rowData.numOfRecoveryInvoices"
							style="height: 26px; width: 26px; padding: 2px 0px 0px 0px"
							@click="showRecoveryInvoices(props.rowData)"
							class="show-recovery-invoices-btn"
							title="Prikaži račune za oporavak"
						>
							<i class="fas fa-file-invoice"></i>
						</button>
					</div>
					<div slot="version-slot" class="slot" slot-scope="props">
						<div
							v-if="
								props.rowData.productType == 'LPFR' &&
								props.rowData.version != '/' &&
								props.rowData.version != currentVersion.version &&
								new Date() >= new Date(currentVersion.firstWaveDate)
							"
							class="flex flex-row justify-between items-center"
						>
							{{ props.rowData.version }}
							<button
								v-if="!props.rowData.forceUpdate"
								@click="forceUpdate(props.rowData)"
								style="width: 26px; height: 26px; padding: 2px 1px 0px 1px"
								title="Forsiraj ažuriranje na novu verziju"
								class="ml-1 self-end"
							>
								<i class="fas fa-arrow-circle-up"></i>
							</button>
							<button
								v-else
								@click="cancelForceUpdate(props.rowData)"
								style="width: 26px; height: 26px; padding: 2px 1px 0px 1px"
								title="Otkaži prelazak na novu verziju"
								class="ml-1 self-end bg-red-600 hover:bg-red-800"
							>
								<i class="fas fa-arrow-circle-down"></i>
							</button>
						</div>
						<div class="flex flex-row justify-center items-center" v-else>
							{{ props.rowData.version }}
						</div>
					</div>
				</vuetable>
				<div class="self-end text-sm mt-2 absolute left-10 align-left text-left">
					<div class="pl-2">Aktuelna LPFR verzija: {{ currentVersion.version }}</div>
					<div class="flex flex-row">
						<button
							class="subtle-btn flex flex-row justify-center items-center"
							v-if="new Date() >= new Date(currentVersion.firstWaveDate) && numOfOldVersions"
							@click="forceUpdateAll"
						>
							<i class="fas fa-arrow-circle-up mr-2"></i>Forsiraj ažuriranje svih filtriranih LPFR-ova direktnih
							klijenata
						</button>
						<button
							class="subtle-btn-danger ml-2 flex flex-row justify-center items-center"
							v-if="this.personalClients.filter(x => x.forceUpdate).length > 0"
							@click="cancelUpdateAll"
						>
							<i class="fas fa-arrow-circle-down mr-2"></i>Povuci ažuriranja svih filtriranih LPFR-ova direktnih
							klijenata
						</button>
					</div>
				</div>
				<div class="flex flex-row justify-end items-center">
					<div class="mr-6">
						{{ numOfDisplayed }}
						/
						{{ numOfTotal }}
					</div>
					<div class="flex flex-row per-page-div">
						<input type="radio" id="10-per-page-monitor" :value="10" v-model="perPage" /><label
							:class="{ active: perPage == 10 }"
							for="10-per-page-monitor"
							>10</label
						>
						<input type="radio" id="20-per-page-monitor" :value="20" v-model="perPage" /><label
							:class="{ active: perPage == 20 }"
							for="20-per-page-monitor"
							>20</label
						>
						<input type="radio" id="50-per-page-monitor" :value="50" v-model="perPage" /><label
							:class="{ active: perPage == 50 }"
							for="50-per-page-monitor"
							>50</label
						>
						<input type="radio" id="100-per-page-monitor" :value="100" v-model="perPage" /><label
							:class="{ active: perPage == 100 }"
							for="100-per-page-monitor"
							>100</label
						>
					</div>
					<vuetable-pagination
						ref="pagination"
						class="pull-right"
						@vuetable-pagination:change-page="onChangePage"
					></vuetable-pagination>
				</div>
			</div>
			<div v-else class="mt-24 mb-36">
				<h1 class="text-2xl">Izvršite pretragu iznad ili osvežite tabelu, kako bi učitali podatke</h1>
			</div>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from './VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import _ from 'lodash';
import ModalAddClient from './ModalAddClient.vue';
import ModalEditNickname from './ModalEditNickname.vue';
import ModalListOfLogs from './ModalListOfLogs.vue';
import { mapMutations, mapState } from 'vuex';
import axios from '@/axios/axios.js';
import ModalAddPartner from './ModalAddPartner.vue';
import ModalAddDistributor from './ModalAddDistributor.vue';
import ModalRegisterLpfr from './ModalRegisterLPFR.vue';
import ModalUserDetails from './ModalUserDetails.vue';
import ModalForceUpdate from './ModalForceUpdate.vue';
import vClickOutside from 'v-click-outside';
import ModalLpfrAdditionalInfo from './ModalLpfrAdditionalInfo.vue';
import ModalUploadTaxCoreFile from './ModalUploadTaxCoreFile.vue';
import ModalConfirmLocalTime from './ModalConfirmLocalTime.vue';
import ModalRecoveryInvoices from './ModalRecoveryInvoices.vue';
const semver = require('semver');

export default {
	components: {
		Vuetable,
		VuetablePagination,
		ModalAddClient,
		ModalEditNickname,
		ModalListOfLogs,
		ModalAddPartner,
		ModalAddDistributor,
		ModalRegisterLpfr,
		ModalUserDetails,
		ModalForceUpdate,
		ModalLpfrAdditionalInfo,
		ModalUploadTaxCoreFile,
		ModalConfirmLocalTime,
		ModalRecoveryInvoices
	},
	directives: {
		clickOutside: vClickOutside.directive
	},

	props: {
		refresher: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			initialSearchDone: false,
			currentVersion: {
				version: '?',
				prereleaseDate: null,
				firstWaveDate: null,
				secondWaveDate: null,
				thirdWaveDate: null,
				firstWaveCounter: null,
				secondWaveCounter: null
			},
			loadingTableData: false,
			numOfTotal: 0,
			numOfDisplayed: 0,
			perPage: 20,
			createDropdownActive: false,
			intervalId: null,
			filterStatus: null,
			filterProductType: {
				filterAddLpfr: true,
				filterAddEft: false,
				filterAddIps: false
			},
			filterType: 'PRODUCTION',
			searchInput: '',
			searchFor: '',
			localData: [],
			css: VuetableCssConfig,
			fields: [],
			refreshInterval: 60000,
			lpfrRow: null,
			logs: null,
			selectedUser: null,
			selectedInfo: {
				OU: null,
				O: null,
				STREET: null,
				L: null,
				S: null,
				C: null,
				certificateExpirationDate: null
			},
			onlyWithRecoveryInvoicesFilter: false,
			forceUpdateModal: {
				selectedLpfrs: [],
				selectedLpfr: null,
				updateAll: false
			},
			taxCoreFileModal: {
				selectedLpfr: null
			},
			recoveryInvoicesModal: {
				selectedLpfr: null
			},
			confirmLocalTimeModal: {
				selectedLpfr: null
			},
			listOfLogsModal: {
				selectedLpfr: null
			},
			personalClients: [],
			logExplanations: [],
			selectedLogExplanation: ''
		};
	},

	async created() {
		this.loadingStartedMonitor();

		// await this.getLPFRs();
		// has to be called after getLPFRs() to set up sort fields for admin correctly
		this.setupTableFields();
		this.setupLogExplainations();

		this.loadingFinishedMonitor();

		if (this.user.productTypes.includes('EFT')) {
			this.filterProductType.filterAddEft = true;
		}

		if (this.user.productTypes.includes('IPS')) {
			this.filterProductType.filterAddIps = true;
		}
	},

	computed: {
		...mapState({
			loading: state => {
				return state.loadingMonitor;
			}
		}),
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id,
					canSeePrintLPFR: state.user.user.canSeePrintLPFR,
					productTypes: state.user.user.productTypes
				};
			}
		}),
		searchPlaceholder() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'Naziv distributera/partnera/klijenta, PIB, JID, nadimak, ...';
				case 'DISTRIBUTOR':
					return 'Naziv partnera/klijenta, PIB, JID, nadimak, ...';
				default:
					return 'Naziv klijenta, PIB, JID, nadimak, ...';
			}
		},
		clientFieldTitle() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'Partner / Klijent';
				case 'DISTRIBUTOR':
					return 'Partner / Klijent';
				case 'PARTNER':
					return 'Klijent';
				default:
					return 'Klijent';
			}
		},
		numOfOldVersions() {
			if (this.personalClients.length) {
				return this.personalClients.filter(lpfr => {
					return lpfr.version != '/' && lpfr.version != this.currentVersion.version && !lpfr.forceUpdate;
				}).length;
			} else {
				return 0;
			}
		}
	},

	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		},

		async filterStatus() {
			if (this.filterStatus && this.initialSearchDone) {
				this.loadingTableData = true;
				await this.refreshData();
				this.loadingTableData = false;
			}
		},

		async filterProductType() {
			if (this.filterProductType && this.initialSearchDone) {
				this.loadingTableData = true;
				await this.refreshData();
				this.loadingTableData = false;
			}
		},

		async filterType() {
			if (this.filterType && this.initialSearchDone) {
				this.loadingTableData = true;
				await this.refreshData();
				this.loadingTableData = false;
			}
		},

		refresher() {
			this.refreshData();
		}
	},
	methods: {
		...mapMutations(['loadingStartedMonitor', 'loadingFinishedMonitor', 'setLpfrs', 'setArchived']),

		onLoaded() {
			this.numOfDisplayed = this.$refs.vuetable.countTableData;
			this.numOfTotal = this.$refs.pagination.tablePagination.total;
		},

		async getDataThroughFilter() {
			this.loadingTableData = true;
			await this.refreshData();
			this.initialSearchDone = true;
			this.loadingTableData = false;
		},

		async resetIntervals() {
			this.loadingStartedMonitor();
			await this.refreshData();
			this.loadingFinishedMonitor();

			this.removeInterval();
			// this.setInterval();

			// console.log('resetIntervals on monitors');
		},

		async getDataIfNeeded() {
			if (!this.localData || this.localData == null || this.localData.length == 0) {
				this.loadingStartedMonitor();
				await this.refreshData();
				this.loadingFinishedMonitor();
			}
		},

		setInterval() {
			if (this.intervalId) return;
			this.intervalId = setInterval(() => {
				if (this.$router.history.current.name == 'home') {
					this.getLPFRs();
				}
			}, this.refreshInterval);
		},

		removeInterval() {
			if (this.intervalId) {
				clearInterval(this.intervalId);
				this.intervalId = null;
			}
		},

		computeOU(text) {
			if (text.length > 25) {
				return text.substring(0, 22) + '...';
			} else {
				return text;
			}
		},

		setupTableFields() {
			this.fields = [
				{
					name: 'client-slot',
					title: 'Klijent',
					sortField: 'clientName'
				},
				{
					name: 'pib',
					title: 'PIB klijenta'
				},
				{
					name: 'ou-slot',
					title: 'OU',
					sortField: `additionalInfo.OU`
				},
				{
					name: 'jid-slot',
					title: 'JID'
				},
				{
					name: 'productType',
					title: 'Tip',
					sortField: 'productType'
				},
				{
					name: 'last-update-slot',
					title: 'Poslednje javljanje',
					sortField: 'lastUpdateSort'
				},
				{ name: 'status-slot', title: 'Status', sortField: 'status' },
				{ name: 'type', title: 'Env.', sortField: 'type' },
				{
					name: 'version-slot',
					title: 'Ver.',
					sortField: 'versionOrder'
				},
				{
					name: 'log-slot',
					title: 'Log (nepotvrđeni/neposlati/oporavak)'
				},
				{
					name: 'reset-lpfr-slot',
					title: 'Akcije'
				}
			];

			if (this.user.role != 'PARTNER' && this.user.role != 'ONPREMADMIN') {
				this.fields.unshift({
					name: 'partner-slot',
					title: 'Partner',
					sortField: `partner.name`
				});
			}

			if (this.user.role == 'SUPERADMIN') {
				this.fields.unshift({
					name: 'distributor-slot',
					title: 'Distributer',
					sortField: `distributor.name`
				});
			}
			// this.fields.unshift({
			// 	name: 'ou-slot',
			// 	title: 'OU',
			// 	sortField: `additionalInfo.OU`
			// });
		},

		setupLogExplainations() {
			this.logExplanations = [
				{
					logText: 'Neuspešno kreiranje Audit paketa za potpisan račun!',
					desc: 'Došlo je do greške prilikom potpisivanja računa na kartici, ali je kartica uvećala brojač.',
					action:
						'Korisnik može da nastavi sa izdavanjem računa, dok ne dođe do statusa:\nLimit je preko 75% popunjenosti!\nLimit je preko 90% popunjenosti!\n\nNakon čega je potrebno zameniti karticu.'
				},
				{
					logText: 'Audit paket odbijen u PU!',
					desc: 'Došlo je do greške prilikom slanja računa na TaxCore API. Najčešći razlog jeste lažno odbijanje, tako da ponovno slanje može rešiti problem.',
					action:
						'Na status ekranu LPFRa pojaviće se dugme "Ponovi slanje računa", koje je potrebno pritisnuti, a potom sačekati da dođe do slanja i potvrde računa.'
				},
				{
					logText: 'Limit je preko 75% popunjenosti!',
					desc: 'Iznos fiskalizovanih nepotvrđenih računa je prešao 75% dozvoljenog limita.',
					action:
						'Ukazuje na problem sa nedobijanjem potvrde za fiskalizovane račune. Potrebno je proveriti da li je uzrok greška "Neuspešno kreiranje Audit paketa za potpisan račun! ", kada je potrebno pribaviti novu karticu, ili je uzrok nedostupnost TaxCore APIja, kada je potrebno uraditi Lokalno iščitavanje računa.'
				},
				{
					logText: 'Limit je preko 90% popunjenosti!',
					desc: 'Iznos fiskalizovanih nepotvrđenih računa je prešao 90% dozvoljenog limita.',
					action:
						'Ukazuje na problem sa nedobijanjem potvrde za fiskalizovane račune. Potrebno je proveriti da li je uzrok greška "Neuspešno kreiranje Audit paketa za potpisan račun! ", kada je potrebno pribaviti novu karticu, ili je uzrok nedostupnost TaxCore APIja, kada je potrebno uraditi Lokalno iščitavanje računa.'
				},
				{
					logText: 'Limit je 100% popunjen! Blokirano do potvrde računa.',
					desc: 'Iznos fiskalizovanih nepotvrđenih računa je dostigao 100% dozvoljenog limita. Dalja fiskalizacija računa nije moguća.',
					action:
						'Ukazuje na problem sa nedobijanjem potvrde za fiskalizovane račune. Potrebno je proveriti da li je uzrok greška "Neuspešno kreiranje Audit paketa za potpisan račun! ", kada je potrebno pribaviti novu karticu, ili je uzrok nedostupnost TaxCore APIja, kada je potrebno uraditi Lokalno iščitavanje računa.'
				},
				{
					logText: 'Licenca ističe za manje od 7 dana',
					desc: 'Do kraja meseca ističe licenca za korišćenje LPFRa.',
					action: 'Potrebno je kroz Web monitor alat produžiti licencu korisniku.'
				},
				{
					logText: 'Licenca istekla.',
					desc: 'Licenca za LPFR je istekla. Dalje korišćenje je onemogućeno.',
					action:
						'Potrebno je kroz Web monitor alat produžiti licencu korisniku a potom resetovati LPFR, kako bi povukao novu licencu.'
				},
				{
					logText: 'Sertifikat na kartici ističe za manje od 30 dana',
					desc: 'U roku od 30 dana ističe sertifikat na kartici izdat od strane PU.',
					action: 'Potrebno je pribaviti novu karticu, sa novim validnim sertifikatom.'
				},
				{
					logText: 'Račun nije poslat u PU više od 3 dana',
					desc: 'U poslednja tri dana nije uspešno poslat račun na TaxCore.',
					action: 'Potrebno je proveriti Internet konekciju kao i dostupnost TaxCore APIja.'
				},
				{
					logText: 'Račun nije poslat u PU više od 5 dana',
					desc: 'U poslednjih pet dana nije uspešno poslat račun na TaxCore. Dalja fiskalizacija računa nije moguća.',
					action:
						'Potrebno je proveriti Internet konekciju kao i dostupnost TaxCore APIja. Neophodno je uraditi lokalno iščitavanje. Detaljno uputstvo se nalazi na web monitor aplikaciji.'
				},
				{
					logText: 'Račun nije potvrđen u PU više od 5 dana',
					desc: 'U poslednjih pet dana nije uspešno potvrđen račun na TaxCore.',
					action:
						'Korisnik može da nastavi sa izdavanjem računa, dok ne dođe do statusa:\nLimit je preko 75% popunjenosti!\nLimit je preko 90% popunjenosti!\n\nNakon čega je potrebno zameniti karticu.'
				},
				{
					logText: 'Tax Core API nije dostupan',
					desc: 'TLS konekcija kao TaxCore API serveru i pozivanje TaxCoreAPIja nisu bili uspešni.',
					action:
						'Potrebno je proveriti Internet konekciju kao i dostupnost TaxCore APIja. Uzrok može biti i zaglavljivanje kartice, kada je potrebno izvući pa vratiti karticu i resetovati LPFR.'
				},
				{
					logText: 'Sinhronizacija vremena je bila pre više od 48 časova',
					desc: 'NTP server nije uspešno kontaktiran u poslednjih 48 časova. Dalja fiskalizacija nije moguća.',
					action:
						'Potrebno je proveriti Internet konekciju kao i dostupnost NTP servera. Alternativno, klikom na dugme Potvrdi vreme se može manuelno izvršiti potvrda lokalnog vremena.'
				}
			];
		},

		computeLogExplanation(logText) {
			//if logExplanations contains logText, return explanation

			const logExplanation = this.logExplanations.find(logExplanation => logText.includes(logExplanation.logText));

			if (logExplanation) {
				this.selectedLogExplanation = `${logText}\n\nOpis: ${logExplanation.desc}\n\nAkcija: ${logExplanation.action}`;
			} else {
				this.selectedLogExplanation = '';
				return;
			}
		},

		toggleCreateDropdown() {
			this.createDropdownActive = !this.createDropdownActive;
		},

		closeCreateDropdown() {
			this.createDropdownActive = false;
		},

		confirmLocalTime(rowData) {
			this.confirmLocalTimeModal.selectedLpfr = rowData;
			this.$modal.show('confirm-local-time');
		},

		doLocalAudit(rowData, doLocalAudit) {
			let text = '';
			if (doLocalAudit) {
				text = 'Da li ste sigurni da želite da izvršite lokalnu reviziju?';
			} else {
				text = 'Da li ste sigurni da želite da poništite izvršavanje lokalne revizije?';
			}

			this.$fire({
				title: 'Lokalna revizija proizvoda',
				text,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Potvrdi',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					axios
						.post(`lpfr/${rowData.id}/doLocalAudit`, { doLocalAudit })
						.then(response => {
							this.refreshData();
						})
						.catch(error => {
							this.loadingFinishedMonitor();
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške. Molimo pokušajte kasnije.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
		},

		resetLPFR(rowData) {
			this.$fire({
				title: 'Resetovanje proizvoda',
				html: `Da li ste sigurni da želite da resetujete<br><b>${rowData.productType} - ${rowData.jid} ${
					rowData.jidNickname ? '(' + rowData.jidNickname + ')' : ''
				}</b>?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Resetuj LPFR',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					axios
						.post(`lpfr/${rowData.id}/reset`, { reset: true })
						.then(response => {
							this.refreshData();
						})
						.catch(error => {
							this.loadingFinishedMonitor();
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom resetovanja LPFR.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
		},

		forceUpdate(lpfr) {
			this.forceUpdateModal.selectedLpfr = lpfr;
			this.forceUpdateModal.updateAll = false;
			this.$modal.show('force-update');
		},

		openTaxCoreFileModal(lpfr) {
			this.taxCoreFileModal.selectedLpfr = lpfr;
			this.$modal.show('tax-core-file');
		},

		showRecoveryInvoices(lpfr) {
			this.recoveryInvoicesModal.selectedLpfr = lpfr;
			this.$modal.show('recovery-invoices-modal');
		},

		forceUpdateAll() {
			const lpfrsToUpdate = this.personalClients.filter(lpfr => {
				return (
					lpfr.productType == 'LPFR' &&
					lpfr.version != '/' &&
					lpfr.version != this.currentVersion.version &&
					!lpfr.forceUpdate
				);
			});

			this.forceUpdateModal.selectedLpfrs = lpfrsToUpdate;
			this.forceUpdateModal.updateAll = true;
			this.$modal.show('force-update');
		},

		cancelUpdateAll() {
			const lpfrsToCancel = this.personalClients.filter(lpfr => {
				return lpfr.forceUpdate;
			});
			const lpfrIds = lpfrsToCancel.map(lpfr => {
				return lpfr.id;
			});

			this.$fire({
				title: 'Otkazivanje ažuriranja verzije',
				html: `Da li ste sigurni da želite da otkažete ažuriranje svih LPFR-ova?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Otkaži ažuriranje',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					axios
						.post('lpfr/cancel-update', {
							lpfrIds
						})
						.then(response => {
							this.refreshData();
							this.$fire({
								title: 'Uspešna akcija',
								text: 'Ažuriranje verzija je uspešno otkazano.',
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
						})
						.catch(error => {
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom otkazivanja ažuriranja.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
		},

		cancelForceUpdate(lpfr) {
			this.$fire({
				title: 'Otkazivanje ažuriranja verzije',
				html: `Da li ste sigurni da želite da otkažete ažuriranje<br><b>LPFR - ${lpfr.jid} ${
					lpfr.jidNickname ? '(' + lpfr.jidNickname + ')' : ''
				}</b>?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Otkaži ažuriranje',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					axios
						.post('lpfr/cancel-update', {
							lpfrIds: [lpfr.id]
						})
						.then(response => {
							this.refreshData();
							this.$fire({
								title: 'Uspešna akcija',
								text: 'Ažuriranje verzije je uspešno otkazano.',
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
						})
						.catch(error => {
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom otkazivanja ažuriranja LPFR.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
		},

		cancelResetLPFR(rowData) {
			this.$fire({
				title: 'Poništavanje resetovanja LPFR',
				html: `Da li ste sigurni da želite da poništite resetovanje<br><b>LPFR - ${rowData.jid} ${
					rowData.jidNickname ? '(' + rowData.jidNickname + ')' : ''
				}</b>?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Poništi resetovanje',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					axios
						.post(`lpfr/${rowData.id}/reset`, { reset: false })
						.then(response => {
							this.refreshData();
						})
						.catch(error => {
							this.loadingFinishedMonitor();
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom poništavanja resetovanja LPFR.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
		},

		async getCurrentVersion() {
			const response = await axios.get('version-info');
			this.currentVersion = response.data;
		},

		async getLPFRs() {
			try {
				this.getCurrentVersion();

				const productTypesToInclude = [];

				if (this.filterProductType.filterAddLpfr) {
					productTypesToInclude.push('LPFR');
				}
				if (this.filterProductType.filterAddEft) {
					productTypesToInclude.push('EFT');
				}
				if (this.filterProductType.filterAddIps) {
					productTypesToInclude.push('IPS');
				}

				const response = await axios.get('client/lpfrs', {
					params: {
						status: this.filterStatus,
						type: this.filterType,
						search: this.searchInput,
						productType: productTypesToInclude,
						includeOnlyWithRecoveryInvoices: this.onlyWithRecoveryInvoicesFilter
					}
				});

				this.localData = response.data;

				//* setting date for unknown status
				let dateMinus10Minutes = new Date();
				dateMinus10Minutes = new Date(dateMinus10Minutes.getTime() - 10 * 60000);

				//* sort by versions to assign correct order
				this.localData.sort((a, b) => {
					if (semver.valid(b.version) && semver.valid(a.version)) {
						return semver.compare(a.version, b.version);
					} else if (semver.valid(b.version)) {
						return -1;
					} else if (semver.valid(a.version)) {
						return 1;
					}
				});

				//* Assigning order by version and setting up unknown status when needed
				for (const row of this.localData) {
					row.versionOrder = this.localData.indexOf(row);
					if (!row.version) row.version = '/';
					if (row.lastUpdate) {
						row.lastUpdateSort = row.lastUpdate;
					} else {
						row.lastUpdateSort = new Date(0);
					}
					if (new Date(row.lastUpdate) < dateMinus10Minutes) {
						row.status = 0;
					}
					if (row.type && row.type.toLowerCase() == 'production') {
						row.type = 'PROD';
					} else if (row.type && row.type.toLowerCase() == 'sandbox') {
						row.type = 'DEV';
					} else if (row.type && row.type.toLowerCase() == 'print') {
						row.type = 'PRINT';
					} else {
						row.type = 'UNKWN';
					}
					row.clientName = row.client.name;

					// Setting up a special indicator if the LPFR is under direct ownership of the logged in user
					let ownedByLoggedUser = false;
					if (row.admin && row.admin.id == this.user.id) {
						ownedByLoggedUser = true;
					} else if (row.distributor && !row.partner && row.distributor.id == this.user.id) {
						ownedByLoggedUser = true;
					} else if (row.partner && row.partner.id == this.user.id) {
						ownedByLoggedUser = true;
					}
					row.ownedByLoggedUser = ownedByLoggedUser;
				}

				//* Sorting by last update (default sort for table)
				this.localData.sort((a, b) => {
					return new Date(b.lastUpdateSort) - new Date(a.lastUpdateSort);
				});

				//* Splitting data into two tables (Monitor and Archive tabs)
				const archived = this.localData.filter(lpfr => lpfr.archived);
				const notArchived = this.localData.filter(lpfr => !lpfr.archived);

				this.localData = notArchived;

				//* Setting up data in vuex store
				this.setArchived(archived);
				this.setLpfrs(notArchived);

				//! Code below this line is executed after the data is packed into vuex store - it only influences the Monitor tab
				// This is done to prevent filters below from influencing the Dashboard tab

				//* Getting list of personal clients (used for bulk actions)
				this.personalClients = this.localData.filter(lpfr => {
					if (this.user.role == 'SUPERADMIN') {
						if (lpfr.admin) return true;
					} else if (this.user.role == 'DISTRIBUTOR') {
						if (!lpfr.partner && lpfr.distributor && lpfr.distributor.id == this.user.id) return true;
					} else {
						if (lpfr.partner && lpfr.partner.id == this.user.id) return true;
					}
				});

				// //* Filter by environment (DEV/PROD)
				// let formattedType = '';
				// if (this.filterType == 'SANDBOX') formattedType = 'DEV';
				// else if (this.filterType == 'PRODUCTION') formattedType = 'PROD';
				// else formattedType = 'PRINT';
				// this.localData = this.localData.filter((lpfr) => {
				// 	return lpfr.type == formattedType;
				// });

				// //* Filter by LPFR status
				// if (this.filterStatus) {
				// 	this.localData = this.localData.filter(
				// 		(lpfr) => lpfr.status == +this.filterStatus
				// 	);
				// }
			} catch (error) {
				// console.log(error);
			}
		},

		search() {
			this.searchFor = this.searchInput;
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},

		async cancelFilter() {
			this.searchFor = '';
			this.searchInput = '';
			this.filterStatus = null;
			this.filterType = 'PRODUCTION';
			this.filterProductType = {
				filterAddIps: false,
				filterAddEft: false,
				filterAddLpfr: true
			};
			if (this.user.productTypes.includes('EFT')) {
				this.filterProductType.filterAddEft = true;
			}

			if (this.user.productTypes.includes('IPS')) {
				this.filterProductType.filterAddIps = true;
			}
			this.localData = [];
			this.initialSearchDone = false;
		},

		showUserDetails(user) {
			this.selectedUser = user;
			// console.log('selected', this.selectedUser);
			this.$modal.show('user-details');
		},

		addClient() {
			this.closeCreateDropdown();
			this.$modal.show('add-client');
		},

		registerLpfr() {
			this.closeCreateDropdown();
			this.$modal.show('register-lpfr');
		},

		addPartner() {
			this.closeCreateDropdown();
			this.$modal.show('add-partner');
		},
		addDistributor() {
			this.closeCreateDropdown();
			this.$modal.show('add-distributor');
		},

		computeLogText(text) {
			if (text.length > 30) {
				return text.substring(0, 27) + '...';
			} else {
				return text;
			}
		},

		closeModal(name) {
			this.$modal.hide(name);
		},

		async refreshData() {
			this.loadingTableData = true;
			await this.getLPFRs();
			this.loadingTableData = false;
		},

		viewAdditionalInfo(row) {
			const info = row.additionalInfo;
			info.jid = row.jid;
			info.posId = row.posId;
			info.winVersion = row.winVersion;
			info.lastAuditTime = row.lastAuditTime;
			info.mrc = row.mrc;
			info.client = row.client;
			info.productType = row.productType;
			info.productInfo = row.productInfo;
			if (row.installType) {
				info.installType = row.installType;
			}
			if (row.readerName) {
				info.readerName = row.readerName;
			}
			if (row.jidNickname) info.jidNickname = row.jidNickname;

			if (row.certificateExpirationDate) {
				info.certificateExpirationDate = row.certificateExpirationDate;
			}

			this.selectedInfo = info;
			this.$modal.show('additional-info');
		},

		editNickname(rowData) {
			this.lpfrRow = rowData;
			this.$modal.show('edit-nickname', {
				rowData: rowData
			});
		},

		showLogs(row) {
			this.listOfLogsModal.selectedLpfr = row;
			this.$modal.show('logs');
		},

		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.client.name.search(txt) >= 0 ||
						item.pib.search(txt) >= 0 ||
						item.jid.search(txt) >= 0 ||
						(item.lastNotifyLog && item.lastNotifyLog.search(txt) >= 0) ||
						(item.jidNickname != null && item.jidNickname.search(txt) >= 0) ||
						(item.partner ? item.partner.name.search(txt) >= 0 : false) ||
						(item.distributor ? item.distributor.name.search(txt) >= 0 : false) ||
						(item.additionalInfo && item.additionalInfo.OU ? item.additionalInfo.OU.search(txt) >= 0 : false)
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.create-dropdown {
	width: 120px !important;
	min-width: unset !important;
}
.monitor-container {
	display: flex;
	flex-direction: column;
}

.status-slot img {
	object-fit: contain;
	width: 24px;
	height: 24px;
}

.status-slot {
	// min-width: 95px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.monitor-table {
	font-size: 15px;
}
.log-text {
	max-width: 100%;
}
.text-ellipsis {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.edit-nick-btn {
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	i {
		font-size: 13px;
	}
}
.logs-btn {
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
}
.num-of-invoices {
	font-weight: bold;
	white-space: nowrap;
}
.distributor-name {
	max-width: 100px;
}
.partner-client-txt {
	max-width: 150px;
}
.jid-txt {
	max-width: 150px;
}
.button-row button {
	margin-top: 0px;
	font-size: 0.9rem;
	margin-bottom: 0px;
}
.status-container {
	margin-right: 0px;
}
</style>

<style lang="scss">
.vuetable-th-slot-reset-lpfr-slot {
	text-align: center;
}
.monitor-table .vuetable th {
	font-size: 13px !important;
}
</style>
