<template>
	<div class="modal modal-add-client">
		<h1 class="text-2xl">Prenos licence</h1>
		<hr />
		<div class="modal-content text-lg">
			<h2>Naziv klijenta</h2>
			<h1>{{ lpfr.client.name }}</h1>
			<div class="flex">
				<div class="mr-12">
					<h2>PIB</h2>
					<h1>{{ lpfr.client.pib }}</h1>
				</div>
				<div>
					<h2>JID</h2>
					<h1>{{ lpfr.jid }}</h1>
				</div>
			</div>

			<h2>Licenca važi do (zaključno sa)</h2>
			<h1>{{ lpfr.expirationDate | formatDateNoTime }}</h1>

			<h2>Prenesi licencu na</h2>
			<v-select
				placeholder="Pretražite i izaberite JID"
				class="select-style mb-1"
				v-model="selectedLPFR"
				:options="options"
			></v-select>

			<div class="mb-6">
				<p
					v-if="selectedLPFR != null && new Date(selectedLPFR.expirationDate) > new Date()"
					class="text-yellow-600 text-sm"
				>
					<span class="text-red-600">UPOZORENJE:</span> Izabrani LPFR trenutno poseduje važeću licencu. Ukoliko
					nastavite, ta licenca će biti poništena i zamenjena.
				</p>
			</div>

			<div class="flex items-center p-2 bg-primary bg-opacity-10 rounded-md">
				<input type="checkbox" id="checkbox" v-model="confirm" class="mr-2" /><label class="text-xs" for="checkbox"
					>Saglasan sam da se izvrši prenos licence na izabrani JID. Nakon prenosa, licenca za izvorni JID će biti van
					funkcije i LPFR neće biti u operativnom stanju.</label
				>
			</div>
			<div class="flex flex-row mt-3">
				<button :disabled="loading" class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button :disabled="!confirm || !this.selectedLPFR || loading" class="ml-2 flex-grow-2" @click="transferLicence">
					Prenesi licencu
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';

export default {
	data() {
		return {
			confirm: false,
			loading: false,
			lpfr: null,
			options: [],
			selectedLPFR: null
		};
	},

	props: {
		row: {
			type: Object,
			required: true
		}
	},

	computed: {
		...mapState({
			user: state => {
				return {
					id: state.user.user.id,
					name: state.user.user.name,
					phone: state.user.user.phone,
					email: state.user.user.email,
					role: state.user.user.role,
					pib: state.user.user.pib,
					alternateId: state.user.user.alternateId ? state.user.user.alternateId : null
				};
			}
		})
	},

	async created() {
		this.lpfr = this.row;

		try {
			const optionsResponse = await axios.get(`client/${this.lpfr.client.id}/lpfrs`);

			this.options = optionsResponse.data.map(lpfr => {
				lpfr.label = `${lpfr.jid}` + (lpfr.jidNickname ? ` (${lpfr.jidNickname})` : '');
				return lpfr;
			});

			this.options.splice(
				this.options.findIndex(lpfr => lpfr.id === this.lpfr.id),
				1
			);
		} catch (error) {}
	},

	methods: {
		async transferLicence() {
			this.loading = true;
			try {
				await axios.post(`licence/transfer`, {
					fromJID: this.lpfr.jid,
					toJID: this.selectedLPFR.jid,
					alternateLoginId: this.user.alternateId ? this.user.alternateId : null
				});
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Licenca je uspešno prenesena.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
				this.$emit('clearSelection');
			} catch (error) {
				// console.log(error);
				this.$emit('close');
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom prenosa licence.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
h1 {
	font-size: 1.5rem !important;
}

.modal-content h1 {
	margin-bottom: 12px;
}

h2 {
	color: rgb(90, 90, 90) !important;
}
</style>

<style lang="scss">
.select-style .vs__selected-bg,
.select-style .vs__state-active-bg {
	background-color: var(--primary-color) !important;
}

.select-style .vs__clear:hover {
	background-color: unset;
}

.select-style .vs__dropdown-menu {
	max-height: 150px;
}

.select-style *::placeholder {
	color: gray !important;
}
</style>
