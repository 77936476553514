<template>
	<div class="modal">
		<h1>Statusi e-fakture</h1>
		<hr class="mb-4" />
		<div v-if="loading" class="mt-6 mb-6">
			<spinner-component :size="64" />
		</div>
		<div v-else>
			<div class="mt-6" v-for="(group, propertyName, index) in data" :key="index">
				<h2>{{ propertyName }}</h2>
				<hr class="mb-4 mr-48" />
				<div class="flex flex-row flex-wrap">
					<div class="min-dimensions" v-for="(value, propertyName, index) in group" :key="index">
						<h3>{{ propertyName }}</h3>
						<h1 v-if="value">{{ value }}</h1>
						<h2 v-else>/</h2>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	data() {
		return {
			loading: true,
			data: {}
		};
	},

	methods: {
		async loadStatuses() {
			this.loading = true;
			try {
				const response = await axios.get(`efaktura/invoice-statuses/?invoiceId=${this.invoiceId}`);
				this.data = response.data;
				//place null keys at the end
				for (const key in this.data) {
					const group = this.data[key];
					const nullKeys = Object.keys(group).filter(key => !group[key]);
					for (const nullKey of nullKeys) {
						const value = group[nullKey];
						delete group[nullKey];
						group[nullKey] = value;
					}
				}
			} catch (error) {
				console.log(error);
				this.$emit('close');
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom provere statusa e-fakture.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	},

	async created() {
		await this.loadStatuses();
	},
	props: {
		invoiceId: {
			type: Number,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
.min-dimensions {
	min-width: 50%;
	min-height: 56px;
}
</style>
