<template>
	<div>
		<div>
			<modal :adaptive="true" height="auto" name="modal-add-exchange-rate">
				<modal-add-exchange-rate @close="closeModal('modal-add-exchange-rate')" @refresh="refreshData">
				</modal-add-exchange-rate>
			</modal>
		</div>
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-if="!loading" class="w-1/4 m-auto">
			<div class="flex flex-row justify-end mb-1">
				<button @click="createExchangeRate">Ručno dodavanje kursa</button>
			</div>

			<vuetable
				ref="vuetable"
				:api-mode="false"
				:fields="fields"
				:css="css.table"
				:per-page="perPage"
				noDataTemplate="Nisu pronađene kursne liste"
				:data-manager="dataManager"
				pagination-path="pagination"
				@vuetable:pagination-data="onPaginationData"
				@vuetable:loaded="onLoaded"
				class="exchange-rates-table"
				track-by="date"
			>
				<div class="slot" slot="exchange-rate-slot" slot-scope="props">
					{{ props.rowData.exchangeRate }}
				</div>
				<div class="slot" slot="date-slot" slot-scope="props">
					{{ props.rowData.date | formatDateNoTime }}
				</div>
			</vuetable>
			<vuetable-pagination
				ref="pagination"
				class="pull-right"
				@vuetable-pagination:change-page="onChangePage"
			></vuetable-pagination>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import Vuetable from 'vuetable-2';
import VuetablePagination from '../components/VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import ModalAddExchangeRate from '../components/ModalAddExchangeRate.vue';

export default {
	components: {
		Vuetable,
		VuetablePagination,
		ModalAddExchangeRate
	},

	data() {
		return {
			css: VuetableCssConfig,
			perPage: 20,
			loading: true,
			localData: [],
			fields: [
				{
					name: 'date-slot',
					title: 'Datum',
					sortField: 'date'
				},
				{
					name: 'exchange-rate-slot',
					title: 'Srednji kurs (EUR u RSD)',
					sortField: 'exchange-rate'
				}
			]
		};
	},

	methods: {
		async getExchangeRates() {
			const response = await axios.get('/finances/exchange-rates');

			this.localData = response.data;
		},

		async refreshData() {
			this.loading = true;
			try {
				await this.getExchangeRates();
			} catch (error) {
			} finally {
				this.loading = false;
			}
		},

		createExchangeRate() {
			this.$modal.show('modal-add-exchange-rate');
		},

		closeModal(name) {
			this.$modal.hide(name);
		},

		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return false;
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},

		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onLoaded() {
			this.numberOfLoadedClients = this.$refs.vuetable.tableData.length;
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		}
	},

	async created() {
		this.loading = true;
		try {
			await this.getExchangeRates();
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},

	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		}
	}
};
</script>

<style lang="scss">
.exchange-rates-table {
	td {
		padding: 4px 6px;
	}
}
</style>
