<template>
	<div class="modal">
		<h1>Kreiranje grupnog nacrta faktura</h1>
		<hr class="mb-4" />
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-show="!loading">
			<div class="draft-form-container">
				<div class="left-col mr-4">
					<h1>Izbor obveznika</h1>
					<div class="bg-white-600 rounded p-1">
						<label class="text-gray-400 text-sm mt-1"
							>Sa liste su izostavljeni svi obveznici za koje ne postoje svi potrebni podaci za fakturisanje. Ovi podaci
							se mogu podesiti u okviru liste korisnika.</label
						>
					</div>
					<div class="flex flex-row justify-between mt-2">
						<div class="flex flex-row">
							<button class="mr-2" @click="selectAll('DISTRIBUTOR')" v-if="user.role == 'SUPERADMIN'">
								Svi distributeri
							</button>
							<button class="mr-2" @click="selectAll('PARTNER')" v-if="user.role == 'SUPERADMIN'">Svi partneri</button>
							<button class="mr-2" @click="selectAll('CLIENT')">Svi klijenti</button>
						</div>
						<button @click="$refs.vuetable.selectedTo = []">Ukloni sve</button>
					</div>

					<div class="mt-2"></div>
					<vuetable
						ref="vuetable"
						:api-mode="false"
						:fields="fields"
						:css="css.table"
						:per-page="perPage"
						noDataTemplate="Nisu pronađeni validni obveznici"
						:data-manager="dataManager"
						pagination-path="pagination"
						@vuetable:pagination-data="onPaginationData"
						@vuetable:loaded="onLoaded"
						@vuetable:row-clicked="onRowClicked"
						class="invoice-drafts-table"
						track-by="index"
					>
						<div class="slot" slot="name-slot" slot-scope="props">
							{{ props.rowData.nameFormatted }}
						</div>
						<div class="slot" slot="user-type-slot" slot-scope="props">
							{{ props.rowData.typeFormatted }}
						</div></vuetable
					>
					<vuetable-pagination
						ref="pagination"
						class="pull-right"
						@vuetable-pagination:change-page="onChangePage"
					></vuetable-pagination>
				</div>
				<div class="middle-col">
					<h1>Opcije</h1>
					<hr class="mb-2" />
					<div v-if="user.role == 'SUPERADMIN'">
						<h2>Fakturisanje</h2>
						<div class="flex flex-row items-center justify-between">
							<div class="flex flex-row items-center">
								<input type="checkbox" name="onlyDirect" id="only-direct-clients" v-model="onlyDirect" />
								<label for="only-direct-clients" class="pl-1 whitespace-nowrap flex-nowrap pr-2"
									>Samo direktni klijenti</label
								>
							</div>
							<label class="text-gray-400 text-sm mt-1 w-48"
								>Ovu opciju je potrebno izabrati samo ako se fakturišu ISKLJUČIVO direktni LPFR-ovi odabranih
								obveznika.</label
							>
						</div>
					</div>
					<div class="flex flex-row">
						<div>
							<h2 class="mt-2">Datum prometa</h2>
							<vue-datetime
								type="date"
								input-class="form-control form-control"
								placeholder="Izaberite datum prometa"
								hidden-name="webstorm"
								format="dd.MM.yyyy."
								:phrases="{
									ok: 'U redu',
									cancel: 'Otkaži'
								}"
								v-model="turnoverDate"
							></vue-datetime>
						</div>
						<div class="ml-2" v-show="turnoverDate">
							<h2 class="mt-2">Srednji kurs (1€ u RSD)</h2>
							<money
								v-if="!exchangeRateExists"
								class="money-input"
								v-model="exchangeRate"
								v-bind="{ precision: 4 }"
							></money>
							<input type="text" v-else v-model="exchangeRate" disabled />
						</div>
					</div>
					<div class="flex flex-row">
						<div>
							<h2 class="mt-2">Datum valute (opciono)</h2>
							<vue-datetime
								type="date"
								input-class="form-control form-control"
								placeholder="Izaberite datum valutea"
								hidden-name="webstorm"
								format="dd.MM.yyyy."
								:phrases="{
									ok: 'U redu',
									cancel: 'Otkaži'
								}"
								v-model="valueDate"
							></vue-datetime>
						</div>
					</div>
					<div class="mt-2 flex flex-row">
						<div>
							<h2>Godina</h2>
							<money class="money-input" v-model="year" v-bind="{ precision: 0, thousands: '' }"> </money>
						</div>
						<div class="flex-grow ml-2">
							<h2>Period obračuna</h2>
							<v-select
								placeholder="Izaberite period obračuna"
								class="select-style client-select months-select mb-1 w-full"
								v-model="selectedMonth"
								:options="months"
							></v-select>
						</div>
					</div>
					<div class="mt-2">
						<h2>Opis nacrta</h2>
						<input type="text" v-model="description" style="width: 100%" />
					</div>
					<div class="mt-2">
						<h2>Broj gratis licenci</h2>

						<div class="flex flex-row justify-between items-start mt-1">
							<money class="money-input" v-model="numOfFreeLicences" v-bind="{ precision: 0, thousands: '' }"></money>
							<label class="text-gray-400 text-sm ml-6"
								>Ukoliko ne bude preostalo licenci za naplatu, a nisu definisane prilagođene stavke, fakture neće biti
								kreirane.</label
							>
						</div>
					</div>
				</div>
				<div class="right-col ml-4">
					<h1>Prilagođene stavke</h1>
					<hr class="mb-2" />
					<div v-if="user.role == 'SUPERADMIN'"></div>
					<div class="flex flex-col">
						<div>
							<h2>Opis stavke</h2>
							<input type="text" v-model="customItemDescription" style="width: 100%" />
						</div>
						<div class="flex flex-row mt-2">
							<div class="mr-2">
								<h2>Količina</h2>
								<money
									class="money-input"
									v-model="customItemQuantity"
									v-bind="{ precision: 0, thousands: '' }"
								></money>
							</div>
							<div>
								<h2>Cena po komadu</h2>
								<money
									class="money-input"
									v-model="customItemPrice"
									v-bind="{
										precision: 2,
										thousands: ' ',
										suffix: ' RSD'
									}"
								></money>
							</div>
						</div>
						<div class="flex flex-row justify-end mt-2">
							<label class="text-gray-400 text-sm mr-2"
								>Porez će biti uzet u obzir za svaku dodatu stavku. Rabat iz cenovnika obveznika se ne uzima u obzir i
								ne uvažava se.</label
							>
							<button
								class="whitespace-nowrap"
								:disabled="
									!customItemDescription ||
									customItemDescription == '' ||
									customItemPrice <= 0 ||
									customItemQuantity <= 0
								"
								@click="addCustomItem()"
							>
								Dodaj stavku
							</button>
						</div>
					</div>
					<hr class="mt-2" />
					<div class="list-of-custom-items mt-2">
						<div v-for="(item, index) of customItems" :key="index" class="mb-2 mr-2 bg-white-600 p-2 rounded">
							<div class="flex flex-row justify-between items-center">
								<div>
									<p class="custom-item-index">{{ index + 1 }}</p>
								</div>
								<div class="w-3/4">
									<h2>
										'{{ item.description }}'
										<span class="whitespace-nowrap">x {{ item.quantity }},&nbsp; </span>
										<span class="whitespace-nowrap">jed. cena: {{ formatMoney(item.pricePerUnit) }} RSD</span>
									</h2>
								</div>
								<button
									class="bg-secondary hover:bg-red-800 btn-sm bg-red-600"
									style="width: 28px; height: 28px; line-height: 0; padding: 0"
									@click="removeItem(index)"
								>
									<i class="fas fa-minus"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="selectedClients.length > 0">
				<h2>Izabrani obveznici</h2>

				<div class="flex flex-row flex-wrap">
					<div v-for="client in selectedClients" :key="client.index">
						<div class="flex flex-row items-center mr-2 mt-2 bg-blue-200 p-1 rounded">
							<h2>{{ client.name }} ({{ client.typeFormatted }}), {{ client.pib }}</h2>
						</div>
					</div>
				</div>
			</div>
			<hr class="mt-2" />
			<div class="flex flex-row mt-3">
				<button class="w-1/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button :disabled="!inputIsValid" class="flex-grow ml-2" @click="getPreview()">Preview</button>
				<button :disabled="!inputIsValid" class="flex-grow ml-2" @click="saveInvoiceGroup('DRAFT')">
					Sačuvaj nacrt
				</button>
				<button :disabled="!inputIsValid" class="flex-grow ml-2" @click="saveInvoiceGroup('CREATED')">
					Izdaj fakture
				</button>
				<button
					:disabled="!inputIsValid"
					class="flex-grow ml-2"
					@click="saveInvoiceGroup('SENT')"
					v-if="user.role != 'ONPREMADMIN'"
				>
					Izdaj fakture i pošalji mejlove
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from '../components/VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import axios from '@/axios/axios.js';
import { Money } from 'v-money';
import { mapState } from 'vuex';
export default {
	components: {
		Vuetable,
		VuetablePagination,
		Money
	},
	props: {
		invoiceGroupId: {
			type: Number,
			required: false
		}
	},
	data() {
		return {
			groupId: null,
			onlyDirect: false,
			loading: false,
			css: VuetableCssConfig,
			perPage: 15,
			localData: [],
			originalData: [],
			fields: [],
			turnoverDate: null,
			valueDate: null,
			exchangeRate: '',
			exchangeRateExists: false,
			selectedClients: [],
			year: new Date().getFullYear(),
			months: [
				{ label: 'Godišnji', value: 'YEARLY' },
				{ label: 'Januar', value: 0 },
				{ label: 'Februar', value: 1 },
				{ label: 'Mart', value: 2 },
				{ label: 'April', value: 3 },
				{ label: 'Maj', value: 4 },
				{ label: 'Jun', value: 5 },
				{ label: 'Jul', value: 6 },
				{ label: 'Avgust', value: 7 },
				{ label: 'Septembar', value: 8 },
				{ label: 'Oktobar', value: 9 },
				{ label: 'Novembar', value: 10 },
				{ label: 'Decembar', value: 11 }
			],
			selectedMonth: null,
			description: '',
			numOfFreeLicences: 0,
			customItemQuantity: 1,
			customItemPrice: 0,
			customItemDescription: '',
			customItems: []
		};
	},

	async created() {
		this.loading = true;
		this.setupTableFields();
		try {
			await this.loadData();
			if (this.invoiceGroupId) {
				await this.loadInvoiceGroup();
			}
		} catch (error) {
			console.log(error);
		} finally {
			this.loading = false;
		}
	},

	methods: {
		async loadInvoiceGroup() {
			const response = await axios.get(`finances/group/${this.invoiceGroupId}`);
			const group = response.data;

			this.groupId = group.id;

			this.description = group.description;

			this.turnoverDate = new Date(group.dateTurnover).toISOString();
			if (group.dateValue) {
				this.valueDate = new Date(group.dateValue).toISOString();
			}
			this.exchangeRate = group.exchangeRateEURtoRSD;
			this.year = group.year;
			if (group.accountingPeriod == 'MONTHLY') {
				this.selectedMonth = this.months.find(month => month.value == group.month);
			} else {
				this.selectedMonth = this.months[0];
			}
			this.onlyDirect = group.onlyDirect;
			this.numOfFreeLicences = group.numOfFreeLicences;

			for (const item of group.customItems) {
				console.log(item);
				console.log(item.pricePerUnit);
				console.log(parseFloat(item.pricePerUnit));
				this.customItems.push({
					description: item.description,
					quantity: parseInt(item.quantity),
					pricePerUnit: parseFloat(item.pricePerUnit)
				});
			}

			this.$refs.vuetable.selectedTo = [];

			if (group.clientIds && group.clientIds.length > 0) {
				const ids = group.clientIds.split(',');
				for (const id of ids) {
					const client = this.originalData.find(c => c.id == id && c.type == 'CLIENT');
					if (client) {
						this.$refs.vuetable.selectedTo.push(client.index);
					}
				}
			}

			if (group.userIds && group.userIds.length > 0) {
				const ids = group.userIds.split(',');
				for (const id of ids) {
					const user = this.originalData.find(u => u.id == id && u.type != 'CLIENT');
					if (user) {
						this.$refs.vuetable.selectedTo.push(user.index);
					}
				}
			}
		},

		async getPreview() {
			const userIds = this.selectedClients.filter(x => x.type != 'CLIENT').map(x => x.id);
			const clientIds = this.selectedClients.filter(x => x.type == 'CLIENT').map(x => x.id);
			const includeNested = !this.onlyDirect;
			const year = this.year;
			const dateTurnover = this.turnoverDate;
			const dateValue = this.valueDate ? this.valueDate : null;
			const exchangeRate = this.exchangeRate;
			let accountingPeriod = 'YEARLY';
			if (this.selectedMonth && this.selectedMonth.value != 'YEARLY') {
				accountingPeriod = 'MONTHLY';
			}
			const month = this.selectedMonth && this.selectedMonth.value != 'YEARLY' ? this.selectedMonth.value : null;
			const status = 'DRAFT';
			const description = this.description;

			const parameters = {
				description,
				userIds,
				clientIds,
				includeNested,
				dateTurnover,
				dateValue,
				exchangeRate,
				year,
				accountingPeriod,
				month,
				status
			};

			if (this.numOfFreeLicences) {
				parameters.numOfFreeLicences = this.numOfFreeLicences;
			}

			if (this.customItems && this.customItems.length > 0) {
				parameters.customItems = this.customItems;
			}

			if (this.groupId) {
				parameters.id = this.groupId;
			}
			try {
				this.loading = true;
				const response = await axios.post('/finances/invoice-preview', parameters);
				const preview = response.data;

				let text = '';

				for (const name in preview) {
					text += `<b>${name}</b><br/>
					Broj mesečnih PROD licenci: ${preview[name].numberOfProdLicences}<br/>
					Broj godišnjih PROD licenci: ${preview[name].numberOfYearlyLicences}<br/>
					Broj mesečnih PRINT licenci: ${preview[name].numberOfPrintLicences}<br/><br/>`;
				}

				this.$fire({
					title: 'Preview računa',
					html: `${text}`,
					type: 'info',
					showConfirmButton: true
				});
				this.$emit('refresh');
			} catch (error) {
				console.error(error);
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom obrade preview-a računa.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		},

		addCustomItem() {
			this.customItems.push({
				quantity: this.customItemQuantity,
				pricePerUnit: this.customItemPrice,
				description: this.customItemDescription
			});
			this.customItemQuantity = 1;
			this.customItemPrice = 0;
			this.customItemDescription = '';
		},

		removeItem(index) {
			this.customItems.splice(index, 1);
		},

		formatMoney(value) {
			const moneyFormatter = new Intl.NumberFormat('de-DE', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 4
			});

			return moneyFormatter.format(value);
		},

		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.name.search(txt) >= 0 ||
						item.pib.search(txt) >= 0 ||
						(item.legalEntityName && item.legalEntityName.search(txt) >= 0) ||
						(item.legalEntityEmails && item.legalEntityEmails.search(txt) >= 0) ||
						(item.legalEntityAddress && item.legalEntityAddress.search(txt) >= 0)
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},

		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onLoaded() {},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		onRowClicked(row) {
			if (this.$refs.vuetable.selectedTo.includes(row.data.index)) {
				this.$refs.vuetable.selectedTo.splice(this.$refs.vuetable.selectedTo.indexOf(row.data.index), 1);
			} else {
				this.$refs.vuetable.selectedTo.push(row.data.index);
			}
		},

		selectAll(type) {
			let rows = [];

			if (type == 'DISTRIBUTOR') {
				const distributors = this.originalData.filter(x => x.type == 'DISTRIBUTOR');
				rows = distributors;
			} else if (type == 'PARTNER') {
				const partners = this.originalData.filter(x => x.type == 'PARTNER');
				rows = partners;
			} else if (type == 'CLIENT') {
				const clients = this.originalData.filter(x => x.type == 'CLIENT');
				rows = clients;
			}

			rows = rows.map(x => x.index);

			const allSelected = new Set([...this.$refs.vuetable.selectedTo, ...rows]);
			this.$refs.vuetable.selectedTo = Array.from(allSelected);
		},

		setupTableFields() {
			this.fields = [
				{
					name: 'name-slot',
					title: 'Naziv korisnika',
					sortField: 'name'
				},
				{
					name: 'user-type-slot',
					title: 'Tip korisnika',
					sortField: 'type'
				},
				{
					name: 'pib',
					title: 'PIB',
					sortField: 'pib'
				},
				{
					name: '__checkbox',
					titleClass: 'center-checkbox',
					dataClass: 'center-checkbox'
				}
			];
		},

		async loadData() {
			const response = await axios.get('/finances/valid-clients');

			this.formatTableData(response.data);
		},

		formatTableData(data) {
			this.localData = data.map(row => {
				switch (row.type) {
					case 'DISTRIBUTOR':
						row.typeFormatted = 'Distributer';
						break;
					case 'PARTNER':
						row.typeFormatted = 'Partner';
						break;
					case 'CLIENT':
						row.typeFormatted = 'Klijent';
						break;
				}

				row.nameFormatted = row.name;
				if (row.name.length > 24) {
					row.nameFormatted = row.nameFormatted.substr(0, 21) + '...';
				}

				return row;
			});

			this.originalData = this.localData;
		},
		async saveInvoiceGroup(invoiceGroupStatus) {
			const userIds = this.selectedClients.filter(x => x.type != 'CLIENT').map(x => x.id);
			const clientIds = this.selectedClients.filter(x => x.type == 'CLIENT').map(x => x.id);
			const includeNested = !this.onlyDirect;
			const year = this.year;
			const dateTurnover = this.turnoverDate;
			const dateValue = this.valueDate ? this.valueDate : null;
			const exchangeRate = this.exchangeRate;
			let accountingPeriod = 'YEARLY';
			if (this.selectedMonth && this.selectedMonth.value != 'YEARLY') {
				accountingPeriod = 'MONTHLY';
			}
			const month = this.selectedMonth && this.selectedMonth.value != 'YEARLY' ? this.selectedMonth.value : null;
			const status = invoiceGroupStatus;
			const description = this.description;

			const parameters = {
				description,
				userIds,
				clientIds,
				includeNested,
				dateTurnover,
				dateValue,
				exchangeRate,
				year,
				accountingPeriod,
				month,
				status
			};

			if (this.numOfFreeLicences) {
				parameters.numOfFreeLicences = this.numOfFreeLicences;
			}

			if (this.customItems && this.customItems.length > 0) {
				parameters.customItems = this.customItems;
			}

			if (this.groupId) {
				parameters.id = this.groupId;
			}
			try {
				this.loading = true;
				await axios.post('/finances/invoice', parameters);
				this.$emit('close');
				let text = ``;
				if (invoiceGroupStatus == 'DRAFT') {
					text = `Nacrt faktura je uspešno sačuvan.`;
				} else if (invoiceGroupStatus == 'CREATED') {
					text = `Grupa faktura je uspešno kreirana i fakture su uspešno izdate.`;
				} else if (invoiceGroupStatus == 'SENT') {
					text = `Grupa faktura je uspešno kreirana i fakture su uspešno izdate. Mejlovi se šalju.`;
				}
				this.$fire({
					title: 'Uspešna akcija',
					text: `${text}`,
					type: 'success',
					showConfirmButton: true
				});
				this.$emit('refresh');
			} catch (error) {
				console.error(error);
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom obrade nacrta fakture.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	},
	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		},

		async turnoverDate() {
			if (!this.turnoverDate) return;
			try {
				const response = await axios.get(`finances/exchange-rate-by-date/${this.turnoverDate}`);

				this.exchangeRate = response.data;
				this.exchangeRateExists = true;
			} catch (error) {
				this.exchangeRate = '0';
				this.exchangeRateExists = false;
			}
		}
	},
	mounted() {
		this.$watch(
			() => this.$refs.vuetable.selectedTo,
			val => {
				this.selectedClients = this.$refs.vuetable.selectedTo.map(index => {
					return this.originalData.find(item => item.index === index);
				});
			}
		);
	},
	computed: {
		inputIsValid() {
			const hasSelectedClients = this.selectedClients.length > 0;
			const hasTurnoverDate = this.turnoverDate;
			const hasExchangeRate = this.exchangeRate > 0;
			const hasYear = this.year;
			const monthSelected = this.selectedMonth ? true : false;

			return hasSelectedClients && hasTurnoverDate && hasExchangeRate && hasYear && monthSelected;
		},
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		})
	}
};
</script>

<style lang="scss">
.invoice-drafts-table tbody tr:hover {
	background-color: var(--primary-color-hover);
	cursor: pointer;
}

.invoice-drafts-table td {
	padding: 3px !important;
}

.months-select .vs__dropdown-toggle {
	border-radius: 0;
}

.months-select .vs__dropdown-toggle {
	height: 42px !important;
}
</style>

<style lang="scss" scoped>
.draft-form-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.left-col {
	width: 40%;
}

.middle-col {
	width: 30%;
}

.right-col {
	width: 30%;
}

.money-input {
	border-color: #d0d0d0 !important;
}

.custom-item-index {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	font-weight: bold;
	color: #f5f5f5;
	background-color: var(--primary-color);
}

.list-of-custom-items {
	max-height: 250px;
	overflow-y: auto;
}
</style>
