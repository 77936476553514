<template>
	<div class="modal">
		<h1>Postavljanje detalja za Update LPFR softvera</h1>
		<hr class="mb-4" />
		<div v-if="loading" class="mt-12 mb-12">
			<spinner-component :size="64" />
		</div>
		<div v-else>
			<div v-if="fileAlreadyExists && user.role != 'SUPERADMIN'" class="mb-6 flex flex-col">
				<h1>Postojeći podaci</h1>
				<div v-if="updateUrl">
					<h2>default-update-file.exe</h2>
					<a :href="updateUrl">Link za preuzimanje</a>
				</div>
				<div v-if="changelogUrl">
					<h2>Changelog</h2>
					<a :href="changelogUrl">Link do istorijata izmena</a>
				</div>
			</div>

			<h2>{{ uploadHeader }}</h2>
			<label
				class="file-upload-label mt-1"
				for="updateFile"
				v-if="!file"
				@dragover.prevent
				@dragleave.prevent
				@drop="onDrop"
				>Izaberi ili prevuci .zip fajl</label
			>
			<div v-else class="flex flex-row items-center">
				<h1>{{ fileName }}</h1>
				<button class="ml-4" style="height: 28px; width: 28px; padding: 0" @click="removeFile()">
					<i class="fas fa-times"></i>
				</button>
			</div>

			<input type="file" name="updateFile" id="updateFile" @change="onFileChange" style="display: none" />
		</div>
		<div class="flex flex-row mt-3" v-if="!loading">
			<button :disabled="loading" class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
			<button class="ml-2 flex-grow-2" :disabled="!file" @click="uploadFile">Postavi fajl</button>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
const FormData = require('form-data');
import { mapState } from 'vuex';

export default {
	async created() {
		this.loading = true;
		const response = await axios.get(`/client/default-update-details`);
		if (response && (response.data.updateUrl || response.data.changelogUrl)) {
			this.fileAlreadyExists = true;
			if (response.data.updateUrl) {
				this.updateUrl = response.data.updateUrl;
			}

			if (response.data.changelogUrl) {
				this.changelogUrl = response.data.changelogUrl;
			}
		}
		this.loading = false;
	},
	data() {
		return {
			file: null,
			loading: false,
			fileAlreadyExists: false,
			updateUrl: '',
			changelogUrl: ''
		};
	},
	methods: {
		onDrop(e) {
			e.stopPropagation();
			e.preventDefault();
			var file = e.dataTransfer.files[0];

			this.setFile(file);
		},

		onFileChange(e) {
			const file = e.target.files[0];

			this.setFile(file);
		},

		setFile(file) {
			if (this.validateInput(file.name)) {
				this.file = file;
			} else {
				this.$fire({
					title: 'Neispravan fajl',
					text: 'Izabrani fajl je neispravan. Molimo izaberite validan .exe fajl.',
					type: 'error',
					showConfirmButton: true
				});
			}
		},

		validateInput(name) {
			let allowedExtensions = ['exe'];
			let extension = name.substring(name.lastIndexOf('.') + 1, name.length);
			return allowedExtensions.indexOf(extension) > -1;
		},

		removeFile() {
			this.file = null;
		},

		async uploadFile() {
			try {
				this.loading = true;
				//create formData from file
				const formData = new FormData();
				formData.append('file', this.file);

				const response = await axios.post(`client/update-file/default`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});
				const fileUrl = response.data;
				const body = {
					updateUrl: fileUrl,
					type: 'DEFAULT'
				};

				await axios.post(`client/update-details`, body);
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Fajl je uspešno postavljen i detalji su zapamćeni.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				this.$emit('close');
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom podešavanja update detalja.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	},

	computed: {
		fileName() {
			return this.file ? this.file.name : '';
		},
		uploadHeader() {
			return this.fileAlreadyExists ? 'Postavi novi fajl' : 'Postavi fajl';
		},
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id,
					pib: state.user.user.pib
				};
			}
		})
	}
};
</script>

<style lang="scss" scoped>
.file-upload-label {
	display: block;
	margin-bottom: 16px;
	cursor: pointer;
	border: 1px solid #ccc;
	padding: 16px 12px;
	border-radius: 4px;
	transition: all 0.2s ease-in-out;
}
</style>
