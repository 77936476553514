<template>
	<div>
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-if="!loading">
			<div class="rounded border p-8 m-auto text-left" style="max-width: 640px">
				<div class="flex flex-row justify-between items-end">
					<h1 class="text-3xl font-semibold">Faktura {{ invoice.number }}</h1>
					<div>
						<button
							@click="sendEmails()"
							class="btn btn-sm"
							title="Pošalji mejl sa PDF-om fakture"
							style="width: 26px; height: 26px; padding: 0px"
							v-if="user.role != 'ONPREMADMIN'"
						>
							<i class="fas fa-envelope"></i>
						</button>
						<button
							@click="downloadPdf()"
							class="btn btn-sm mb-1 ml-1"
							title="Preuzmi PDF fakture"
							style="width: 26px; height: 26px; padding: 0px"
							id="pdf-btn"
						>
							<i class="fas fa-file-pdf" id="pdf-icon"></i>
						</button>
						<button
							v-if="invoice.status == 'UNPAID'"
							@click="setInvoiceStatus('PAID')"
							class="btn btn-sm ml-1"
							title="Obeleži kao plaćenu"
							style="width: 26px; height: 26px; padding: 0px"
						>
							<i class="fas fa-check"></i>
						</button>
						<button
							v-if="invoice.status != 'CANCELLED'"
							@click="setInvoiceStatus('CANCELLED')"
							class="btn btn-sm bg-red-600 ml-1"
							title="Storniraj fakturu"
							style="width: 26px; height: 26px; padding: 0px"
						>
							<i class="fas fa-ban"></i>
						</button>
					</div>
				</div>
				<hr class="mb-2" />
				<div class="flex flex-row justify-between">
					<div class="flex-grow w-1/2 pr-4">
						<div class="mb-2">
							<h3 class="text-gray-200">Obveznik</h3>
							<h2 class="text-xl">{{ obligor }}</h2>
						</div>
						<div class="mb-2">
							<h3 class="text-gray-200">Naziv obveznika za fakturisanje</h3>
							<h2 class="text-xl">
								{{ invoice.obligor.financialSettings.legalEntityName }}
							</h2>
						</div>
					</div>
					<div class="flex-grow w-1/2">
						<div class="mb-2">
							<h3 class="text-gray-200">PIB obveznika</h3>
							<h2 class="text-xl">{{ this.invoice.obligor.pib }}</h2>
						</div>
						<div class="mb-2">
							<h3 class="text-gray-200">Matični broj obveznika</h3>
							<h2 class="text-xl">
								{{ this.invoice.obligor.financialSettings.MB }}
							</h2>
						</div>
					</div>
				</div>

				<div class="mb-2">
					<h3 class="text-gray-200">E-mail adrese za fakturisanje</h3>
					<h2 class="text-xl">
						{{ invoice.obligor.financialSettings.legalEntityEmails.split(',').join(', ') }}
					</h2>
				</div>
				<div class="mb-2">
					<h3 class="text-gray-200">E-mail sa fakturom u prilogu</h3>
					<h2 class="text-xl">
						{{ invoice.mailSent ? 'Poslat' : 'Nije poslat' }}
					</h2>
				</div>
				<hr class="mb-2" />
				<div class="mb-2">
					<h3 class="text-gray-200">Obračunski period</h3>
					<h2 class="text-xl">{{ invoice.accountingPeriod }}</h2>
				</div>
				<div class="flex flex-row justify-between">
					<div class="left-col flex-grow w-1/2 pr-4">
						<div class="mb-2">
							<h3 class="text-gray-200">Datum prometa</h3>
							<h2 class="text-xl">
								{{ invoice.dateTurnover | formatDateNoTime }}
							</h2>
						</div>
						<div class="mb-2">
							<h3 class="text-gray-200">Ukupno (bez PDV-a)</h3>
							<h2 class="text-xl">{{ invoice.total }} RSD</h2>
						</div>
						<div class="mb-2">
							<h3 class="text-gray-200">Ukupno (sa PDV-om)</h3>
							<h2 class="text-xl">{{ invoice.totalWithVat }} RSD</h2>
						</div>
					</div>
					<div class="right-col flex-grow w-1/2">
						<div class="mb-2">
							<h3 class="text-gray-200">Status</h3>
							<h2 class="text-xl">{{ invoice.statusFormatted }}</h2>
						</div>
						<div class="mb-2">
							<h3 class="text-gray-200">Datum izdavanja</h3>
							<h2 class="text-xl">
								{{ invoice.dateOfIssue | formatDateNoTime }}
							</h2>
						</div>

						<div class="mb-2">
							<h3 class="text-gray-200">Ukupan PDV</h3>
							<h2 class="text-xl">{{ invoice.totalVat }} RSD</h2>
						</div>
					</div>
				</div>
				<hr class="mb-2" />
				<div class="flex flex-row justify-between">
					<div class="left-col flex-grow w-1/2 pr-4">
						<div class="mb-2">
							<h3 class="text-gray-200">Broj licenci za posmatrani period</h3>
							<h2 class="text-xl">{{ invoice.items[0].quantity }}</h2>
						</div>

						<div class="mb-2">
							<h3 class="text-gray-200">Rabat na količinu</h3>
							<h2 class="text-xl" v-if="invoice.items[0].discountPercentage != 0">
								{{ Math.trunc(invoice.items[0].discountPercentage) }}%
							</h2>
							<h2 class="text-xl" v-else>Nema</h2>
						</div>
					</div>
					<div class="right-col flex-grow w-1/2">
						<div class="mb-2">
							<h3 class="text-gray-200">Standardna cena licence</h3>
							<h2 class="text-xl">{{ invoice.items[0].pricePerUnit }} RSD</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';

export default {
	data() {
		return {
			id: this.$route.params.id,
			invoice: null,
			loading: true,
			months: [
				'Januar',
				'Februar',
				'Mart',
				'April',
				'Maj',
				'Jun',
				'Jul',
				'Avgust',
				'Septembar',
				'Oktobar',
				'Novembar',
				'Decembar'
			],
			obligor: null,
			moneyFormatter: new Intl.NumberFormat('de-DE', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 4
			})
		};
	},
	async created() {
		await this.refreshData();
	},
	computed: {
		...mapState({
			user: state => {
				return {
					id: state.user.user.id,
					name: state.user.user.name,
					phone: state.user.user.phone,
					email: state.user.user.email,
					role: state.user.user.role,
					pib: state.user.user.pib
				};
			}
		})
	},
	methods: {
		async refreshData() {
			try {
				this.loading = true;
				const response = await axios.get(`finances/invoice/${this.id}`);
				console.log(response);
				this.invoice = response.data;
				switch (this.invoice.status) {
					case 'UNPAID':
						this.invoice.statusFormatted = 'Nije plaćena';
						break;
					case 'PAID':
						this.invoice.statusFormatted = 'Plaćena';
						break;
					case 'CANCELLED':
						this.invoice.statusFormatted = 'Stornirana';
						break;
					default:
						this.invoice.statusFormatted = 'Nepoznato';
						break;
				}
				switch (this.invoice.accountingPeriod) {
					case 'YEARLY':
						this.invoice.accountingPeriod = `${this.invoice.year}. (Godišnja)`;
						break;
					case 'MONTHLY':
						this.invoice.accountingPeriod = `${this.months[this.invoice.month]} ${this.invoice.year}.`;
						break;
					case 'PERIOD':
						let periodFormatted = '';
						for (const period of this.invoice.months) {
							periodFormatted += `${this.months[period.month]} ${period.year}, `;
						}
						periodFormatted = periodFormatted.slice(0, -2);
						periodFormatted += '.';
						this.invoice.accountingPeriod = periodFormatted;
						break;
					default:
						this.invoice.accountingPeriod = 'Nepoznato';
						break;
				}
				let role = '';
				switch (this.invoice.obligor.role) {
					case 'DISTRIBUTOR':
						role = 'Distributer';
						break;
					case 'PARTNER':
						role = 'Partner';
						break;
					case 'CLIENT':
						role = 'Klijent';
						break;
					default:
						role = 'Nepoznato';
						break;
				}
				this.obligor = `${this.invoice.obligor.name} (${role})`;
				this.invoice.total = this.moneyFormatter.format(this.invoice.total);
				this.invoice.totalWithVat = this.moneyFormatter.format(this.invoice.totalWithVat);
				this.invoice.totalVat = this.moneyFormatter.format(this.invoice.totalVat);
				this.invoice.items[0].pricePerUnitDiscounted = this.moneyFormatter.format(
					this.invoice.items[0].pricePerUnitDiscounted
				);
				this.invoice.items[0].pricePerUnit = this.moneyFormatter.format(this.invoice.items[0].pricePerUnit);
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},

		async sendEmails() {
			this.$fire({
				title: 'Slanje mejla',
				html: `Da li ste sigurni da želite da pošaljete mejl sa pdf-om fakture na imejl adrese obveznika?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Pošalji mejl',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`finances/invoice-emails/invoice/${this.invoice.id}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								text: `Uspešno poslat mejl.`,
								type: 'success',
								showConfirmButton: true
							});
							this.refreshData();
						})
						.catch(error => {
							console.error(error);
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom slanja mejla.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
		},

		async setInvoiceStatus(status) {
			let text = '';
			if (status == 'CANCELLED') {
				text = 'Da li ste sigurni da želite da stornirate fakturu?';
			} else if (status == 'PAID') {
				text = 'Da li ste sigurni da želite da obeležite fakturu kao plaćenu?';
			}
			this.$fire({
				title: 'Promena statusa fakture',
				text,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Promeni status fakture',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`finances/invoice/${this.invoice.id}/status/${status}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								text: `Uspešno promenjen status fakture.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							console.log(error);
							this.$fire({
								title: 'Greška',
								text: `Greška prilikom promene statusa fakture.`,
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
							this.loading = false;
						});
				}
			});
		},
		async downloadPdf() {
			const pdfIconElement = document.getElementById(`pdf-icon`);
			const pdfBtnElement = document.getElementById(`pdf-btn`);

			pdfIconElement.classList.add('fa-spinner');
			pdfIconElement.classList.add('animation-rotate');
			pdfIconElement.classList.remove('fa-file-pdf');
			pdfBtnElement.setAttribute('disabled', 'true');

			try {
				const response = await axios.get(`finances/generate-invoice-pdf/${this.invoice.id}`, { responseType: 'blob' });
				console.log(response);

				console.log(typeof response.data);
				const blob = new Blob([response.data], {
					type: 'application/pdf'
				});
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.invoice.number}.pdf`);
				document.body.appendChild(link);
				link.click();
				setTimeout(function () {
					// For Firefox it is necessary to delay revoking the ObjectURL
					window.URL.revokeObjectURL(url);
				}, 100);
			} catch (error) {
				console.log(error);
			} finally {
				pdfIconElement.classList.remove('fa-spinner');
				pdfIconElement.classList.remove('animation-rotate');
				pdfIconElement.classList.add('fa-file-pdf');
				pdfBtnElement.removeAttribute('disabled');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.animation-rotate {
	animation: rotation 1.5s linear infinite;
}
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
</style>
