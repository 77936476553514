<template>
	<div>
		<div>
			<modal
				height="auto"
				:width="1600"
				name="create-financial-draft"
				:scrollable="true"
				:adaptive="false"
				:clickToClose="false"
			>
				<modal-create-invoice-group-draft
					@close="closeModal('create-financial-draft')"
					@refresh="refreshData"
					:invoiceGroupId="selectedGroupId"
				></modal-create-invoice-group-draft>
			</modal>
		</div>
		<div>
			<modal height="auto" :width="720" name="draft-details" :scrollable="true" :adaptive="false">
				<div class="flex justify-end m-2 absolute right-1 top-1">
					<button
						class="bg-secondary hover:bg-secondary-light"
						style="width: 34px; height: 34px; line-height: 0"
						@click="$modal.hide('draft-details')"
					>
						<i class="fas fa-times" />
					</button>
				</div>
				<modal-invoice-draft-details
					@close="closeModal('draft-details')"
					:invoiceGroupId="selectedGroupId"
				></modal-invoice-draft-details>
			</modal>
		</div>
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-show="!loading">
			<h1 class="text-2xl">Nacrti (grupe faktura)</h1>
			<div class="flex flex-row justify-end mb-2">
				<button @click="createDraft">Kreiraj novi nacrt faktura</button>
			</div>
			<vuetable
				ref="vuetable"
				:api-mode="false"
				:fields="fields"
				:css="css.table"
				:per-page="perPage"
				noDataTemplate="Nisu pronađeni nacrti"
				:data-manager="dataManager"
				pagination-path="pagination"
				@vuetable:pagination-data="onPaginationData"
				@vuetable:loaded="onLoaded"
				class="draft-table"
				track-by="id"
			>
				<div class="slot" slot="date-created-slot" slot-scope="props">
					<span v-if="props.rowData.dateCreated">{{ props.rowData.dateCreated | formatDateNoTime }}</span>
					<span v-else>/</span>
				</div>
				<div class="slot" slot="date-drafted-slot" slot-scope="props">
					{{ props.rowData.dateDrafted | formatDateNoTime }}
				</div>
				<div class="slot" slot="date-turnover-slot" slot-scope="props">
					{{ props.rowData.dateTurnover | formatDateNoTime }}
				</div>
				<div class="slot" slot="accounting-period-slot" slot-scope="props">
					<div v-if="props.rowData.accountingPeriod == 'PERIOD'" :title="props.rowData.accountingPeriodFormatted">
						{{ props.rowData.accountingPeriodFormattedShort }}
					</div>
					<div v-else>
						{{ props.rowData.accountingPeriodFormatted }}
					</div>
				</div>
				<div class="slot" slot="status-slot" slot-scope="props">
					{{ props.rowData.statusFormatted }}
				</div>
				<div class="slot flex flex-row justify-center" slot="actions-slot" slot-scope="props">
					<button
						class="btn btn-sm mr-2"
						title="Prikaži detalje"
						style="width: 26px; height: 26px; padding: 0px"
						@click="showDetails(props.rowData)"
					>
						<i class="fas fa-search"></i>
					</button>
					<button
						v-if="props.rowData.status == 'DRAFT'"
						class="btn btn-sm mr-2"
						title="Izdaj fakture"
						style="width: 26px; height: 26px; padding: 0px"
						@click="createInvoice(props.rowData)"
					>
						<i class="fas fa-file-upload" />
					</button>
					<button
						v-if="props.rowData.status == 'DRAFT'"
						class="btn btn-sm mr-2"
						title="Izmeni nacrt"
						style="width: 26px; height: 26px; padding: 0px"
						@click="createDraft(props.rowData)"
					>
						<i class="fas fa-pen" />
					</button>
					<button
						v-if="props.rowData.status == 'DRAFT'"
						class="btn btn-sm bg-red-600 hover:bg-red-800"
						title="Obriši nacrt"
						style="width: 26px; height: 26px; padding: 0px"
						@click="deleteDraft(props.rowData)"
					>
						<i class="fas fa-trash" />
					</button>

					<button
						v-if="props.rowData.status == 'CREATED' && user.role != 'ONPREMADMIN'"
						class="btn btn-sm"
						title="Pošalji mejlove"
						style="width: 26px; height: 26px; padding: 0px"
						@click="sendEmails(props.rowData)"
					>
						<i class="fas fa-envelope"></i>
					</button>
				</div>
			</vuetable>
			<div class="flex flex-row justify-end items-center">
				<div class="mr-4">{{ numOfDisplayed }} / {{ numOfTotal }}</div>
				<div class="flex flex-row per-page-div">
					<input type="radio" id="10-per-page-monitor" :value="10" v-model="perPage" /><label
						:class="{ active: perPage == 10 }"
						for="10-per-page-monitor"
						>10</label
					>
					<input type="radio" id="20-per-page-monitor" :value="20" v-model="perPage" /><label
						:class="{ active: perPage == 20 }"
						for="20-per-page-monitor"
						>20</label
					>
					<input type="radio" id="50-per-page-monitor" :value="50" v-model="perPage" /><label
						:class="{ active: perPage == 50 }"
						for="50-per-page-monitor"
						>50</label
					>
					<input type="radio" id="100-per-page-monitor" :value="100" v-model="perPage" /><label
						:class="{ active: perPage == 100 }"
						for="100-per-page-monitor"
						>100</label
					>
				</div>
				<vuetable-pagination ref="pagination" class="pull-right" @vuetable-pagination:change-page="onChangePage">
				</vuetable-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import ModalCreateFinancialDraft from '../components/ModalCreateFinancialDraft.vue';
import ModalCreateInvoiceGroupDraft from '../components/ModalCreateInvoiceGroupDraft.vue';
import ModalInvoiceDraftDetails from '../components/ModalInvoiceDraftDetails.vue';
import Vuetable from 'vuetable-2';
import VuetablePagination from '../components/VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';

export default {
	components: {
		ModalCreateInvoiceGroupDraft,
		ModalInvoiceDraftDetails,
		Vuetable,
		VuetablePagination
	},

	computed: {
		...mapState({
			user: state => {
				return {
					id: state.user.user.id,
					name: state.user.user.name,
					phone: state.user.user.phone,
					email: state.user.user.email,
					role: state.user.user.role,
					pib: state.user.user.pib
				};
			}
		})
	},

	data() {
		return {
			css: VuetableCssConfig,
			fields: [],
			localData: [],
			loading: false,
			perPage: 20,
			selectedGroupId: null,
			months: [
				'Januar',
				'Februar',
				'Mart',
				'April',
				'Maj',
				'Jun',
				'Jul',
				'Avgust',
				'Septembar',
				'Oktobar',
				'Novembar',
				'Decembar'
			],
			numOfDisplayed: 0,
			numOfTotal: 0
		};
	},

	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		}
	},

	methods: {
		showDetails(row) {
			this.selectedGroupId = row.id;
			this.$modal.show('draft-details');
		},
		async loadData() {
			const response = await axios.get('finances/invoice-groups');
			this.formatData(response.data);
		},

		onLoaded() {
			this.numOfDisplayed = this.$refs.vuetable.countTableData;
			this.numOfTotal = this.$refs.pagination.tablePagination.total;
		},

		formatData(data) {
			this.localData = data.map(item => {
				let periodFormatted;
				let periodFormattedShort;
				if (item.accountingPeriod === 'PERIOD') {
					periodFormatted = '';
					periodFormattedShort = '';
					let i = 1;
					for (const period of item.months) {
						periodFormatted += `${this.months[period.month]} ${period.year}, `;
						if (i <= 2) {
							periodFormattedShort += `${this.months[period.month]} ${period.year}, `;
						}
						i++;
					}
					if (periodFormatted != periodFormattedShort) {
						periodFormattedShort = periodFormattedShort + '...';
					} else {
						periodFormattedShort = periodFormatted.slice(0, -2);
						periodFormattedShort += '.';
					}
					periodFormatted = periodFormatted.slice(0, -2);
					periodFormatted += '.';
				}

				if (item.accountingPeriod == 'MONTHLY') {
					item.accountingPeriodFormatted = `${this.months[item.month]} ${item.year}.`;
				} else if (item.accountingPeriod == 'YEARLY') {
					item.accountingPeriodFormatted = `${item.year}. (Godišnji)`;
				} else if (item.accountingPeriod == 'PERIOD') {
					item.accountingPeriodFormattedShort = periodFormattedShort;
					item.accountingPeriodFormatted = periodFormatted;
				}

				if (item.status == 'DRAFT') {
					item.statusFormatted = 'Nacrt';
				} else if (item.status == 'CREATED') {
					item.statusFormatted = 'Izdate fakture';
				} else if (item.status == 'SENT') {
					item.statusFormatted = 'Izdate fakture i poslati mejlovi';
				}

				let numOfObligors = 0;
				if (item.clientIds) {
					numOfObligors += item.clientIds.split(',').length;
				}
				if (item.userIds) {
					numOfObligors += item.userIds.split(',').length;
				}

				return item;
			});

			//sort localData by dateCreated
			this.localData.sort((a, b) => {
				return new Date(b.dateDrafted) - new Date(a.dateDrafted);
			});
		},

		setupTableFields() {
			this.fields = [
				{
					name: 'description',
					title: 'Opis nacrta',
					sortField: 'description'
				},
				{
					name: 'accounting-period-slot',
					title: 'Obračunski period'
				},
				{
					name: 'date-drafted-slot',
					title: 'Datum kreiranja nacrta',
					sortField: 'dateDrafted'
				},
				{
					name: 'date-turnover-slot',
					title: 'Datum prometa',
					sortField: 'dateTurnover'
				},
				{
					name: 'date-created-slot',
					title: 'Datum izdavanja faktura',
					sortField: 'dateCreated'
				},
				{
					name: 'status-slot',
					title: 'Status',
					sortField: 'status'
				},
				{
					name: 'actions-slot',
					title: 'Akcije',
					sortable: false
				}
			];
		},

		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.name.search(txt) >= 0 ||
						item.pib.search(txt) >= 0 ||
						(item.legalEntityName && item.legalEntityName.search(txt) >= 0) ||
						(item.legalEntityEmails && item.legalEntityEmails.search(txt) >= 0) ||
						(item.legalEntityAddress && item.legalEntityAddress.search(txt) >= 0)
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		createDraft(row = null) {
			this.$modal.show('create-financial-draft');
			if (row) {
				this.selectedGroupId = row.id;
			}
		},
		closeModal(name) {
			this.$modal.hide(name);
			this.selectedGroupId = null;
		},
		async refreshData() {
			this.loading = true;
			await this.loadData();
			this.loading = false;
		},
		async createInvoice(row) {
			this.$fire({
				title: 'Izdavanje faktura',
				html: `Da li ste sigurni da želite da izdate fakture izabrane grupe?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Izdaj fakture',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`finances/post-invoice-group/${row.id}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								text: `Uspešno izdate fakture.`,
								type: 'success',
								showConfirmButton: true
							});
							this.refreshData();
						})
						.catch(error => {
							console.error(error);

							if (error.response.status == 400 && error.response.data.message == 'No invoices created') {
								this.$fire({
									title: 'Greška',
									text: 'Za dati nacrt je kreirano 0 faktura. Izdavanje faktura nije moguće.',
									type: 'error',
									showConfirmButton: true
								});
							} else {
								this.$fire({
									title: 'Greška',
									text: 'Došlo je do greške prilikom izdavanja računa.',
									type: 'error',
									timer: 2500,
									showConfirmButton: false
								});
							}
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});
		},

		async deleteDraft(row) {
			this.$fire({
				title: 'Brisanje nacrta',
				html: `Da li ste sigurni da želite da obrišete izabrani nacrt faktura?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Obriši nacrt',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.delete(`finances/invoice-group/${row.id}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								text: `Uspešno obrisan nacrt fakture.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							console.error(error);
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom brisanja nacrta fakture.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});
		},

		async sendEmails(row) {
			this.$fire({
				title: 'Slanje mejlova',
				html: `Da li ste sigurni da želite da pošaljete mejlove sa kreiranim fakturama obveznicima iz izabrane grupe?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Pošalji mejlove',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`finances/invoice-emails/group/${row.id}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								text: `Uspešno poslati mejlovi.`,
								type: 'success',
								showConfirmButton: true
							});
							this.refreshData();
						})
						.catch(error => {
							console.error(error);
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom slanja mejlova.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});
		}
	},

	async created() {
		this.loading = true;
		try {
			this.setupTableFields();
			await this.loadData();
		} catch (error) {
			console.log(error);
		} finally {
			this.loading = false;
		}
	}
};
</script>

<style></style>
