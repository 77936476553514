<template>
	<div>
		<div>
			<modal height="auto" name="add-ignored-lpfr" :scrollable="true" :adaptive="false">
				<modal-add-ignored-lpfr @close="closeModal('add-ignored-lpfr')" @refresh="refreshData"></modal-add-ignored-lpfr>
			</modal>
		</div>
		<div>
			<h1 class="text-2xl">LPFR-ovi izuzeti iz fakturisanja</h1>
		</div>
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-else>
			<div class="flex flex-row justify-end mb-2">
				<button @click="addIgnoredLpfr()">Dodaj izuzeti LPFR</button>
			</div>
			<vuetable
				ref="vuetable"
				:api-mode="false"
				:fields="fields"
				:css="css.table"
				:per-page="perPage"
				noDataTemplate="Nisu pronađeni izuzeti LPFR-ovi"
				:data-manager="dataManager"
				pagination-path="pagination"
				@vuetable:pagination-data="onPaginationData"
				@vuetable:loaded="onLoaded"
				class="ignored-lpfrs-table"
				track-by="id"
			>
				<div class="slot flex flex-row justify-center" slot="actions-slot" slot-scope="props">
					<button
						v-if="props.rowData.ignoreInvoicing"
						style="height: 26px; width: 38px; padding: 2px 1px 0px 0px"
						@click="changeInvoicingStatus(props.rowData, false)"
						class="reset-lpfr-btn btn btm-sm"
						title="Uključi u fakturisanje"
					>
						<i class="fas fa-dollar-sign"></i>
						<i class="fas fa-undo ml-1 text-sm"></i>
					</button>
				</div>
			</vuetable>
			<div class="flex flex-row justify-end items-center">
				<div class="mr-4">{{ numOfDisplayed }} / {{ numOfTotal }}</div>
				<div class="flex flex-row per-page-div">
					<input type="radio" id="10-per-page-monitor" :value="10" v-model="perPage" /><label
						:class="{ active: perPage == 10 }"
						for="10-per-page-monitor"
						>10</label
					>
					<input type="radio" id="20-per-page-monitor" :value="20" v-model="perPage" /><label
						:class="{ active: perPage == 20 }"
						for="20-per-page-monitor"
						>20</label
					>
					<input type="radio" id="50-per-page-monitor" :value="50" v-model="perPage" /><label
						:class="{ active: perPage == 50 }"
						for="50-per-page-monitor"
						>50</label
					>
					<input type="radio" id="100-per-page-monitor" :value="100" v-model="perPage" /><label
						:class="{ active: perPage == 100 }"
						for="100-per-page-monitor"
						>100</label
					>
				</div>
				<vuetable-pagination ref="pagination" class="pull-right" @vuetable-pagination:change-page="onChangePage">
				</vuetable-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from '../components/VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import axios from '@/axios/axios.js';
import ModalAddIgnoredLpfr from '../components/ModalAddIgnoredLpfr.vue';
import { mapState } from 'vuex';

export default {
	components: {
		Vuetable,
		VuetablePagination,
		ModalAddIgnoredLpfr
	},
	computed: {
		...mapState({
			user: state => {
				return {
					id: state.user.user.id,
					name: state.user.user.name,
					phone: state.user.user.phone,
					email: state.user.user.email,
					role: state.user.user.role,
					pib: state.user.user.pib
				};
			}
		}),

		role() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'SUPER ADMINISTRATOR';
				case 'ONPREMADMIN':
					return 'ADMINISTRATOR';
				case 'DISTRIBUTOR':
					return 'DISTRIBUTER';
				case 'PARTNER':
					return 'PARTNER';
				default:
					return 'Nepoznato';
			}
		}
	},
	data() {
		return {
			loading: true,
			css: VuetableCssConfig,
			perPage: 20,
			numOfDisplayed: 0,
			numOfTotal: 0,
			fields: [],
			localData: [],
			originalData: [],
			moneyFormatter: new Intl.NumberFormat('de-DE', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 4
			}),
			selectedRow: null
		};
	},

	async created() {
		try {
			this.loading = true;
			this.setupTableFields();
			await this.loadData();
		} catch (error) {
			console.log(error);
		} finally {
			this.loading = false;
		}
	},

	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		}
	},

	methods: {
		setupTableFields() {
			this.fields = [
				{
					name: 'clientFormatted',
					title: 'Klijent',
					sortField: 'client'
				},
				{
					name: 'jid',
					title: 'JID',
					sortField: 'jid'
				},
				{
					name: 'jidNicknameFormatted',
					title: 'JID nadimak',
					sortField: 'jidNickname'
				},
				{
					name: 'typeFormatted',
					title: 'Tip',
					sortField: 'type'
				},
				{
					name: 'actions-slot',
					title: 'Akcije',
					sortable: false
				}
			];

			if (this.user.role == 'SUPERADMIN') {
				this.fields.unshift({
					name: 'partnerFormatted',
					title: 'Partner',
					sortField: 'partner'
				});
				this.fields.unshift({
					name: 'distributorFormatted',
					title: 'Distributer',
					sortField: 'distributor'
				});
			}
		},

		addIgnoredLpfr() {
			this.$modal.show('add-ignored-lpfr');
		},

		async changeInvoicingStatus(row, status) {
			let text = '';

			if (status) {
				text = 'isključite izabrani LPFR iz budućih fakturisanja?';
			} else {
				text = 'uključite izabrani LPFR u buduća fakturisanja?';
			}

			this.$fire({
				title: 'Fakturisanje LPFR-a',
				text: 'Da li ste sigurni da želite da ' + text,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Potvrdi',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					axios
						.post(`finances/lpfr/${row.jid}/ignore/${status}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								text: 'LPFR je uspešno uključen u fakturisanje.',
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							this.loadingFinishedMonitor();
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom promene statusa fakturisanja LPFR-a.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
		},

		closeModal(name) {
			this.$modal.hide(name);
			this.selectedRow = null;
		},

		async refreshData() {
			this.loading = true;
			await this.loadData();
			this.loading = false;
		},

		async loadData() {
			this.loading = true;
			const response = await axios.get('finances/ignored-lpfrs');
			this.formatData(response.data);
			this.loading = false;
		},

		formatData(data) {
			const formattedData = data.map(row => {
				if (row.jidNickname) {
					row.jidNicknameFormatted = row.jidNickname;
				} else {
					row.jidNicknameFormatted = '/';
				}

				if (row.client) {
					row.clientFormatted = row.client.name;
				} else {
					row.clientFormatted = '/';
				}

				if (row.distributor) {
					row.distributorFormatted = row.distributor.name;
				} else {
					row.distributorFormatted = '/';
				}

				if (row.partner) {
					row.partnerFormatted = row.partner.name;
				} else {
					row.partnerFormatted = '/';
				}

				switch (row.type) {
					case 'PRODUCTION':
						row.typeFormatted = 'Produkciona';
						break;
					case 'PRINT':
						row.typeFormatted = 'Print';
						break;
					case 'SANDBOX':
						row.typeFormatted = 'Razvojna';
						break;
					default:
						row.typeFormatted = 'Nepoznato';
				}

				return row;
			});

			this.originalData = data;
			this.localData = data;
		},

		onLoaded() {
			this.numOfDisplayed = this.$refs.vuetable.countTableData;
			this.numOfTotal = this.$refs.pagination.tablePagination.total;
		},

		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.obligor.pib.search(txt) >= 0 || item.obligor.name.search(txt) >= 0 || item.number.search(txt) >= 0
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		}
	}
};
</script>

<style></style>
