<template>
	<div id="app">
		<Header v-if="this.$router.currentRoute.path != '/login'" />
		<router-view />
	</div>
</template>

<script>
import Header from './components/HeaderComponent.vue';

export default {
	name: 'App',
	components: {
		Header
	},

	data() {
		return {};
	}
};
</script>

<style lang="scss">
:root {
	--primary-color: #005ca9;
	--primary-color-light: #0091cc;
	--primary-color-disabled: #929fb3;
	--primary-color-dark: #0a4092;
	--primary-color-hover: #d6e5f1;
	--secondary-color: #3d3d3d;
	--secondary-color-light: #636363;
}

body {
	margin: 0;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
.toast-bubble {
	background-color: rgba(0, 145, 204, 1) !important;
	.action {
		color: white !important;
	}
}
</style>
