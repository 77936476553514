<template>
	<div class="wrapper">
		<div>
			<modal :adaptive="true" height="auto" name="additional-info">
				<div class="flex flex-row justify-end m-2 absolute right-1 top-1">
					<button
						class="bg-secondary hover:bg-secondary-light"
						style="width: 34px; height: 34px; line-height: 0"
						@click="$modal.hide('additional-info')"
					>
						<i class="fas fa-times" />
					</button>
				</div>
				<modal-chat-user-info @close="closeModal('additional-info')" :chat="chatToShowInfo"> </modal-chat-user-info>
			</modal>
		</div>
		<div class="chat-list">
			<div v-for="chat in chatList" :key="chat.id" @click="getChatMessagesFromList(chat)">
				<div
					class="chat-list__item mb-2 mr-1 p-1 rounded-xl cursor-pointer select-none"
					:class="{
						'chat-list__item-active': selectedChat && chat.id == selectedChat.id
					}"
				>
					<div class="text-sm text-left flex justify-between text-ellipsis">
						ADMIN: {{ chat.admin.name ? chat.admin.name : '/' }}
						<div>
							<i class="fas fa-info info-user" @click="openUserInfo($event, chat)" title="Info"></i>
							<i
								v-if="!chat.admin.name || chat.admin.id != user.id"
								class="fas fa-user-plus info-user"
								@click="takeChat($event, chat)"
								title="Preuzmi chat"
							></i>
							<i
								v-if="chat.admin.id && chat.admin.id == user.id"
								class="fas fa-user-minus info-user"
								@click="releaseChat($event, chat)"
								title="Oslobodi chat"
							></i>
							<i
								v-if="!chat.user.online"
								class="fas fa-envelope"
								@click="sendChatToEmail($event, chat.id)"
								title="Pošalji na email"
							></i>
						</div>
					</div>
					<div class="flex justify-between mt-2">
						<div class="text-left text-sm text-ellipsis">
							Status:
							<span
								class="font-bold"
								:class="{
									'color-red': chat.status == 'Open',
									'color-green': chat.status != 'Open'
								}"
								>{{ chat.status == 'Open' ? 'Otvoreno' : 'Rešeno' }}</span
							>
						</div>
						<div>
							<i
								v-if="chat.status == 'Open'"
								class="fas fa-check"
								@click="changeChatStatusToClosed($event, chat)"
								title="Označi kao rešeno"
							></i>
							<i
								v-if="chat.status != 'Open'"
								class="fas fa-times"
								@click="changeChatStatusToOpen($event, chat)"
								title="Označi kao otvoreno"
							></i>
						</div>
					</div>
					<div class="font-semibold text-lg text-left text-ellipsis flex">
						<div class="indicator green" v-if="chat.user.online"></div>
						<div class="indicator red" v-if="!chat.user.online"></div>
						{{ chat.user.name }}
					</div>
					<div class="text-lg text-left text-ellipsis">
						<span v-if="chat.hasUnread" class="unread-alert"></span>{{ chat.lastMessage.message }}
					</div>
					<div class="text-sm text-left">
						{{ chat.lastMessage.timestamp | formatDate }}
					</div>
				</div>
			</div>
		</div>
		<div class="chat-window" id="chatWindow">
			<div>
				<infinite-loading
					direction="top"
					@infinite="infiniteHandler"
					:identifier="(selectedChat ? selectedChat.id : 'no-chat') + 'chat'"
				></infinite-loading>
				<div
					v-for="chatMessage in chatMessages"
					:key="chatMessage.id"
					class="chat-window__item mb-2 p-2 rounded-xl mx-2"
					:class="{
						'chat-window__my-message': chatMessage.user.role == 'SUPERADMIN',
						'text-right': chatMessage.user.role == 'SUPERADMIN',
						'text-left': chatMessage.user.role != 'SUPERADMIN',
						'chat-list__item-user': chatMessage.user.role != 'SUPERADMIN'
					}"
				>
					<div class="relative">
						<i
							v-if="chatMessage.user.role == 'SUPERADMIN'"
							class="fas fa-trash cursor-pointer"
							style="font-size: 12px"
							@click="deleteMessage(chatMessage.id)"
						/>

						<div
							class="text-lg break-words"
							style="white-space: pre-wrap"
							v-html="chatMessage.message"
							v-linkified
						></div>
						<div
							class="medias"
							:class="{
								'justify-end': chatMessage.user.role == 'SUPERADMIN',
								'justify-start': chatMessage.user.role != 'SUPERADMIN'
							}"
						>
							<div v-for="media in chatMessage.medias" :key="media.id">
								<div v-if="media.type.includes('image')">
									<img
										class="media-image cursor-pointer my-2"
										:src="media.imageUrl"
										:alt="media.name"
										@click="downloadMedia(media.path)"
									/>
								</div>
								<div
									v-else
									@click="downloadMedia(media.path)"
									class="media-file cursor-pointer px-2 py-1 my-2 rounded-2xl ml-2 text-left"
								>
									{{ media.name }}
								</div>
							</div>
						</div>
						<div class="text-sm mt-2">
							{{ chatMessage.adminName ? chatMessage.adminName : '' }}
						</div>
						<div class="text-sm">
							{{ chatMessage.timestamp | formatDate }}
						</div>
					</div>
				</div>
			</div>
			<div class="create-message-wrapper" v-if="isChatPossible()">
				<div class="create-message-files">
					<div class="create-message-file select-none rounded-xl" v-for="(file, index) in selectedFiles" :key="index">
						{{ file.name }}
						<i class="fas fa-trash" @click="deleteFile(index)"></i>
					</div>
				</div>
				<div class="create-message">
					<input style="display: none" type="file" @change="onFileSelected" ref="fileInput" multiple />
					<button class="btn-add-file" title="Dodaj prilog" @click="addFile()" style="width: 42px">
						<i class="fas fa-paperclip"></i>
					</button>
					<textarea
						class="focus:ring-primary focus:border-primary"
						v-model="chatMessageText"
						placeholder="Unesite poruku..."
						v-on:keyup.enter.exact="sendMessage"
						@input="autoresizeTextarea"
						:style="{ height: messageTextAreaHeight + 'px' }"
						rows="1"
						ref="textInput"
						@paste="pasteFunction($event)"
					/>
					<button class="btn-send-message" title="Pošali" @click="sendMessage()" style="width: 42px">
						<i class="fas fa-paper-plane"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import linkify from 'vue-linkify';
import ModalChatUserInfo from './ModalChatUserInfo.vue';

const LIMIT = 10;

export default {
	components: { InfiniteLoading, ModalChatUserInfo },
	props: {},
	directives: {
		linkified: linkify
	},
	computed: {
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		})
	},
	data() {
		return {
			chatList: [],
			chatMessages: [],
			chatMessageText: '',
			messageTextAreaHeight: 40,
			selectedChat: null,
			refreshInterval: 5000,
			intervalId: null,
			selectedFiles: [],
			loadinMessages: true,
			page: 1,
			totalMessages: 0,
			lastMessageId: null,
			hasNewMessage: false,
			chatToShowInfo: null
		};
	},
	methods: {
		closeModal(name) {
			this.$modal.hide(name);
		},
		autoresizeTextarea(event) {
			event.target.style.height = 'auto';
			event.target.style.height = event.target.scrollHeight + 'px';
			if (event.target.scrollHeight > 110) {
				event.target.style.height = '110px';
			}
		},
		setInterval() {
			this.intervalId = setInterval(async () => {
				if (this.$router.history.current.name == 'home') {
					await this.getChatList(false);
					await this.checkForNewMessages(this.selectedChat);
				}
			}, this.refreshInterval);
		},
		async resetIntervals() {
			this.chatMessages = [];
			// TODO: Add loaders
			await this.getChatList();
			this.setInterval();
		},
		async removeInterval() {
			this.selectedChat = null;
			if (this.intervalId) {
				clearInterval(this.intervalId);
				this.intervalId = null;
				this.chatMessages = [];
			}
		},
		async getChatList(shouldGetChat = true) {
			try {
				const response = await axios.get('chat');
				this.chatList = response.data;
				this.chatList.sort((a, b) => {
					return Date(b.lastMessage.timestamp) - Date(a.lastMessage.timestamp);
				});
				if (shouldGetChat) {
					if (this.chatList && this.chatList.length > 0) {
						this.getChatMessagesFromList(this.chatList[0]);
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		async checkForNewMessages(chat) {
			try {
				this.selectedChat = chat;
				const response = await axios.get(`chat/${chat.id}`, {
					params: {
						page: 1,
						limit: 1
					}
				});
				if (response.data.messages) {
					const lastMessage = response.data.messages[response.data.messages.length - 1];
					const newLastMessageId = lastMessage.id;
					const totalDifference = response.data.total - this.totalMessages;
					if (newLastMessageId != this.lastMessageId) {
						if (this.lastMessageId && lastMessage.user.id != this.user.id) {
							this.hasNewMessage = true;

							if (totalDifference > 0) {
								this.getChatAllMessages(this.selectedChat, false, totalDifference);
							} else {
								this.getChatAllMessages(this.selectedChat, false);
							}
						}
						this.lastMessageId = newLastMessageId;
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		async getChatAllMessages(chat, shouldScrollToBottom = true, numberToGetOverLimit = 1) {
			try {
				this.selectedChat = chat;
				const response = await axios.get(`chat/${chat.id}`, {
					params: {
						page: 1,
						limit: this.page * LIMIT + numberToGetOverLimit
					}
				});
				this.totalMessages = response.data.total;
				let messages = response.data.messages.filter;
				messages = await this.fillChatMedias(response.data.messages);
				this.chatMessages = messages.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
				if (shouldScrollToBottom) {
					setTimeout(() => {
						var container = this.$el.querySelector('#chatWindow');
						container.scrollTop = container.scrollHeight;
					}, 100);
				}
			} catch (error) {
				console.log(error);
			}
		},
		async getChatMessagesPaginated(chat) {
			try {
				this.selectedChat = chat;
				const response = await axios.get(`chat/${chat.id}`, {
					params: {
						page: this.page,
						limit: LIMIT
					}
				});
				this.totalMessages = response.data.total;
				let newMessages = response.data.messages;
				newMessages = await this.fillChatMedias(newMessages);
				this.chatMessages.unshift(...newMessages);
				this.chatMessages = this.chatMessages
					.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)
					.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
			} catch (error) {
				console.log(error);
			}
		},
		async fillChatMedias(chatMessages) {
			for (const message of chatMessages) {
				for (const media of message.medias) {
					if (media.type.includes('image')) {
						media.imageUrl = await this.getImageBase64(media.path);
					}
				}
			}
			return chatMessages;
		},
		async downloadMedia(path) {
			const pathArr = path.split('-');
			let fileName = path;
			if (pathArr.length >= 5) {
				pathArr.splice(0, 5);
				fileName = pathArr.join('');
			}

			const file = await axios.get(`chat/download/${path}`, {
				responseType: 'blob'
			});
			var binaryData = [];
			binaryData.push(file.data);
			const url = window.URL.createObjectURL(new Blob(binaryData, { type: file.headers['content-type'] }));
			const downloadLink = document.createElement('a');
			downloadLink.href = url;
			downloadLink.download = fileName;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			downloadLink.remove();
		},
		async getImageBase64(path) {
			const file = await axios.get(`chat/download/${path}`, {
				responseType: 'blob'
			});
			const base64 = await this.blobToBase64(file.data);
			return base64;
		},
		blobToBase64(blob) {
			return new Promise((resolve, _) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.readAsDataURL(blob);
			});
		},
		addFile() {
			this.$refs.fileInput.click();
		},
		onFileSelected(event) {
			// validate file size
			for (let file of event.target.files) {
				if (file.size > 5122059) {
					this.showFailModal('Fajl ne može biti veći od 5MB');
					return;
				}
			}
			for (const file of event.target.files) {
				this.selectedFiles.push(file);
			}
		},
		async sendMessage() {
			if ((!this.chatMessageText || this.chatMessageText.trim() == '') && this.selectedFiles.length == 0) {
				return;
			}
			let formData = new FormData();
			for (let i = 0; i < this.selectedFiles.length; i++) {
				formData.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name);
			}

			formData.append('message', this.chatMessageText.trim());
			formData.append('userId', this.selectedChat.user.id);
			formData.append('adminId', this.selectedChat.admin.id);

			try {
				await axios.post('chat', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});
				this.chatMessageText = '';
				this.selectedFiles = [];
				this.messageTextAreaHeight = 40;
				this.$refs.textInput.style.height = '40px';
				this.getChatAllMessages(this.selectedChat);
			} catch (error) {
				// TODO: Do something
			}
		},
		openUserInfo($event, chat) {
			$event.stopPropagation();
			this.chatToShowInfo = chat;
			this.$modal.show('additional-info');
		},
		async takeChat($event, chat) {
			$event.stopPropagation();
			await axios.post(`chat/take/${chat.id}`);
			await this.getChatList(false);
			await this.getChatMessagesFromList(chat);
			this.selectedChat.admin = {
				id: this.user.id
			};
		},
		async releaseChat($event, chat) {
			$event.stopPropagation();
			await axios.post(`chat/release/${chat.id}`);
			await this.getChatList(false);
			this.selectedChat.admin = null;
		},
		deleteFile(index) {
			this.selectedFiles.splice(index, 1);
		},
		pasteFunction(pasteEvent) {
			if (pasteEvent.clipboardData == false) {
				return;
			}

			var items = pasteEvent.clipboardData.items;

			if (items == undefined) {
				return;
			}
			for (var i = 0; i < items.length; i++) {
				if (items[i].type.indexOf('image') == -1) continue;
				var blob = items[i].getAsFile();
				this.selectedFiles.push(blob);
			}
		},
		async infiniteHandler($state) {
			if (!this.selectedChat || this.totalMessages == 0) {
				$state.reset();
				return;
			}
			if (this.totalMessages > this.chatMessages.length) {
				this.page = this.page + 1;
				await this.getChatMessagesPaginated(this.selectedChat);
				$state.loaded();
			} else {
				$state.complete();
			}
			// $state.loaded(); ili $state.complete() ako nema podataka ILI $state.reset() ako se 1. put upali pre podataka da se resetuje kako bi radio
		},
		async getChatMessagesFromList(chat) {
			this.page = 1;
			this.chatMessages = [];
			this.lastMessageId = null;
			this.hasNewMessage = false;
			await this.getChatMessagesPaginated(chat);

			if (this.chatMessages && this.chatMessages.length > 0) {
				this.lastMessageId = this.chatMessages[this.chatMessages.length - 1].id;
			}
			setTimeout(() => {
				var container = this.$el.querySelector('#chatWindow');
				container.scrollTop = container.scrollHeight;
			}, 100);
		},
		isChatPossible() {
			return this.selectedChat && this.selectedChat.admin && this.selectedChat.admin.id == this.user.id;
		},
		deleteMessage(chatMessageId) {
			this.$fire({
				title: 'Brisanje poruke',
				html: 'Da li ste sigurni da želite da obrišete poruku',
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Obriši',
				confirmButtonColor: '#e74c3c',
				reverseButtons: true
			}).then(async result => {
				if (result.value) {
					await axios.delete(`chat/message/${chatMessageId}`);
					await this.getChatAllMessages(this.selectedChat, false);
				}
			});
		},
		async changeChatStatusToClosed($event, chat) {
			$event.stopPropagation();
			this.$fire({
				title: 'Razrešavanje chata',
				html: 'Da li ste sigurni da želite da označite chat kao razrešen. <br/> Ovo podrazumeva slanje poruke klijentu da je prijava rešena.',
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Razreši chat',
				confirmButtonColor: '#e74c3c',
				reverseButtons: true
			}).then(async result => {
				if (result.value) {
					await axios.post(`chat/${chat.id}/close`);
					await this.getChatList(false);
					await this.getChatMessagesFromList(chat);
					this.selectedChat.admin = null;
				}
			});
		},
		async changeChatStatusToOpen($event, chat) {
			$event.stopPropagation();
			this.$fire({
				title: 'Otvaranje chata',
				html: 'Da li ste sigurni da želite da označite chat kao otvoren?',
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Otvori chat',
				confirmButtonColor: '#e74c3c',
				reverseButtons: true
			}).then(async result => {
				if (result.value) {
					await axios.post(`chat/${chat.id}/open`);
					await this.getChatList(false);
					await this.getChatMessagesFromList(chat);
				}
			});
		},
		sendChatToEmail($event, chatId) {
			$event.stopPropagation();
			this.$fire({
				title: 'Slanje poruka na email',
				html: 'Da li ste sigurni da želite da pošaljete nepročitane poruke partneru na email',
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Pošalji',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(async result => {
				if (result.value) {
					try {
						await axios.post(`chat/email-unread-messages/${chatId}`);
						this.$fire({
							title: 'Uspešna akcija',
							html: `Email je uspešno poslat.`,
							type: 'success',
							timer: 2500,
							showConfirmButton: false
						});
					} catch (error) {
						if (error.response.data.message == 'No messages to send') {
							this.$fire({
								title: 'Greška',
								html: `Ne postoje nepročitane poruke od strane korisnika.`,
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						} else {
							this.$fire({
								title: 'Greška',
								html: `Došlo je do greške prilikom slanja emaila.`,
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						}
					}
				}
			});
		}
	}
};
</script>
<style lang="scss">
.linkified {
	color: #80dbff;
}
</style>
<style lang="scss" scoped>
.wrapper {
	height: calc(100vh - 120px - 12px - 50px - 32px - 32px - 12px);
	display: flex;
}
.chat-list {
	height: 100%;
	width: 25%;
	overflow: auto;
	border-right: 2px solid var(--primary-color);
}
.chat-window {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 75%;
	overflow: auto;
	position: relative;
	.chat-window__item {
		width: fit-content;
		max-width: 50%;
		background: rgba(10, 64, 146, 1);
		color: white;
	}
	.chat-window__my-message {
		margin-left: auto;
	}
	.media-image {
		max-height: 150px;
		object-fit: contain;
	}
	.media-file {
		background: white;
		color: black;
		font-weight: 600;
	}
}
.chat-list__item {
	background: linear-gradient(97deg, rgba(10, 64, 146, 1) 0%, rgba(0, 92, 169, 1) 41%, rgba(0, 145, 204, 1) 100%);
	color: white;
	padding: 8px 12px;
	&-active {
		background: #083577 !important;
	}
}
.chat-list__item-user {
	background: rgba(0, 145, 204, 1) !important;
}
.text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.create-message-wrapper {
	position: sticky;
	bottom: 0;
	width: 100%;
	background: white;
}
.create-message {
	display: flex;
	align-items: center;

	textarea {
		margin: 0 12px;
		flex: 1;
		resize: none;
		overflow: auto;
	}
	.btn-send-message {
		margin-right: 12px;
	}
	.btn-add-file {
		margin-left: 12px;
	}
}
.create-message-files {
	display: flex;
	flex-wrap: wrap;
	margin-left: 12px;
	.create-message-file {
		display: flex;
		align-items: center;
		margin-right: 12px;
		padding: 4px 12px;
		background: linear-gradient(97deg, rgba(10, 64, 146, 1) 0%, rgba(0, 92, 169, 1) 41%, rgba(0, 145, 204, 1) 100%);
		color: white;
		margin-bottom: 3px;
		i {
			margin-left: 8px;
			cursor: pointer;
		}
	}
}
.unread-alert {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: red;
	display: inline-block;
	margin-right: 8px;
}
.indicator {
	height: 8px;
	width: 8px;
	border-radius: 50%;
	margin-right: 8px;
	margin-top: auto;
	margin-bottom: auto;
}
.green {
	background: green;
}
.red {
	background: red;
}
.info-user {
	margin-right: 12px;
}
.color-red {
	color: red;
}
.color-green {
	color: green;
}
</style>
