<template>
	<div class="modal modal-add-client">
		<h1 class="text-2xl">Nadimak LPFR-a</h1>
		<hr />
		<div class="modal-content text-lg">
			<div class="form-item">
				<h2>JID</h2>
				<h1>{{ row.jid }}</h1>
			</div>
			<div class="form-item">
				<h2 for="nickname">Nadimak</h2>
				<input
					type="text"
					id="nickname"
					v-model="nickname"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button :disabled="loading || nickname == row.jidNickname" class="flex-grow ml-2" @click="changeNickname()">
					Promeni nadimak
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';

export default {
	data() {
		return {
			loading: false,
			nickname: ''
		};
	},

	props: {
		row: {
			type: Object,
			required: true
		}
	},

	created() {
		this.nickname = this.row.jidNickname;
	},

	methods: {
		async changeNickname() {
			this.loading = true;
			try {
				await axios.post(`client/lpfr/${this.row.id}/nickname`, {
					nickname: this.nickname
				});
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Nadimak je uspešno promenjen',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom promene nadimka.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style></style>
