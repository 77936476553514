<template>
	<div class="modal">
		<h1>Podešavanje kursa</h1>
		<hr class="mb-4" />
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-else>
			<div class="flex flex-row">
				<div>
					<h2 class="mt-2">Datum</h2>
					<vue-datetime
						type="date"
						input-class="form-control form-control"
						placeholder="Izaberite datum"
						hidden-name="webstorm"
						format="dd.MM.yyyy."
						:phrases="{
							ok: 'U redu',
							cancel: 'Otkaži'
						}"
						v-model="date"
					></vue-datetime>
				</div>
				<div class="ml-2" v-show="date">
					<h2 class="mt-2">Srednji kurs (1€ u RSD)</h2>
					<money class="money-input" v-model="rate" v-bind="{ precision: 4 }"></money>
				</div>
			</div>
			<div class="flex flex-row mt-3">
				<button :disabled="loading" class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button :disabled="!inputValid" class="ml-2 flex-grow-2" @click="postExchangeRate">Sačuvaj kurs</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	data() {
		return {
			loading: false,
			date: null,
			rate: '',
			oldRate: '',
			rateExists: false
		};
	},
	watch: {
		async date() {
			if (!this.date) return;
			try {
				const response = await axios.get(`finances/exchange-rate-by-date/${this.date}`);

				this.rate = response.data;
				this.rateExists = true;
				this.oldRate = this.rate;
			} catch (error) {
				this.rate = '0';
				this.rateExists = false;
			}
		}
	},

	computed: {
		inputValid() {
			console.log('testing');
			console.log(this.rate.length > 0);
			console.log(this.rate != this.oldRate);
			console.log(this.rate > 0);
			return this.rate != this.oldRate && this.rate > 0;
		}
	},

	methods: {
		async postExchangeRate() {
			this.loading = true;
			try {
				await axios.post('finances/exchange-rate', {
					date: this.date,
					rate: this.rate
				});
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Kurs je uspešno podešen.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom podešavanja kursa.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('close');
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style></style>

<style lang="scss" scoped>
.money-input {
	border-color: #d0d0d0 !important;
}
</style>
