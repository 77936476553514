<template>
	<div class="modal modal-version-details">
		<h1 v-if="!updateAll">Forsirano ažuriranje verzije</h1>
		<h1 v-else>Forsirano ažuriranje verzije svih LPFR</h1>
		<hr />
		<div v-if="loading" class="mt-4 mb-4 flex flex-row justify-center text-center">
			<spinner-component :size="50" />
		</div>
		<div class="modal-content text-lg">
			<div v-if="!loading">
				<div v-if="!updateAll">
					<div>
						<h2>LPFR</h2>
						<h1>
							{{ lpfr.jid }}
							<span v-if="lpfr.jidNickname">({{ lpfr.jidNickname }})</span>
						</h1>
					</div>
					<div class="form-item-horizontal items-end">
						<div class="w-3/5">
							<h2>Verzija LPFR</h2>
							<h1>{{ lpfr.version }}</h1>
						</div>
						<div class="w-2/5">
							<h2>Aktuelna verzija</h2>
							<h1>{{ currentVersion.version }}</h1>
						</div>
					</div>
				</div>
				<div v-else>
					<h2>Aktuelna verzija</h2>
					<h1>{{ currentVersion.version }}</h1>
				</div>
				<hr class="mt-2" />
				<div class="mt-3">Datumi prelaska na aktuelnu verziju su:</div>
				<div class="flex flex-row items-center mt-2">
					<div class="mr-4 circle-icon red">1</div>
					<div class="form-item-horizontal flex-grow-2">
						<div class="w-1/2">
							<h2>Datum prvog talasa</h2>
							<h1>{{ currentVersion.firstWaveDate | formatDateNoTime }}</h1>
						</div>

						<div class="w-1/2">
							<h2>Preostalo mesta</h2>
							<h1>{{ currentVersion.firstWaveCounter }}</h1>
						</div>
					</div>
				</div>
				<div class="flex flex-row items-center mt-2">
					<div class="mr-4 circle-icon yellow">2</div>
					<div class="form-item-horizontal flex-grow-2">
						<div class="w-1/2">
							<h2>Datum drugog talasa</h2>
							<h1>{{ currentVersion.secondWaveDate | formatDateNoTime }}</h1>
						</div>

						<div class="w-1/2">
							<h2>Preostalo mesta</h2>
							<h1>{{ currentVersion.secondWaveCounter }}</h1>
						</div>
					</div>
				</div>
				<div class="flex flex-row items-center mt-2">
					<div class="mr-4 circle-icon green">3</div>
					<div class="form-item-horizontal flex-grow-2">
						<div class="w-1/2">
							<h2>Datum trećeg talasa</h2>
							<h1>{{ currentVersion.thirdWaveDate | formatDateNoTime }}</h1>
						</div>
					</div>
				</div>

				<div v-if="updateAll" class="flex items-center mt-4 p-2 bg-yellow-600 bg-opacity-10 rounded-md">
					<div class="flex flex-row items-center text-sm mr-2">
						<i class="fas fa-exclamation ml-1 mr-2 flex flex-row justify-center items-center text-red-600 text-lg"></i>
						<span class="font-semibold text-red-600 mr-2">UPOZORENJE</span>
					</div>

					<div class="text-sm">
						Ova akcija će forsirati ažuriranje svih LPFR vaših direktnih klijenata.<br />(To uključuje i
						<span class="font-semibold">PRODUCTION</span> i <span class="font-semibold">DEVELOPMENT</span> kartice)
					</div>
				</div>

				<div class="flex items-center mt-4 p-2 bg-primary bg-opacity-10 rounded-md">
					<input type="checkbox" id="checkbox" v-model="confirm" class="mr-2" /><label
						class="text-xs"
						for="checkbox"
						v-if="!updateAll"
						>Na sopstvenu odgovornost želim da LPFR sa JID '{{ lpfr.jid }}' ažuriram odmah na verziju
						{{ currentVersion.version }}</label
					><label class="text-xs pl-2" for="checkbox" v-else
						>Na sopstvenu odgovornost želim da ažuriram odmah na verziju {{ currentVersion.version }} sledeće
						LPFR-ove:<br /><br />
						{{ computedLpfrJids }}</label
					>
				</div>
			</div>
		</div>
		<div v-if="!loading" class="flex flex-row mt-6">
			<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
			<button :disabled="!confirm || saveInProgress" @click="forceUpdate" class="flex-grow ml-2">
				Forsiraj ažuriranje
			</button>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
const semver = require('semver');

export default {
	props: {
		lpfr: {
			type: Object,
			required: false
		},
		lpfrs: {
			type: Array,
			required: false
		},
		updateAll: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			currentVersion: null,
			confirm: false,
			saveInProgress: false
		};
	},

	computed: {
		computedLpfrJids() {
			if (this.updateAll && this.lpfrs) {
				const text = this.lpfrs.map(lpfr => lpfr.jid).join(', ');
				return text.substring(0, text.length - 2);
			} else return '';
		}
	},

	created() {
		this.getCurrentVersion();
	},
	methods: {
		getCurrentVersion() {
			axios
				.get('version-info')
				.then(response => {
					this.currentVersion = response.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		async forceUpdate() {
			try {
				this.saveInProgress = true;
				if (this.updateAll) {
					const lpfrIds = this.lpfrs.map(lpfr => lpfr.id);
					await axios.post('lpfr/force-update', {
						lpfrIds
					});
					this.$emit('refresh');
					this.$emit('close');
					this.$fire({
						title: 'Uspešna akcija',
						text: 'Svi LPFR će biti ažurirani na verziju ' + this.currentVersion.version,
						type: 'success',
						timer: 2500,
						showConfirmButton: true
					});
				} else {
					await axios.post('lpfr/force-update', {
						lpfrIds: [this.lpfr.id]
					});
					this.$emit('refresh');
					this.$emit('close');
					this.$fire({
						title: 'Uspešna akcija',
						text: 'LPFR će biti ažuriran na verziju ' + this.currentVersion.version,
						type: 'success',
						timer: 2500,
						showConfirmButton: true
					});
				}
			} catch (error) {
				this.$emit('close');
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom ažuriranja verzije.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.saveInProgress = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.circle-icon {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.1rem;
	font-weight: bold;
	color: #f5f5f5;
}
.red {
	background-color: #e057083f;
	color: #751700;
}
.green {
	background-color: #00b3003b;
	color: #0e4e05;
}
.blue {
	background-color: #00b3ff34;
	color: #005a96;
}
.yellow {
	background-color: #bd930a3f;
	color: #887f07;
}
</style>
