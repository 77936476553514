import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import router from '../router/index';

const instance = axios.create({
	baseURL: process.env.VUE_APP_LPFR_WEBAPP_BACKEND_URL
});

// Refresh authorization token
const refreshAuthLogic = async failedRequest => {
	const refreshToken = localStorage.getItem('refresh-token');
	const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));

	try {
		const response = await instance.post(
			'/auth/refresh',
			{
				user: userFromLocalStorage,
				refreshToken: refreshToken
			},
			{ skipAuthRefresh: true }
		);
		const user = response.data;
		const newToken = response.headers['authorization'];
		localStorage.setItem('token', response.headers['authorization']);
		localStorage.setItem('refresh-token', response.headers['refresh-token']);
		localStorage.setItem('user', JSON.stringify(user));

		failedRequest.response.config.headers.Authorization = `Bearer ${newToken}`;
		instance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
		return Promise.resolve();
	} catch (err) {
		delete instance.defaults.headers.common['Authorization'];

		instance.post(
			'auth/revoke-refresh-token',
			{
				user: { id: userFromLocalStorage.id },
				refreshToken
			},
			{ skipAuthRefresh: true }
		);
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		localStorage.removeItem('refresh-token');
		router.push('/login');
		return Promise.reject();
	}
};

//Instantiate the interceptor
createAuthRefreshInterceptor(instance, refreshAuthLogic);

// Obtain the token from the local storage
function getAccessToken() {
	return localStorage.getItem('token');
}

// Obtain a fresh token each time a request is made
instance.interceptors.request.use(request => {
	const token = getAccessToken();
	request.headers['Authorization'] = `Bearer ${token}`;
	return request;
});

export default instance;
