<template>
	<div class="modal modal-recovery-invoices">
		<h2 class="text-2xl">Računi za oporavak</h2>
		<hr />
		<div class="filter-tab flex flex-row justify-between items-center p-2 mt-2 mb-2 border">
			<div class="flex flex-row items-center">
				<h1>Status računa:</h1>
				<v-select
					class="ml-2 select-style w-64"
					placeholder="Izaberite status"
					v-model="selectedStatus"
					:options="possibleStatuses"
				></v-select>
				<h1 class="ml-4">Status akcije:</h1>
				<v-select
					class="ml-2 select-style w-64"
					placeholder="Izaberite status"
					v-model="selectedAction"
					:options="possibleActionStatuses"
				></v-select>
			</div>
			<div>
				<button @click="getRecoveryInvoices"><i title="Osveži" class="fas fa-redo px-1"></i></button>
			</div>
		</div>
		<div v-if="loading" class="mt-10 mb-4 flex flex-row justify-center text-center">
			<spinner-component :size="50" />
		</div>
		<div v-else class="modal-content flex flex-column justify-start items-start">
			<div
				v-if="recoveryInvoices && recoveryInvoices.length > 0"
				class="w-full flex flex-row flex-wrap"
				:key="redrawCounter"
			>
				<div
					class="form-item"
					style="width: 560px"
					v-for="recoveryInvoice in recoveryInvoices"
					:key="recoveryInvoice.invoiceNumber"
					:class="{
						'limited-height': recoveryInvoice.journalHidden
					}"
				>
					<div class="flex flex-row justify-between">
						<div>
							<h2>Broj računa</h2>
							<h1>{{ recoveryInvoice.invoiceNumber }}</h1>
						</div>
						<div class="w-1/2" v-if="recoveryInvoice.refundInvoiceNumber">
							<h2>Broj refundacionog računa</h2>
							<h1>{{ recoveryInvoice.refundInvoiceNumber || '/' }}</h1>
						</div>
					</div>
					<div class="flex flex-row justify-between">
						<div>
							<h2>Status</h2>
							<h1
								class="command-status"
								:class="{
									'grey-bg': recoveryInvoice.status == 'Initial',
									'blue-bg': recoveryInvoice.status == 'Sent',
									'green-bg': recoveryInvoice.status == 'Refunded'
								}"
							>
								{{ recoveryInvoice.status | formatRecoveryInvoiceStatus }}
							</h1>
						</div>
						<div class="w-1/2">
							<h2>Vreme</h2>
							<h1>{{ recoveryInvoice.dateTime | formatDate }}</h1>
						</div>
					</div>
					<h2 class="mt-2">Žurnal</h2>
					<div
						class="text-center py-6 px-0 my-2 mx-24"
						style="background-color: white"
						v-if="!recoveryInvoice.journalHidden"
						:key="redrawCounter"
					>
						<pre style="font-size: 12px">{{ recoveryInvoice.journal }}</pre>
					</div>
					<button class="btn btn-primary py-0 w-2/12" @click="toggleJournal(recoveryInvoice.invoiceNumber)">
						{{ recoveryInvoice.journalHidden ? 'Prikaži' : 'Sakrij' }}
					</button>

					<div class="flex flex-row justify-between mt-4">
						<div>
							<h2>Komanda</h2>
							<h1>{{ recoveryInvoice.monitorCommand | formatRecoveryInvoiceMonitorCommand }}</h1>
						</div>
						<div class="w-1/2">
							<h2>Status komande</h2>
							<h1
								class="command-status"
								:class="{
									'yellow-bg': recoveryInvoice.commandStatus == 'Scheduled',
									'green-bg': recoveryInvoice.commandStatus == 'In progress'
								}"
							>
								{{ recoveryInvoice.commandStatus | formatRecoveryInvoiceCommandStatus }}
							</h1>
						</div>
					</div>
					<hr class="mt-2 mb-3" />
					<div class="available-commands flex flex-row justify-end">
						<span v-if="!recoveryInvoice.commandStatus">
							<button
								v-if="recoveryInvoice.status == 'Initial'"
								class="btn btn-primary py-0 mmx-2"
								@click="sendCommand(recoveryInvoice.invoiceNumber, 'send')"
							>
								Pošalji račun na TaxCore
							</button>
							<button
								v-if="recoveryInvoice.status == 'Sent'"
								class="btn btn-primary py-0 mx-2"
								@click="sendCommand(recoveryInvoice.invoiceNumber, 'refund')"
							>
								Refundiraj račun na TaxCore
							</button>
							<button
								class="btn bg-red-600 hover:bg-red-800 py-0 mx-2"
								@click="sendCommand(recoveryInvoice.invoiceNumber, 'delete')"
							>
								Ukloni iz pregleda
							</button>
						</span>
						<span v-else-if="recoveryInvoice.commandStatus == 'Scheduled'">
							<button
								class="btn bg-yellow-600 hover:bg-yellow-800 py-0 mx-2"
								@click="sendCommand(recoveryInvoice.invoiceNumber, 'cancel')"
							>
								Poništi komandu
							</button>
						</span>
						<span v-else class="w-full">
							<p class="text-gray-600">Izvršavanje komande je u toku i trenutno ne može biti poništeno.</p>
						</span>
					</div>
				</div>
			</div>
			<div v-else>
				<h1>Nisu pronađeni računi.</h1>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	data() {
		return {
			loading: false,
			initialRecoveryInvoices: [],
			recoveryInvoices: [],
			redrawCounter: 0,
			selectedStatus: null,
			possibleStatuses: [
				{ label: 'Inicijalni', value: 'Initial' },
				{ label: 'Poslati na TaxCore', value: 'Sent' },
				{ label: 'Refundirani na TaxCore', value: 'Refunded' }
			],
			possibleActionStatuses: [
				{ label: 'Bez izabrane akcije', value: null },
				{ label: 'Zakazana akcija', value: 'Scheduled' },
				{ label: 'Izvršavanje akcije u toku', value: 'In progress' }
			],
			selectedAction: null
		};
	},

	props: {
		lpfr: {
			type: Object,
			required: true
		}
	},

	async created() {
		await this.getRecoveryInvoices();
	},

	watch: {
		selectedStatus() {
			this.filterRecoveryInvoices();
		},
		selectedAction() {
			this.filterRecoveryInvoices();
		}
	},

	methods: {
		filterRecoveryInvoices() {
			this.recoveryInvoices = this.initialRecoveryInvoices.filter(recoveryInvoice => {
				if (this.selectedStatus && recoveryInvoice.status !== this.selectedStatus.value) {
					return false;
				}

				if (this.selectedAction && recoveryInvoice.commandStatus !== this.selectedAction.value) {
					return false;
				}

				return true;
			});
		},

		async toggleJournal(invoiceNumber) {
			const recoveryInvoiceIndex = this.recoveryInvoices.findIndex(ri => invoiceNumber === ri.invoiceNumber);
			this.recoveryInvoices[recoveryInvoiceIndex].journalHidden =
				!this.recoveryInvoices[recoveryInvoiceIndex].journalHidden;
			this.redrawCounter++;
		},

		async sendCommand(invoiceNumber, command) {
			this.loading = true;
			try {
				await axios.post(`/lpfr/${this.lpfr.id}/recovery-invoices/commands`, {
					command,
					invoiceNumber
				});
				await this.getRecoveryInvoices();
			} catch (error) {
				console.log(error);
				this.$emit('close');
			} finally {
				this.loading = false;
			}
		},

		async getRecoveryInvoices() {
			this.loading = true;
			try {
				const { data } = await axios.get(`/lpfr/${this.lpfr.id}/recovery-invoices`);
				this.recoveryInvoices = data;
				this.recoveryInvoices.sort((a, b) => {
					return new Date(b.dateTime) - new Date(a.dateTime);
				});
				this.recoveryInvoices.map(recoveryInvoice => {
					recoveryInvoice.journalHidden = true;
				});
				this.initialRecoveryInvoices = this.recoveryInvoices;
				this.filterRecoveryInvoices();
			} catch (error) {
				console.log(error);
				this.$emit('close');
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.filter-tab h1 {
	padding-bottom: 0 !important;
}

.command-status {
	display: inline-block;
}

.modal h1 {
	font-size: 1rem !important;
}

.yellow-bg {
	background-color: #c4960c;
	color: white;
	padding: 3px 16px;
	border-radius: 12px;
}
.grey-bg {
	background-color: rgb(77, 82, 85);
	color: rgb(255, 255, 255);
	padding: 3px 16px;
	border-radius: 12px;
}
.blue-bg {
	background-color: rgb(0, 122, 255);
	color: rgb(255, 255, 255);
	padding: 3px 16px;
	border-radius: 12px;
}
.green-bg {
	background-color: rgb(40, 167, 69);
	color: rgb(255, 255, 255);
	padding: 3px 16px;
	border-radius: 12px;
}

.form-item {
	background-color: rgb(241, 241, 241);
	border-radius: 12px;
	padding: 12px;
	margin-right: 12px;
	max-height: 303px;
	overflow-y: auto;
}

.status span {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.status span img {
	margin-bottom: 4px;
	margin-right: 6px;
}

.modal-content {
	margin-top: 0 !important;
	max-height: 640px;
	overflow-y: auto;
}

h2 {
	color: rgb(90, 90, 90) !important;
}
h1 {
	font-size: 1.2rem;
}
</style>
