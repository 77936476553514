<script>
import { Pie, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;
export default {
	extends: Pie,
	mixins: [reactiveProp],
	props: {
		chartData: {
			type: Object,
			default: null
		},
		options: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			loading: true
		};
	},

	mounted() {
		this.loading = false;
		this.renderChart(this.chartData, this.options);
	},

	methods: {
		// * triggers chart redraw
		// ! Is this needed? Commenting out for now to see if it breaks anything
		// rerender() {
		// 	if (!this.loading) {
		// 		if (this._data._chart) this._data._chart.destroy();
		// 		setTimeout(() => {
		// 			this.renderChart(this.chartData, this.options);
		// 		}, 50);
		// 	}
		// }
	}
};
</script>

<style></style>
