<template>
	<div class="modal modal-list-of-logs">
		<h2 class="text-2xl">Dodatne informacije o proizvodu</h2>
		<hr />
		<div class="modal-content">
			<div class="form-item">
				<button class="w-32 absolute right-14 top-3" style="height: 34px; line-height: 0" @click="editClient()">
					Prikaži klijenta
				</button>
				<h2>Identifikator proizvoda</h2>
				<h1 style="padding-bottom: 2px">
					{{ `${info.jid}` + (info.jidNickname ? `(${info.jidNickname})` : '') }}
				</h1>

				<h2>Tip proizvoda</h2>
				<h1 class="font-semibold">{{ info.productType || '/' }}</h1>

				<h2>Win. Version</h2>
				<h1 class="font-semibold">{{ info.winVersion || '/' }}</h1>

				<h2>Install Type</h2>
				<h1 class="font-semibold">{{ info.installType || '/' }}</h1>

				<h2>Certificate Expiration Date</h2>
				<h1 v-if="info.certificateExpirationDate" class="font-semibold">
					{{ info.certificateExpirationDate | formatDateNoTime }}
				</h1>
				<h1 v-else class="font-semibold">/</h1>

				<div v-if="info.productType == 'LPFR'">
					<h2>Pos. Id</h2>
					<h1 class="font-semibold">{{ info.posId || '/' }}</h1>

					<h2>MRC</h2>
					<h1 class="font-semibold">{{ info.mrc || '/' }}</h1>

					<h2>Organizational Unit</h2>
					<h1 class="font-semibold">{{ info.OU || '/' }}</h1>

					<h2>Organization</h2>
					<h1 class="font-semibold">{{ info.O || '/' }}</h1>

					<h2>Locality</h2>
					<h1 class="font-semibold">{{ info.L || '/' }}</h1>

					<h2>State or Province Name</h2>
					<h1 class="font-semibold">{{ info.S || '/' }}</h1>

					<h2>Country name</h2>
					<h1 class="font-semibold">{{ info.C || '/' }}</h1>

					<h2>Street</h2>
					<h1 class="font-semibold">{{ info.STREET || '/' }}</h1>

					<h2>Last Audit Time</h2>
					<h1 class="font-semibold" v-if="info.lastAuditTime">
						{{ info.lastAuditTime }}
					</h1>
					<h1 class="font-semibold" v-else>/</h1>

					<h2>Reader Name</h2>
					<h1 class="font-semibold">{{ info.readerName || '/' }}</h1>
				</div>
				<div v-else>
					<div v-for="(value, index) in Object.entries(info.productInfo)" :key="index">
						<!-- turn camelCase into comma separated title -->
						<h2>
							{{ value[0].charAt(0).toUpperCase() + value[0].slice(1).replace(/([a-z])([A-Z])/g, '$1$2') }}
						</h2>
						<h1 class="font-semibold">
							{{ value[1] === null || value[1] === undefined || value[1] === '' ? '/' : value[1] }}
						</h1>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: false
		};
	},

	props: {
		info: {
			type: Object,
			required: true
		}
	},
	methods: {
		editClient() {
			console.log('info', this.info);
			this.$emit('close');
			this.$emit('edit-client', this.info.client);
		}
	}
};
</script>

<style lang="scss" scoped>
.form-item {
	background-color: rgb(241, 241, 241);
	border-radius: 12px;
	padding: 12px;
	margin-right: 12px;
}

.status span {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.status span img {
	margin-bottom: 4px;
	margin-right: 6px;
}

.modal-content {
	max-height: 600px;
	overflow-y: auto;
}

h2 {
	color: rgb(90, 90, 90) !important;
}

h1 {
	font-size: 1.2rem;
}
</style>
