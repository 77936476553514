var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal"},[_c('h1',[_vm._v("Postavljanje komandi za inicijalna podešavanja")]),_c('hr',{staticClass:"mb-4"}),(_vm.loading)?_c('div',{staticClass:"mt-12"},[_c('spinner-component',{attrs:{"size":64}})],1):_c('div',[(_vm.user.role == 'SUPERADMIN')?_c('div',{staticClass:"mb-2"},[_c('h2',[_vm._v("PIB")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pib),expression:"pib"}],staticClass:"focus:ring-primary focus:border-primary font-semibold text-lg",attrs:{"type":"text","id":"pib"},domProps:{"value":(_vm.pib)},on:{"input":function($event){if($event.target.composing)return;_vm.pib=$event.target.value}}})]):_vm._e(),_c('div',{staticClass:"w-64"},[_c('h2',[_vm._v("Tip")]),_c('v-select',{staticClass:"select-style mb-1",attrs:{"placeholder":"Izaberite tip","options":[
					{ label: 'Sandbox', value: 'SANDBOX' },
					{ label: 'Produkcione', value: 'PRODUCTION' }
				]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),(_vm.lookingForExisting)?_c('div',{staticClass:"mb-6 mt-6 flex flex-col justify-between items-center"},[_c('spinner-component',{attrs:{"size":32}})],1):_vm._e(),(_vm.fileAlreadyExists && !_vm.lookingForExisting)?_c('div',{staticClass:"flex flex-row justify-between items-center"},[_vm._m(0),_c('div',[_c('button',{staticClass:"whitespace-nowrap",attrs:{"disabled":_vm.downloadInProgress || _vm.loading},on:{"click":function($event){return _vm.downloadExistingFile()}}},[_vm._v(" Preuzmi postojeći fajl ")])])]):_vm._e(),_c('h2',{staticClass:"mt-6"},[_vm._v(_vm._s(_vm.uploadHeader))]),(!_vm.file)?_c('label',{staticClass:"file-upload-label mt-1",attrs:{"for":"taxCoreFile"},on:{"dragover":function($event){$event.preventDefault();},"dragleave":function($event){$event.preventDefault();},"drop":_vm.onDrop}},[_vm._v("Izaberi ili prevuci .zip fajl")]):_c('div',{staticClass:"flex flex-row items-center"},[_c('h1',[_vm._v(_vm._s(_vm.fileName))]),_c('button',{staticClass:"ml-4",staticStyle:{"height":"28px","width":"28px","padding":"0"},on:{"click":function($event){return _vm.removeFile()}}},[_c('i',{staticClass:"fas fa-times"})])]),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","name":"taxCoreFile","id":"taxCoreFile"},on:{"change":_vm.onFileChange}})]),(!_vm.loading)?_c('div',{staticClass:"flex flex-row mt-3"},[_c('button',{staticClass:"w-4/12 bg-red-600 hover:bg-red-800",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Otkaži")]),_c('button',{staticClass:"ml-2 flex-grow-2",attrs:{"disabled":!this.type ||
				!this.type.value ||
				!_vm.file ||
				(_vm.user.role == 'SUPERADMIN' && (_vm.pib == '' || _vm.pib.match(/^[0-9,A-Z,a-z]{9,13}$/) == null))},on:{"click":_vm.uploadFile}},[_vm._v(" Postavi fajl ")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4 py-1 w-full bg-primary bg-opacity-10 rounded-md my-2 mr-4"},[_c('label',{staticClass:"text-xs text-primary"},[_c('b',[_vm._v("NAPOMENA")]),_vm._v(": Već je postavljen .zip fajl komandi za inicijalna podešavanja. Postavljanje novog fajla će izbrisati stari.")])])
}]

export { render, staticRenderFns }