<template>
	<div class="modal">
		<div class="flex flex-row justify-between items-center">
			<h1>Grupni prenos licenci</h1>
		</div>

		<hr class="mb-4" />
		<div v-if="loading" class="mt-12 mb-8">
			<spinner-component :size="64" />
		</div>
		<div v-show="!loading">
			<div class="flex flex-row justify-between items-center">
				<div class="flex flex-row mb-2">
					<div>
						<h2>Tip proizvoda</h2>
						<div class="mrc-transfer-filter-options flex flex-row items-center px2">
							<div class="flex flex-row">
								<input type="radio" id="type-prod-mrc" value="PRODUCTION" v-model="productType" />
								<label for="type-prod-mrc">Produkcioni</label>
							</div>
							<div class="flex flex-row">
								<input type="radio" id="type-sandbox-mrc" value="SANDBOX" v-model="productType" />
								<label for="type-sandbox-mrc">Razvojni</label>
							</div>
							<div class="flex flex-row">
								<input type="radio" id="type-print-mrc" value="PRINT" v-model="productType" />
								<label for="type-print-mrc">Print</label>
							</div>
						</div>
					</div>
					<div class="ml-4">
						<h2>Pretraga</h2>
						<div class="flex flex-row flex-nowrap mrc-text-search">
							<input
								type="text"
								class="focus:ring-primary focus:border-primary"
								v-model="search"
								:placeholder="searchPlaceholder"
								style="width: 420px"
								v-on:keyup.enter="manualSearch"
							/>
							<button class="ml-4 mr-4" title="Pretraži" @click="manualSearch()">
								<i class="fas fa-search" />
							</button>
							<button
								v-if="manualSearchPerformed && search"
								title="Poništi filter i osveži tabelu"
								class="cancel-filter-button"
								@click="cancelFilter()"
							>
								<i class="fas fa-times" />
							</button>
						</div>
					</div>
				</div>
				<div>
					<button
						class="grow-0 mr-4"
						:disabled="selectedProducts.length == 0"
						@click="transferSelectedByMrc(undefined)"
					>
						Prenesi licence za izabrane proizvode
					</button>
					<button
						class="grow-0 bg-red-600"
						:class="{ 'hover:bg-red-800': selectedProducts.length > 0 }"
						:disabled="selectedProducts.length == 0"
						@click="ignoreMrcTransferable(undefined)"
					>
						Ignoriši prenos za izabrane proizvode
					</button>
				</div>
			</div>
			<vuetable
				ref="vuetable"
				:api-mode="false"
				:fields="fields"
				:css="css.table"
				:per-page="perPage"
				noDataTemplate="Nema proizvoda čije se licence mogu automatski preneti"
				:data-manager="dataManager"
				@vuetable:pagination-data="onPaginationData"
				pagination-path="pagination"
				@vuetable:loaded="onLoaded"
				@vuetable:row-clicked="onRowClicked"
				class="invoice-drafts-table"
				track-by="rowId"
			>
				<div class="slot" slot="distributor-slot" slot-scope="props">
					{{ props.rowData.distributor_name }}
				</div>
				<div class="slot" slot="partner-slot" slot-scope="props">
					{{ props.rowData.partner_name }}
				</div>
				<div class="slot" slot="client-slot" slot-scope="props">
					{{ props.rowData.client_name }}
				</div>
				<div class="slot" slot="source-jid-slot" slot-scope="props">
					{{ props.rowData.linked_jid }}
				</div>
				<div class="slot" slot="jid-slot" slot-scope="props">
					{{ props.rowData.jid }}
				</div>
				<div class="slot" slot="ou-slot" slot-scope="props">
					{{ props.rowData.ou }}
				</div>
				<div class="slot" slot="mrc-slot" slot-scope="props">
					{{ props.rowData.mrc }}
				</div>
				<div class="slot" slot="action-slot" slot-scope="props">
					<button
						style="height: 26px; width: 26px; padding: 0"
						title="Prenesi licencu"
						@click="transferSelectedByMrc(props.rowData)"
					>
						<i class="fas fa-sign-out-alt"></i>
					</button>
					<button
						style="height: 26px; margin-left: 6px; width: 26px; padding: 0"
						class="bg-red-600 hover:bg-red-800"
						title="Ignoriši prenos"
						@click="ignoreMrcTransferable(props.rowData)"
					>
						<i class="fas fa-window-close"></i>
					</button>
				</div>
			</vuetable>
			<vuetable-pagination
				ref="pagination"
				class="pull-right"
				@vuetable-pagination:change-page="onChangePage"
			></vuetable-pagination>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from '../components/VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import axios from '@/axios/axios.js';

export default {
	components: {
		Vuetable,
		VuetablePagination
	},
	data() {
		return {
			perPage: 10,
			css: VuetableCssConfig,
			loading: true,
			selectedProducts: [],
			products: [],
			fields: [],
			search: '',
			productType: 'PRODUCTION',
			searchPlaceholder: 'Naziv distributera/partnera/klijenta, PIB, JID, nadimak, ...',
			manualSearchPerformed: false
		};
	},
	async created() {
		this.loading = true;
		this.setupTableFields();
		try {
			await this.fetchProducts();
		} catch (error) {
			console.log(error);
		} finally {
			this.loading = false;
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.$watch(
				() => this.$refs.vuetable.selectedTo,
				() => {
					this.selectedProducts = this.$refs.vuetable.selectedTo.map(id => {
						return this.products.find(product => {
							const [jid, linked_jid] = id.split('_separator_');
							return product.jid == jid && product.linked_jid == linked_jid;
						});
					});
					console.log(this.selectedProducts);
					console.log(this.selectedProducts.map(p => p.jid));
				}
			);
		});
	},
	watch: {
		products() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		}
	},
	methods: {
		transferSelectedByMrc() {
			let jids = [];
			jids = this.selectedProducts.map(row => row.jid);
			const transferPairs = this.selectedProducts.map(row => {
				return {
					newJid: row.jid,
					oldJid: row.linked_jid
				};
			});

			this.$fire({
				title: 'Prenos licenci',
				text: 'Da li ste sigurni da želite da izvršite automatski prenos licenci za izabrane proizvode?',
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Prenesi licence za izabrane proizvode',
				showLoaderOnConfirm: true,
				allowOutsideClick: false,
				preConfirm: () => {
					return axios
						.post(`licence/transfer-by-mrc`, {
							transferPairs
						})
						.then(response => {
							return response.data;
						})
						.catch(error => {
							throw error;
						});
				}
			})
				.then(response => {
					if (response.dismiss) return;
					response = response.value;
					if (response.totalSuccess) {
						this.$fire({
							title: 'Uspešna akcija',
							html: `Licence svih izabranih proizvoda su uspešno prenete.`,
							type: 'success',
							timer: 2500,
							showConfirmButton: false
						});
					} else {
						console.log('idemo');
						this.handleMrcTransferError(response.products);
					}
					this.fetchProducts();
				})
				.catch(error => {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom prenosa licenci.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
					this.refreshData();
				});
			event.stopPropagation();
		},
		handleMrcTransferError(products) {
			console.log('ayoo', products);
			const productsThatFailed = products.filter(product => !product.success).map(product => product.jid);
			//form a html string with all the products that failed and their explanations

			const productsThatFailedHtmlText = productsThatFailed
				.map(jid => {
					const product = products.find(product => product.jid == jid);
					return `<b>${jid}</b>: ${this.composeMrctransferErrorMessage(product.errorCode)}`;
				})
				.join('<br/>');

			const numberOfSuccess = products.filter(product => product.success).length;
			const numOfSuccessText =
				'Uspešno' +
				(numberOfSuccess > 1 ? ' su prenete licence' : ' je preneta licenca') +
				' za ' +
				numberOfSuccess +
				(numberOfSuccess > 1 ? ' proizvoda' : ' proizvod') +
				'.';

			let title = 'Parcijalna uspešnost';
			let type = 'warning';
			if (productsThatFailed.length == products.length) {
				title = 'Greška';
				type = 'error';
			}

			this.$fire({
				title,
				html: `Došlo je do greške prilikom prenosa licenci.<br/>${numOfSuccessText}<br>Proizvodi kojima nije uspeo prenos licence:<br/>${productsThatFailedHtmlText}`,
				type,
				showConfirmButton: true
			});
		},
		ignoreMrcTransferable() {
			let jids = [];
			jids = this.selectedProducts.map(row => row.jid);

			this.$fire({
				title: 'Ignorisanje prenosa licenci',
				html: `Da li ste sigurni da želite da ignorišete prenos licence za izabrane proizvode?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Ignoriši prenos',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					axios
						.post(`licence/ignore-mrc-transfer`, {
							jids
						})
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								html: `Automatski prenos za izabrane proizvode je uspešno ignorisan.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});

							this.fetchProducts();
						})
						.catch(error => {
							this.loadingFinishedLicences();
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom ignorisanja prenosa licenci.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
			event.stopPropagation();
		},
		setupTableFields() {
			this.fields = [
				{
					name: 'distributor-slot',
					title: 'Distributer',
					sortField: 'distributor_name'
				},
				{
					name: 'partner-slot',
					title: 'Partner',
					sortField: 'partner_name'
				},
				{
					name: 'client-slot',
					title: 'Klijent',
					sortField: 'client_name'
				},
				{
					name: 'source-jid-slot',
					title: 'Stari JID',
					sortField: 'linked_jid'
				},
				{
					name: 'jid-slot',
					title: 'Novi JID',
					sortField: 'jid'
				},
				{
					name: 'ou-slot',
					title: 'OU',
					sortField: 'ou'
				},
				{
					name: 'mrc-slot',
					title: 'MRC',
					sortField: 'mrc'
				},
				/*{
					name: 'action-slot',
					title: 'Akcije',
					dataClass: 'center-checkbox'
				},*/
				{
					name: '__checkbox',
					titleClass: 'center-checkbox',
					dataClass: 'center-checkbox'
				}
			];
		},
		manualSearch() {
			this.manualSearchPerformed = true;
			this.fetchProducts();
		},
		async fetchProducts() {
			this.loading = true;
			if (this.$refs.vuetable) this.$refs.vuetable.selectedTo = [];
			try {
				const query = {
					type: this.productType
				};
				if (this.search) {
					query.search = this.search;
				}
				const queryStr = JSON.stringify(query);
				const base64Str = Buffer.from(queryStr).toString('base64');
				const encodedQuery = encodeURIComponent(base64Str);

				console.log(query);
				console.log(encodedQuery);

				const response = await axios.get('/licence/transferable-by-mrc', {
					params: {
						q: encodedQuery
					}
				});
				this.products = response.data.map(product => {
					return {
						...product,
						rowId: product.jid + '_separator_' + product.linked_jid
					};
				});
			} catch (error) {
			} finally {
				this.loading = false;
			}
		},
		cancelFilter() {
			this.search = '';
			this.productType = 'PRODUCTION';
			this.manualSearchPerformed = false;
			this.fetchProducts();
		},
		dataManager(sortOrder, pagination) {
			let data = this.products;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.name.search(txt) >= 0 ||
						item.pib.search(txt) >= 0 ||
						(item.legalEntityName && item.legalEntityName.search(txt) >= 0) ||
						(item.legalEntityEmails && item.legalEntityEmails.search(txt) >= 0) ||
						(item.legalEntityAddress && item.legalEntityAddress.search(txt) >= 0)
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onLoaded() {},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		onRowClicked(row) {
			if (this.$refs.vuetable.selectedTo.includes(row.data.jid + '_separator_' + row.data.linked_jid)) {
				this.$refs.vuetable.selectedTo.splice(
					this.$refs.vuetable.selectedTo.indexOf(row.data.jid + '_separator_' + row.data.linked_jid),
					1
				);
			} else {
				this.$refs.vuetable.selectedTo.push(row.data.jid + '_separator_' + row.data.linked_jid);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.mrc-transfer-filter-options {
	padding: 4px;
	div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	label {
		padding-left: 6px;
		margin-right: 6px;
		font-size: 0.85rem;
	}
}
input[type='text'] {
	height: 28px;
	padding-top: 0px;
	padding-bottom: 0px;
}
.mrc-text-search {
	button {
		height: 28px;
		width: 28px;
		padding: 0;
	}
}
</style>
