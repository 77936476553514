<template>
	<div>
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div>
			<modal :adaptive="true" height="auto" :width="860" name="e-invoice-options">
				<div class="flex flex-row justify-end m-2 absolute right-1 top-1">
					<button
						class="bg-secondary hover:bg-secondary-light"
						style="width: 34px; height: 34px; line-height: 0"
						@click="$modal.hide('e-invoice-options')"
					>
						<i class="fas fa-times" />
					</button>
				</div>
				<modal-e-invoice-options
					@close="closeModal('e-invoice-options')"
					@refresh="refreshData"
					:invoice="selectedInvoice"
				>
				</modal-e-invoice-options>
			</modal>
		</div>
		<div v-show="!loading">
			<div class="flex flex-row items-center justify-start mb-3">
				<div class="filter-container">
					<div class="status-container">
						<h1 class="text-xl font-semibold status-filter-header">Status</h1>
						<div class="status-options flex items-center px2">
							<input type="radio" id="status-paid" value="PAID" v-model="filterStatus" />
							<label for="status-paid">Plaćene</label>
							<input type="radio" id="status-unpaid" value="UNPAID" v-model="filterStatus" />
							<label for="status-unpaid">Neplaćene</label>
							<input type="radio" id="status-cancelled" value="CANCELLED" v-model="filterStatus" />
							<label for="status-cancelled">Stornirane</label>
						</div>
					</div>
					<div class="flex flex-row flex-nowrap">
						<input
							type="text"
							class="focus:ring-primary focus:border-primary"
							style="width: 400px"
							placeholder="Br. fakture, naziv/PIB obveznika, ukupno sa/bez PDV-a"
							v-on:keyup.enter="search"
							v-model="searchInput"
						/>
						<div class="flex flex-row items-center justify-center ml-2">
							<v-select
								placeholder="Izaberi mesec"
								class="select-style mb-1 w-48"
								v-model="monthFilter"
								:options="months"
							></v-select>
							<v-select
								placeholder="Izaberi godinu"
								class="select-style mb-1 w-48 ml-1"
								v-model="yearFilter"
								:options="availableYears"
							></v-select>
						</div>
						<button class="ml-2" title="Pretraži" @click="search()" style="width: 42px">
							<i class="fas fa-search" />
						</button>
						<button
							title="Poništi filter"
							class="cancel-filter-button ml-2"
							@click="cancelFilter()"
							style="width: 42px"
						>
							<i class="fas fa-times" />
						</button>
					</div>
				</div>
				<spinner-component class="ml-48" v-if="fetchingDataInProgress" :size="45" />
			</div>
			<div v-if="searchDone">
				<vuetable
					ref="vuetable"
					:api-mode="false"
					:fields="fields"
					:css="css.table"
					:per-page="perPage"
					noDataTemplate="Nisu pronađene fakture"
					:data-manager="dataManager"
					pagination-path="pagination"
					@vuetable:pagination-data="onPaginationData"
					@vuetable:row-clicked="onRowClicked"
					@vuetable:loaded="onLoaded"
					class="invoices-table"
					track-by="id"
				>
					<div class="slot" slot="obligor-slot" slot-scope="props">
						<span :title="props.rowData.obligor.name">{{ props.rowData.obligorFormatted }}</span>
					</div>
					<div class="slot" slot="total-slot" slot-scope="props">
						{{ props.rowData.totalFormatted }}
					</div>
					<div class="slot" slot="total-slot-with-vat" slot-scope="props">
						{{ props.rowData.totalWithVatFormatted }}
					</div>
					<div
						class="slot text-center rounded shadow-md status-slot"
						:class="{
							'green-background': props.rowData.status == 'PAID',
							'red-background': props.rowData.status == 'CANCELLED',
							'yellow-background': props.rowData.status == 'UNPAID'
						}"
						slot="status-slot"
						slot-scope="props"
					>
						{{ props.rowData.statusFormatted }}
					</div>
					<div
						class="slot text-center rounded shadow-md efaktura-slot"
						@click="openEInvoiceModal('e-invoice-options', props.rowData)"
						:class="{
							'green-background': props.rowData.efakturaData?.status == 'Approved',
							'red-background':
								props.rowData.efakturaData?.status == 'Rejected' || props.rowData.efakturaData?.status == 'Mistake',
							'gray-background':
								props.rowData.efakturaData?.status == 'Not sent' ||
								props.rowData.efakturaData?.status == 'Send failed' ||
								!props.rowData.efakturaData?.status,
							'dark-gray-background':
								props.rowData.efakturaData?.status == 'Cancelled' || props.rowData.efakturaData?.status == 'Storno',
							'blue-background': props.rowData.efakturaData?.status == 'Sent successfully'
						}"
						slot="efaktura-slot"
						slot-scope="props"
					>
						{{ props.rowData.eFakturaStatus }}
					</div>
					<div class="slot" slot="email-slot" slot-scope="props">
						{{ props.rowData.mailSent ? 'Poslat' : 'Nije poslat' }}
					</div>
					<div class="slot" slot="date-of-issue-slot" slot-scope="props">
						{{ props.rowData.dateOfIssue | formatDateNoTime }}
					</div>
					<div class="slot" slot="accounting-period-slot" slot-scope="props">
						<div v-if="props.rowData.accountingPeriod == 'PERIOD'" :title="props.rowData.accountingPeriodFormatted">
							{{ props.rowData.accountingPeriodFormattedShort }}
						</div>
						<div v-else>
							{{ props.rowData.accountingPeriodFormatted }}
						</div>
					</div>
					<div class="slot" slot="licences-slot" slot-scope="props">
						{{ props.rowData.itemsQuantity }}
					</div>
					<div class="slot flex flex-row justify-center" slot="actions-slot" slot-scope="props">
						<button
							v-if="user.role != 'ONPREMADMIN'"
							@click="sendEmails(props.rowData)"
							class="btn btn-sm"
							title="Pošalji mejl sa PDF-om fakture"
							style="width: 26px; height: 26px; padding: 0px"
						>
							<i class="fas fa-envelope"></i>
						</button>
						<button
							@click="downloadDetails(props.rowData)"
							class="btn btn-sm ml-1"
							title="Preuzmi izveštaj u excel formatu"
							style="width: 26px; height: 26px; padding: 0px"
							:id="`excel-btn-${props.rowData.id}`"
						>
							<i class="fas fa-file-excel" :id="`excel-icon-${props.rowData.id}`"></i>
						</button>
						<button
							@click="downloadPdf(props.rowData)"
							class="btn btn-sm ml-1"
							title="Preuzmi PDF fakture"
							style="width: 26px; height: 26px; padding: 0px"
							:id="`pdf-btn-${props.rowData.id}`"
						>
							<i class="fas fa-file-pdf" :id="`pdf-icon-${props.rowData.id}`"></i>
						</button>
						<button
							v-if="props.rowData.status == 'UNPAID'"
							@click="setInvoiceStatus(props.rowData, 'PAID')"
							class="btn btn-sm ml-1"
							title="Obeleži kao plaćenu"
							style="width: 26px; height: 26px; padding: 0px"
						>
							<i class="fas fa-check"></i>
						</button>
						<button
							v-if="user.role != 'ONPREMADMIN'"
							@click="openEInvoiceModal('e-invoice-options', props.rowData)"
							class="btn btn-sm ml-1 bg-green-700 hover:bg-green-900"
							title="Otvori sistem za e-fakture"
							style="width: 26px; height: 26px; padding: 0px"
						>
							<i class="fas fa-file-code"></i>
						</button>
						<button
							v-if="props.rowData.status != 'CANCELLED'"
							@click="setInvoiceStatus(props.rowData, 'CANCELLED')"
							class="btn btn-sm bg-red-600 ml-1"
							title="Storniraj fakturu"
							style="width: 26px; height: 26px; padding: 0px"
						>
							<i class="fas fa-ban"></i>
						</button>
						<button
							v-if="!props.rowData.archived && props.rowData.status == 'CANCELLED'"
							style="height: 26px; width: 26px; padding: 0"
							class="reset-lpfr-btn ml-1"
							title="Arhiviraj fakturu"
							@click="archiveLpfr(props.rowData.id)"
						>
							<i class="fas fa-archive"></i>
						</button>
					</div>
				</vuetable>
				<div class="flex flex-row justify-end items-center">
					<div class="mr-4">{{ numOfDisplayed }} / {{ numOfTotal }}</div>
					<div class="flex flex-row per-page-div">
						<input type="radio" id="10-per-page-monitor" :value="10" v-model="perPage" /><label
							:class="{ active: perPage == 10 }"
							for="10-per-page-monitor"
							>10</label
						>
						<input type="radio" id="20-per-page-monitor" :value="20" v-model="perPage" /><label
							:class="{ active: perPage == 20 }"
							for="20-per-page-monitor"
							>20</label
						>
						<input type="radio" id="50-per-page-monitor" :value="50" v-model="perPage" /><label
							:class="{ active: perPage == 50 }"
							for="50-per-page-monitor"
							>50</label
						>
						<input type="radio" id="100-per-page-monitor" :value="100" v-model="perPage" /><label
							:class="{ active: perPage == 100 }"
							for="100-per-page-monitor"
							>100</label
						>
					</div>
					<vuetable-pagination ref="pagination" class="pull-right" @vuetable-pagination:change-page="onChangePage">
					</vuetable-pagination>
				</div>
			</div>
			<div v-else class="mt-24 mb-36">
				<h1 class="text-2xl">Izvršite pretragu iznad ili osvežite tabelu, kako bi učitali podatke</h1>
			</div>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from '../components/VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';
import * as XLSX from 'xlsx-js-style';
import moment from 'moment';
import ModalEInvoiceOptions from '../components/ModalEInvoiceOptions.vue';

export default {
	components: {
		Vuetable,
		VuetablePagination,
		ModalEInvoiceOptions
	},
	computed: {
		...mapState({
			user: state => {
				return {
					id: state.user.user.id,
					name: state.user.user.name,
					phone: state.user.user.phone,
					email: state.user.user.email,
					role: state.user.user.role,
					pib: state.user.user.pib
				};
			}
		})
	},
	data() {
		return {
			fields: [],
			localData: [],
			originalData: [],
			loading: false,
			css: VuetableCssConfig,
			perPage: 20,
			filterStatus: null,
			months: [
				'Januar',
				'Februar',
				'Mart',
				'April',
				'Maj',
				'Jun',
				'Jul',
				'Avgust',
				'Septembar',
				'Oktobar',
				'Novembar',
				'Decembar'
			],
			searchInput: '',
			searchFor: '',
			searchDone: false,
			fetchingDataInProgress: false,
			numOfDisplayed: 0,
			numOfTotal: 0,
			monthFrom: null,
			monthFilter: null,
			yearFilter: null,
			availableYears: [],
			selectedInvoice: null
		};
	},
	async created() {
		try {
			this.loading = true;
			this.setupTableFields();
		} catch (error) {
			console.log(error);
		} finally {
			this.loading = false;
		}

		// get available years from 2021 to current year
		let currentYear = new Date().getFullYear();
		for (let i = 2021; i <= currentYear; i++) {
			this.availableYears.push(i);
		}
	},
	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		},
		async filterStatus() {
			if (this.filterStatus && this.searchDone) {
				await this.fetchData();
			}
		},
		async monthFilter() {
			if (this.monthFilter && this.searchDone) {
				await this.fetchData();
			}
		},

		async yearFilter() {
			if (this.yearFilter && this.searchDone) {
				await this.fetchData();
			}
		}
	},

	methods: {
		async search() {
			await this.fetchData();
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},
		closeModal(name) {
			this.$modal.hide(name);
		},
		openEInvoiceModal(name, data) {
			event.stopPropagation();
			this.selectedInvoice = data;
			this.$modal.show(name);
		},
		cancelFilter() {
			this.searchFor = '';
			this.searchInput = '';
			this.filterStatus = null;
			this.monthFilter = null;
			this.yearFilter = null;
			this.localData = this.originalData;
			this.searchDone = false;
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},

		async fetchData() {
			this.fetchingDataInProgress = true;
			await this.loadData();
			this.fetchingDataInProgress = false;
		},

		computeFilters() {
			let finalData = this.originalData;

			if (this.filterStatus) {
				finalData = finalData.filter(invoice => invoice.status == this.filterStatus);
			}

			if (this.monthFilter) {
				finalData = finalData.filter(
					invoice =>
						invoice.month == this.months.indexOf(this.monthFilter) ||
						(invoice.months && invoice.months.some(period => period.month == this.months.indexOf(this.monthFilter)))
				);
			}

			if (this.yearFilter) {
				finalData = finalData.filter(
					(invoice => invoice.year == this.yearFilter) ||
						(invoice.months && invoice.months.some(period => period.year == this.yearFilter))
				);
			}

			this.localData = finalData;
		},

		onLoaded() {
			this.numOfDisplayed = this.$refs.vuetable.countTableData;
			this.numOfTotal = this.$refs.pagination.tablePagination.total;
			this.addRefreshButton();
		},
		addRefreshButton() {
			//check if button already exists
			if (document.getElementById('refresh-button')) return;

			//find element by class vuetable-th-slot-efaktura-slot
			let element = document.getElementsByClassName('vuetable-th-slot-efaktura-slot')[0];

			//encircle all elements in element with a div
			let div = document.createElement('div');
			div.className = 'd-flex justify-content-center align-items-center';
			while (element.firstChild) {
				div.appendChild(element.firstChild);
			}
			element.appendChild(div);

			//change element to flex
			element.style.display = 'flex';
			element.style.justifyContent = 'space-between';
			element.style.alignItems = 'center';

			//create button
			let button = document.createElement('button');
			button.className = ' m-0 px-2 py-1';
			//add unique id to button
			button.id = 'refresh-button';
			button.innerHTML = '<i class="fas fa-sync-alt"></i>';
			button.addEventListener('click', () => {
				event.stopPropagation();
				this.refreshAllEfakturaStatuses();
			});
			//append button to element
			element.appendChild(button);
		},
		archiveLpfr(id) {
			this.$fire({
				title: 'Arhiviranje fakture',
				html: `Da li ste sigurni da želite da arhivirate izabranu fakturu?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Arhiviraj fakturu',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`finances/archive-invoice/${id}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								html: `Faktura je uspešno arhivirana.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							this.loadingFinishedLicences();
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom arhiviranja fakture.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});

			event.stopPropagation();
		},

		async refreshAllEfakturaStatuses() {
			const filteredInvoices = this.localData.filter(
				invoice =>
					invoice.efakturaData &&
					invoice.efakturaData.status != 'Not sent' &&
					invoice.efakturaData.status != 'Send failed'
			);
			const invoiceIds = filteredInvoices.map(invoice => invoice.id);
			this.fetchingDataInProgress = true;
			try {
				const response = await axios.post('efaktura/get-all-statuses', { invoiceIds });
				const invoiceStatuses = response.data;
				for (let invoice of invoiceStatuses) {
					console.log('invoice', invoice);
					const index = this.localData.findIndex(localInvoice => localInvoice.id == invoice.id);
					const originalDataIndex = this.originalData.findIndex(originalInvoice => originalInvoice.id == invoice.id);

					this.localData[index].efakturaData.status = invoice.status;
					this.localData[index].eFakturaStatus =
						this.translatedEFakturaStatus(this.localData[index].efakturaData.status) ?? 'Nije poslato';
					this.localData[index].statusSortField = this.localData[index].eFakturaStatus;

					this.originalData[originalDataIndex].efakturaData.status = this.localData[index].efakturaData.status;
					this.originalData[originalDataIndex].eFakturaStatus = this.localData[index].eFakturaStatus;
					this.originalData[originalDataIndex].statusSortField = this.localData[index].statusSortField;

					console.log('localData', this.localData[index]);
					console.log('originalData', this.originalData[originalDataIndex]);
				}
			} catch (error) {
				console.log(error);
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom osvežavanja statusa e-fakture.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.fetchingDataInProgress = false;
			}

			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},
		async setInvoiceStatus(row, status) {
			event.stopPropagation();
			let text = '';
			if (status == 'CANCELLED') {
				text = 'Da li ste sigurni da želite da stornirate fakturu?';
			} else if (status == 'PAID') {
				text = 'Da li ste sigurni da želite da obeležite fakturu kao plaćenu?';
			}
			this.$fire({
				title: 'Promena statusa fakture',
				text,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Promeni status fakture',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`finances/invoice/${row.id}/status/${status}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								text: `Uspešno promenjen status fakture.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							console.log(error);
							this.$fire({
								title: 'Greška',
								text: `Greška prilikom promene statusa fakture.`,
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
							this.loading = false;
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});
		},

		async sendEmails(row) {
			event.stopPropagation();
			this.$fire({
				title: 'Slanje mejla',
				html: `Da li ste sigurni da želite da pošaljete mejl sa pdf-om fakture na imejl adrese obveznika?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Pošalji mejl',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`finances/invoice-emails/invoice/${row.id}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								text: `Uspešno poslat mejl.`,
								type: 'success',
								showConfirmButton: true
							});
							this.refreshData();
						})
						.catch(error => {
							console.error(error);
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom slanja mejla.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});
		},

		async refreshData() {
			await this.fetchData();
		},

		async downloadDetails(row) {
			event.stopPropagation();

			const excelIconElement = document.getElementById(`excel-icon-${row.id}`);
			const excelBtnElement = document.getElementById(`excel-btn-${row.id}`);

			excelIconElement.classList.add('animation-rotate');
			excelIconElement.classList.remove('fa-file-excel');
			excelIconElement.classList.add('fa-spinner');
			excelBtnElement.setAttribute('disabled', 'true');

			try {
				const response = await axios.get(`finances/invoice/${row.id}`);
				const invoice = response.data;
				const invoiceDetails = invoice.invoiceDetails;

				if (!invoiceDetails) {
					this.$fire({
						title: 'Nisu kreirani excel izveštaji',
						text: 'Računi kreirani po starom modelu ne poseduju detalje potrebne za kreiranje izveštaja.',
						type: 'warn',
						showConfirmButton: true
					});
					return;
				}

				// console.log(invoiceDetails);

				const wb = XLSX.utils.book_new();

				for (const year in invoiceDetails) {
					for (const month in invoiceDetails[year]) {
						// console.log(month);
						const data = invoiceDetails[year][month];
						const dataArray = [];
						const eventsArray = [];
						for (const jid in data) {
							for (const item of data[jid]) {
								// console.log('item', item);
								dataArray.push({
									JID: jid,
									ENV: item.lpfrType,
									TYPE: item.productType,
									'INVOICING REASON':
										item.invoiceType === 'YEARLY LICENCE'
											? 'Yearly licence'
											: item.numberOfDaysActive === true
											? 'Licence transferred to this LPFR'
											: 'Num. of days active',
									'NUM. OF DAYS ACTIVE': item.numberOfDaysActive === true ? '' : item.numberOfDaysActive,
									CLIENT: item.client?.name ?? '/',
									'CL. PIB': item.client?.pib ?? '/',
									PARTNER: item.client?.user?.name ?? '/',
									'P. PIB': item.client?.user?.pib ?? '/'
								});

								if (item.events) {
									for (const event of item.events) {
										eventsArray.push({
											JID: jid,
											TIMESTAMP: moment(event.timestamp, moment.ISO_8601).format('DD.MM.YYYY - HH:mm:ss'),
											EVENT: event.event,
											'TRIGGERED BY': event.triggeredBy
										});
									}
								}
							}
						}

						// console.log(dataArray);

						const ws = XLSX.utils.json_to_sheet(dataArray);
						const eventWs = XLSX.utils.json_to_sheet(eventsArray);

						for (let i = 0; i < 9; i++) {
							const addr = XLSX.utils.encode_cell({
								c: i,
								r: 0
							});

							const styleSetings = {
								font: {
									bold: true,
									color: {
										rgb: 'ffffff'
									}
								},
								fill: { fgColor: { rgb: '0066CC' } }
							};

							if (i < 4) {
								eventWs[addr].s = styleSetings;
							}

							ws[addr].s = styleSetings;
						}

						let oldJID = '';
						for (let i = 1; i <= eventsArray.length; i++) {
							const addr = XLSX.utils.encode_cell({
								c: 0,
								r: i
							});
							if (eventWs[addr].v != oldJID) {
								for (let j = 0; j < 4; j++) {
									const editAddr = XLSX.utils.encode_cell({
										c: j,
										r: i
									});

									eventWs[editAddr].s = {
										border: {
											top: { style: 'medium', color: { auto: 1 } }
										}
									};
								}
							}
							oldJID = eventWs[addr].v;
						}

						ws['!cols'] = this.fitToColumn(dataArray);

						eventWs['!cols'] = this.fitToColumn(eventsArray);

						XLSX.utils.book_append_sheet(wb, ws, `${year}-${+month + 1}`);
						XLSX.utils.book_append_sheet(wb, eventWs, `Events - ${year}-${+month + 1}`);
					}
				}

				const filename = 'izvestaj-racun-' + invoice.number + '.xls';
				XLSX.writeFile(wb, filename);
				// console.log(invoiceDetails);
			} catch (error) {
				console.log(error);
			} finally {
				excelIconElement.classList.remove('fa-spinner');
				excelIconElement.classList.remove('animation-rotate');
				excelIconElement.classList.add('fa-file-excel');
				excelBtnElement.removeAttribute('disabled');
			}
		},

		fitToColumn(data) {
			// get maximum character of each column
			//get number of properties
			let numberOfProperties = Object.keys(data[0]).length;
			const columnWidths = [];

			for (const row of data) {
				for (let i = 0; i < numberOfProperties; i++) {
					let column = Object.keys(row)[i];
					if (!row[column]) continue;
					let columnLength = row[column].toString().length;
					if (!columnWidths[i] || columnLength > columnWidths[i]) {
						if (columnLength > 100) {
							columnWidths[i] = 100;
						} else {
							columnWidths[i] = columnLength + 5;
						}
					}
					if (Object.keys(row)[i].length > columnWidths[i]) {
						columnWidths[i] = Object.keys(row)[i].length + 5;
					}
				}
			}

			//create array of column widths
			const cols = [];
			for (let i = 0; i < numberOfProperties; i++) {
				cols.push({
					wch: columnWidths[i]
				});
			}

			return cols;
		},

		async downloadPdf(row) {
			event.stopPropagation();
			const pdfIconElement = document.getElementById(`pdf-icon-${row.id}`);
			const pdfBtnElement = document.getElementById(`pdf-btn-${row.id}`);

			pdfIconElement.classList.add('fa-spinner');
			pdfIconElement.classList.add('animation-rotate');
			pdfIconElement.classList.remove('fa-file-pdf');
			pdfBtnElement.setAttribute('disabled', 'true');

			try {
				const response = await axios.get(`finances/generate-invoice-pdf/${row.id}`, { responseType: 'blob' });
				// console.log(response);

				// console.log(typeof response.data);
				const blob = new Blob([response.data], {
					type: 'application/pdf'
				});
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${row.number}.pdf`);
				document.body.appendChild(link);
				link.click();
				setTimeout(function () {
					// For Firefox it is necessary to delay revoking the ObjectURL
					window.URL.revokeObjectURL(url);
				}, 100);
			} catch (error) {
				console.log(error);
			} finally {
				pdfIconElement.classList.remove('fa-spinner');
				pdfIconElement.classList.remove('animation-rotate');
				pdfIconElement.classList.add('fa-file-pdf');
				pdfBtnElement.removeAttribute('disabled');
			}
		},

		onRowClicked(row) {
			this.$router.push({
				name: 'invoice-details',
				params: {
					id: row.data.id
				}
			});
		},
		async loadData() {
			const params = {
				status: this.filterStatus,
				search: this.searchInput,
				year: this.yearFilter
			};
			if (this.monthFilter) {
				params.month = this.months.indexOf(this.monthFilter);
			}
			const response = await axios.get('finances/invoices', {
				params
			});
			this.formatData(response.data);
			if (this.filterStatus) {
				this.localData = this.originalData.filter(invoice => invoice.status == this.filterStatus);
			} else {
				this.localData = this.originalData;
			}
			// this.computeFilters();
			this.searchDone = true;
		},
		formatData(data) {
			const moneyFormatter = new Intl.NumberFormat('de-DE', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 4
			});

			const formattedData = data.map(row => {
				row.totalFormatted = moneyFormatter.format(row.total) + ' RSD';
				row.totalWithVatFormatted = moneyFormatter.format(row.totalWithVat) + ' RSD';
				row.total = parseFloat(row.total);
				row.eFakturaStatus = this.translatedEFakturaStatus(row.efakturaData?.status) ?? 'Nije poslato';
				row.statusSortField = row.eFakturaStatus;

				switch (row.status) {
					case 'UNPAID':
						row.statusFormatted = 'Nije plaćena';
						break;
					case 'PAID':
						row.statusFormatted = 'Plaćena';
						break;
					case 'CANCELLED':
						row.statusFormatted = 'Stornirana';
						break;
					default:
						row.statusFormattted = 'Nepoznato';
						break;
				}

				let periodFormatted;
				let periodFormattedShort;

				if (row.accountingPeriod === 'PERIOD') {
					periodFormatted = '';
					periodFormattedShort = '';
					let i = 1;
					for (const period of row.months) {
						periodFormatted += `${this.months[period.month]} ${period.year}, `;
						if (i <= 2) {
							periodFormattedShort += `${this.months[period.month]} ${period.year}, `;
						}
						i++;
					}
					if (periodFormatted != periodFormattedShort) {
						periodFormattedShort = periodFormattedShort + '...';
					} else {
						periodFormattedShort = periodFormatted.slice(0, -2);
						periodFormattedShort += '.';
					}
					periodFormatted = periodFormatted.slice(0, -2);
					periodFormatted += '.';
				}
				switch (row.accountingPeriod) {
					case 'YEARLY':
						row.accountingPeriodFormatted = `${row.year}. (Godišnji)`;
						row.accountingPeriodSort = `${row.year}-12`;
						break;
					case 'MONTHLY':
						row.accountingPeriodFormatted = `${this.months[row.month]} ${row.year}.`;
						row.accountingPeriodSort = `${row.year}-${row.month}`;
						break;
					case 'PERIOD':
						row.accountingPeriodFormatted = periodFormatted;
						row.accountingPeriodFormattedShort = periodFormattedShort;
						break;
					default:
						row.accountingPeriodFormatted = 'Nepoznato';
						row.accountingPeriodSort = null;
						break;
				}

				let total = 0;
				for (const item of row.items) {
					if (item.type == 'LICENCE') {
						total += item.quantity;
					}
				}

				row.itemsQuantity = total;

				let role = '';
				switch (row.obligor.role) {
					case 'DISTRIBUTOR':
						role = 'Distributer';
						break;
					case 'PARTNER':
						role = 'Partner';
						break;
					case 'CLIENT':
						role = 'Klijent';
						break;
					default:
						role = 'Nepoznato';
						break;
				}

				if (row.obligor.name.length > 20) {
					row.obligorFormatted =
						row.obligor.name.substring(0, 20) + '...' + ` (${row.obligor.role}), ${row.obligor.pib}`;
				} else {
					row.obligorFormatted = row.obligor.name + ` (${role}), ${row.obligor.pib}`;
				}
				row.totalWithVat = +row.totalWithVat;
				return row;
			});

			//sort formattedData by dateOfIssue
			formattedData.sort((a, b) => {
				return new Date(b.dateOfIssue) - new Date(a.dateOfIssue);
			});
			this.localData = formattedData;
			this.originalData = formattedData;
		},
		setupTableFields() {
			this.fields = [
				{
					name: 'number',
					title: 'Br. fakture',
					sortField: 'number'
				},
				{
					name: 'obligor-slot',
					title: 'Obveznik',
					sortField: 'obligor.name'
				},
				{
					name: 'accounting-period-slot',
					title: 'Obrаčunski period',
					sortField: 'accountingPeriodSort'
				},
				{
					name: 'licences-slot',
					title: 'Br. licenci',
					sortField: 'itemsQuantity'
				},
				{
					name: 'total-slot',
					title: 'Ukupno (bez PDV)',
					sortField: 'total'
				},
				{
					name: 'total-slot-with-vat',
					title: 'Ukupno (sa PDV)',
					sortField: 'totalWithVat'
				},

				{
					name: 'date-of-issue-slot',
					title: 'Izdato',
					sortField: 'dateOfIssue'
				},
				{
					name: 'status-slot',
					title: 'Status',
					sortField: 'status'
				},
				{
					name: 'efaktura-slot',
					title: 'E-faktura',
					sortField: 'statusSortField'
				},
				{
					name: 'email-slot',
					title: 'E-mail',
					sortField: 'mailSent'
				},

				{
					name: 'actions-slot',
					title: 'Akcije',
					sortable: false
				}
			];
		},
		translatedEFakturaStatus(status) {
			switch (status) {
				case 'Not sent':
					return 'Nije poslato';
				case 'Sent successfully':
					return 'Poslato';
				case 'Send failed':
					return 'Nije poslato';
				case 'Storno':
					return 'Stornirana';
				case 'Approved':
					return 'Prihvaćena';
				case 'Rejected':
					return 'Odbijena';
				case 'Cancelled':
					return 'Otkazana';
				case 'Mistake':
					return 'Greška';
				default:
					return 'Nije poslato';
			}
		},

		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.obligor.pib.search(txt) >= 0 || item.obligor.name.search(txt) >= 0 || item.number.search(txt) >= 0
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		}
	}
};
</script>

<style lang="scss">
.invoices-table tbody tr:hover {
	background-color: var(--primary-color-hover);
	cursor: pointer;
}
</style>

<style lang="scss" scoped>
.slot {
	font-size: 0.9rem;
	margin: 0px;
}

.vuetable-slot {
	padding: 0 !important;
}

.efaktura-slot,
.status-slot {
	font-size: 0.8rem !important;
}

.animation-rotate {
	animation: rotation 1.5s linear infinite;
}
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.status-background {
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.orange-background {
	background-color: rgb(255, 94, 0);
	color: white;
	padding: 0px 3px 0px 5px;
}

.red-background {
	background-color: rgb(240, 40, 13);
	color: white;
	padding: 0px 3px 0px 5px;
}

.green-background {
	background-color: rgb(0, 128, 0);
	color: white;
	padding: 0px 3px 0px 5px;
}

.blue-background {
	background-color: #0091cc;
	color: white;
	padding: 0px 3px 0px 5px;
}

.gray-background {
	background-color: rgb(163, 170, 179);
	color: rgb(245, 245, 245);
	padding: 0px 3px 0px 5px;
}

.yellow-background {
	background-color: rgb(219, 166, 19);
	color: rgb(255, 255, 255);
	padding: 0px 3px 0px 5px;
}

.dark-gray-background {
	background-color: rgb(61, 61, 61);
	color: rgb(197, 197, 197);
	padding: 0px 3px 0px 5px;
}
</style>
