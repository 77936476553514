<template>
	<div id="app">
		<div>
			<partner-tabs />
		</div>
	</div>
</template>

<script>
import PartnerTabs from '../components/PartnerTabs.vue';

export default {
	name: 'App',
	components: {
		PartnerTabs
	},

	data() {
		return {};
	}
};
</script>

<style></style>
