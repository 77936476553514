var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal"},[_c('h1',[_vm._v("Generisanje izveštaja o aktivacijama")]),_c('hr',{staticClass:"mb-1"}),_vm._m(0),(_vm.loading)?_c('div',{staticClass:"mt-12 mb-12"},[_c('spinner-component',{attrs:{"size":64}})],1):_c('div',[_c('div',{staticClass:"mb-3"},[_c('h2',{staticClass:"mt-2"},[_vm._v("Datum od (počevši od)")]),_c('vue-datetime',{attrs:{"type":"date","input-class":"form-control form-control","placeholder":"Izaberite početni datum","hidden-name":"webstorm","format":"dd.MM.yyyy.","phrases":{
					ok: 'U redu',
					cancel: 'Otkaži'
				}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1),_c('div',{staticClass:"mb-3"},[_c('h2',{staticClass:"mt-2"},[_vm._v("Datum do (zaključno sa)")]),_c('vue-datetime',{attrs:{"type":"date","input-class":"form-control form-control","placeholder":"Izaberite krajnji datum","hidden-name":"webstorm","format":"dd.MM.yyyy.","phrases":{
					ok: 'U redu',
					cancel: 'Otkaži'
				}},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1),(_vm.user.role == 'SUPERADMIN' || _vm.user.role == 'DISTRIBUTOR')?_c('div',{staticClass:"mb-3 mt-3"},[_c('h2',[_vm._v("Generisati izveštaj za:")]),_c('v-select',{staticClass:"select-style mb-1",attrs:{"placeholder":`Pretražite i izaberite partnera${_vm.user.role == 'SUPERADMIN' ? '/distributera' : ''}`,"options":_vm.partners},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1):_vm._e(),_c('h2',[_vm._v("Grupisanje po klijentu:")]),_c('div',{staticClass:"mt-1"},[_c('button',{staticClass:"p-1 pl-3 pr-3",on:{"click":function($event){_vm.grouped = !_vm.grouped}}},[_vm._v(" "+_vm._s(_vm.grouped ? 'Da' : 'Ne')+" ")])]),_vm._m(1),_c('hr',{staticClass:"mb-4"}),_c('button',{staticClass:"w-full",attrs:{"disabled":!_vm.inputValid || _vm.loading},on:{"click":function($event){return _vm.generateReport()}}},[_vm._v("Generiši izveštaj")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-xs text-primary mb-4"},[_c('b',[_vm._v("NAPOMENA")]),_vm._v(": Ovom akcijom se generiše izveštaj o aktivacijama LPFR-ova za izabrani period."),_c('br'),_vm._v(" Izabrani početni datum mora biti raniji od izabranog zaključnog datuma. U izveštaj je uključen i ceo dan izabranog zaključnog datuma.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-xs text-gray-400 mb-2"},[_vm._v("Negrupisani izveštaj zasebno prikazuje vreme aktivacije svakog LPFR-a."),_c('br'),_vm._v("Grupisani izveštaj prikazuje broj aktivacija u izabranom periodu za svakog klijenta.")])
}]

export { render, staticRenderFns }