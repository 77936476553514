<template>
	<div class="settings-container">
		<h1 class="text-2xl mb-4">Podešavanje vrednosti na nivou sistema</h1>
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-if="!loading">
			<div class="flex flex-col text-left">
				<hr class="mb-4" />
				<label for="bookkeeping-email" class="text-lg">Računovodstveni e-mail</label>
				<label for="bookkeeping-email" class="text-gray-400 text-sm"
					>Adresa za slanje kopija mejlova i računa kreiranih kroz sistem za fakturisanje</label
				>
				<input v-model="bookkeepingEmail" type="text" id="bookkeeping-email" placeholder="placeholder@mail.com" /><label
					v-if="problematicEmail"
					for="bookkeeping-email"
					class="text-gray-400 text-sm mt-1 p-1 bg-white-600 rounded"
					><span class="text-red-600">NAPOMENA</span>: Izbeći
					<span class="text-blue-600">@proudsourceit.rs</span> adrese, dok se ne reši koji problem postoji sa slanjem
					mejlova na adrese na ovom domenu.</label
				>
				<div class="mt-8"></div>
				<label for="pdv" class="text-lg">PDV (%)</label>
				<label for="pdv" class="text-gray-400 text-sm">0.00 - 100.00</label>
				<input v-model="pdv" v-money="{}" type="text" id="pdv" placeholder="20" />
			</div>
			<hr class="mt-4" />
			<div class="flex flex-row justify-end mt-4">
				<button :disabled="nothingChanged || errorInInput" class="btn btn-primary w-full" @click="saveSettings">
					Sačuvaj promenu
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';

export default {
	data() {
		return {
			bookkeepingEmail: '',
			oldEmail: '',
			oldPdv: '',
			pdv: '',
			loading: true,
			emailRegex:
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		};
	},

	computed: {
		problematicEmail() {
			return this.bookkeepingEmail.includes('@proudsourceit.rs');
		},

		nothingChanged() {
			return this.bookkeepingEmail === this.oldEmail && this.pdv === this.oldPdv;
		},

		errorInInput() {
			return (
				this.bookkeepingEmail.length === 0 ||
				this.bookkeepingEmail.length > 50 ||
				!this.emailRegex.test(this.bookkeepingEmail) ||
				this.pdv.length === 0 ||
				this.pdv.length > 5 ||
				this.pdv < 0 ||
				this.pdv > 100
			);
		}
	},

	async created() {
		this.loading = true;
		try {
			await this.getSettings();
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},

	methods: {
		async getSettings() {
			const response = await axios.get('finances/global-settings');
			this.bookkeepingEmail = response.data.bookkeepingEmail ? response.data.bookkeepingEmail : '';
			this.oldEmail = response.data.bookkeepingEmail ? response.data.bookkeepingEmail : '';
			this.pdv = response.data.pdv ? (parseFloat(response.data.pdv) * 100).toFixed(2) : 0;
			this.oldPdv = this.pdv;
		},

		async saveSettings() {
			this.loading = true;
			try {
				await axios.post('finances/global-settings', {
					bookkeepingEmail: this.bookkeepingEmail,
					pdv: parseFloat(this.pdv) / 100
				});
				this.$fire({
					title: 'Uspešna akcija',
					html: `Promene globalnih podešavanja su uspešno sačuvane.`,
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
			} catch (error) {
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom čuvanja promena.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss">
.settings-container {
	width: 480px;
	margin: auto;
}
</style>
