import axios from '@/axios/axios.js';
import Vue from 'vue';
import InfiniteLoading from 'vue-infinite-loading';
import VueRouter from 'vue-router';
import store from '../store/store';
import ArchivedInvoices from '../views/ArchivedInvoices.vue';
import ExchangeRates from '../views/ExchangeRates.vue';
import FinancesClients from '../views/FinancesClients.vue';
import FinancesDashboard from '../views/FinancesDashboard.vue';
import FinancesGroupDrafts from '../views/FinancesGroupDrafts.vue';
import FinancesInvoiceDetails from '../views/FinancesInvoiceDetails.vue';
import FinancesInvoices from '../views/FinancesInvoices.vue';
import FinancesView from '../views/FinancesView.vue';
import GlobalFinancialSettings from '../views/GlobalFinancialSettings.vue';
import IgnoredLpfrsView from '../views/IgnoredLpfrsView.vue';
import LoginView from '../views/LoginView.vue';
import Main from '../views/Main.vue';
import PricelistsView from '../views/PricelistsView.vue';

Vue.use(VueRouter);
Vue.use(InfiniteLoading, {
	slots: {
		noMore: '',
		error: '',
		noResults: ''
	}
});
const routes = [
	{
		path: '/',
		name: 'home',
		component: Main
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView
	},
	{
		path: '/finances',
		name: 'finances',
		component: FinancesView,
		children: [
			{
				path: 'clients',
				name: 'clients',
				component: FinancesClients
			},
			{
				path: 'dashboard',
				name: 'dashboard',
				component: FinancesDashboard
			},
			{
				path: 'ignored-lpfrs',
				name: 'ignored-lpfrs',
				component: IgnoredLpfrsView
			},
			{
				path: 'exchange-rates',
				name: 'exchange-rates',
				component: ExchangeRates
			},
			{
				path: 'pricelists',
				name: 'pricelists',
				component: PricelistsView
			},
			{
				path: 'settings',
				name: 'settings',
				component: GlobalFinancialSettings
			},
			{
				path: 'invoices',
				name: 'invoices',
				component: FinancesInvoices
			},
			{
				path: 'invoice/:id',
				name: 'invoice-details',
				component: FinancesInvoiceDetails
			},
			{
				path: 'drafts',
				name: 'drafts',
				component: FinancesGroupDrafts
			},
			{
				path: 'archived-invoices',
				name: 'archived-invoices',
				component: ArchivedInvoices
			}
		]
	},
	{
		path: '/logout',
		name: 'logout',
		beforeEnter(to, from, next) {
			store.commit('unsetUser');
			delete axios.defaults.headers.common['Authorization'];
			const user = JSON.parse(localStorage.getItem('user'));
			const refreshToken = localStorage.getItem('refresh-token');
			axios.post(
				'auth/revoke-refresh-token',
				{
					user: { id: user.id },
					refreshToken
				},
				{ skipAuthRefresh: true }
			);
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			localStorage.removeItem('refresh-token');
			next({ name: 'login' });
		}
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	if (!store.getters['getUser']) {
		if (localStorage.getItem('token')) {
			//setup axios headers
			const token = localStorage.getItem('token');
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

			const user = JSON.parse(localStorage.getItem('user'));

			//get user info
			store.commit('setUser', user);
		}
	}

	if (to.name !== 'login' && to.name !== 'logout' && store.getters.getUser == null) {
		next({ name: 'login' });
	} else {
		next();
	}

	//check if path contains finances
	if (to.path.includes('finances')) {
		const user = store.getters.getUser;
		if (user.role !== 'SUPERADMIN' && user.role !== 'ONPREMADMIN') {
			next({ name: 'home' });
		}
	}

	if (to.name == 'ignored-lpfrs') {
		const user = store.getters.getUser;
		if (user.role !== 'SUPERADMIN') {
			next({ name: 'clients' });
		}
	}
});

export default router;
