var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal modal-recovery-invoices"},[_c('h2',{staticClass:"text-2xl"},[_vm._v("Računi za oporavak")]),_c('hr'),_c('div',{staticClass:"filter-tab flex flex-row justify-between items-center p-2 mt-2 mb-2 border"},[_c('div',{staticClass:"flex flex-row items-center"},[_c('h1',[_vm._v("Status računa:")]),_c('v-select',{staticClass:"ml-2 select-style w-64",attrs:{"placeholder":"Izaberite status","options":_vm.possibleStatuses},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),_c('h1',{staticClass:"ml-4"},[_vm._v("Status akcije:")]),_c('v-select',{staticClass:"ml-2 select-style w-64",attrs:{"placeholder":"Izaberite status","options":_vm.possibleActionStatuses},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}})],1),_c('div',[_c('button',{on:{"click":_vm.getRecoveryInvoices}},[_c('i',{staticClass:"fas fa-redo px-1",attrs:{"title":"Osveži"}})])])]),(_vm.loading)?_c('div',{staticClass:"mt-10 mb-4 flex flex-row justify-center text-center"},[_c('spinner-component',{attrs:{"size":50}})],1):_c('div',{staticClass:"modal-content flex flex-column justify-start items-start"},[(_vm.recoveryInvoices && _vm.recoveryInvoices.length > 0)?_c('div',{key:_vm.redrawCounter,staticClass:"w-full flex flex-row flex-wrap"},_vm._l((_vm.recoveryInvoices),function(recoveryInvoice){return _c('div',{key:recoveryInvoice.invoiceNumber,staticClass:"form-item",class:{
					'limited-height': recoveryInvoice.journalHidden
				},staticStyle:{"width":"560px"}},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',[_c('h2',[_vm._v("Broj računa")]),_c('h1',[_vm._v(_vm._s(recoveryInvoice.invoiceNumber))])]),(recoveryInvoice.refundInvoiceNumber)?_c('div',{staticClass:"w-1/2"},[_c('h2',[_vm._v("Broj refundacionog računa")]),_c('h1',[_vm._v(_vm._s(recoveryInvoice.refundInvoiceNumber || '/'))])]):_vm._e()]),_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',[_c('h2',[_vm._v("Status")]),_c('h1',{staticClass:"command-status",class:{
								'grey-bg': recoveryInvoice.status == 'Initial',
								'blue-bg': recoveryInvoice.status == 'Sent',
								'green-bg': recoveryInvoice.status == 'Refunded'
							}},[_vm._v(" "+_vm._s(_vm._f("formatRecoveryInvoiceStatus")(recoveryInvoice.status))+" ")])]),_c('div',{staticClass:"w-1/2"},[_c('h2',[_vm._v("Vreme")]),_c('h1',[_vm._v(_vm._s(_vm._f("formatDate")(recoveryInvoice.dateTime)))])])]),_c('h2',{staticClass:"mt-2"},[_vm._v("Žurnal")]),(!recoveryInvoice.journalHidden)?_c('div',{key:_vm.redrawCounter,staticClass:"text-center py-6 px-0 my-2 mx-24",staticStyle:{"background-color":"white"}},[_c('pre',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(recoveryInvoice.journal))])]):_vm._e(),_c('button',{staticClass:"btn btn-primary py-0 w-2/12",on:{"click":function($event){return _vm.toggleJournal(recoveryInvoice.invoiceNumber)}}},[_vm._v(" "+_vm._s(recoveryInvoice.journalHidden ? 'Prikaži' : 'Sakrij')+" ")]),_c('div',{staticClass:"flex flex-row justify-between mt-4"},[_c('div',[_c('h2',[_vm._v("Komanda")]),_c('h1',[_vm._v(_vm._s(_vm._f("formatRecoveryInvoiceMonitorCommand")(recoveryInvoice.monitorCommand)))])]),_c('div',{staticClass:"w-1/2"},[_c('h2',[_vm._v("Status komande")]),_c('h1',{staticClass:"command-status",class:{
								'yellow-bg': recoveryInvoice.commandStatus == 'Scheduled',
								'green-bg': recoveryInvoice.commandStatus == 'In progress'
							}},[_vm._v(" "+_vm._s(_vm._f("formatRecoveryInvoiceCommandStatus")(recoveryInvoice.commandStatus))+" ")])])]),_c('hr',{staticClass:"mt-2 mb-3"}),_c('div',{staticClass:"available-commands flex flex-row justify-end"},[(!recoveryInvoice.commandStatus)?_c('span',[(recoveryInvoice.status == 'Initial')?_c('button',{staticClass:"btn btn-primary py-0 mmx-2",on:{"click":function($event){return _vm.sendCommand(recoveryInvoice.invoiceNumber, 'send')}}},[_vm._v(" Pošalji račun na TaxCore ")]):_vm._e(),(recoveryInvoice.status == 'Sent')?_c('button',{staticClass:"btn btn-primary py-0 mx-2",on:{"click":function($event){return _vm.sendCommand(recoveryInvoice.invoiceNumber, 'refund')}}},[_vm._v(" Refundiraj račun na TaxCore ")]):_vm._e(),_c('button',{staticClass:"btn bg-red-600 hover:bg-red-800 py-0 mx-2",on:{"click":function($event){return _vm.sendCommand(recoveryInvoice.invoiceNumber, 'delete')}}},[_vm._v(" Ukloni iz pregleda ")])]):(recoveryInvoice.commandStatus == 'Scheduled')?_c('span',[_c('button',{staticClass:"btn bg-yellow-600 hover:bg-yellow-800 py-0 mx-2",on:{"click":function($event){return _vm.sendCommand(recoveryInvoice.invoiceNumber, 'cancel')}}},[_vm._v(" Poništi komandu ")])]):_c('span',{staticClass:"w-full"},[_c('p',{staticClass:"text-gray-600"},[_vm._v("Izvršavanje komande je u toku i trenutno ne može biti poništeno.")])])])])}),0):_c('div',[_c('h1',[_vm._v("Nisu pronađeni računi.")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }