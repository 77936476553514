<template>
	<div class="licence-tab">
		<div v-if="loading" style="height: 200px" class="flex flex-col justify-center">
			<spinner-component :size="50" />
		</div>
		<div>
			<modal :adaptive="true" :height="'auto'" :width="1000" :focusTrap="false" name="renew-licence">
				<modal-renew-licence
					@close="closeModal('renew-licence')"
					@refresh="refreshData"
					@clearSelection="clearSelection"
					:rows="this.selectedRows"
				/>
			</modal>
		</div>
		<div>
			<modal :adaptive="true" height="auto" name="user-details-licences">
				<modal-user-details
					@close="closeModal('user-details-licences')"
					@refresh="refreshData"
					:user="selectedUser"
				></modal-user-details>
			</modal>
		</div>
		<div>
			<modal :adaptive="true" height="auto" :width="1600" :scrollable="true" name="mrc-licence-transfer">
				<div class="flex justify-end m-2 absolute right-1 top-1">
					<button
						class="bg-secondary hover:bg-secondary-light"
						style="width: 34px; height: 34px; line-height: 0"
						@click="$modal.hide('mrc-licence-transfer')"
					>
						<i class="fas fa-times" />
					</button>
				</div>
				<modal-mrc-transfer @close="closeModal('mrc-licence-transfer')" @refresh="refreshData"></modal-mrc-transfer>
			</modal>
		</div>

		<modal :draggable="true" :adaptive="true" height="auto" :width="600" name="action-history">
			<div class="flex justify-end m-2 absolute right-1 top-1">
				<button
					class="bg-secondary hover:bg-secondary-light"
					style="width: 34px; height: 34px; line-height: 0"
					@click="$modal.hide('action-history')"
				>
					<i class="fas fa-times" />
				</button>
			</div>
			<modal-licence-action-history
				@close="closeModal('action-history')"
				:lpfr="this.selectedLpfr"
				@refresh="refreshData"
		/></modal>
		<modal :draggable="true" :adaptive="true" height="auto" name="transfer-licence">
			<modal-transfer-licence
				@close="closeModal('transfer-licence')"
				:row="this.selectedRows[0]"
				@refresh="refreshData"
				@clearSelection="clearSelection"
		/></modal>
		<modal :draggable="true" :adaptive="true" :height="'auto'" :focusTrap="true" name="cancel-licence">
			<modal-cancel-licence
				@close="closeModal('cancel-licence')"
				@refresh="refreshData"
				@clearSelection="clearSelection"
				:row="this.selectedRows[0]"
		/></modal>
		<modal :adaptive="true" height="auto" name="licence-details">
			<div class="flex justify-end m-2 absolute right-1 top-1">
				<button
					class="bg-secondary hover:bg-secondary-light"
					style="width: 34px; height: 34px; line-height: 0"
					@click="$modal.hide('licence-details')"
				>
					<i class="fas fa-times" />
				</button>
			</div>
			<modal-licence-details
				@close="closeModal('licence-details')"
				:licence="this.selectedLicence"
				@refresh="refreshData"
		/></modal>
		<div v-if="!loading">
			<div class="button-row">
				<div class="filter-container">
					<div class="status-container">
						<h1 class="text-xl font-semibold status-filter-header">Env.</h1>
						<div class="status-options flex items-center licence-tab-filter-options px2">
							<div class="flex flex-row">
								<input type="radio" id="type-prod-licence" value="PRODUCTION" v-model="filterType" />
								<label for="type-prod-licence">PROD</label>
							</div>
							<div class="flex flex-row">
								<input type="radio" id="type-dev-licence" value="SANDBOX" v-model="filterType" />
								<label for="type-dev-licence">DEV</label>
							</div>
							<div v-if="user.canSeePrintLPFR" class="flex flex-row">
								<input type="radio" id="type-print-licence" value="PRINT" v-model="filterType" />
								<label for="type-print-licence">PRINT</label>
							</div>
						</div>
					</div>
					<div class="status-container">
						<h1 class="pl-4 pr-4 text-xl font-semibold status-filter-header">Status licence</h1>
						<div class="status-options licence-tab-filter-options flex items-center px-2">
							<div class="flex flex-row">
								<input type="radio" id="licence-status-1" :value="1" v-model="filterStatus" />
								<label for="licence-status-1">Aktivna</label>
							</div>
							<div class="flex flex-row">
								<input type="radio" id="licence-status-2" :value="2" v-model="filterStatus" />
								<label for="licence-status-2">Ističe uskoro</label>
							</div>
							<div class="flex flex-row">
								<input type="radio" id="licence-status-3" :value="3" v-model="filterStatus" />
								<label for="licence-status-3">Istekla</label>
							</div>
						</div>
					</div>

					<div v-if="user.productTypes != null && user.productTypes.length > 0" class="status-container">
						<h1 class="text-xl font-semibold status-filter-header">Tip</h1>
						<div class="status-options licence-tab-filter-options flex items-center px-2">
							<div class="flex flex-row">
								<input type="checkbox" id="product-type-1" v-model="filterProductType.filterAddLpfr" />
								<label for="product-type-1">LPFR</label>
							</div>
							<div v-if="user.productTypes.includes('EFT')" class="flex flex-row">
								<input type="checkbox" id="product-type-2" v-model="filterProductType.filterAddEft" />
								<label for="product-type-2">EFT</label>
							</div>
							<div v-if="user.productTypes.includes('IPS')" class="flex flex-row">
								<input type="checkbox" id="product-type-3" v-model="filterProductType.filterAddIps" />
								<label for="product-type-3">IPS</label>
							</div>
						</div>
					</div>

					<!-- <div class="status-container">
						<h1 class="pl-4 pr-4 text-xl font-semibold status-filter-header">Grupni prenos</h1>
						<div class="status-options licence-tab-filter-options flex items-center px-2">
							<div class="flex flex-row">
								<input type="checkbox" id="transferable-mrc" v-model="filterTransferable.mrcTransferable" />
								<label for="transferable-mrc">Prenosivi po MRC</label>
							</div>
						</div>
					</div> -->
					<div class="flex flex-row flex-nowrap justify-center items-center">
						<input
							type="text"
							class="focus:ring-primary focus:border-primary"
							v-model="searchInput"
							:placeholder="searchPlaceholder"
							style="width: 420px"
							v-on:keyup.enter="getDataThroughFilter"
						/>
						<button
							title="Pretraži"
							@click="getDataThroughFilter()"
							style="width: 38px; height: 38px; margin-bottom: 0px"
						>
							<i class="fas fa-search" />
						</button>
						<button
							v-if="initialSearchDone"
							title="Poništi filter i osveži tabelu"
							class="cancel-filter-button"
							@click="cancelFilter()"
							style="width: 38px; height: 38px; margin-bottom: 0px"
						>
							<i class="fas fa-times" />
						</button>
					</div>
				</div>
				<spinner-component v-if="loadingTableData" :size="45" />
				<div v-if="!user.licenceActionsDisabled" class="flex flex-row">
					<div class="flex flex-col">
						<button
							:disabled="
								selectedRows.length == 0 ||
								selectedRows.length > 1 ||
								new Date(selectedRows[0].expirationDate) <= this.currentDate
							"
							@click="transferLicence"
						>
							Prenesi licencu
						</button>
					</div>
					<div style="margin-left: 24px; margin-right: 12px; border-left: 1px solid #d0d0d0"></div>
					<div class="flex flex-row">
						<button :disabled="selectedRows.length == 0 || selectedRowsIncludeLongYearly" @click="renewLicence">
							Produži licence
						</button>
						<button
							:disabled="
								selectedRows.length == 0 ||
								(this.user.role != 'SUPERADMIN' && selectedRows.length > 1) ||
								(selectedRows.length > 1 && selectedRows.some(row => row.licenceType == 'G')) ||
								new Date(selectedRows[0].expirationDate) <= this.currentDate ||
								selectedRows[0].licenceType == 'G'
							"
							@click="cancelLicence"
						>
							Poništi licence
						</button>
						<button class="grow-0" @click="openMrcTransfer">Grupni prenos licenci</button>
					</div>
					<div style="margin-left: 24px; margin-right: 12px; border-left: 1px solid #d0d0d0"></div>
					<button title="Osveži tabelu" @click="getDataThroughFilter()">
						Osveži
						<i class="fas fa-redo ml-2"></i>
					</button>
				</div>
			</div>
			<div v-if="initialSearchDone">
				<vuetable
					class="licence-table"
					ref="vuetable"
					:api-mode="false"
					:fields="fields"
					:css="css.table"
					:per-page="perPage"
					:data-manager="dataManager"
					:row-class="computeRowClass"
					noDataTemplate="Nisu pronađene licence"
					pagination-path="pagination"
					@vuetable:pagination-data="onPaginationData"
					@vuetable:checkbox-toggled="onCheckboxToggled"
					@vuetable:checkbox-toggled-all="onCheckboxToggled"
					@vuetable:row-clicked="onRowClicked"
					@vuetable:loaded="onLoaded"
				>
					<div class="slot" slot="type-slot" slot-scope="props" style="font-size: 15px">
						{{ props.rowData.type == 'PRODUCTION' ? 'PROD' : 'DEV' }}
					</div>
					<div class="slot flex flex-row" slot="distributor-slot" slot-scope="props">
						<span class="text-xs" v-if="props.rowData.client.user.distributor">
							{{ props.rowData.client.user.distributor.name }}
						</span>
						<span class="text-xs" v-else-if="props.rowData.client.user.role == 'DISTRIBUTOR'">
							{{ props.rowData.client.user.name }}
						</span>
						<span v-else class="text-xs text-center">/</span>
					</div>
					<div class="slot flex flex-row items-center text-xs" slot="partner-slot" slot-scope="props">
						<span
							v-if="
								props.rowData.client.user.name &&
								props.rowData.client.user.id != user.id &&
								props.rowData.client.user.role == 'PARTNER'
							"
						>
							{{ props.rowData.client.user.name }}</span
						>
						<span v-else class="text-xs text-center">/</span>
					</div>
					<div class="slot flex flex-row items-center" slot="client-slot" slot-scope="props">
						<div
							:title="props.rowData.client.name"
							class="font-semibold text-ellipsis partner-client-txt cursor-pointer hover:text-primary"
							@click="showUserDetails(props.rowData.client)"
							style="width: 100%"
						>
							{{ props.rowData.client.name }}
						</div>
					</div>
					<div class="slot" slot="issue-date-slot" slot-scope="props">
						<span>{{ props.rowData.issueDate | formatDateNoTime }}</span>
					</div>
					<div class="slot" slot="expiration-date-slot" slot-scope="props">
						<span>{{ props.rowData.expirationDate | formatDateNoTime }}</span>
					</div>
					<div class="slot" slot="licence-type-slot" slot-scope="props">
						<span class="flex flex-row justify-center">{{ props.rowData.licenceType }}</span>
					</div>
					<div class="slot" slot="licence-status-slot" slot-scope="props">
						<div class="licence-details-container flex flex-row flex-nowrap justify-between items-center">
							<span
								class="text-green-600"
								v-if="getNumOfDaysBetweenDates(currentDate, new Date(props.rowData.expirationDate)) > 15"
								>Aktivna</span
							>
							<span
								class="text-yellow-600"
								v-else-if="currentDate <= new Date(props.rowData.expirationDate).setHours(0, 0, 0, 0)"
								>{{
									computeExpirationLabel(currentDate, new Date(props.rowData.expirationDate).setHours(0, 0, 0, 0))
								}}</span
							>
							<span v-else class="text-red-600">Istekla</span>
							<button
								title="Prikaži informacije o licenci"
								v-if="new Date(props.rowData.expirationDate).setHours(0, 0, 0, 0) >= currentDate"
								style="width: 26px; height: 26px; padding: 0"
								@click="openLicenceDetails(props.rowData)"
								class="ml-2"
							>
								<i class="fas fa-info" style="width: 16px" />
							</button>
						</div>
					</div>
					<div class="slot" slot="jid-slot" slot-scope="props">
						<span v-if="props.rowData.jidNickname">{{
							props.rowData.jid + ' (' + props.rowData.jidNickname + ')'
						}}</span>
						<span v-else>{{ props.rowData.jid }}</span>
					</div>
					<div class="slot" slot="licence-actions-slot" slot-scope="props">
						<div class="flex flex-row justify-center">
							<button
								class="logs-btn"
								title="Prikaži akcije"
								@click="showActionHistory(props.rowData)"
								style="width: 26px; height: 26px; padding: 0"
							>
								<i class="fas fa-list"></i>
							</button>
						</div>
					</div>
					<div class="slot text-center" slot="archive-slot" slot-scope="props">
						<button
							v-if="!props.rowData.code || currentDate > new Date(props.rowData.expirationDate).setHours(0, 0, 0, 0)"
							style="height: 26px; width: 26px; padding: 0"
							class="reset-lpfr-btn"
							title="Arhiviraj LPFR"
							@click="archiveLpfr(props.rowData)"
						>
							<i class="fas fa-archive"></i>
						</button>
						<div v-else class="text-sm text-center">/</div>
					</div>
					<div class="slot text-center" slot="mrc-transfer" slot-scope="props">
						<div v-if="props.rowData.mrcTransferable">
							<button
								style="height: 26px; width: 26px; padding: 0"
								title="Prenesi licencu po MRC"
								@click="transferSelectedByMrc(props.rowData)"
							>
								<i class="fas fa-sign-out-alt"></i>
							</button>
							<button
								style="height: 26px; margin-left: 6px; width: 26px; padding: 0"
								class="bg-red-600 hover:bg-red-800"
								title="Ignoriši prenos po MRC"
								@click="ignoreMrcTransferable(props.rowData)"
							>
								<i class="fas fa-window-close"></i>
							</button>
						</div>
						<div v-else>/</div>
					</div>
				</vuetable>
				<div class="flex flex-row justify-end items-center">
					<div class="mr-6">
						{{ numOfDisplayed }}
						/
						{{ numOfTotal }}
					</div>
					<div class="flex flex-row per-page-div">
						<input type="radio" id="10-per-page-licences" :value="10" v-model="perPage" /><label
							:class="{ active: perPage == 10 }"
							for="10-per-page-licences"
							>10</label
						>
						<input type="radio" id="20-per-page-licences" :value="20" v-model="perPage" /><label
							:class="{ active: perPage == 20 }"
							for="20-per-page-licences"
							>20</label
						>
						<input type="radio" id="50-per-page-licences" :value="50" v-model="perPage" /><label
							:class="{ active: perPage == 50 }"
							for="50-per-page-licences"
							>50</label
						>
						<input type="radio" id="100-per-page-licences" :value="100" v-model="perPage" /><label
							:class="{ active: perPage == 100 }"
							for="100-per-page-licences"
							>100</label
						>
					</div>
					<vuetable-pagination
						ref="pagination"
						class="pull-right"
						@vuetable-pagination:change-page="onChangePage"
					></vuetable-pagination>
				</div>
			</div>
			<div v-else class="mt-24 mb-36">
				<h1 class="text-2xl">Izvršite pretragu iznad ili osvežite tabelu, kako bi učitali podatke</h1>
			</div>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from './VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import _ from 'lodash';
import ModalCancelLicence from './ModalCancelLicence.vue';
import ModalTransferLicence from './ModalTransferLicence.vue';
import { mapState, mapMutations } from 'vuex';
import axios from '@/axios/axios.js';
import ModalLicenceDetails from './ModalLicenceDetails.vue';
import ModalRenewLicence from './ModalRenewLicence.vue';
import ModalLicenceActionHistory from './ModalLicenceActionHistory.vue';
import ModalUserDetails from './ModalUserDetails.vue';
import ModalMrcTransfer from './ModalMrcTransfer.vue';

export default {
	components: {
		Vuetable,
		VuetablePagination,
		ModalCancelLicence,
		ModalTransferLicence,
		ModalLicenceDetails,
		ModalRenewLicence,
		ModalLicenceActionHistory,
		ModalUserDetails,
		ModalMrcTransfer
	},

	async created() {
		this.loadingStartedLicences();
		this.setupTableFields();
		// await this.getLicences();
		// has to happen after getLicences() to setup correct sort columns for superadmin
		this.loadingFinishedLicences();

		if (this.user.productTypes.includes('EFT')) {
			this.filterProductType.filterAddEft = true;
		}

		if (this.user.productTypes.includes('IPS')) {
			this.filterProductType.filterAddIps = true;
		}
	},

	props: {
		refresher: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			intervalId: null,
			loadingTableData: false,
			refreshInterval: 60000,
			currentDate: new Date().setHours(0, 0, 0, 0),
			selectedRows: [],
			searchInput: '',
			perPage: 20,
			numOfDisplayed: 0,
			numOfTotal: 0,
			selectedLicence: null,
			selectedLpfr: null,
			selectedUser: null,
			filterStatus: null,
			filterProductType: {
				filterAddLpfr: true,
				filterAddEft: false,
				filterAddIps: false
			},
			filterTransferable: {
				mrcTransferable: false
			},
			filterType: 'PRODUCTION',
			fields: [],
			css: VuetableCssConfig,
			localData: [],
			allData: [],
			distributorSortColumnName: 'client.user.distributor.name',
			partnerSortColumnName: 'client.user.name',
			initialSearchDone: false
		};
	},
	computed: {
		...mapState({
			loading: state => {
				return state.loadingLicences;
			}
		}),
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id,
					canSeePrintLPFR: state.user.user.canSeePrintLPFR,
					licenceActionsDisabled: state.user.user.licenceActionsDisabled,
					productTypes: state.user.user.productTypes
				};
			}
		}),

		selectedRowsIncludeProductsNotMrcTransferable() {
			// return true if any of the selected rows is not mrzTransferable
			if (this.selectedRows && this.selectedRows.length > 0) {
				return this.selectedRows.some(lpfr => {
					return !lpfr.mrcTransferable;
				});
			} else {
				return false;
			}
		},

		selectedRowsIncludeLongYearly() {
			if (this.selectedRows && this.selectedRows.length > 0) {
				return this.selectedRows.some(lpfr => {
					if (lpfr.yearlyLicenceEndDate) {
						const endDate = new Date(lpfr.yearlyLicenceEndDate);
						const today = new Date();

						return !(
							(today.getMonth() == endDate.getMonth() && today.getFullYear() == endDate.getFullYear()) ||
							endDate < today
						);
					}
				});
			} else {
				return false;
			}
		},

		searchPlaceholder() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'Naziv distributera/partnera/klijenta, PIB, JID, nadimak, ...';
				case 'DISTRIBUTOR':
					return 'Naziv partnera/klijenta, PIB, JID, nadimak, ...';
				default:
					return 'Naziv klijenta, PIB, JID, nadimak, ...';
			}
		}
	},
	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
			this.computeSelectedRows();
		},

		async filterStatus() {
			if (this.filterStatus && this.initialSearchDone) {
				this.loadingTableData = true;
				await this.refreshData();
				this.loadingTableData = false;
			}
		},

		async filterProductType() {
			if (this.filterProductType && this.initialSearchDone) {
				this.loadingTableData = true;
				await this.refreshData();
				this.loadingTableData = false;
			}
		},

		async filterTransferable() {
			if (this.filterTransferable && this.initialSearchDone) {
				this.loadingTableData = true;
				await this.refreshData();
				this.loadingTableData = false;
			}
		},

		async filterType() {
			if (this.filterType && this.initialSearchDone) {
				this.loadingTableData = true;
				await this.refreshData();
				this.loadingTableData = false;
			}
		}
	},
	methods: {
		...mapMutations(['loadingStartedLicences', 'loadingFinishedLicences', 'setLicences']),

		onLoaded() {
			this.numOfDisplayed = this.$refs.vuetable.countTableData;
			this.numOfTotal = this.$refs.pagination.tablePagination.total;
		},

		showUserDetails(user) {
			event.stopPropagation();
			this.selectedUser = user;
			// console.log('selected', this.selectedUser);
			this.$modal.show('user-details-licences');
		},

		async resetIntervals() {
			this.loadingStartedLicences();
			await this.refreshData();
			this.loadingFinishedLicences();
			this.removeInterval();
			// this.setInterval();

			// console.log('resetIntervals on licences');
		},

		async getDataThroughFilter() {
			this.loadingTableData = true;
			await this.refreshData();
			this.initialSearchDone = true;
			this.loadingTableData = false;
		},

		async getDataIfNeeded() {
			if (!this.localData || this.localData == null || this.localData.length == 0) {
				this.loadingStartedLicences();
				await this.refreshData();
				this.loadingFinishedLicences();
			}
		},

		setInterval() {
			if (this.intervalId) return;
			this.intervalId = setInterval(() => {
				if (this.$router.history.current.name == 'home') {
					this.currentDate = new Date().setHours(0, 0, 0, 0);
					this.getLicences();
				}
			}, this.refreshInterval);
		},

		removeInterval() {
			// console.log('removed interval on licences');
			if (this.intervalId) {
				clearInterval(this.intervalId);
				this.intervalId = null;
			}
		},

		setupTableFields() {
			this.fields = [
				{
					name: 'client-slot',
					title: 'Klijent',
					sortField: 'client.name'
				},
				{
					name: 'client.pib',
					title: 'PIB'
				},
				{
					name: 'jid-slot',
					title: 'JID'
				},
				{
					name: 'licence-status-slot',
					title: 'Status licence',
					sortField: 'expirationDate'
				},
				{
					name: 'issue-date-slot',
					title: 'Datum izdavanja',
					sortField: 'issueDate'
				},
				{
					name: 'expiration-date-slot',
					title: 'Datum isteka',
					sortField: 'expirationDate'
				},
				{
					name: 'licence-type-slot',
					title: 'Tip',
					sortField: 'licenceType'
				},
				{ name: 'type-slot', title: 'Env.', sortField: 'type' },
				{
					name: 'activationOrder',
					title: 'RA',
					sortField: 'activationOrder'
				},
				{
					name: 'licence-actions-slot',
					title: `Istorijat`
				},
				{
					name: 'archive-slot',
					title: 'ARC'
				},
				/* {
					name: 'mrc-transfer',
					title: 'MRC prenos'
				}, */
				{
					name: '__checkbox',
					titleClass: 'center-checkbox',
					dataClass: 'center-checkbox'
				}
			];

			if (this.user.role != 'PARTNER' && this.user.role != 'ONPREMADMIN') {
				this.fields.unshift({
					name: 'partner-slot',
					title: 'Partner',
					sortField: `${this.partnerSortColumnName}`
				});
			}

			if (this.user.role == 'SUPERADMIN') {
				this.fields.unshift({
					name: 'distributor-slot',
					title: 'Distributer',
					sortField: `${this.distributorSortColumnName}`
				});
			}
		},

		archiveLpfr(rowData) {
			this.$fire({
				title: 'Arhiviranje LPFR',
				html: `Da li ste sigurni da želite da arhivirate<br><b>LPFR - ${rowData.jid} ${
					rowData.jidNickname ? '(' + rowData.jidNickname + ')' : ''
				}</b>?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Arhiviraj LPFR',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					axios
						.post(`lpfr/${rowData.id}/archive`, { archived: true })
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								html: `LPFR je uspešno arhiviran.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							this.loadingFinishedLicences();
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom arhiviranja LPFR.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});

			event.stopPropagation();
		},

		async refreshData() {
			this.loadingTableData = true;
			await this.getLicences();
			this.loadingTableData = false;
		},

		openLicenceDetails(rowData) {
			this.selectedLicence = rowData.licences.find(licence => rowData.expirationDate == licence.expirationDate);
			this.$modal.show('licence-details');
			event.stopPropagation();
		},

		openMrcTransfer() {
			this.$modal.show('mrc-licence-transfer');
		},

		showActionHistory(rowData) {
			this.selectedLpfr = rowData;
			this.$modal.show('action-history');
			event.stopPropagation();
		},

		computeRowClass(dataItem, index) {
			if (!this.$refs.vuetable) {
				return;
			}
			const selected = this.$refs.vuetable.selectedTo.indexOf(dataItem.id) > -1;

			let classList = 'row-class';

			if (selected) classList += ' selected-class';

			return classList;
		},

		computeExpirationLabel(date1, date2) {
			let label = 'Ističe';
			const numOfDays = this.getNumOfDaysBetweenDates(date1, date2);
			if (numOfDays == 0) {
				label = label + ' danas';
			} else if (numOfDays == 1) {
				label = label + ' za 1 dan';
			} else {
				label = label + ' za ' + numOfDays + ' dana';
			}

			return label;
		},

		getNumOfDaysBetweenDates(date1, date2) {
			if (new Date(date2).setHours(0, 0, 0, 0) < new Date(date1).setHours(0, 0, 0, 0)) {
				return -1;
			}

			const date1NoTime = new Date(date1).setHours(0, 0, 0, 0);
			const date2NoTime = new Date(date2).setHours(0, 0, 0, 0);

			return Math.round((new Date(date2NoTime) - new Date(date1NoTime)) / 86400000);
		},

		async getLicences() {
			// console.log('getting licences data');
			try {
				const productTypesToInclude = [];

				if (this.filterProductType.filterAddLpfr) {
					productTypesToInclude.push('LPFR');
				}

				if (this.filterProductType.filterAddEft) {
					productTypesToInclude.push('EFT');
				}
				if (this.filterProductType.filterAddIps) {
					productTypesToInclude.push('IPS');
				}

				const response = await axios.get(`client/lpfr/licences`, {
					params: {
						status: this.filterStatus,
						type: this.filterType,
						search: this.searchInput,
						productType: productTypesToInclude,
						transferable: this.filterTransferable
					}
				});
				this.localData = response.data;
				this.allData = this.localData;

				const unarchived = this.localData.filter(licence => licence.archived == false);
				this.localData = unarchived;

				//sort by activationOrder
				this.localData.sort((a, b) => {
					if (a.activationOrder < b.activationOrder) {
						return -1;
					}
					if (a.activationOrder > b.activationOrder) {
						return 1;
					}
					return 0;
				});

				let counterProd = 1;
				let counterDev = 1;
				let counterPrint = 1;

				//mapping for licence type
				this.localData.map(licence => {
					if (licence.activationOrder) {
						switch (licence.type) {
							case 'PRODUCTION':
								licence.activationOrderComputed = counterProd++;
								break;
							case 'SANDBOX':
								licence.activationOrderComputed = counterDev++;
								break;
							case 'PRINT':
								licence.activationOrderComputed = counterPrint++;
								break;
							default:
								break;
						}
					} else {
						licence.activationOrderComputed = '/';
					}
					licence.licenceType = 'M';

					if (licence.yearlyLicenceStartDate && licence.yearlyLicenceEndDate) {
						const start = new Date(licence.yearlyLicenceStartDate).setHours(0, 0, 0, 0);
						const end = new Date(licence.yearlyLicenceEndDate).setHours(0, 0, 0, 0);
						const today = new Date(this.currentDate).setHours(0, 0, 0, 0);

						if (today >= start && today <= end) {
							licence.licenceType = 'G';
						} else if (today < start) {
							licence.licenceType = 'M->G';
						}
					}

					return licence;
				});

				// mapping for superadmin sorting
				if (this.user.role == 'SUPERADMIN') {
					this.localData.map(licence => {
						if (this.user.id != licence.client.user.id && licence.client.user.role != 'SUPERADMIN')
							licence.partnerSort = licence.client.user.name;
						else {
							licence.partnerSort = '/';
						}
						licence.distributorSort = '/';
						if (licence.client.user.role == 'DISTRIBUTOR') {
							licence.distributorSort = licence.client.user.name;
							licence.partnerSort = '/';
						} else if (licence.client.user.distributor) {
							licence.distributorSort = licence.client.user.distributor.name;
						}
					});

					this.distributorSortColumnName = 'distributorSort';
					this.partnerSortColumnName = 'partnerSort';
				}

				//sort by issue date
				this.localData.sort((a, b) => {
					if (new Date(a.issueDate) < new Date(b.issueDate)) {
						return 1;
					}
					if (new Date(a.issueDate) > new Date(b.issueDate)) {
						return -1;
					}
					return 0;
				});

				this.setLicences(this.localData);

				// //split into PROD/DEV
				// //* this is done as the last step, after setting LPFRs in store, to avoid issues with the dashboard tab
				// this.localData = this.localData.filter((lpfr) => {
				// 	if (this.filterType == 'PROD') {
				// 		return lpfr.type == 'PRODUCTION';
				// 	} else if (this.filterType == 'DEV') {
				// 		return lpfr.type == 'SANDBOX';
				// 	} else if (this.filterType == 'PRINT') {
				// 		return lpfr.type == 'PRINT';
				// 	} else {
				// 		return true;
				// 	}
				// });

				// if (this.filterStatus) {
				// 	if (this.filterStatus == 1) {
				// 		this.localData = this.localData.filter(
				// 			(licence) =>
				// 				this.getNumOfDaysBetweenDates(
				// 					new Date(),
				// 					new Date(licence.expirationDate)
				// 				) > 15
				// 		);
				// 	} else if (this.filterStatus == 2) {
				// 		this.localData = this.localData.filter((licence) => {
				// 			const check =
				// 				this.getNumOfDaysBetweenDates(
				// 					new Date(),
				// 					new Date(licence.expirationDate)
				// 				) >= 0 &&
				// 				this.getNumOfDaysBetweenDates(
				// 					new Date(),
				// 					new Date(licence.expirationDate)
				// 				) <= 15;
				// 			return check;
				// 		});
				// 	} else {
				// 		this.localData = this.localData.filter(
				// 			(licence) =>
				// 				this.getNumOfDaysBetweenDates(
				// 					new Date(),
				// 					new Date(licence.expirationDate)
				// 				) < 0
				// 		);
				// 	}
				// }
			} catch (error) {
				// console.log('error', error);
			}
		},

		closeModal(name) {
			this.$modal.hide(name);
		},
		clearSelection() {
			this.$refs.vuetable.selectedTo = [];
		},
		search() {
			this.searchFor = this.searchInput;
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},
		cancelFilter() {
			this.searchFor = '';
			this.searchInput = '';
			this.filterType = 'PRODUCTION';
			this.filterStatus = null;
			this.filterProductType = {
				filterAddIps: false,
				filterAddEft: false,
				filterAddLpfr: true
			};
			if (this.user.productTypes.includes('EFT')) {
				this.filterProductType.filterAddEft = true;
			}

			if (this.user.productTypes.includes('IPS')) {
				this.filterProductType.filterAddIps = true;
			}
			this.initialSearchDone = false;
			this.localData = [];
		},

		onRowClicked(row) {
			if (this.$refs.vuetable.selectedTo.includes(row.data.id)) {
				this.$refs.vuetable.selectedTo.splice(this.$refs.vuetable.selectedTo.indexOf(row.data.id), 1);
			} else {
				this.$refs.vuetable.selectedTo.push(row.data.id);
			}

			this.computeSelectedRows();
		},

		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},

		onCheckboxToggled() {
			this.computeSelectedRows();
		},
		computeSelectedRows() {
			if (!this.$refs.vuetable) return;
			this.selectedRows = this.allData.filter(row => this.$refs.vuetable.selectedTo.indexOf(row.id) > -1);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		cancelLicence() {
			if (this.selectedRows.length == 1) {
				this.$modal.show('cancel-licence');
			} else if (this.user.role == 'SUPERADMIN') {
				this.$fire({
					title: 'Grupno poništavanje licenci',
					html: `Da li ste sigurni da želite da poništite licence izabranih LPFR-ova?<br/><br/>Izabrani LPFR-ovi:<br/>${this.selectedRows
						.map(row => row.jid)
						.join(', ')}`,
					type: 'warning',
					showCancelButton: true,
					cancelButtonText: 'Odustani',
					showConfirmButton: true,
					confirmButtonText: 'Poništi licence',
					confirmButtonColor: '#005ca9',
					reverseButtons: true
				}).then(result => {
					if (result.value) {
						axios
							.post(`licence/cancel-group`, {
								jids: this.selectedRows.map(row => row.jid)
							})
							.then(response => {
								this.$fire({
									title: 'Uspešna akcija',
									html: `Licence su uspešno poništene.`,
									type: 'success',
									timer: 2500,
									showConfirmButton: false
								});
								this.refreshData();
							})
							.catch(error => {
								this.loadingFinishedLicences();
								this.$fire({
									title: 'Greška',
									text: 'Došlo je do greške prilikom poništavanja licenci.',
									type: 'error',
									timer: 2500,
									showConfirmButton: false
								});
							});
					}
				});
			}
		},
		transferLicence() {
			this.$modal.show('transfer-licence');
		},
		renewLicence() {
			if (this.selectedRowsIncludeLongYearly) {
				this.$fire({
					title: 'Greška',
					text: 'Nemoguća akcija, izabrani LPFR-ovi imaju godišnju licencu koja ne ističe uskoro.',
					type: 'error',
					timer: 3000,
					showConfirmButton: false
				});
			} else {
				this.$modal.show('renew-licence');
			}
		},
		ignoreMrcTransferable(selectedRow) {
			this.$fire({
				title: 'Ignorisanje prenosa po MRC',
				html: `Da li ste sigurni da želite da ignorišete prenos licence po MRC za LPFR - ${selectedRow.jid} ${
					selectedRow.jidNickname ? '(' + selectedRow.jidNickname + ')' : ''
				}?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Ignoriši prenos po MRC',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					axios
						.post(`licence/ignore-mrc-transfer`, {
							jids: [selectedRow.jid]
						})
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								html: `Prenos po MRC je uspešno ignorisan.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							this.loadingFinishedLicences();
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom ignorisanja prenosa po MRC.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
			event.stopPropagation();
		},
		transferAllByMrc() {
			this.$fire({
				title: 'Prenos svih licenci po MRC',
				text: 'Da li ste sigurni da želite da izvršite prenos licenci po MRC za sve aktivne proizvode u produkciji?',
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Prenesi sve licence',
				showLoaderOnConfirm: true,
				allowOutsideClick: false,
				preConfirm: () => {
					return axios
						.post(`licence/transfer-by-mrc`, {
							transferAll: true
						})
						.then(response => {
							return response.data;
						})
						.catch(error => {
							throw error;
						});
				}
			})
				.then(response => {
					if (response.dismiss) return;
					response = response.value;
					if (response.totalSuccess) {
						this.$fire({
							title: 'Uspešna akcija',
							html: `Licence svih proizvoda su uspešno prenete po MRC.`,
							type: 'success',
							timer: 2500,
							showConfirmButton: false
						});
					} else {
						this.handleMrcTransferError(response.products);
					}
					this.refreshData();
				})
				.catch(error => {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom prenosa licenci po MRC.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
					this.refreshData();
				});
		},

		handleMrcTransferError(products) {
			console.log('ayoo', products);
			const productsThatFailed = products.filter(product => !product.success).map(product => product.jid);
			//form a html string with all the products that failed and their explanations

			const productsThatFailedHtmlText = productsThatFailed
				.map(jid => {
					const product = products.find(product => product.jid == jid);
					return `<b>${jid}</b>: ${this.composeMrctransferErrorMessage(product.errorCode)}`;
				})
				.join('<br/>');

			const numberOfSuccess = products.filter(product => product.success).length;
			const numOfSuccessText =
				'Uspešno' +
				(numberOfSuccess > 1 ? ' su prenete licence' : ' je preneta licenca') +
				' za ' +
				numberOfSuccess +
				(numberOfSuccess > 1 ? ' proizvoda' : ' proizvod') +
				'.';

			let title = 'Parcijalna uspešnost';
			let type = 'warning';
			if (productsThatFailed.length == products.length) {
				title = 'Greška';
				type = 'error';
			}

			this.$fire({
				title,
				html: `Došlo je do greške prilikom prenosa licenci po MRC.<br/>${numOfSuccessText}<br>Proizvodi kojima nije uspeo prenos licence:<br/>${productsThatFailedHtmlText}`,
				type,
				showConfirmButton: true
			});
		},

		composeMrctransferErrorMessage(code) {
			switch (code) {
				case 1000:
					return 'Nije pronađen odgovarajući proizvod sa istim MRC.';
				case 1001:
					return 'Nije pronađena validna licenca za prenos po MRC.';
				case 1002:
					return 'Licenca preuzeta sa proizvoda je istekla.';
				default:
					return 'Došlo je do greške prilikom prenosa licenci po MRC.';
			}
		},

		transferSelectedByMrc(selectedRow) {
			let jids = [];
			if (selectedRow) {
				jids.push(selectedRow.jid);
			} else {
				jids = this.selectedRows.map(row => row.jid);
			}

			this.$fire({
				title: 'Prenos izabranih licenci po MRC',
				text: 'Da li ste sigurni da želite da izvršite prenos licenci po MRC za izabrane proizvode?',
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Prenesi licence za izabrane proizvode',
				showLoaderOnConfirm: true,
				allowOutsideClick: false,
				preConfirm: () => {
					return axios
						.post(`licence/transfer-by-mrc`, {
							jids
						})
						.then(response => {
							return response.data;
						})
						.catch(error => {
							throw error;
						});
				}
			})
				.then(response => {
					if (response.dismiss) return;
					response = response.value;
					if (response.totalSuccess) {
						this.$fire({
							title: 'Uspešna akcija',
							html: `Licence svih izabranih proizvoda su uspešno prenete po MRC`,
							type: 'success',
							timer: 2500,
							showConfirmButton: false
						});
					} else {
						console.log('idemo');
						this.handleMrcTransferError(response.products);
					}
					this.refreshData();
				})
				.catch(error => {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom prenosa licenci po MRC.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
					this.refreshData();
				});
			event.stopPropagation();
		},
		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.client.name.search(txt) >= 0 ||
						item.client.pib.search(txt) >= 0 ||
						item.jid.search(txt) >= 0 ||
						item.issueDate.search(txt) >= 0 ||
						item.expirationDate.search(txt) >= 0 ||
						(item.jidNickname != null && item.jidNickname.search(txt) >= 0) ||
						(item.client.user ? item.client.user.name.search(txt) >= 0 : false) ||
						(item.client.user.distributor ? item.client.user.distributor.name.search(txt) >= 0 : false)
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		}
	}
};
</script>

<style lang="scss">
.licence-tab-filter-options {
	flex-direction: column !important;
	align-items: flex-start;
}
.button-container {
	font-size: 0.9rem;
}
.selected-class {
	background-color: rgb(186, 218, 240) !important;
}
.vuetable-th-slot-licence-actions-slot {
	padding: 12px 6px !important;
}
</style>

<style lang="scss">
.licence-table .row-class:hover {
	cursor: pointer;
	background-color: var(--primary-color-hover);
}
.licence-table {
	font-size: 15px;
}
</style>

<style lang="scss" scoped>
.button-row button {
	margin-top: 0px;
	font-size: 0.9rem;
	margin-bottom: 0px;
}
.status-container {
	margin-right: 0px;
}
.logs-btn {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
}
.text-ellipsis {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.partner-client-txt {
	max-width: 225px;
}
</style>
