<template>
	<div class="modal">
		<h1>Generisanje izveštaja o aktivacijama</h1>
		<hr class="mb-1" />
		<label class="text-xs text-primary mb-4"
			><b>NAPOMENA</b>: Ovom akcijom se generiše izveštaj o aktivacijama LPFR-ova za izabrani period.<br />
			Izabrani početni datum mora biti raniji od izabranog zaključnog datuma. U izveštaj je uključen i ceo dan izabranog
			zaključnog datuma.</label
		>
		<div v-if="loading" class="mt-12 mb-12">
			<spinner-component :size="64" />
		</div>
		<div v-else>
			<div class="mb-3">
				<h2 class="mt-2">Datum od (počevši od)</h2>
				<vue-datetime
					type="date"
					input-class="form-control form-control"
					placeholder="Izaberite početni datum"
					hidden-name="webstorm"
					format="dd.MM.yyyy."
					:phrases="{
						ok: 'U redu',
						cancel: 'Otkaži'
					}"
					v-model="from"
				></vue-datetime>
			</div>
			<div class="mb-3">
				<h2 class="mt-2">Datum do (zaključno sa)</h2>
				<vue-datetime
					type="date"
					input-class="form-control form-control"
					placeholder="Izaberite krajnji datum"
					hidden-name="webstorm"
					format="dd.MM.yyyy."
					:phrases="{
						ok: 'U redu',
						cancel: 'Otkaži'
					}"
					v-model="to"
				></vue-datetime>
			</div>
			<div class="mb-3 mt-3" v-if="user.role == 'SUPERADMIN' || user.role == 'DISTRIBUTOR'">
				<h2>Generisati izveštaj za:</h2>
				<v-select
					:placeholder="`Pretražite i izaberite partnera${user.role == 'SUPERADMIN' ? '/distributera' : ''}`"
					class="select-style mb-1"
					v-model="selectedUser"
					:options="partners"
				></v-select>
			</div>
			<h2>Grupisanje po klijentu:</h2>

			<div class="mt-1">
				<button class="p-1 pl-3 pr-3" @click="grouped = !grouped">
					{{ grouped ? 'Da' : 'Ne' }}
				</button>
			</div>
			<label class="text-xs text-gray-400 mb-2"
				>Negrupisani izveštaj zasebno prikazuje vreme aktivacije svakog LPFR-a.<br />Grupisani izveštaj prikazuje broj
				aktivacija u izabranom periodu za svakog klijenta.</label
			>

			<hr class="mb-4" />
			<button class="w-full" @click="generateReport()" :disabled="!inputValid || loading">Generiši izveštaj</button>
		</div>
	</div>
</template>

<script>
import * as XLSX from 'xlsx-js-style';
import axios from '@/axios/axios.js';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	async created() {
		this.loading = true;
		if (this.user.role == 'DISTRIBUTOR') {
			const response = await axios.get('user/distributor/partners');
			this.partners = response.data.map(partner => {
				partner.label = `${partner.name} (PIB: ${partner.pib})`;
				partner.value = partner.id;
				return partner;
			});
		} else if (this.user.role == 'SUPERADMIN') {
			const response = await axios.get('user/partners/all');
			this.partners = response.data.map(partner => {
				partner.label = `${partner.name} (PIB: ${partner.pib})`;
				partner.value = partner.id;
				return partner;
			});
		}

		const defaultOption = {
			label: `${this.user.role == 'SUPERADMIN' ? 'Sve distributere i partnere' : 'Sve partnere'}`,
			value: 'all'
		};
		this.partners.unshift(defaultOption);
		this.selectedUser = defaultOption;
		this.loading = false;
	},

	data() {
		return {
			grouped: false,
			from: null,
			to: null,
			loading: false,
			partners: [],
			selectedUser: null
		};
	},
	computed: {
		...mapState({
			user: state => {
				return {
					role: state.user.user.role
				};
			}
		}),
		inputValid: function () {
			return this.from && this.to && this.from < this.to && this.selectedUser;
		}
	},

	methods: {
		async generateReport() {
			this.loading = true;

			try {
				//add 1 day to to
				this.to = new Date(this.to);
				this.to = new Date(this.to).setDate(this.to.getDate() + 1);
				this.to = new Date(this.to).toISOString();

				const response = await axios.get(
					`reports/activations/user/${this.selectedUser.value}/${this.from}/${
						this.to
					}/group-by-pib/${this.grouped.toString()}`
				);

				if (response.data.length === 0) {
					this.$fire({
						title: 'Ne postoje aktivacije',
						text: `Nisu pronađene aktivacije u izabranom periodu za izabrane partnere${
							this.user.role == 'SUPERADMIN' ? ' i distributere' : ''
						}.`,
						type: 'info',
						showConfirmButton: true
					});
					return;
				} //map dates to excel format
				const data = response.data.map(item => {
					const row = {
						naziv: item.name,
						pib: item.pib
					};

					if (item.jid) {
						row.jid = item.jid;
					}

					if (item.activation) {
						row.aktivacija = moment(item.activation, moment.ISO_8601).format('DD.MM.YYYY - HH:mm:ss');
					}
					if (item.count) {
						row['broj aktivacija'] = item.count;
					}
					return row;
				});

				let workbook = XLSX.utils.book_new();
				let worksheet = XLSX.utils.json_to_sheet(data);

				for (const i in worksheet) {
					const cell = worksheet[i];
					//check if object
					if (typeof cell === 'object') {
						cell.s = {};

						//borders
						cell.s.border = {
							top: { style: 'thin' },
							left: { style: 'thin' },
							bottom: { style: 'thin' },
							right: { style: 'thin' }
						};

						//bold headers
						if (
							cell.v === 'naziv' ||
							cell.v === 'pib' ||
							cell.v === 'jid' ||
							cell.v === 'aktivacija' ||
							cell.v === 'broj aktivacija'
						) {
							cell.s.font = { bold: true, sz: 16 };
							cell.s.fill = { fgColor: { rgb: 'E4EDF0' } };
						}
					}
				}

				worksheet['!cols'] = this.fitToColumn(data);

				XLSX.utils.book_append_sheet(workbook, worksheet, 'Izveštaj');

				const filename = 'izvestaj_aktivacija_' + moment().format('DD-MM-YYYY') + '.xlsx';
				XLSX.writeFile(workbook, filename);
			} catch (error) {
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom generisanja izveštaja. Molimo probajte kasnije.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		},
		fitToColumn(data) {
			// get maximum character of each column

			//get number of properties
			let numberOfProperties = Object.keys(data[0]).length;
			const columnWidths = [];

			for (const row of data) {
				for (let i = 0; i < numberOfProperties; i++) {
					let column = Object.keys(row)[i];
					let columnLength = row[column].toString().length;
					if (!columnWidths[i] || columnLength > columnWidths[i]) {
						if (columnLength > 100) {
							columnWidths[i] = 100;
						} else {
							columnWidths[i] = columnLength + 5;
						}
					}
					if (Object.keys(row)[i].length > columnWidths[i]) {
						columnWidths[i] = Object.keys(row)[i].length + 5;
					}
				}
			}

			//create array of column widths
			const cols = [];
			for (let i = 0; i < numberOfProperties; i++) {
				cols.push({
					wch: columnWidths[i]
				});
			}

			return cols;
		}
	}
};
</script>

<style></style>
