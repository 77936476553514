<template>
	<div class="modal">
		<h1>{{ modalTitle }}</h1>
		<hr />
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-if="!loading">
			<div class="mt-2">
				<div class="mt-2 w-full input-container">
					<h2>Naziv cenovnika</h2>
					<input type="text" v-model="name" />
					<h2>Tip cenovnika</h2>
					<v-select
						:placeholder="`Izaberite tip kartica`"
						class="select-style mb-1"
						v-model="type"
						:options="types"
					></v-select>
					<h2>Nominalna cena po jedinici</h2>
					<label class="text-sm text-gray-200" for="emails">Cena jedne licence izražena u evrima, npr. 5.25</label>
					<money v-bind="{ precision: 2 }" type="text" v-model="flatPrice"></money>
					<h2>Postoji rabat</h2>
					<div class="flex flex-row mt-1">
						<div class="flex flex-row items-center border p-2">
							<input v-model="hasDiscount" :value="true" type="radio" name="rabat" id="rabat-yes" />
							<label class="pl-2" for="rabat-yes">Da</label>
						</div>
						<div class="flex flex-row items-center ml-4 border p-2">
							<input v-model="hasDiscount" :value="false" type="radio" name="rabat" id="rabat-no" />
							<label class="pl-2" for="rabat-no">Ne</label>
						</div>
					</div>
					<div v-if="hasDiscount">
						<h2>Rabat</h2>
						<div class="border border-gray-100 p-2">
							<div :key="index" v-for="(discount, index) in discounts" class="discounts mt-1 mb-1">
								<div class="flex flex-row items-center">
									<h2 class="mr-2 font-semibold text-xl">{{ index + 1 }}.</h2>
									<h2>Za broj licenci ≥</h2>
									<money class="discounts-input" v-model="discount.licenceCount" v-bind="money"></money>

									<h2>, rabat iznosi</h2>
									<money class="discounts-input" v-model="discount.percentage" v-bind="money"></money>
									<h2>%</h2>
								</div>
							</div>
							<div class="flex flex-row">
								<button
									class="mt-2"
									style="border-radius: 100%; height: 32px; width: 32px; padding: 0"
									@click="addDiscount"
								>
									<i class="fas fa-plus" />
								</button>
								<button
									v-if="this.discounts.length > 1"
									class="mt-2 ml-2 bg-red-600 hover:bg-red-800"
									style="border-radius: 100%; height: 32px; width: 32px; padding: 0"
									@click="removeDiscount"
								>
									<i class="fas fa-minus" />
								</button>
							</div>
						</div>
					</div>
					<div v-if="type && type.value != 'PRINT'">
						<h2>Cena godišnje licence</h2>
						<label class="text-sm text-gray-200" for="emails"
							>Cena jedne godišnje licence izražena u evrima, npr. 50</label
						>
						<money v-bind="{ precision: 2 }" type="text" v-model="flatYearlyPrice"></money>
					</div>
				</div>
			</div>
			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button :disabled="!inputIsValid" class="flex-grow ml-2" @click="savePricelist()">
					{{ modalConfirm }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	data() {
		return {
			money: {
				precision: 0
			},
			loading: false,
			hasDiscount: false,
			discounts: [
				{
					licenceCount: 0,
					percentage: 0
				}
			],
			name: '',
			flatPrice: 0,
			flatYearlyPrice: 0,
			id: null,
			modalTitle: 'Kreiranje cenovnika',
			modalConfirm: 'Kreiraj cenovnik',
			types: [
				{
					label: 'Produkcione kartice',
					value: 'PRODUCTION'
				},
				{
					label: 'Print kartice',
					value: 'PRINT'
				},
				{
					label: 'Produkcione IPS kartice',
					value: 'PRODUCTION_IPS'
				},
				{
					label: 'Produkcione EFT kartice',
					value: 'PRODUCTION_EFT'
				}
			],
			type: null
		};
	},
	props: {
		row: {
			type: Object,
			required: false
		}
	},
	methods: {
		addDiscount() {
			this.discounts.push({
				licenceCount: 0,
				percentage: 0
			});
		},
		isNumber(evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		removeDiscount() {
			this.discounts.pop();
		},

		async savePricelist() {
			const data = {};

			if (this.id) data.id = this.id;

			data.name = this.name;
			data.flatPrice = parseFloat(this.flatPrice);
			if (this.hasDiscount) {
				data.priceModel = 'VARIABLE';
			} else {
				data.priceModel = 'FIXED';
			}
			data.type = this.type.value;

			if (data.type != 'PRINT') {
				data.flatYearlyPrice = parseFloat(this.flatYearlyPrice);
			}

			//compute and adjust discounts for the server
			const discounts = [];
			for (const discount of this.discounts) {
				const licenceCount = discount.licenceCount;
				const percentage = (parseFloat(discount.percentage) / 100).toFixed(2);
				discounts.push(`${licenceCount}-${percentage}`);
			}

			const discountString = discounts.join(',');
			data.discountRanges = discountString;

			let text = '';
			if (this.id) {
				text = 'izmenjen.';
			} else {
				text = 'kreiran.';
			}

			this.loading = true;
			try {
				const response = await axios.post('/finances/pricelist', data);
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Cenovnik je uspešno ' + text,
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				console.error(error);
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom obrade cenovnika.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	},

	computed: {
		inputIsValid() {
			const baseInputIsValid = this.name.length > 0 && this.flatPrice > 0 && this.type;
			const yearlyValid = (this.type && this.type.value == 'PRINT') || this.flatYearlyPrice > 0;
			if (this.hasDiscount) {
				const discountsValid = this.discounts.every(discount => {
					return discount.licenceCount > 0 && discount.percentage > 0 && discount.percentage <= 100;
				});

				for (let i = 1; i < this.discounts.length; i++) {
					if (
						parseInt(this.discounts[i].licenceCount) <= parseInt(this.discounts[i - 1].licenceCount) ||
						parseInt(this.discounts[i].percentage) <= parseInt(this.discounts[i - 1].percentage)
					) {
						return false;
					}
				}

				return baseInputIsValid && discountsValid && yearlyValid;
			} else {
				return baseInputIsValid && yearlyValid;
			}
		}
	},

	created() {
		if (this.row) {
			this.name = this.row.name;
			this.flatPrice = parseFloat(this.row.flatPrice).toFixed(2);
			this.flatYearlyPrice = parseFloat(this.row.flatYearlyPrice).toFixed(2);
			this.id = this.row.id;
			this.modalTitle = 'Izmena cenovnika';
			this.modalConfirm = 'Izmeni cenovnik';
			this.type = this.types.find(type => type.value === this.row.type);
			this.hasDiscount =
				this.row.priceModel == 'VARIABLE' && this.row.discountRanges && this.row.discountRanges.length > 0
					? true
					: false;
		}

		if (this.hasDiscount) {
			const discounts = this.row.discountRanges.split(',');
			this.discounts = [];
			for (const discount of discounts) {
				const discountData = discount.split('-');
				this.discounts.push({
					licenceCount: discountData[0],
					percentage: (discountData[1] * 100).toFixed(0)
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.input-container input[type='text'] {
	width: 100%;
}
.input-container h2 {
	margin-top: 12px;
}

.discounts {
	h2 {
		margin-top: unset !important;
	}
}

.discounts-input {
	width: 64px !important;
	margin: 0 4px !important;
	border-color: rgb(168, 168, 168) !important;
}
</style>
