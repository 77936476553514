var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal"},[_c('div',[_c('modal',{attrs:{"adaptive":true,"height":"auto","width":640,"name":"e-invoice-statuses"}},[_c('div',{staticClass:"flex flex-row justify-end m-2 absolute right-1 top-1"},[_c('button',{staticClass:"bg-secondary hover:bg-secondary-light",staticStyle:{"width":"34px","height":"34px","line-height":"0"},on:{"click":function($event){return _vm.$modal.hide('e-invoice-statuses')}}},[_c('i',{staticClass:"fas fa-times"})])]),_c('modal-invoice-e-faktura-status',{attrs:{"invoiceId":_vm.invoice.id},on:{"close":function($event){return _vm.closeModal('e-invoice-statuses')}}})],1)],1),_c('h1',[_vm._v("Sistem za e-fakture")]),_c('hr',{staticClass:"mb-4"}),(_vm.loading)?_c('div',{staticClass:"mt-6 mb-6"},[_c('spinner-component',{attrs:{"size":64}})],1):_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"left-column"},[_c('h2',[_vm._v("Broj računa")]),_c('h1',[_vm._v(_vm._s(_vm.invoice.number))])]),_c('div',{staticClass:"left-column"},[_c('h2',[_vm._v("Obračunski period")]),_c('h1',[_vm._v(_vm._s(_vm.invoice.accountingPeriodFormattedShort))])]),_c('div',{staticClass:"right-column"},[_c('h2',[_vm._v("Ukupno (sa PDV)")]),_c('h1',[_vm._v(_vm._s(_vm.invoice.totalWithVatFormatted))])])]),_c('h2',[_vm._v("Obveznik")]),_c('h1',[_vm._v(_vm._s(_vm.invoice.obligorFormatted))]),_c('div',{staticClass:"flex flex-row"}),_c('hr',{staticClass:"mb-2 mt-2"}),_c('div',[_c('h2',[_vm._v("Status eFakture")]),_c('h1',{staticClass:"status mt-1",class:{
					'red-border':
						_vm.eFakturaData.status == 'Send failed' ||
						_vm.eFakturaData.status == 'Rejected' ||
						_vm.eFakturaData.status == 'Mistake',
					'green-border': _vm.eFakturaData.status == 'Approved',
					'blue-border': _vm.eFakturaData.status == 'Sent successfully',
					'gray-border':
						_vm.eFakturaData.status == 'Not sent' ||
						!_vm.eFakturaData.status ||
						_vm.eFakturaData.status == 'Cancelled' ||
						_vm.eFakturaData.status == 'Storno'
				}},[_vm._v(" "+_vm._s(_vm.eFakturaData.statusTranslated)+" ")]),(_vm.eFakturaData.status == 'Sent successfully')?_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"left-column"},[_c('h2',[_vm._v("Jedinstveni ID računa")]),_c('h1',[_vm._v(_vm._s(_vm.eFakturaData.invoiceId))])]),_c('div',{staticClass:"left-column"},[_c('h2',[_vm._v("Jedinstveni ID zahteva")]),_c('h1',[_vm._v(_vm._s(_vm.eFakturaData.uniqueRequestId))])]),_c('div',{staticClass:"left-column"},[_c('h2',[_vm._v("ID \"sales\" računa")]),_c('h1',[_vm._v(_vm._s(_vm.eFakturaData.salesInvoiceId))])]),_c('div',{staticClass:"right-column"},[_c('h2',[_vm._v("ID \"purchase\" računa")]),_c('h1',[_vm._v(_vm._s(_vm.eFakturaData.purchaseInvoiceId))])])]),_c('div',{staticClass:"flex flex-row"})]):_vm._e()]),_c('hr',{staticClass:"mb-2 mt-2"}),_c('div',{staticClass:"flex flex-row justify-end"},[(
					!_vm.eFakturaData.status ||
					_vm.eFakturaData.status == 'Not sent' ||
					_vm.eFakturaData.status == 'Send failed' ||
					_vm.eFakturaData.status == 'Cancelled'
				)?_c('button',{staticClass:"w-4/12",on:{"click":_vm.sendToSystem}},[_vm._v(" Pošalji na sistem e-faktura ")]):_vm._e(),(_vm.eFakturaData.status == 'Sent successfully' || _vm.eFakturaData.status == 'Rejected')?_c('button',{staticClass:"w-4/12 bg-red-600 hover:bg-red-800",on:{"click":_vm.cancelInvoice}},[_vm._v(" Storniraj e-fakturu ")]):_vm._e(),(_vm.eFakturaData.status && _vm.eFakturaData.status != 'Not sent' && _vm.eFakturaData.status != 'Send failed')?_c('button',{staticClass:"ml-2",on:{"click":_vm.checkStatus}},[_vm._v(" Proveri statuse na sistemu eFaktura ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }