export default {
	state: {
		user: null
	},
	mutations: {
		setUser(state, payload) {
			state.user = payload;
		},

		unsetUser(state) {
			state.user = null;
		}
	},
	getters: {
		getUser(state) {
			return state.user;
		}
	}
};
