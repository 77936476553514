import store from '@/store/store';
import { Settings } from 'luxon';
import moment from 'moment';
import 'tailwindcss/tailwind.css';
import money from 'v-money';
import Vue from 'vue';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import VModal from 'vue-js-modal';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VueSimpleAlert from 'vue-simple-alert';
import Spinner from 'vue-simple-spinner';
import Toasted from 'vue-toasted';
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import App from './App.vue';
import './css/all.min.css';
import './css/source-font.css';
import './index.css';
import router from './router';
Vue.component('vuetable-field-checkbox', VuetableFieldCheckbox);
Vue.component('v-select', vSelect);

Vue.config.productionTip = false;
Vue.use(VModal);
Vue.use(VueSimpleAlert);
Vue.use(Datetime);
Settings.defaultLocale = 'rs';
Vue.component('vue-datetime', Datetime);
Vue.component('spinner-component', Spinner);
Vue.use(money, { precision: 2 });

Vue.filter('formatDate', function (value) {
	if (!value) return '';
	value = moment(value, moment.ISO_8601).format('DD.MM.YYYY - HH:mm:ss');
	return value;
});
Vue.filter('formatDateNoTime', function (value) {
	if (!value) return '';
	value = moment(value, moment.ISO_8601).format('DD.MM.YYYY');
	return value;
});

Vue.filter('formatRecoveryInvoiceStatus', function (value) {
	if (!value) return '';
	if (value == 'Initial') return 'Inicijalan';
	if (value == 'Sent') return 'Poslat na TaxCore';
	if (value == 'Refunded') return 'Refundiran na TaxCore';
	if (value == 'Deleted') return 'Obrisan';
});

Vue.filter('formatRecoveryInvoiceMonitorCommand', function (value) {
	if (!value) return 'Nije data komanda';
	if (value == 'send') return 'Pošalji na TaxCore';
	if (value == 'refund') return 'Refundiraj na TaxCore';
	if (value == 'delete') return 'Obriši račun';
});

Vue.filter('formatRecoveryInvoiceCommandStatus', function (value) {
	if (!value) return '/';
	if (value == 'Scheduled') return 'Zakazana';
	if (value == 'In progress') return 'U toku';
});

Vue.use(Toasted, {
	position: 'bottom-right',
	iconPack: 'fontawesome',
	icon: 'fa-bell',
	theme: 'bubble',
	className: ['toast-bubble'],
	singleton: true,
	closeOnSwipe: true,
	action: [
		{
			text: 'Zatvori',
			onClick: (e, toastObject) => {
				toastObject.goAway(0);
			}
		}
	]
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
