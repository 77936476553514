<template>
	<div class="modal">
		<h1>Postavljanje komandi za inicijalna podešavanja</h1>
		<hr class="mb-4" />
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-else>
			<div v-if="user.role == 'SUPERADMIN'" class="mb-2">
				<h2>PIB</h2>
				<input
					type="text"
					id="pib"
					v-model="pib"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="w-64">
				<h2>Tip</h2>
				<v-select
					placeholder="Izaberite tip"
					class="select-style mb-1"
					v-model="type"
					:options="[
						{ label: 'Sandbox', value: 'SANDBOX' },
						{ label: 'Produkcione', value: 'PRODUCTION' }
					]"
				></v-select>
			</div>

			<div v-if="lookingForExisting" class="mb-6 mt-6 flex flex-col justify-between items-center">
				<spinner-component :size="32" />
			</div>
			<div v-if="fileAlreadyExists && !lookingForExisting" class="flex flex-row justify-between items-center">
				<div class="px-4 py-1 w-full bg-primary bg-opacity-10 rounded-md my-2 mr-4">
					<label class="text-xs text-primary"
						><b>NAPOMENA</b>: Već je postavljen .zip fajl komandi za inicijalna podešavanja. Postavljanje novog fajla će
						izbrisati stari.</label
					>
				</div>
				<div>
					<button class="whitespace-nowrap" @click="downloadExistingFile()" :disabled="downloadInProgress || loading">
						Preuzmi postojeći fajl
					</button>
				</div>
			</div>

			<h2 class="mt-6">{{ uploadHeader }}</h2>
			<label
				class="file-upload-label mt-1"
				for="taxCoreFile"
				v-if="!file"
				@dragover.prevent
				@dragleave.prevent
				@drop="onDrop"
				>Izaberi ili prevuci .zip fajl</label
			>
			<div v-else class="flex flex-row items-center">
				<h1>{{ fileName }}</h1>
				<button class="ml-4" style="height: 28px; width: 28px; padding: 0" @click="removeFile()">
					<i class="fas fa-times"></i>
				</button>
			</div>

			<input type="file" name="taxCoreFile" id="taxCoreFile" @change="onFileChange" style="display: none" />
		</div>
		<div class="flex flex-row mt-3" v-if="!loading">
			<button :disabled="loading" class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
			<button
				class="ml-2 flex-grow-2"
				:disabled="
					!this.type ||
					!this.type.value ||
					!file ||
					(user.role == 'SUPERADMIN' && (pib == '' || pib.match(/^[0-9,A-Z,a-z]{9,13}$/) == null))
				"
				@click="uploadFile"
			>
				Postavi fajl
			</button>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';
export default {
	async created() {},

	data() {
		return {
			file: null,
			loading: false,
			pib: '',
			fileAlreadyExists: false,
			lookingForExisting: false,
			downloadInProgress: false,
			type: null
		};
	},
	methods: {
		onDrop(e) {
			e.stopPropagation();
			e.preventDefault();
			var file = e.dataTransfer.files[0];

			this.setFile(file);
		},

		async findExisting(pib = this.user.pib) {
			this.lookingForExisting = true;
			const response = await axios.get(`/client/init-commands-file-exists/${this.type.value}/${pib}`);
			if (response) {
				this.fileAlreadyExists = response.data;
			}
			this.lookingForExisting = false;
		},

		onFileChange(e) {
			const file = e.target.files[0];

			this.setFile(file);
		},

		async downloadExistingFile() {
			this.downloadInProgress = true;
			let pib;
			if (this.user.role == 'SUPERADMIN') {
				pib = this.pib;
			} else {
				pib = this.user.pib;
			}
			axios({
				url: `client/init-commands-file-by-pib/${this.type.value}/${pib}`,
				method: 'GET',
				responseType: 'base64'
			})
				.then(response => {
					const b64toBlob = (base64, type = 'application/octet-stream') =>
						fetch(`data:${type};base64,${base64}`).then(res => res.blob());

					b64toBlob(response.data).then(blob => {
						const url = window.URL.createObjectURL(blob);
						const link = document.createElement('a');
						link.href = url;
						const filename = 'default_commands.zip';
						link.setAttribute('download', `${filename}`);
						document.body.appendChild(link);
						link.click();
					});
				})
				.catch(error => {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom skidanja fajla.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
					console.log(error);
				})
				.finally(() => {
					this.downloadInProgress = false;
				});
		},

		setFile(file) {
			if (this.validateInput(file.name)) {
				this.file = file;
			} else {
				this.$fire({
					title: 'Neispravan fajl',
					text: 'Izabrani fajl je neispravan. Molimo izaberite validan .zip fajl.',
					type: 'error',
					showConfirmButton: true
				});
			}
		},

		validateInput(name) {
			let allowedExtensions = ['zip'];
			let extension = name.substring(name.lastIndexOf('.') + 1, name.length);
			return allowedExtensions.indexOf(extension) > -1;
		},

		removeFile() {
			this.file = null;
		},

		//get base64 from file
		getBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
					resolve(base64String);
				};
				reader.onerror = function (error) {
					reject(error);
				};
			});
		},

		async uploadFile() {
			try {
				this.loading = true;
				const fileBase64 = await this.getBase64(this.file);
				const data = {
					content: fileBase64,
					type: this.type.value
				};
				if (this.user.role == 'SUPERADMIN') {
					data.pib = this.pib;
				}
				await axios.post(`/client/init-commands-file`, data);
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Fajl je uspešno postavljen.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				this.$emit('close');
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom postavke fajla.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	},

	computed: {
		fileName() {
			return this.file ? this.file.name : '';
		},

		uploadHeader() {
			return this.fileAlreadyExists ? 'Postavi novi fajl' : 'Postavi fajl';
		},
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id,
					pib: state.user.user.pib
				};
			}
		})
	},

	watch: {
		type() {
			if (this.type && this.type.value) {
				if (this.user.role != 'SUPERADMIN') {
					this.fileAlreadyExists = false;
					this.findExisting();
				} else if (this.pib.length == 9) {
					this.fileAlreadyExists = false;
					this.findExisting(this.pib);
				}
			} else {
				this.fileAlreadyExists = false;
			}
		},

		pib() {
			if (this.pib.length == 9 && this.type && this.type.value) {
				this.fileAlreadyExists = false;
				this.findExisting(this.pib);
			} else {
				this.fileAlreadyExists = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.file-upload-label {
	display: block;
	margin-bottom: 16px;
	cursor: pointer;
	border: 1px solid #ccc;
	padding: 16px 12px;
	border-radius: 4px;
	transition: all 0.2s ease-in-out;
}
</style>
