<template>
	<div class="modal modal-add-client">
		<h1 class="text-2xl">Dodaj novog distributera</h1>
		<hr />
		<div class="modal-content text-lg">
			<div class="form-item">
				<div class="flex">
					<h2 for="name">Naziv distributera</h2>
					<span class="ml-1 font-semibold text-red-600">*</span>
				</div>
				<input
					type="text"
					id="name"
					v-model="name"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<div class="flex">
					<h2 for="pib">PIB</h2>

					<span class="ml-1 font-semibold text-red-600">*</span>
				</div>
				<p class="text-sm text-gray-200 mt-0">Poreski identifikacioni broj, primer: 123456789</p>
				<input
					type="text"
					id="pib"
					v-model="pib"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>

			<div class="form-item">
				<div class="flex">
					<h2 for="email">E-mail</h2>
					<span class="ml-1 font-semibold text-red-600">*</span>
				</div>

				<input
					type="text"
					id="email"
					v-model="email"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<h2 for="phone">Kontakt telefon</h2>

				<vue-phone-number-input
					id="phone"
					color="#005ca9"
					valid-color="#00796b"
					default-country-code="RS"
					v-model="phoneRaw"
					:translations="{
						countrySelectorLabel: 'Država',
						countrySelectorError: 'Greska',
						phoneNumberLabel: 'Broj telefona',
						example: 'Primer:'
					}"
					:no-example="true"
					:preferred-countries="['RS', 'ME', 'BA', 'HR', 'MK', 'HU', 'RO', 'BG']"
					:show-code-on-list="true"
					size="lg"
					:countries-height="25"
					:no-country-selector="false"
					@update="updatePhoneNumber($event)"
				/>
			</div>

			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button
					:disabled="
						loading ||
						name == '' ||
						pib == '' ||
						pib.match(/^[0-9]{9}$/) == null ||
						!this.email.match(this.emailRegex) ||
						(this.phoneRaw && !this.phone.isValid)
					"
					class="flex-grow ml-2"
					@click="addDistributor()"
				>
					Dodaj
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
	components: {
		VuePhoneNumberInput
	},

	data() {
		return {
			name: '',
			pib: '',
			loading: false,
			email: '',
			phoneRaw: null,
			phone: {
				isValid: null,
				number: null
			},
			emailRegex:
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		};
	},

	methods: {
		updatePhoneNumber($event) {
			this.phone = {
				isValid: $event.isValid,
				number: $event.formattedNumber
			};
		},

		async addDistributor() {
			this.loading = true;
			try {
				const requestBody = {
					name: this.name,
					pib: this.pib,
					email: this.email
				};

				if (this.phoneRaw && this.phone.isValid) {
					requestBody.phone = this.phone.number;
				}

				await axios.post('user/distributor', requestBody);
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					html: `Distributer je uspešno dodat.<br>Kredencijali za prijavu su poslati na <b>${requestBody.email}</b>`,
					type: 'success',
					// timer: 2500,
					showConfirmButton: true
				});
				this.$emit('refresh');
			} catch (error) {
				this.$emit('close');
				if (error.response.status == 409) {
					this.$fire({
						title: 'Greška',
						text: 'Korisnik sa navedenim PIB-om ili email-om već postoji.',
						type: 'error',
						showConfirmButton: true
					});
				} else {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom dodavanja distributera.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
				}
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style></style>
