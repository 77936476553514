<template>
	<div class="modal">
		<h1 class="text-2xl">Izuzimanje LPFR-a iz fakturisanja</h1>
		<hr />
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-else>
			<div class="modal-content text-lg">
				<div class="form-item">
					<h2>JID</h2>
					<input
						type="text"
						id="jid"
						v-model="jid"
						class="focus:ring-primary focus:border-primary font-semibold text-lg"
					/>
				</div>
			</div>
			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button :disabled="jid.length != 8" class="flex-grow ml-2" @click="addIgnoredLpfr()">
					Isključi iz fakturisanja
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	data() {
		return {
			loading: false,
			jid: ''
		};
	},
	methods: {
		async addIgnoredLpfr() {
			this.loading = true;
			try {
				await axios.post(`finances/lpfr/${this.jid}/ignore/${true}`);
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'LPFR je uspešno isključen iz daljeg fakturisanja.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (e) {
				console.error(e);
				if (e.response.status == 400) {
					this.$fire({
						title: 'Greška',
						text: 'Nije pronađen LPFR sa datim JID-om.',
						type: 'error',
						showConfirmButton: true
					});
				} else {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom isključenja LPFR-a iz fakturisanja',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
				}
			}
			this.loading = false;
		}
	},
	watch: {
		jid(val) {
			this.jid = val.toUpperCase();
		}
	}
};
</script>

<style></style>
