<template>
	<div class="wrapper">
		<div class="chat-admin">
			Razgovarate sa: <span class="name">{{ currentAdmin }}</span>
			<div class="indicator green" v-if="isAdminOnline && currentAdmin != '/'"></div>
			<div class="indicator red" v-if="!isAdminOnline && currentAdmin != '/'"></div>
			<div class="online-admins-count">
				(Trenutno je online {{ onlineAdmins.length }} administrator<span v-if="onlineAdmins.length > 1">a</span>)
			</div>
		</div>
		<div class="chat-window" id="chatWindow">
			<div>
				<infinite-loading
					direction="top"
					@infinite="infiniteHandler"
					:identifier="selectedChat ? selectedChat.id + 'chat' : 'chat'"
				></infinite-loading>
				<div
					v-for="chatMessage in chatMessages"
					:key="chatMessage.id"
					class="chat-window__item mb-2 p-2 rounded-xl mx-2"
					:class="{
						hidden: chatMessage.adminName && chatMessage.adminName == 'chatbot',
						'chat-window__my-message': chatMessage.user.role != 'SUPERADMIN',
						'text-right': chatMessage.user.role != 'SUPERADMIN',
						'text-left': chatMessage.user.role == 'SUPERADMIN',
						'chat-list__item-user': chatMessage.user.role == 'SUPERADMIN'
					}"
				>
					<div>
						<div
							class="text-lg break-words"
							style="white-space: pre-wrap"
							v-html="chatMessage.message"
							v-linkified
						></div>
						<div
							class="medias"
							:class="{
								'justify-end': chatMessage.user.role != 'SUPERADMIN',
								'justify-start': chatMessage.user.role == 'SUPERADMIN'
							}"
						>
							<div v-for="media in chatMessage.medias" :key="media.id">
								<div v-if="media.type.includes('image')">
									<img
										class="media-image cursor-pointer my-2"
										:src="media.imageUrl"
										:alt="media.name"
										@click="downloadMedia(media.path)"
									/>
								</div>
								<div
									v-else
									@click="downloadMedia(media.path)"
									class="media-file cursor-pointer px-2 py-1 my-2 rounded-2xl ml-2 text-left"
								>
									{{ media.name }}
								</div>
							</div>
						</div>
						<div class="text-sm mt-2">
							{{ chatMessage.adminName ? chatMessage.adminName : '' }}
						</div>
						<div class="text-sm">
							{{ chatMessage.timestamp | formatDate }}
						</div>
					</div>
				</div>
			</div>
			<div class="create-message-wrapper">
				<div class="create-message-files">
					<div class="create-message-file select-none rounded-xl" v-for="(file, index) in selectedFiles" :key="index">
						{{ file.name }}
						<i class="fas fa-trash" @click="deleteFile(index)"></i>
					</div>
				</div>
				<div class="create-message">
					<input style="display: none" type="file" @change="onFileSelected" ref="fileInput" multiple />
					<button class="btn-add-file" title="Dodaj prilog" @click="addFile()" style="width: 42px">
						<i class="fas fa-paperclip"></i>
					</button>
					<textarea
						class="focus:ring-primary focus:border-primary"
						v-model="chatMessageText"
						placeholder="Unesite poruku..."
						v-on:keyup.enter.exact="sendMessage"
						@input="autoresizeTextarea"
						:style="{ height: messageTextAreaHeight + 'px' }"
						ref="textInput"
						rows="1"
						@paste="pasteFunction($event)"
					/>
					<button class="btn-send-message" title="Pošali" @click="sendMessage()" style="width: 42px">
						<i class="fas fa-paper-plane"></i>
					</button>
					<div v-if="hasNewMessage" class="unread-alert"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import linkify from 'vue-linkify';

const LIMIT = 10;

export default {
	components: { InfiniteLoading },
	props: {},
	directives: {
		linkified: linkify
	},
	computed: {
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		})
	},
	data() {
		return {
			chatList: [],
			chatMessages: [],
			chatMessageText: '',
			messageTextAreaHeight: 40,
			selectedChat: null,
			refreshInterval: 5000,
			intervalId: null,
			selectedFiles: [],
			page: 1,
			totalMessages: 0,
			lastMessageId: null,
			hasNewMessage: false,
			currentAdmin: '/',
			onlineAdmins: [],
			isAdminOnline: false
		};
	},
	methods: {
		autoresizeTextarea(event) {
			event.target.style.height = 'auto';
			event.target.style.height = event.target.scrollHeight + 'px';
			if (event.target.scrollHeight > 110) {
				event.target.style.height = '110px';
			}
		},
		setInterval() {
			this.intervalId = setInterval(async () => {
				if (this.$router.history.current.name == 'home') {
					if (!this.selectedChat) {
						await this.getChatList();
					} else {
						await this.getChatList(false);
						await this.checkForNewMessages(this.selectedChat);
					}
				}
			}, this.refreshInterval);
		},
		async resetIntervals() {
			await this.getChatList();
			this.setInterval();
		},
		async removeInterval() {
			this.selectedChat = null;
			if (this.intervalId) {
				clearInterval(this.intervalId);
				this.intervalId = null;
			}
		},
		async getChatList(shouldGetChat = true) {
			try {
				const response = await axios.get('chat');
				this.chatList = response.data;
				this.chatList.sort((a, b) => {
					return Date(b.lastMessage.timestamp) - Date(a.lastMessage.timestamp);
				});
				if (shouldGetChat) {
					if (this.chatList && this.chatList.length > 0) {
						this.page = 1;
						this.totalMessages = 0;
						this.chatMessages = [];
						this.selectedChat = this.chatList[0];
						this.setChatAdmin();
						this.getChatMessages(this.chatList[0]);
						setTimeout(() => {
							var container = this.$el.querySelector('#chatWindow');
							container.scrollTop = container.scrollHeight;
						}, 100);
					}
				} else {
					if (this.chatList && this.chatList.length > 0) {
						this.selectedChat = this.chatList[0];
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		async getChatMessages(chat) {
			try {
				const response = await axios.get(`chat/${chat.id}`, {
					params: {
						page: this.page,
						limit: LIMIT
					}
				});
				this.totalMessages = response.data.total;
				let newMessages = response.data.messages;
				newMessages = await this.fillChatMedias(newMessages);
				this.chatMessages.unshift(...newMessages);
				this.chatMessages = this.chatMessages
					.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)
					.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
			} catch (error) {
				console.log(error);
			}
		},
		openMediaInNewTab(path) {
			window.open(path);
		},
		addFile() {
			this.$refs.fileInput.click();
		},
		onFileSelected(event) {
			// validate file size
			for (let file of event.target.files) {
				if (file.size > 5122059) {
					this.showFailModal('Fajl ne može biti veći od 5MB');
					return;
				}
			}
			for (const file of event.target.files) {
				this.selectedFiles.push(file);
			}
		},
		async sendMessage() {
			if ((!this.chatMessageText || this.chatMessageText.trim() == '') && this.selectedFiles.length == 0) {
				return;
			}
			let formData = new FormData();
			for (let i = 0; i < this.selectedFiles.length; i++) {
				formData.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name);
			}

			formData.append('message', this.chatMessageText.trim());
			formData.append('userId', this.user.id);

			try {
				await axios.post('chat', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});
				this.chatMessageText = '';
				this.selectedFiles = [];
				this.messageTextAreaHeight = 40;
				this.$refs.textInput.style.height = '40px';
				if (this.selectedChat) {
					this.getChatAllMessages(this.selectedChat);
				} else {
					await this.getChatList();
				}
			} catch (error) {
				// TODO: Do something
			}
		},
		deleteFile(index) {
			this.selectedFiles.splice(index, 1);
		},
		pasteFunction(pasteEvent) {
			if (pasteEvent.clipboardData == false) {
				return;
			}

			var items = pasteEvent.clipboardData.items;

			if (items == undefined) {
				return;
			}
			for (var i = 0; i < items.length; i++) {
				if (items[i].type.indexOf('image') == -1) continue;
				var blob = items[i].getAsFile();
				this.selectedFiles.push(blob);
			}
		},
		async infiniteHandler($state) {
			if (!this.selectedChat || (this.totalMessages == 0 && this.chatMessages.length == 0)) {
				$state.reset();
				return;
			}

			if (this.totalMessages > this.chatMessages.length) {
				this.page = this.page + 1;
				await this.getChatMessages(this.selectedChat);
				$state.loaded();
			} else {
				$state.complete();
			}

			// $state.loaded(); ili $state.complete() ako nema podataka ILI $state.reset() ako se 1. put upali pre podataka da se resetuje kako bi radio
		},
		async checkForNewMessages(chat) {
			try {
				this.selectedChat = chat;
				this.setChatAdmin();
				const response = await axios.get(`chat/${chat.id}`, {
					params: {
						page: 1,
						limit: 1
					}
				});
				if (response.data.messages) {
					const lastMessage = response.data.messages[response.data.messages.length - 1];
					const newLastMessageId = lastMessage.id;
					const totalDifference = response.data.total - this.totalMessages;
					if (newLastMessageId != this.lastMessageId) {
						if (this.lastMessageId && lastMessage.user.id != this.user.id) {
							this.hasNewMessage = true;
							setTimeout(() => {
								this.hasNewMessage = false;
							}, 3000);
							if (totalDifference > 0) {
								this.getChatAllMessages(this.selectedChat, false, totalDifference);
							} else {
								this.getChatAllMessages(this.selectedChat, false);
							}
						}
						this.lastMessageId = newLastMessageId;
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		async getChatAllMessages(chat, shouldScrollToBottom = true, numberToGetOverLimit = 1) {
			try {
				this.selectedChat = chat;
				const response = await axios.get(`chat/${chat.id}`, {
					params: {
						page: 1,
						limit: this.page * LIMIT + numberToGetOverLimit
					}
				});
				let messages = response.data.messages;
				messages = await this.fillChatMedias(response.data.messages);
				this.chatMessages = messages.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
				this.totalMessages = response.data.total;
				if (shouldScrollToBottom) {
					setTimeout(() => {
						var container = this.$el.querySelector('#chatWindow');
						container.scrollTop = container.scrollHeight;
					}, 100);
				}
			} catch (error) {
				console.log(error);
			}
		},
		async setChatAdmin() {
			if (this.selectedChat && this.selectedChat.admin?.id) {
				this.currentAdmin = this.selectedChat.admin.name;
			} else {
				this.currentAdmin = '/';
			}

			const response = await axios.get('chat/online-admins');
			this.onlineAdmins = response.data;
			if (this.currentAdmin != '/') {
				if (this.onlineAdmins && this.onlineAdmins.length > 0) {
					this.isAdminOnline = this.onlineAdmins.findIndex(i => i.name == this.currentAdmin) != -1;
				} else {
					this.isAdminOnline = false;
				}
			}
		},
		async downloadMedia(path) {
			const pathArr = path.split('-');
			let fileName = path;
			if (pathArr.length >= 5) {
				pathArr.splice(0, 5);
				fileName = pathArr.join('');
			}

			const file = await axios.get(`chat/download/${path}`, {
				responseType: 'blob'
			});
			var binaryData = [];
			binaryData.push(file.data);
			const url = window.URL.createObjectURL(new Blob(binaryData, { type: file.headers['content-type'] }));
			const downloadLink = document.createElement('a');
			downloadLink.href = url;
			downloadLink.download = fileName;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			downloadLink.remove();
		},
		async getImageBase64(path) {
			const file = await axios.get(`chat/download/${path}`, {
				responseType: 'blob'
			});
			const base64 = await this.blobToBase64(file.data);
			return base64;
		},
		blobToBase64(blob) {
			return new Promise((resolve, _) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.readAsDataURL(blob);
			});
		},
		async fillChatMedias(chatMessages) {
			for (const message of chatMessages) {
				for (const media of message.medias) {
					if (media.type.includes('image')) {
						media.imageUrl = await this.getImageBase64(media.path);
					}
				}
			}
			return chatMessages;
		}
	}
};
</script>
<style lang="scss" scoped>
.wrapper {
	height: calc(100vh - 120px - 12px - 50px - 32px - 32px - 12px);
	display: flex;
	flex-direction: column;
}
.chat-window {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	overflow: auto;
	position: relative;
	.chat-window__item {
		width: fit-content;
		max-width: 50%;
		background: linear-gradient(97deg, rgba(10, 64, 146, 1) 0%, rgba(0, 92, 169, 1) 41%, rgba(0, 145, 204, 1) 100%);
		color: white;
	}
	.chat-window__my-message {
		margin-left: auto;
	}
	.media-image {
		max-height: 150px;
		object-fit: contain;
	}
	.media-file {
		background: white;
		color: black;
		font-weight: 600;
	}
}
.text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.create-message-wrapper {
	position: sticky;
	bottom: 0;
	width: 100%;
	background: white;
}
.create-message {
	display: flex;
	align-items: center;
	position: relative;

	textarea {
		margin: 0 12px;
		flex: 1;
		resize: none;
		overflow: auto;
	}
	.btn-send-message {
		margin-right: 12px;
	}
	.btn-add-file {
		margin-left: 12px;
	}
}
.create-message-files {
	display: flex;
	flex-wrap: wrap;
	margin-left: 12px;
	.create-message-file {
		display: flex;
		align-items: center;
		margin-right: 12px;
		padding: 4px 12px;
		background: linear-gradient(97deg, rgba(10, 64, 146, 1) 0%, rgba(0, 92, 169, 1) 41%, rgba(0, 145, 204, 1) 100%);
		color: white;
		margin-bottom: 3px;
		i {
			margin-left: 8px;
			cursor: pointer;
		}
	}
}
.unread-alert {
	border-radius: 50%;
	display: inline-block;
	width: 10px;
	height: 10px;
	background: red;
	position: absolute;
	top: -15px;
	right: 14px;
}
.chat-admin {
	text-align: left;
	margin-left: 8px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	.indicator {
		height: 8px;
		width: 8px;
		border-radius: 50%;
		margin-left: 8px;
	}
	.green {
		background: green;
	}
	.red {
		background: red;
	}
	.online-admins-count {
		margin-left: 16px;
	}
	.name {
		font-weight: bold;
		margin-left: 4px;
	}
}
</style>
