<template>
	<div class="modal modal-add-client">
		<h1 class="text-2xl">Poništavanje licence</h1>
		<hr />
		<div class="modal-content text-lg">
			<h2>Naziv klijenta</h2>
			<h1>{{ lpfr.client.name }}</h1>
			<div class="flex">
				<div class="mr-12">
					<h2>PIB</h2>
					<h1>{{ lpfr.client.pib }}</h1>
				</div>
				<div>
					<h2>JID</h2>
					<h1>
						{{ lpfr.jid }}
						{{ lpfr.jidNickname ? ` (${lpfr.jidNickname})` : '' }}
					</h1>
				</div>
			</div>

			<h2>Licenca važi do (zaključno sa)</h2>
			<h1>{{ lpfr.expirationDate | formatDateNoTime }}</h1>

			<h2>Poništi licencu:</h2>
			<div class="flex flex-row mb-4">
				<div class="mr-12 flex items-center" v-if="!alreadyFinalMonth">
					<input
						:disabled="alreadyFinalMonth"
						type="radio"
						v-model="untilEndOfMonth"
						:value="true"
						id="until-end-of-month-radio"
					/>
					<label class="ml-2" for="until-end-of-month-radio">Na kraju meseca</label>
				</div>
				<div class="flex items-center">
					<input type="radio" v-model="untilEndOfMonth" :value="false" id="not-until-end-of-month-radio" />
					<label class="ml-2" for="not-until-end-of-month-radio">Odmah</label>
				</div>
			</div>

			<div class="flex items-center p-2 bg-primary bg-opacity-10 rounded-md">
				<input type="checkbox" id="checkbox" v-model="confirm" class="mr-2" /><label class="text-xs" for="checkbox"
					>Saglasan sam da se izvrši poništavanje licence za izabrani JID. Nakon poništavanja licenca ce biti van
					funkcije i LPFR neće biti u operativnom stanju.</label
				>
			</div>
			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button :disabled="!confirm || loading" class="flex-grow ml-2" @click="cancelLicence">Poništi licencu</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import { mapState } from 'vuex';

export default {
	data() {
		return {
			confirm: false,
			loading: false,
			lpfr: null,
			untilEndOfMonth: true,
			alreadyFinalMonth: false
		};
	},

	props: {
		row: {
			type: Object,
			required: true
		}
	},

	created() {
		this.lpfr = this.row;

		if (
			new Date(this.lpfr.expirationDate).getMonth() === new Date().getMonth() &&
			new Date(this.lpfr.expirationDate).getFullYear() === new Date().getFullYear()
		) {
			this.alreadyFinalMonth = true;
			this.untilEndOfMonth = false;
		}
	},

	computed: {
		...mapState({
			user: state => {
				return {
					id: state.user.user.id,
					name: state.user.user.name,
					phone: state.user.user.phone,
					email: state.user.user.email,
					role: state.user.user.role,
					pib: state.user.user.pib,
					alternateId: state.user.user.alternateId ? state.user.user.alternateId : null
				};
			}
		})
	},

	methods: {
		async cancelLicence() {
			this.loading = true;
			try {
				await axios.post(`licence/cancel`, {
					jid: this.lpfr.jid,
					untilEndOfMonth: this.untilEndOfMonth,
					alternateLoginId: this.user.alternateId ? this.user.alternateId : null
				});
				this.$emit('refresh');
				this.$emit('clearSelection');
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Licenca je uspešno poništena.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
			} catch (error) {
				// console.log(error);
				this.$emit('close');
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom poništavanja licence.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
h1 {
	font-size: 1.5rem !important;
	margin-bottom: 12px;
}

h2 {
	color: rgb(90, 90, 90) !important;
}
</style>
