<template>
	<div class="modal modal-list-of-logs">
		<h2 class="text-2xl">Dodatne informacije o korisniku</h2>
		<hr />
		<div class="modal-content">
			<div class="form-item">
				<h2>Naziv</h2>
				<h1 class="font-semibold">
					{{ chat.user.name }}
				</h1>

				<h2>Email</h2>
				<h1 class="font-semibold">
					<a :href="`mailto:${chat.user.email}`">{{ chat.user.email }}</a>
				</h1>

				<h2>Telefon</h2>
				<h1 class="font-semibold">
					<a :href="`tel:${chat.user.phone}`">{{ chat.user.phone || '/' }}</a>
				</h1>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: false
		};
	},

	props: {
		chat: {
			type: Object,
			required: true
		}
	},
	methods: {}
};
</script>

<style lang="scss" scoped>
.form-item {
	background-color: rgb(241, 241, 241);
	border-radius: 12px;
	padding: 12px;
	margin-right: 12px;
}

.status span {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.status span img {
	margin-bottom: 4px;
	margin-right: 6px;
}

.modal-content {
	max-height: 600px;
	overflow-y: auto;
}

h2 {
	color: rgb(90, 90, 90) !important;
}
h1 {
	font-size: 1.2rem;
}
</style>
