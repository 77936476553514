<template>
	<div class="monitor-container">
		<div v-if="loading" style="height: 200px" class="flex flex-col justify-center">
			<spinner-component :size="50" />
		</div>
		<div>
			<modal :adaptive="true" height="auto" name="user-details-from-log-files">
				<modal-user-details
					@close="closeModal('user-details-from-log-files')"
					@refresh="refreshData"
					:user="selectedUser"
				></modal-user-details>
			</modal>
		</div>
		<div>
			<modal :draggable="true" :focusTrap="true" :height="'auto'" name="list-of-log-files">
				<div class="flex justify-end m-2 absolute right-1 top-1">
					<button
						class="bg-secondary hover:bg-secondary-light"
						style="width: 34px; height: 34px; line-height: 0"
						@click="$modal.hide('list-of-log-files')"
					>
						<i class="fas fa-times" />
					</button>
				</div>
				<modal-list-of-log-files :row="selectedRow" />
			</modal>
		</div>
		<div>
			<modal :adaptive="true" height="auto" name="logfile-date">
				<modal-edit-logfile-date
					@close="closeModal('logfile-date')"
					@refresh="refreshData"
					:row="selectedRow"
				></modal-edit-logfile-date>
			</modal>
		</div>

		<div class="content" v-if="!loading">
			<div class="button-row">
				<div class="filter-container">
					<div>
						<input
							type="text"
							class="focus:ring-primary focus:border-primary"
							v-model="searchInput"
							:placeholder="searchPlaceholder"
							style="width: 420px"
							v-on:keyup.enter="getDataThroughFilter"
						/>
						<button title="Pretraži" @click="getDataThroughFilter()" style="width: 42px">
							<i class="fas fa-search" />
						</button>
						<button
							v-if="initialSearchDone"
							title="Poništi filter i osveži tabelu"
							class="cancel-filter-button"
							@click="cancelFilter()"
							style="width: 42px"
						>
							<i class="fas fa-times" />
						</button>
					</div>
				</div>
				<spinner-component v-if="loadingTableData" :size="45" />
				<div>
					<button title="Osveži tabelu" @click="getDataThroughFilter()">
						Osveži
						<i class="fas fa-redo ml-2"></i>
					</button>
				</div>
			</div>

			<div v-if="initialSearchDone">
				<vuetable
					ref="vuetable"
					:api-mode="false"
					:fields="fields"
					:css="css.table"
					:per-page="25"
					noDataTemplate="Nisu pronađeni LPFR-ovi"
					:data-manager="dataManager"
					pagination-path="pagination"
					@vuetable:pagination-data="onPaginationData"
					class="monitor-table"
				>
					<div class="slot flex flex-row" slot="distributor-slot" slot-scope="props">
						<span
							:title="props.rowData.distributor.name"
							class="text-xs text-ellipsis distributor-name"
							v-if="props.rowData.distributor"
							style="width: 100%"
						>
							<div
								@click="showUserDetails(props.rowData.distributor)"
								style="width: 100%"
								class="cursor-pointer hover:text-primary"
							>
								{{ props.rowData.distributor.name }}
							</div>
						</span>
						<span v-else class="text-xs text-center">/</span>
					</div>
					<div class="slot flex flex-row" slot="partner-slot" slot-scope="props">
						<span
							:title="props.rowData.partner.name"
							class="text-xs text-ellipsis distributor-name"
							v-if="props.rowData.partner"
							style="width: 100%"
						>
							<div
								@click="showUserDetails(props.rowData.partner)"
								style="width: 100%"
								class="cursor-pointer hover:text-primary"
							>
								{{ props.rowData.partner.name }}
							</div>
						</span>
						<span v-else class="text-xs text-center">/</span>
					</div>
					<div class="slot flex flex-row items-center" slot="client-slot" slot-scope="props" style="width: 100%">
						<div
							:title="props.rowData.client.name"
							class="font-semibold text-ellipsis partner-client-txt cursor-pointer hover:text-primary"
							@click="showUserDetails(props.rowData.client)"
							style="width: 100%"
						>
							{{ props.rowData.client.name }}
						</div>
					</div>
					<div class="slot" slot="jid-slot" slot-scope="props">
						<div style="display: flex; flex-direction: row; align-items: center; max-width: 300px">
							<span
								v-if="props.rowData.jidNickname"
								:title="props.rowData.jid + ' (' + props.rowData.jidNickname + ')'"
								>{{ props.rowData.jid + ' (' + props.rowData.jidNickname + ')' }}</span
							>
							<span v-else>{{ props.rowData.jid }}</span>
						</div>
					</div>
					<div class="slot flex flex-row justify-between items-center" slot="logfile-date-slot" slot-scope="props">
						<span v-if="props.rowData.sendLogFileForDate">{{
							props.rowData.sendLogFileForDate | formatDateNoTime
						}}</span>
						<span v-else>/</span>
						<button title="Izmeni datum log fajla za slanje" @click="changeDate(props.rowData)" class="edit-nick-btn">
							<i v-if="props.rowData.sendLogFileForDate" class="fas fa-pen"></i>
							<i v-else class="fas fa-plus"></i>
						</button>
					</div>
					<div class="slot flex flex-row justify-between items-center" slot="logfiles-slot" slot-scope="props">
						<span v-if="props.rowData.logFiles.length">Br. fajlova: {{ props.rowData.logFiles.length }}</span>
						<span v-else>/</span>
						<button
							v-if="props.rowData.logFiles.length"
							title="Lista log fajlova"
							class="edit-nick-btn"
							@click="openListOfLogFiles(props.rowData)"
						>
							<i class="fas fa-list"></i>
						</button>
					</div>
					<div class="slot flex flex-row justify-between items-center" slot="last-logfile-slot" slot-scope="props">
						<span v-if="props.rowData.logFiles.length > 0">
							<b>{{ props.rowData.logFiles[0].filename }}</b
							>&nbsp;({{ props.rowData.logFiles[0].date | formatDateNoTime }})
						</span>
						<span v-else>/</span>
						<button
							title="Preuzmi log fajl"
							v-if="props.rowData.logFiles.length > 0"
							class="edit-nick-btn"
							:disabled="downloadInProgress"
							@click="downloadLogFile(props.rowData.logFiles[0])"
						>
							<i class="fas fa-download"></i>
						</button>
					</div>
				</vuetable>
				<vuetable-pagination
					ref="pagination"
					class="pull-right"
					@vuetable-pagination:change-page="onChangePage"
				></vuetable-pagination>
			</div>
			<div v-else class="mt-24 mb-36">
				<h1 class="text-2xl">Izvršite pretragu iznad ili osvežite tabelu, kako bi učitali podatke</h1>
			</div>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from './VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import axios from '@/axios/axios.js';
import ModalUserDetails from './ModalUserDetails.vue';
import ModalEditLogfileDate from './ModalEditLogfileDate.vue';
import ModalListOfLogFiles from './ModalListOfLogFiles.vue';

export default {
	components: {
		Vuetable,
		VuetablePagination,
		ModalUserDetails,
		ModalEditLogfileDate,
		ModalListOfLogFiles
	},

	props: {
		refresher: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			initialSearchDone: false,
			loadingTableData: false,
			downloadInProgress: false,
			intervalId: null,
			searchInput: '',
			searchFor: '',
			localData: [],
			css: VuetableCssConfig,
			fields: [],
			refreshInterval: 60000,
			lpfrRow: null,
			selectedUser: null,
			selectedRow: null
		};
	},

	async created() {
		this.setupTableFields();
	},

	computed: {
		...mapState({
			loading: state => {
				return state.loadingLogfiles;
			}
		}),
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		}),
		searchPlaceholder() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'Naziv distributera/partnera/klijenta, PIB, JID, nadimak, ...';
				case 'DISTRIBUTOR':
					return 'Naziv partnera/klijenta, PIB, JID, nadimak, ...';
				default:
					return 'Naziv klijenta, PIB, JID, nadimak, ...';
			}
		},
		clientFieldTitle() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'Partner / Klijent';
				case 'DISTRIBUTOR':
					return 'Partner / Klijent';
				case 'PARTNER':
					return 'Klijent';
				default:
					return 'Klijent';
			}
		}
	},

	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		},

		refresher() {
			this.refreshData();
		}
	},
	methods: {
		...mapMutations(['loadingStartedLogfiles', 'loadingFinishedLogfiles']),

		async resetIntervals() {
			console.log('why');
			this.loadingStartedLogfiles();
			await this.refreshData();
			this.loadingFinishedLogfiles();

			this.removeInterval();
			// this.setInterval();

			// console.log('resetIntervals on monitors');
		},

		async getDataThroughFilter() {
			this.loadingTableData = true;
			await this.refreshData();
			this.initialSearchDone = true;
			this.loadingTableData = false;
		},

		async getDataIfNeeded() {
			if (!this.localData || this.localData == null || this.localData.length == 0) {
				this.loadingStartedLogfiles();
				await this.refreshData();
				this.loadingFinishedLogfiles();
			}
		},

		async changeDate(row) {
			this.selectedRow = row;
			this.$modal.show('logfile-date');
		},

		openListOfLogFiles(row) {
			this.selectedRow = row;
			this.$modal.show('list-of-log-files');
		},

		async downloadLogFile(logfile) {
			this.downloadInProgress = true;

			axios({
				url: `lpfr/logfile/${logfile.id}`,
				method: 'GET',
				responseType: 'base64'
			})
				.then(response => {
					const b64toBlob = (base64, type = 'application/octet-stream') =>
						fetch(`data:${type};base64,${base64}`).then(res => res.blob());

					b64toBlob(response.data).then(blob => {
						const url = window.URL.createObjectURL(blob);
						const link = document.createElement('a');
						link.href = url;
						const filename = logfile.filename.substr(0, logfile.filename.lastIndexOf('.')) + '.zip';
						link.setAttribute('download', `${filename}`);
						document.body.appendChild(link);
						link.click();
					});
				})
				.catch(error => {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom skidanja fajla.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
					console.log(error);
				})
				.finally(() => {
					this.downloadInProgress = false;
				});
		},

		setInterval() {
			this.intervalId = setInterval(() => {
				if (this.$router.history.current.name == 'home') {
					this.getLPFRs();
				}
			}, this.refreshInterval);
		},

		removeInterval() {
			// console.log('removed interval on monitor');
			if (this.intervalId) {
				clearInterval(this.intervalId);
				this.intervalId = null;
			}
		},

		setupTableFields() {
			this.fields = [
				{
					name: 'client-slot',
					title: 'Klijent',
					sortField: 'clientName'
				},
				{
					name: 'pib',
					title: 'PIB klijenta'
				},
				{
					name: 'jid-slot',
					title: 'JID'
				},

				{
					name: 'last-logfile-slot',
					title: 'Poslednji fajl',
					sortField: 'lastLogfileSort'
				},
				{
					name: 'logfiles-slot',
					title: 'Svi fajlovi',
					sortField: 'logfileCount'
				},
				{
					name: 'logfile-date-slot',
					title: 'Fajl za datum',
					sortField: 'sendForDateSort'
				}
			];

			if (this.user.role != 'PARTNER' && this.user.role != 'ONPREMADMIN') {
				this.fields.unshift({
					name: 'partner-slot',
					title: 'Partner',
					sortField: `partner.name`
				});
			}

			if (this.user.role == 'SUPERADMIN') {
				this.fields.unshift({
					name: 'distributor-slot',
					title: 'Distributer',
					sortField: `distributor.name`
				});
			}
		},

		async getLPFRs() {
			try {
				const response = await axios.get('lpfr/lpfrs-with-logfiles', {
					params: {
						search: this.searchInput
					}
				});

				this.localData = response.data.map(lpfr => {
					lpfr.logfileCount = lpfr.logFiles.length;
					lpfr.lastLogfileSort = lpfr.logFiles.length > 0 ? new Date(lpfr.logFiles[0].date) : new Date(0);
					lpfr.sendForDateSort = lpfr.sendLogFileForDate ? new Date(lpfr.sendLogFileForDate) : new Date(0);

					return lpfr;
				});
				this.localData.sort((a, b) => {
					return b.lastLogfileSort - a.lastLogfileSort;
				});
			} catch (error) {
				// console.log(error);
			}
		},

		search() {
			this.searchFor = this.searchInput;
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},

		cancelFilter() {
			this.searchFor = '';
			this.searchInput = '';
			this.localData = [];
			this.initialSearchDone = false;
		},

		showUserDetails(user) {
			this.selectedUser = user;
			this.$modal.show('user-details-from-log-files');
		},

		closeModal(name) {
			this.$modal.hide(name);
		},

		async refreshData() {
			this.loadingTableData = true;
			await this.getLPFRs();
			this.loadingTableData = false;
		},

		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.client.name.search(txt) >= 0 ||
						item.pib.search(txt) >= 0 ||
						item.jid.search(txt) >= 0 ||
						(item.jidNickname != null && item.jidNickname.search(txt) >= 0) ||
						(item.partner ? item.partner.name.search(txt) >= 0 : false) ||
						(item.distributor ? item.distributor.name.search(txt) >= 0 : false)
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.monitor-container {
	display: flex;
	flex-direction: column;
}

.status-slot img {
	object-fit: contain;
	width: 24px;
	height: 24px;
}

.status-slot {
	// min-width: 95px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.monitor-table {
	font-size: 15px;
}
.log-text {
	max-width: 100%;
}
.text-ellipsis {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.edit-nick-btn {
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	i {
		font-size: 13px;
	}
}
.logs-btn {
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
}
.num-of-invoices {
	font-weight: bold;
	white-space: nowrap;
}
.distributor-name {
	max-width: 100px;
}
.partner-client-txt {
	max-width: 150px;
}
.jid-txt {
	max-width: 150px;
}
.button-row button {
	margin-top: 0px;
	font-size: 0.9rem;
	margin-bottom: 0px;
}
</style>

<style lang="scss">
.vuetable-th-slot-reset-lpfr-slot {
	text-align: center;
}
</style>
