<template>
	<div class="modal modal-version-details">
		<h1>Informacije o verziji</h1>
		<hr />
		<div v-if="loading" class="mt-4 mb-4 flex flex-row justify-center text-center">
			<spinner-component :size="50" />
		</div>
		<div class="modal-content text-lg" v-if="!editing">
			<div v-if="!loading">
				<div class="form-item-horizontal">
					<div class="w-1/2">
						<h2>Aktuelna verzija</h2>
						<h1>{{ currentVersion.version }}</h1>
					</div>
					<div class="w-1/2">
						<h2>Obavezna</h2>
						<h1>{{ currentVersion.skippable ? 'Ne' : 'Da' }}</h1>
					</div>
				</div>
				<hr class="mt-2" />
				<div class="flex flex-row items-center mt-2">
					<div class="mr-4 circle-icon blue">DEV</div>
					<div class="mt-1 flex-grow-2">
						<h2>Datum PRERELEASE verzije</h2>
						<h1>{{ currentVersion.prereleaseDate | formatDateNoTime }}</h1>
					</div>
				</div>
				<div class="flex flex-row items-center mt-2">
					<div class="mr-4 circle-icon red">1</div>
					<div class="form-item-horizontal flex-grow-2">
						<div class="w-1/2">
							<h2>Datum prvog talasa</h2>
							<h1>{{ currentVersion.firstWaveDate | formatDateNoTime }}</h1>
						</div>

						<div class="w-1/2">
							<h2>Preostalo mesta</h2>
							<h1>{{ currentVersion.firstWaveCounter }}</h1>
						</div>
					</div>
				</div>
				<div class="flex flex-row items-center mt-2">
					<div class="mr-4 circle-icon yellow">2</div>
					<div class="form-item-horizontal flex-grow-2">
						<div class="w-1/2">
							<h2>Datum drugog talasa</h2>
							<h1>{{ currentVersion.secondWaveDate | formatDateNoTime }}</h1>
						</div>

						<div class="w-1/2">
							<h2>Preostalo mesta</h2>
							<h1>{{ currentVersion.secondWaveCounter }}</h1>
						</div>
					</div>
				</div>
				<div class="flex flex-row items-center mt-2">
					<div class="mr-4 circle-icon green">3</div>
					<div class="form-item-horizontal flex-grow-2">
						<div class="w-1/2">
							<h2>Datum trećeg talasa</h2>
							<h1>{{ currentVersion.thirdWaveDate | formatDateNoTime }}</h1>
						</div>
					</div>
				</div>
				<div class="flex flex-row items-center mt-2">
					<div class="mr-4 circle-icon bg-gray-600">!</div>
					<div class="form-item-horizontal flex-grow-2">
						<div class="w-1/2">
							<h2>Datum obaveznog ažuriranja</h2>
							<h1 v-if="currentVersion.mustUpdateDate">
								{{ currentVersion.mustUpdateDate | formatDateNoTime }}
							</h1>
							<h2 v-else>Nije podešen</h2>
						</div>
					</div>
				</div>
				<div class="mt-4">
					<h2>Default checksum</h2>
					<h1 v-if="currentVersion.checksum">{{ currentVersion.checksum }}</h1>
					<h1 v-else>/</h1>
				</div>
			</div>
		</div>
		<div v-if="editing && !loading" class="modal-content text-lg">
			<div class="form-item-horizontal mb-2">
				<div class="w-1/2">
					<h2>Verzija</h2>
					<p class="text-sm text-gray-200 mt-0">U formatu N.N.N (Na primer 1.0.0)</p>
					<input type="text" class="input-text" v-model="newVersion.version" placeholder="Unesite verziju" />
				</div>
				<div class="w-1/2">
					<h2>Obavezna</h2>
					<p class="text-sm text-gray-200 mt-0">Da li je verziju zabranjeno preskočiti</p>
					<select class="input-select" v-model="newVersion.mandatory">
						<option value="true">Da</option>
						<option value="false">Ne</option>
					</select>
				</div>
			</div>
			<hr class="mt-3" />
			<div class="flex flex-row items-center mt-2">
				<div class="mr-4 circle-icon blue">DEV</div>
				<div class="form-item w-2/5">
					<h2>Datum PRERELEASE verzije</h2>
					<vue-datetime
						type="date"
						style="margin-right: 18px"
						input-class="form-control form-control"
						placeholder="Izaberite datum"
						hidden-name="webstorm"
						format="dd.MM.yyyy."
						:phrases="{
							ok: 'U redu',
							cancel: 'Otkaži'
						}"
						v-model="newVersion.prereleaseDate"
					></vue-datetime>
				</div>
			</div>

			<div class="flex flex-row items-center mt-2">
				<div class="mr-4 circle-icon red">1</div>
				<div class="form-item-horizontal">
					<div class="w-1/2">
						<h2>Datum prvog talasa</h2>
						<vue-datetime
							style="margin-right: 44px"
							type="date"
							input-class="form-control form-control"
							placeholder="Izaberite datum"
							hidden-name="webstorm"
							format="dd.MM.yyyy."
							:phrases="{
								ok: 'U redu',
								cancel: 'Otkaži'
							}"
							v-model="newVersion.firstWaveDate"
						></vue-datetime>
					</div>
					<div class="w-1/2">
						<h2>Broj mesta</h2>
						<input type="text" v-model="newVersion.firstWaveCounter" />
					</div>
				</div>
			</div>
			<div class="flex flex-row items-center mt-2">
				<div class="mr-4 circle-icon yellow">2</div>
				<div class="form-item-horizontal">
					<div class="w-1/2">
						<h2>Datum drugog talasa</h2>
						<vue-datetime
							style="margin-right: 44px"
							type="date"
							input-class="form-control form-control"
							placeholder="Izaberite datum"
							hidden-name="webstorm"
							format="dd.MM.yyyy."
							:phrases="{
								ok: 'U redu',
								cancel: 'Otkaži'
							}"
							v-model="newVersion.secondWaveDate"
						></vue-datetime>
					</div>
					<div class="w-1/2">
						<h2>Broj mesta</h2>
						<input type="text" v-model="newVersion.secondWaveCounter" />
					</div>
				</div>
			</div>
			<div class="flex flex-row items-center mt-2">
				<div class="mr-4 circle-icon green">3</div>
				<div class="form-item w-2/5">
					<h2>Datum trećeg talasa</h2>
					<vue-datetime
						style="margin-right: 18px"
						type="date"
						input-class="form-control form-control"
						placeholder="Izaberite datum"
						hidden-name="webstorm"
						format="dd.MM.yyyy."
						:phrases="{
							ok: 'U redu',
							cancel: 'Otkaži'
						}"
						v-model="newVersion.thirdWaveDate"
					></vue-datetime>
				</div>
			</div>
			<div class="flex flex-row items-center mt-2">
				<div class="mr-4 circle-icon bg-gray-600">!</div>
				<div class="form-item">
					<h2>Datum obaveznog ažuriranja</h2>
					<vue-datetime
						style="margin-right: 18px"
						type="date"
						input-class="form-control form-control"
						placeholder="Izaberite datum"
						hidden-name="webstorm"
						format="dd.MM.yyyy."
						:phrases="{
							ok: 'U redu',
							cancel: 'Otkaži'
						}"
						v-model="newVersion.mustUpdateDate"
					></vue-datetime>
				</div>
			</div>
			<div class="w-full mt-4">
				<h2>Default checksum</h2>
				<input class="w-full" type="text" v-model="newVersion.checksum" />
			</div>
		</div>
		<div v-if="!loading" class="flex flex-row mt-6">
			<button v-if="!editing" class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Izađi</button>
			<button v-if="editing" class="w-4/12 bg-red-600 hover:bg-red-800" @click="cancelEdit">Otkaži</button>
			<button class="flex-grow ml-2" v-if="!editing" @click="editing = true">Izmeni aktuelnu verziju</button>
			<button class="flex-grow ml-2" v-if="editing" @click="saveVersionInfo">Sačuvaj novu verziju</button>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
const semver = require('semver');

export default {
	data() {
		return {
			loading: true,
			editing: false,
			currentVersion: null,
			newVersion: {
				version: '',
				mandatory: true,
				prereleaseDate: '',
				firstWaveDate: '',
				firstWaveCounter: '0',
				secondWaveDate: '',
				secondWaveCounter: '0',
				thirdWaveDate: '',
				checksum: '',
				mustUpdateDate: ''
			}
		};
	},

	created() {
		this.getCurrentVersion();
	},
	methods: {
		getCurrentVersion() {
			axios
				.get('version-info')
				.then(response => {
					this.currentVersion = response.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		cancelEdit() {
			this.editing = false;
			this.newVersion = {
				version: '',
				mandatory: true,
				prereleaseDate: '',
				firstWaveDate: '',
				firstWaveCounter: 0,
				secondWaveDate: '',
				secondWaveCounter: 0,
				thirdWaveDate: '',
				mustUpdateDate: ''
			};
		},
		async saveVersionInfo() {
			if (this.inputValid()) {
				this.loading = true;
				this.newVersion.releaseSkippable = !this.newVersion.mandatory;
				this.newVersion.firstWaveCounter = parseInt(this.newVersion.firstWaveCounter);
				this.newVersion.secondWaveCounter = parseInt(this.newVersion.secondWaveCounter);

				if (!this.newVersion.mustUpdateDate) {
					this.newVersion.mustUpdateDate = null;
				}

				try {
					await axios.post('version-info', this.newVersion);

					this.$fire({
						title: 'Uspešna akcija',
						html: `Nova verzija je uspešno sačuvana.`,
						type: 'success',
						timer: 2500,
						showConfirmButton: false
					});
				} catch (error) {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom čuvanja nove verzije.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
				} finally {
					this.cancelEdit();
					this.getCurrentVersion();
					this.loading = false;
				}
			}
		},

		showErrorInfo(message) {
			this.$fire({
				title: 'Greška',
				text: `${message}`,
				type: 'error',
				showConfirmButton: true
			});
		},
		inputValid() {
			if (!this.newVersion.version) {
				this.showErrorInfo('Potrebno je uneti novu verziju.');
				return false;
			}

			if (!this.newVersion.prereleaseDate) {
				this.showErrorInfo('Potrebno je uneti datum PRERELEASE verzije.');
				return false;
			}

			if (!this.newVersion.firstWaveDate) {
				this.showErrorInfo('Potrebno je uneti datum prvog talasa.');
				return false;
			}

			if (
				this.newVersion.firstWaveCounter == null ||
				this.newVersion.firstWaveCounter == undefined ||
				this.newVersion.firstWaveCounter == '' ||
				isNaN(this.newVersion.firstWaveCounter) ||
				this.newVersion.firstWaveCounter < 0
			) {
				this.showErrorInfo(
					'Potrebno je uneti validan broj mesta prvog talasa, i on mora biti jednak ili veći od nule.'
				);
				return false;
			}

			if (!this.newVersion.secondWaveDate) {
				this.showErrorInfo('Potrebno je uneti datum drugog talasa.');
				return false;
			}

			if (
				this.newVersion.secondWaveCounter == null ||
				this.newVersion.secondWaveCounter == undefined ||
				this.newVersion.secondWaveCounter == '' ||
				isNaN(this.newVersion.secondWaveCounter) ||
				this.newVersion.secondWaveCounter < 0
			) {
				console.log(this.newVersion.secondWaveCounter);
				console.log(this.newVersion.secondWaveCounter == null);
				console.log(this.newVersion.secondWaveCounter == undefined);
				console.log(this.newVersion.secondWaveCounter == '');
				console.log(isNaN(this.newVersion.secondWaveCounter));
				console.log(this.newVersion.secondWaveCounter < 0);
				this.showErrorInfo(
					'Potrebno je uneti validan broj mesta drugog talasa, i on mora biti jednak ili veći od nule.'
				);
				return false;
			}

			if (!this.newVersion.thirdWaveDate) {
				this.showErrorInfo('Potrebno je uneti datum trećeg talasa.');
				return false;
			}

			const prDate = new Date(this.newVersion.prereleaseDate);
			const fwDate = new Date(this.newVersion.firstWaveDate);
			const swDate = new Date(this.newVersion.secondWaveDate);
			const twDate = new Date(this.newVersion.thirdWaveDate);

			if (
				prDate > fwDate ||
				prDate > swDate ||
				prDate > twDate ||
				fwDate > swDate ||
				fwDate > twDate ||
				swDate > twDate
			) {
				this.showErrorInfo('Datumi prerelease verzije i svih talasa moraju biti hronološki poređani.');
				return false;
			}

			if (!semver.valid(this.newVersion.version)) {
				this.showErrorInfo('Unesena verzija nije validna.');
				return false;
			}

			if (
				!semver.gt(this.newVersion.version, this.currentVersion.version) &&
				!semver.eq(this.newVersion.version, this.currentVersion.version)
			) {
				this.showErrorInfo('Verzija mora biti veća od trenutne verzije.');
				return false;
			}

			return true;
		}
	}
};
</script>

<style lang="scss" scoped>
.circle-icon {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.1rem;
	font-weight: bold;
	color: #f5f5f5;
}
.red {
	background-color: #e057083f;
	color: #751700;
}
.green {
	background-color: #00b3003b;
	color: #0e4e05;
}
.blue {
	background-color: #00b3ff34;
	color: #005a96;
}
.yellow {
	background-color: #bd930a3f;
	color: #887f07;
}
</style>
