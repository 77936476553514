var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal modal-add-client"},[_c('h1',{staticClass:"text-2xl"},[_vm._v("Dodaj novi podnalog")]),_c('hr'),_c('div',{staticClass:"modal-content text-lg"},[_c('div',{staticClass:"form-item"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"focus:ring-primary focus:border-primary font-semibold text-lg",attrs:{"type":"text","id":"name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{staticClass:"form-item"},[_c('h2',{attrs:{"for":"email"}},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"focus:ring-primary focus:border-primary font-semibold text-lg",attrs:{"type":"text","id":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"form-item"},[_c('h2',{attrs:{"for":"phone"}},[_vm._v("Kontakt telefon")]),_c('vue-phone-number-input',{attrs:{"id":"phone","color":"#005ca9","valid-color":"#00796b","default-country-code":"RS","translations":{
					countrySelectorLabel: 'Država',
					countrySelectorError: 'Greska',
					phoneNumberLabel: 'Broj telefona',
					example: 'Primer:'
				},"no-example":true,"preferred-countries":['RS', 'ME', 'BA', 'HR', 'MK', 'HU', 'RO', 'BG'],"show-code-on-list":true,"size":"lg","countries-height":25,"no-country-selector":false},on:{"update":function($event){return _vm.updatePhoneNumber($event)}},model:{value:(_vm.phoneRaw),callback:function ($$v) {_vm.phoneRaw=$$v},expression:"phoneRaw"}})],1),_c('div',{staticClass:"flex flex-row mt-3"},[_c('button',{staticClass:"w-4/12 bg-red-600 hover:bg-red-800",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Otkaži")]),_c('button',{staticClass:"flex-grow ml-2",attrs:{"disabled":_vm.loading || _vm.name == '' || !this.email.match(this.emailRegex) || (this.phoneRaw && !this.phone.isValid)},on:{"click":function($event){return _vm.addAlternateLogin()}}},[_vm._v(" Dodaj ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('h2',{attrs:{"for":"name"}},[_vm._v("Naziv partnera")]),_c('span',{staticClass:"ml-1 font-semibold text-red-600"},[_vm._v("*")])])
}]

export { render, staticRenderFns }