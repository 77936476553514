<template>
	<div class="dashboard-container">
		<div>
			<modal :adaptive="true" :height="'auto'" :width="1000" :focusTrap="false" name="send-email">
				<modal-send-email @close="closeModal('send-email')" />
			</modal>
		</div>
		<div v-if="loadingDashboard" style="height: 200px" class="flex flex-col justify-center">
			<spinner-component :size="50" />
		</div>
		<div class="flex w-full justify-end mb-1 pr-4" v-if="loggedUser.role == 'SUPERADMIN'">
			<button title="Email" @click="sendEmail">
				Pošalji email klijentima
				<i class="fas fa-envelope ml-2"></i>
			</button>
		</div>
		<div v-if="!loadingDashboard">
			<div class="flex flex-row justify-center mb-4">
				<div class="flex items-center mr-8">
					<input type="radio" name="lpfr-type-both" id="lpfr-type-both-prod" v-model="dashboardType" value="PROD" />
					<label class="text-2xl font-semibold pl-3" for="lpfr-type-both-prod">PRODUCTION</label>
				</div>
				<div class="flex items-center">
					<input type="radio" name="lpfr-type-both" id="lpfr-type-both-dev" v-model="dashboardType" value="DEV" />
					<label class="text-2xl font-semibold pl-3" for="lpfr-type-both-dev">DEVELOPMENT</label>
				</div>
			</div>
		</div>
		<div v-if="!loadingDashboard" class="flex flex-row justify-center">
			<div class="grow-2 chart-container relative flex flex-col items-center mr-24" style="width: 650px">
				<h1 class="font-semibold text-3xl mb-4">Status operativnosti LPFR-a</h1>

				<div>
					<div class="flex flex-row justify-center items-center mb-4" v-if="!loadingDashboard">
						<input type="radio" name="status-type" id="status-type-prod" v-model="statusType" value="PROD" />
						<label class="ml-2" for="status-type-prod">PRODUCTION</label>
						<input
							class="ml-12"
							type="radio"
							name="status-type"
							id="status-type-dev"
							v-model="statusType"
							value="DEV"
						/>
						<label class="ml-2" for="status-type-dev">DEVELOPMENT</label>
					</div>
					<piechart-component
						class="flex flex-row justify-center"
						v-if="!loadingDashboard"
						:chart-data="currentStatusChartData"
						:options="chartOptions"
						:height="400"
						ref="lpfrStatusPieChart"
					/>
					<div class="mt-4">
						<spinner-component v-if="loadingDashboard" :size="50" />
					</div>
					<div
						v-if="statusType == 'PROD'"
						v-show="!loadingDashboard"
						class="text-xl text-left m-auto mt-4 info-container flex flex-row"
					>
						<div>
							<div>
								<i class="fas fa-circle text-green-600" />
								<h2>Operativno</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.statusDataProd.numOfOperational }}
							</h1>
						</div>
						<div>
							<div>
								<i class="fas fa-circle text-yellow-300" />
								<h2>Upozorenje</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.statusDataProd.numOfWarning }}
							</h1>
						</div>
						<div>
							<div>
								<i class="fas fa-circle text-red-600" />
								<h2>Van funkcije</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.statusDataProd.numOfOOF }}
							</h1>
						</div>
						<div>
							<div>
								<i class="fas fa-circle text-gray-200"></i>
								<h2>Nepoznato</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.statusDataProd.numOfUnknown }}
							</h1>
						</div>
					</div>
					<div v-else v-show="!loadingDashboard" class="text-xl text-left m-auto mt-4 info-container flex flex-row">
						<div>
							<div>
								<i class="fas fa-circle text-green-600" />
								<h2>Operativno</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.statusDataDev.numOfOperational }}
							</h1>
						</div>
						<div>
							<div>
								<i class="fas fa-circle text-yellow-300" />
								<h2>Upozorenje</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.statusDataDev.numOfWarning }}
							</h1>
						</div>
						<div>
							<div>
								<i class="fas fa-circle text-red-600" />
								<h2>Van funkcije</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.statusDataDev.numOfOOF }}
							</h1>
						</div>
						<div>
							<div>
								<i class="fas fa-circle text-gray-200"></i>
								<h2>Nepoznato</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.statusDataDev.numOfUnknown }}
							</h1>
						</div>
					</div>
				</div>
			</div>
			<div class="grow-2 chart-container relative flex flex-col items-center" style="width: 650px">
				<h1 class="font-semibold text-3xl mb-4">Status licenci LPFR-a</h1>

				<div>
					<div class="flex flex-row justify-center items-center mb-4" v-if="!loadingDashboard">
						<input type="radio" name="licence-type" id="licence-type-prod" v-model="licenceType" value="PROD" />
						<label class="ml-2" for="licence-type-prod">PRODUCTION</label>
						<input
							class="ml-12"
							type="radio"
							name="licence-type"
							id="licence-type-dev"
							v-model="licenceType"
							value="DEV"
						/>
						<label class="ml-2" for="licence-type-dev">DEVELOPMENT</label>
					</div>
					<piechart-component
						class="flex flex-row justify-center"
						v-if="!loadingDashboard"
						:chart-data="currentLicenceData"
						:options="chartOptions"
						ref="licencePieChart"
					/>
					<div class="mt-4">
						<spinner-component v-if="loadingDashboard" :size="50" />
					</div>
					<div
						v-if="licenceType == 'PROD'"
						v-show="!loadingDashboard"
						class="text-xl text-left m-auto mt-4 info-container flex flex-row"
					>
						<div>
							<div>
								<i class="fas fa-circle text-green-600" />
								<h2>Aktivno</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.licenceDataProd.numOfActiveLicences }}
							</h1>
						</div>
						<div>
							<div>
								<i class="fas fa-circle text-yellow-300" />
								<h2>Ističe za manje od 15 dana</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.licenceDataProd.numOfExpiringLicences }}
							</h1>
						</div>
						<div>
							<div>
								<i class="fas fa-circle text-red-600" />
								<h2>Isteklo</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.licenceDataProd.numOfExpiredLicences }}
							</h1>
						</div>
					</div>
					<div v-else v-show="!loadingDashboard" class="text-xl text-left m-auto mt-4 info-container flex flex-row">
						<div>
							<div>
								<i class="fas fa-circle text-green-600" />
								<h2>Aktivno</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.licenceDataDev.numOfActiveLicences }}
							</h1>
						</div>
						<div>
							<div>
								<i class="fas fa-circle text-yellow-300" />
								<h2>Ističe za manje od 15 dana</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.licenceDataDev.numOfExpiringLicences }}
							</h1>
						</div>
						<div>
							<div>
								<i class="fas fa-circle text-red-600" />
								<h2>Isteklo</h2>
							</div>
							<h1 class="font-semibold">
								{{ this.licenceDataDev.numOfExpiredLicences }}
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="user.role == 'SUPERADMIN'">
			<div v-if="!loadingDashboard" class="flex flex-row justify-center mt-8">
				<div class="grow-2 chart-container relative flex flex-col items-center mr-24" style="width: 650px">
					<h1 class="font-semibold text-3xl mb-4">Licence po distributerima</h1>
					<vuetable
						ref="vuetable"
						:api-mode="false"
						:fields="fields"
						:css="css.table"
						:per-page="perPage"
						noDataTemplate="Nisu pronađeni podaci"
						pagination-path="pagination"
						@vuetable:pagination-data="onPaginationData"
						:data-manager="dataManager"
						class="dashboard-table"
					>
						<div class="slot flex flex-row" slot="name-slot" slot-scope="props">
							<span :title="props.rowData.name">
								{{ props.rowData.name }}
							</span>
						</div>
						<div class="slot flex flex-row" slot="count-slot" slot-scope="props">
							<span :title="props.rowData.count">
								{{ props.rowData.count }}
							</span>
						</div>
					</vuetable>
					<vuetable-pagination
						ref="pagination"
						class="pull-right"
						@vuetable-pagination:change-page="onChangePage"
					></vuetable-pagination>
				</div>
				<div class="grow-2 chart-container relative flex flex-col items-center" style="width: 650px">
					<h1 class="font-semibold text-3xl mb-4">Licence po partnerima</h1>
					<vuetable
						ref="vuetablePartner"
						:api-mode="false"
						:fields="fieldsPartner"
						:css="css.table"
						:per-page="perPage"
						noDataTemplate="Nisu pronađeni podaci"
						pagination-path="pagination"
						@vuetable:pagination-data="onPaginationDataPartner"
						:data-manager="dataManagerPartner"
						class="dashboard-table"
					>
						<div class="slot flex flex-row" slot="name-slot" slot-scope="props">
							<span :title="props.rowData.name">
								{{ props.rowData.name }}
							</span>
						</div>
						<div class="slot flex flex-row" slot="count-slot" slot-scope="props">
							<span :title="props.rowData.count">
								{{ props.rowData.count }}
							</span>
						</div>
					</vuetable>
					<vuetable-pagination
						ref="paginationPartner"
						class="pull-right"
						@vuetable-pagination:change-page="onChangePagePartner"
					></vuetable-pagination>
				</div>
			</div>
			<div class="grow-2 chart-container relative flex flex-col items-center mt-8 ml-4 mr-4" v-if="!loadingDashboard">
				<h1 class="font-semibold text-3xl mb-4">Broj lpfr po verzijama</h1>
				<vuetable
					ref="vuetableVersion"
					:api-mode="false"
					:fields="fieldsVersion"
					:css="css.table"
					:per-page="perPage"
					noDataTemplate="Nisu pronađeni podaci"
					pagination-path="pagination"
					@vuetable:pagination-data="onPaginationDataVersion"
					:data-manager="dataManagerVersion"
					class="dashboard-table"
				>
					<div class="slot flex flex-row" slot="name-slot" slot-scope="props">
						<span :title="props.rowData.version">
							{{ props.rowData.version }}
						</span>
					</div>
					<div class="slot flex flex-row" slot="count-slot" slot-scope="props">
						<span :title="props.rowData.count">
							{{ props.rowData.count }}
						</span>
					</div>
				</vuetable>
				<vuetable-pagination
					ref="paginationVersion"
					class="pull-right"
					@vuetable-pagination:change-page="onChangePageVersion"
				></vuetable-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import PiechartComponent from './PiechartComponent.vue';
import { mapState, mapMutations } from 'vuex';
import axios from '@/axios/axios.js';
import VuetableCssConfig from '@/VuetableCssConfig';
import Vuetable from 'vuetable-2';
import VuetablePagination from './VuetablePaginationBootstrap';
import ModalSendEmail from './ModalSendEmail.vue';

export default {
	components: {
		PiechartComponent,
		Vuetable,
		VuetablePagination,
		ModalSendEmail
	},
	data() {
		return {
			statusType: 'PROD',
			licenceType: 'PROD',
			dashboardType: 'PROD',
			statusDataProd: {
				numOfOperational: 0,
				numOfWarning: 0,
				numOfOOF: 0,
				numOfUnknown: 0
			},
			statusDataDev: {
				numOfOperational: 0,
				numOfWarning: 0,
				numOfOOF: 0,
				numOfUnknown: 0
			},
			licenceDataProd: {
				numOfActiveLicences: 0,
				numOfExpiringLicences: 0,
				numOfExpiredLicences: 0
			},
			licenceDataDev: {
				numOfActiveLicences: 0,
				numOfExpiringLicences: 0,
				numOfExpiredLicences: 0
			},
			chartLicenceData: { labels: [], datasets: [] },
			chartLicenceDataDev: { labels: [], datasets: [] },
			chartLpfrStatusData: { labels: [], datasets: [] },
			chartLpfrStatusDataDev: { labels: [], datasets: [] },
			refreshInterval: 60000,
			totalNumProd: 0,
			totalNumDev: 0,
			intervalId: null,

			chartOptions: {
				// animation: false,
				responsive: false,
				maintainAspectRatio: false,
				legend: {
					display: false,
					position: 'bottom',
					labels: {
						boxWidth: 15,
						boxHeight: 20,
						fontSize: 15,
						padding: 20
					}
				}
			},
			fields: [
				{
					name: 'name-slot',
					title: 'Naziv distributera',
					sortField: 'name'
				},
				{
					name: 'count-slot',
					title: 'Broj licenci',
					sortField: 'count'
				}
			],
			fieldsPartner: [
				{
					name: 'name-slot',
					title: 'Naziv partnera',
					sortField: 'name'
				},
				{
					name: 'count-slot',
					title: 'Broj licenci',
					sortField: 'count'
				}
			],
			fieldsVersion: [
				{
					name: 'name-slot',
					title: 'Verzija',
					sortField: 'version'
				},
				{
					name: 'count-slot',
					title: 'Broj lpfr',
					sortField: 'count'
				}
			],
			css: VuetableCssConfig,
			perPage: 10,
			distributerTableData: [],
			partnerTableData: [],
			versionTableData: []
		};
	},

	methods: {
		...mapMutations(['loadingStartedDashboard', 'loadingFinishedDashboard']),
		closeModal(name) {
			this.$modal.hide(name);
		},
		refreshLpfrStatusData() {
			this.chartLpfrStatusData = {
				datasets: [
					{
						data: [
							this.statusDataProd.numOfOperational,
							this.statusDataProd.numOfWarning,
							this.statusDataProd.numOfOOF,
							this.statusDataProd.numOfUnknown
						],
						backgroundColor: ['#08a570', '#FFCE56', '#e74c3c']
					}
				],
				labels: ['Operativan', 'Upozorenje', 'Van funkcije', 'Nepoznat']
			};

			this.chartLpfrStatusDataDev = {
				datasets: [
					{
						data: [
							this.statusDataDev.numOfOperational,
							this.statusDataDev.numOfWarning,
							this.statusDataDev.numOfOOF,
							this.statusDataDev.numOfUnknown
						],
						backgroundColor: ['#08a570', '#FFCE56', '#e74c3c']
					}
				],
				labels: ['Operativan', 'Upozorenje', 'Van funkcije', 'Nepoznat']
			};
		},

		refreshLicenceStatusData() {
			this.chartLicenceData = {
				datasets: [
					{
						data: [
							this.licenceDataProd.numOfActiveLicences,
							this.licenceDataProd.numOfExpiringLicences,
							this.licenceDataProd.numOfExpiredLicences
						],
						backgroundColor: ['#08a570', '#FFCE56', '#e74c3c']
					}
				],
				labels: ['Aktivna', 'Ističe uskoro', 'Istekla']
			};

			this.chartLicenceDataDev = {
				datasets: [
					{
						data: [
							this.licenceDataDev.numOfActiveLicences,
							this.licenceDataDev.numOfExpiringLicences,
							this.licenceDataDev.numOfExpiredLicences
						],
						backgroundColor: ['#08a570', '#FFCE56', '#e74c3c']
					}
				],
				labels: ['Aktivna', 'Ističe uskoro', 'Istekla']
			};
		},

		async refreshData() {
			try {
				const responseProd = await axios.get('client/dashboard-data/production');
				const responseSandbox = await axios.get('client/dashboard-data/sandbox');

				this.statusDataProd = responseProd.data.operationalData;
				this.statusDataDev = responseSandbox.data.operationalData;
				this.licenceDataProd = responseProd.data.licenceData;
				this.licenceDataDev = responseSandbox.data.licenceData;
				this.totalNumProd = responseProd.data.totalNum;
				this.totalNumDev = responseSandbox.data.totalNum;
				this.refreshLpfrStatusData();
				this.refreshLicenceStatusData();

				const responseTable = await axios.get('client/dashboard-table/production');
				this.distributerTableData = responseTable.data.distributorData;
				this.partnerTableData = responseTable.data.partnerData;
				if (this.$refs.vuetable) this.$refs.vuetable.reload();
				if (this.$refs.vuetablePartner) this.$refs.vuetablePartner.reload();

				const responseVersion = await axios.get('client/version-count');
				this.versionTableData = responseVersion.data;
				if (this.$refs.vuetableVersion) this.$refs.vuetableVersion.reload();
			} catch (error) {
				console.log('error', error);
			}
		},

		async resetIntervals() {
			this.loadingStartedDashboard();
			await this.refreshData();
			this.loadingFinishedDashboard();

			this.removeInterval();
			// this.setInterval();
		},

		setInterval() {
			if (this.intervalId) return;
			this.intervalId = setInterval(() => {
				if (this.$router.history.current.name == 'home') {
					this.refreshData();
				}
			}, this.refreshInterval);
		},

		removeInterval() {
			// console.log('removed interval on monitor');
			if (this.intervalId) {
				clearInterval(this.intervalId);
				this.intervalId = null;
			}
		},
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onPaginationDataPartner(paginationData) {
			this.$refs.paginationPartner.setPaginationData(paginationData);
		},
		onPaginationDataVersion(paginationData) {
			this.$refs.paginationVersion.setPaginationData(paginationData);
		},
		dataManager(sortOrder, pagination) {
			let data = this.distributerTableData;
			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},
		dataManagerPartner(sortOrder, pagination) {
			let data = this.partnerTableData;
			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}
			pagination = this.$refs.vuetablePartner.makePagination(data.length); // if you don't want to use pagination component, you can just
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},
		dataManagerVersion(sortOrder, pagination) {
			let data = this.versionTableData;
			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}
			pagination = this.$refs.vuetableVersion.makePagination(data.length); // if you don't want to use pagination component, you can just
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},
		onChangePagePartner(page) {
			this.$refs.vuetablePartner.changePage(page);
		},
		onChangePageVersion(page) {
			this.$refs.vuetableVersion.changePage(page);
		},
		sendEmail() {
			this.$modal.show('send-email');
		}
	},
	computed: {
		...mapState({
			loadingDashboard: state => {
				return state.loadingDashboard;
			},
			loggedUser: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		}),
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id,
					canSeePrintLPFR: state.user.user.canSeePrintLPFR
				};
			}
		}),

		currentStatusChartData() {
			this.refreshLpfrStatusData();
			if (this.statusType == 'PROD') {
				return this.chartLpfrStatusData;
			} else {
				return this.chartLpfrStatusDataDev;
			}
		},
		currentLicenceData() {
			this.refreshLicenceStatusData();
			if (this.licenceType == 'PROD') {
				return this.chartLicenceData;
			} else {
				return this.chartLicenceDataDev;
			}
		}
	},
	watch: {
		statusType: function () {
			if (this.statusType == this.licenceType && this.statusType != this.dashboardType)
				this.dashboardType = this.statusType;
		},
		licenceType: function () {
			if (this.licenceType == this.statusType && this.licenceType != this.dashboardType)
				this.dashboardType = this.licenceType;
		},
		dashboardType: function () {
			if (this.statusType != this.dashboardType) this.statusType = this.dashboardType;
			if (this.licenceType != this.dashboardType) this.licenceType = this.dashboardType;
		}
	}
};
</script>

<style lang="scss" scoped>
.chart-container {
	border: rgb(228, 228, 228) solid 1px;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.055);
}

.info-container {
	div {
		display: flex;
		flex-direction: column;
		padding-right: 16px;
		margin-right: 16px;
		border-right: 1px solid #e6e6e6;
		h2 {
			color: rgb(90, 90, 90);
		}
		div {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-right: 0;
			padding-right: 0;
			border-right: none;
			i {
				margin-right: 6px;
				margin-top: 2px;
				font-size: 12px;
			}
		}
	}

	div:last-of-type {
		border-right: none;
	}
}
.dashboard-table {
	font-size: 15;
	width: 100%;
}
</style>
