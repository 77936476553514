<template>
	<div class="modal">
		<h1>Izmena podataka za fakturisanje</h1>
		<hr />
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-if="!loading">
			<div class="mt-2">
				<h2>Naziv korisnika</h2>
				<h1>{{ row.name }}</h1>
				<div class="flex flex-row w-full">
					<div class="flex-grow">
						<h2>Tip</h2>
						<h1>{{ row.typeFormatted }}</h1>
					</div>
					<div class="flex-grow">
						<h2>PIB</h2>
						<h1>{{ row.pib }}</h1>
					</div>
				</div>
				<hr />
				<div class="mt-2 w-full input-container">
					<h2>Naziv obveznika</h2>
					<input type="text" v-model="legalEntity.name" />
					<h2>Matični broj obveznika</h2>
					<input type="text" v-model="legalEntity.mb" @keypress="isNumber($event)" />
					<h2>E-mail adrese obveznika</h2>
					<label class="text-sm text-gray-200" for="emails"
						>E-mail adrese na koje će se slati kreirani računi za datog obveznika. Adrese je potrebno odvojiti zarezom,
						npr. mail@mail.rs,user@gmail.com</label
					>
					<input id="emails" v-model="legalEntity.emails" type="text" />
					<h2>Adresa obveznika</h2>
					<label class="text-sm text-gray-200" for="emails"
						>Adresa obveznika tj. primaoca računa, npr. Pere Perića, 11000 Beograd</label
					>
					<input type="text" v-model="legalEntity.address" />
					<h2>Cenovnik za produkcione LPFR kartice</h2>
					<label class="text-sm text-gray-200" for="emails"
						>Obavezno polje. Bez cenovnika za produkcione kartice nije moguće izvršiti fakturisanje.</label
					>
					<v-select
						placeholder="Pretražite i izaberite cenovnik"
						class="select-style mb-1"
						v-model="selectedPricelistProd"
						:options="pricelistsProd"
					></v-select>

					<h2>Cenovnik za print kartice</h2>
					<label class="text-sm text-gray-200" for="emails"
						>Opciono polje. Ukoliko obveznik poseduje PRINT kartice, fakturisanje neće biti moguće bez PRINT
						cenovnika.</label
					>
					<v-select
						placeholder="Pretražite i izaberite cenovnik"
						class="select-style mb-1"
						v-model="selectedPricelistPrint"
						:options="pricelistsPrint"
					></v-select>
					<h2>Cenovnik za produkcione IPS kartice</h2>
					<label class="text-sm text-gray-200" for="emails"
						>Opciono polje. Ukoliko obveznik poseduje IPS kartice, fakturisanje neće biti moguće bez ovog
						cenovnika.</label
					>
					<v-select
						placeholder="Pretražite i izaberite cenovnik"
						class="select-style mb-1"
						v-model="selectedPricelistIps"
						:options="pricelistsIps"
					></v-select>
					<h2>Cenovnik za produkcione EFT kartice</h2>
					<label class="text-sm text-gray-200" for="emails"
						>Opciono polje. Ukoliko obveznik poseduje EFT kartice, fakturisanje neće biti moguće bez ovog
						cenovnika.</label
					>
					<v-select
						placeholder="Pretražite i izaberite cenovnik"
						class="select-style mb-1"
						v-model="selectedPricelistEft"
						:options="pricelistsEft"
					></v-select>
				</div>
			</div>
			<hr class="mb-2 mt-4" />
			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button :disabled="!inputIsValid" class="flex-grow ml-2" @click="saveSettings()">Promeni podatke</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	data() {
		return {
			money: {
				precision: 0
			},
			loading: false,
			legalEntity: {
				name: '',
				address: '',
				emails: '',
				mb: '',
				pricelistProd: null,
				pricelistPrint: null
			},
			oldLegalEntity: null,
			pricelistsProd: [],
			selectedPricelistProd: null,
			pricelistsPrint: [],
			selectedPricelistPrint: null,
			pricelistsIps: [],
			selectedPricelistIps: null,
			pricelistsEft: [],
			selectedPricelistEft: null
		};
	},
	props: {
		row: {
			type: Object,
			required: true
		}
	},
	methods: {
		isNumber(evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},

		async saveSettings() {
			const data = {};

			//determine type
			if (this.row.type == 'CLIENT') {
				data.legalEntityType = 'CLIENT';
			} else {
				data.legalEntityType = 'USER';
			}

			data.legalEntityId = this.legalEntity.id;
			data.mb = this.legalEntity.mb;
			data.legalEntityName = this.legalEntity.name;
			data.legalEntityEmails = this.legalEntity.emails.split(' ').join('');
			if (this.legalEntity.address && this.legalEntity.address.length > 0) {
				data.legalEntityAddress = this.legalEntity.address;
			}

			data.pricelistProdId = this.selectedPricelistProd.value;

			if (this.selectedPricelistPrint) {
				data.pricelistPrintId = this.selectedPricelistPrint.value;
			}

			if (this.selectedPricelistIps) {
				data.pricelistIpsId = this.selectedPricelistIps.value;
			}

			if (this.selectedPricelistEft) {
				data.pricelistEftId = this.selectedPricelistEft.value;
			}

			//compute and adjust discounts for the server

			this.loading = true;
			try {
				const response = await axios.post('/finances/settings', data);
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Podaci za fakturisanje su uspešno izmenjeni.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				console.error(error);
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom promene podataka za fakturisanje.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		},

		async loadPricelists() {
			const response = await axios.get('/finances/pricelists');

			const pricelistsProd = response.data
				.filter(pricelist => pricelist.type == 'PRODUCTION')
				.map(pricelist => {
					return {
						id: pricelist.id,
						label: pricelist.name,
						value: pricelist.id
					};
				});

			this.pricelistsProd = pricelistsProd;

			const pricelistsPrint = response.data
				.filter(pricelist => pricelist.type == 'PRINT')
				.map(pricelist => {
					return {
						id: pricelist.id,
						label: pricelist.name,
						value: pricelist.id
					};
				});

			this.pricelistsPrint = pricelistsPrint;

			const pricelistsIps = response.data
				.filter(pricelist => pricelist.type == 'PRODUCTION_IPS')
				.map(pricelist => {
					return {
						id: pricelist.id,
						label: pricelist.name,
						value: pricelist.id
					};
				});
			this.pricelistsIps = pricelistsIps;

			const pricelistsEft = response.data
				.filter(pricelist => pricelist.type == 'PRODUCTION_EFT')
				.map(pricelist => {
					return {
						id: pricelist.id,
						label: pricelist.name,
						value: pricelist.id
					};
				});
			this.pricelistsEft = pricelistsEft;
		}
	},

	computed: {
		inputIsValid() {
			const baseInputIsValid =
				this.legalEntity.name.length > 0 &&
				this.legalEntity.emails.length > 0 &&
				this.legalEntity.mb.toString().length == 8 &&
				this.selectedPricelistProd;

			return baseInputIsValid;
		}
	},

	async created() {
		this.loading = true;
		await this.loadPricelists();

		this.legalEntity = {
			name: this.row.legalEntityName ? this.row.legalEntityName : '',
			address: this.row.legalEntityAddress ? this.row.legalEntityAddress : '',
			emails: this.row.legalEntityEmails ? this.row.legalEntityEmails : '',
			id: this.row.id,
			mb: this.row.mb ? this.row.mb : '',
			pricelistProdId: this.row.pricelistProd ? this.row.pricelistProd.id : null,
			pricelistPrintId: this.row.pricelistPrint ? this.row.pricelistPrint.id : null,
			pricelistIpsId: this.row.pricelistIps ? this.row.pricelistIps.id : null,
			pricelistEftId: this.row.pricelistEft ? this.row.pricelistEft.id : null
		};

		this.oldLegalEntity = JSON.parse(JSON.stringify(this.legalEntity));

		if (this.legalEntity.pricelistProdId) {
			this.selectedPricelistProd = this.pricelistsProd.find(
				pricelist => pricelist.id == this.legalEntity.pricelistProdId
			);
		}

		if (this.legalEntity.pricelistPrintId) {
			this.selectedPricelistPrint = this.pricelistsPrint.find(
				pricelist => pricelist.id == this.legalEntity.pricelistPrintId
			);
		}

		if (this.legalEntity.pricelistIpsId) {
			this.selectedPricelistIps = this.pricelistsIps.find(pricelist => pricelist.id == this.legalEntity.pricelistIpsId);
		}

		if (this.legalEntity.pricelistEftId) {
			this.selectedPricelistEft = this.pricelistsEft.find(pricelist => pricelist.id == this.legalEntity.pricelistEftId);
		}

		this.loading = false;
	}
};
</script>

<style lang="scss" scoped>
.input-container input[type='text'] {
	width: 100%;
}
.input-container h2 {
	margin-top: 12px;
}

.discounts {
	h2 {
		margin-top: unset !important;
	}
}

.discounts-input {
	width: 64px !important;
	margin: 0 4px !important;
	border-color: rgb(168, 168, 168) !important;
}
</style>
