<template>
	<div class="modal modal-add-client">
		<h1 class="text-2xl">Slanje log fajla za datum</h1>
		<hr />
		<div class="modal-content text-lg">
			<div class="form-item">
				<h2>LPFR</h2>
				<h1>{{ lpfrDescription }}</h1>
			</div>
			<div class="form-item">
				<h2>Slanje log fajla za datum</h2>
				<div class="flex flex-row items-center">
					<vue-datetime
						type="date"
						input-class="form-control form-control"
						placeholder="Izaberite datum"
						hidden-name="webstorm"
						format="dd.MM.yyyy."
						:phrases="{
							ok: 'U redu',
							cancel: 'Otkaži'
						}"
						v-model="date"
					></vue-datetime>
					<a
						v-if="date"
						class="ml-2 flex flex-row justify-center items-center cursor-pointer hover:text-red-600"
						style="width: 24px; height: 24px; border-radius: 100%"
						@click="date = null"
					>
						<i class="fas fa-times"></i>
					</a>
				</div>
			</div>
			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button :disabled="loading || sameDate" class="flex-grow ml-2" @click="changeDate()">Podesi datum</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';

export default {
	data() {
		return {
			loading: false,
			nickname: '',
			date: null
		};
	},

	props: {
		row: {
			type: Object,
			required: true
		}
	},

	computed: {
		lpfrDescription() {
			let description = this.row.jid;
			if (this.row.jidNickname) {
				description = `${description} (${this.row.jidNickname})`;
			}

			return description;
		},
		sameDate() {
			if (!this.row.sendLogFileForDate) {
				return !this.date;
			} else {
				return new Date(this.row.sendLogFileForDate).toISOString() == new Date(this.date).toISOString();
			}
		}
	},

	created() {
		if (this.row.sendLogFileForDate) {
			this.date = new Date(this.row.sendLogFileForDate).toISOString();
		}
	},

	methods: {
		async changeDate() {
			this.loading = true;
			try {
				await axios.post(`lpfr/${this.row.id}/logfile-date`, {
					date: this.date
				});
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Datum log fajla za slanje je uspešno podešen.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom podešavanja datuma log fajla za slanje.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style></style>
