<template>
	<div>
		<div>
			<modal height="auto" name="edit-pricelist" :scrollable="true" :adaptive="false">
				<modal-edit-pricelist
					@close="closeModal('edit-pricelist')"
					@refresh="refreshData"
					:row="selectedRow"
				></modal-edit-pricelist>
			</modal>
		</div>
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-else>
			<div class="flex flex-row justify-end mb-2">
				<button @click="editPricelist()">Kreiraj cenovnik</button>
			</div>
			<vuetable
				ref="vuetable"
				:api-mode="false"
				:fields="fields"
				:css="css.table"
				:per-page="perPage"
				noDataTemplate="Nisu pronađeni cenovnici"
				:data-manager="dataManager"
				pagination-path="pagination"
				@vuetable:pagination-data="onPaginationData"
				@vuetable:loaded="onLoaded"
				class="pricelists-table"
				track-by="id"
			>
				<div class="slot flex flex-row justify-center" slot="actions-slot" slot-scope="props">
					<button
						style="width: 26px; height: 26px; padding: 0"
						class="btn btn-sm"
						title="Izmeni cenovnik"
						@click="editPricelist(props.rowData)"
					>
						<i class="fas fa-pen" />
					</button>
					<button
						style="width: 26px; height: 26px; padding: 0"
						class="btn btn-sm bg-red-600 hover:bg-red-800 ml-2"
						title="Izbriši cenovnik"
						@click="deletePricelist(props.rowData)"
					>
						<i class="fas fa-trash" />
					</button>
				</div>
			</vuetable>
			<div class="flex flex-row justify-end items-center">
				<div class="mr-4">{{ numOfDisplayed }} / {{ numOfTotal }}</div>
				<div class="flex flex-row per-page-div">
					<input type="radio" id="10-per-page-monitor" :value="10" v-model="perPage" /><label
						:class="{ active: perPage == 10 }"
						for="10-per-page-monitor"
						>10</label
					>
					<input type="radio" id="20-per-page-monitor" :value="20" v-model="perPage" /><label
						:class="{ active: perPage == 20 }"
						for="20-per-page-monitor"
						>20</label
					>
					<input type="radio" id="50-per-page-monitor" :value="50" v-model="perPage" /><label
						:class="{ active: perPage == 50 }"
						for="50-per-page-monitor"
						>50</label
					>
					<input type="radio" id="100-per-page-monitor" :value="100" v-model="perPage" /><label
						:class="{ active: perPage == 100 }"
						for="100-per-page-monitor"
						>100</label
					>
				</div>
				<vuetable-pagination ref="pagination" class="pull-right" @vuetable-pagination:change-page="onChangePage">
				</vuetable-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from '../components/VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import ModalEditPricelist from '../components/ModalEditPricelist.vue';
import axios from '@/axios/axios.js';

export default {
	components: {
		Vuetable,
		VuetablePagination,
		ModalEditPricelist
	},
	data() {
		return {
			loading: true,
			css: VuetableCssConfig,
			perPage: 20,
			numOfDisplayed: 0,
			numOfTotal: 0,
			fields: [],
			localData: [],
			originalData: [],
			moneyFormatter: new Intl.NumberFormat('de-DE', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 4
			}),
			selectedRow: null
		};
	},

	async created() {
		try {
			this.loading = true;
			this.setupTableFields();
			await this.loadData();
		} catch (error) {
			console.log(error);
		} finally {
			this.loading = false;
		}
	},

	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		}
	},

	methods: {
		setupTableFields() {
			this.fields = [
				{
					name: 'name',
					title: 'Naziv',
					sortField: 'name'
				},
				{
					name: 'typeFormatted',
					title: ' Tip',
					sortField: 'name'
				},
				{
					name: 'priceFormatted',
					title: 'Osnovna cena (€)',
					sortField: 'flatPrice'
				},
				{
					name: 'priceModelFormatted',
					title: 'Rabat',
					sortField: 'name'
				},
				{
					name: 'yearlyPriceFormatted',
					title: 'Godišnja cena (€)',
					sortField: 'flatYearlyPrice'
				},
				{
					name: 'actions-slot',
					title: 'Akcije',
					sortable: false
				}
			];
		},

		editPricelist(row = null) {
			console.log('row', row);
			if (row) {
				this.selectedRow = row;
			}
			this.$modal.show('edit-pricelist');
		},
		closeModal(name) {
			this.$modal.hide(name);
			this.selectedRow = null;
		},

		async refreshData() {
			this.loading = true;
			await this.loadData();
			this.loading = false;
		},

		async deletePricelist(row) {
			this.$fire({
				title: 'Brisanje cenovnika',
				html: `Da li ste sigurni da želite da obrišete izabrani cenovnik?<br/><br/><b><span class="text-sm text-gray-200">Brisanje neće biti moguće ukoliko je cenovnik već vezan za nekog klijenta.</span></b>`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Obriši cenovnik',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.delete(`finances/pricelist/${row.id}`)
						.then(response => {
							this.$fire({
								title: 'Uspešna akcija',
								text: `Uspešno obrisan cenovnik.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.refreshData();
						})
						.catch(error => {
							console.error(error);
							this.$fire({
								title: 'Greška',
								text: 'Nije moguće obrisati cenovnik. Proverite da li je cenovnik već vezan za nekog klijenta.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});
		},

		async loadData() {
			this.loading = true;
			const response = await axios.get('finances/pricelists');
			this.formatData(response.data);
			this.loading = false;
		},

		formatData(data) {
			const formattedData = data.map(row => {
				if (row.priceModel == 'VARIABLE' && row.discountRanges) {
					let discountParts = row.discountRanges.split(',');
					for (let i = 0; i < discountParts.length; i++) {
						let discountAmounts = discountParts[i].split('-');
						for (let j = 1; j < discountAmounts.length; j = j + 2) {
							discountAmounts[j] = (parseFloat(discountAmounts[j]) * 100).toFixed(0);
							discountAmounts[j] = discountAmounts[j] + '%';
						}
						discountAmounts = discountAmounts.join(' - ');
						discountParts[i] = discountAmounts;
					}
					discountParts = discountParts.join(', ≥');
					row.priceModelFormatted = '≥' + discountParts;

					if (row.priceModelFormatted.length > 100) {
						row.priceModelFormatted = row.priceModelFormatted.substr(0, 97) + '...';
					}
				} else {
					row.priceModelFormatted = 'Nema (fiksna cena)';
				}

				switch (row.type) {
					case 'PRODUCTION':
						row.typeFormatted = 'Produkcione kartice';
						break;
					case 'PRINT':
						row.typeFormatted = 'Print kartice';
						break;
					case 'PRODUCTION_IPS':
						row.typeFormatted = 'Produkcione kartice (IPS)';
						break;
					case 'PRODUCTION_EFT':
						row.typeFormatted = 'Produkcione kartice (EFT)';
						break;
					default:
						row.typeFormatted = 'Nepoznato';
				}

				row.priceFormatted = this.moneyFormatter.format(row.flatPrice);
				if (row.flatYearlyPrice) {
					row.yearlyPriceFormatted = this.moneyFormatter.format(row.flatYearlyPrice);
				} else {
					row.yearlyPriceFormatted = 'Nema';
					if (row.type == 'PRODUCTION') {
						row.yearlyPriceFormatted += ' (POTREBNO DODATI)';
					}
				}

				return row;
			});

			this.originalData = data;
			this.localData = data;
		},

		onLoaded() {
			this.numOfDisplayed = this.$refs.vuetable.countTableData;
			this.numOfTotal = this.$refs.pagination.tablePagination.total;
		},

		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.obligor.pib.search(txt) >= 0 || item.obligor.name.search(txt) >= 0 || item.number.search(txt) >= 0
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		}
	}
};
</script>

<style></style>
