<template>
	<div>
		<div>
			<modal height="auto" name="edit-financial-settings" :scrollable="true" :adaptive="false">
				<modal-edit-financial-settings
					@close="closeModal('edit-financial-settings')"
					@refresh="refreshData"
					:row="selectedRow"
				></modal-edit-financial-settings>
			</modal>
		</div>
		<div v-if="loading" class="mt-12">
			<spinner-component :size="64" />
		</div>
		<div v-if="!loading">
			<div class="button-row">
				<div class="filter-container">
					<div class="status-container" v-if="user.role == 'SUPERADMIN'">
						<h1 class="text-xl font-semibold status-filter-header">Tip</h1>
						<div class="status-options flex items-center px2">
							<input type="radio" id="type-partner" value="PARTNER" v-model="filterType" />
							<label for="type-partner">Partner</label>
							<input type="radio" id="type-dist" value="DISTRIBUTOR" v-model="filterType" />
							<label for="type-dist">Distributer</label>
							<input type="radio" id="type-client" value="CLIENT" v-model="filterType" />
							<label for="type-client">Klijent</label>
						</div>
					</div>

					<div class="flex flex-row flex-nowrap">
						<input
							type="text"
							class="focus:ring-primary focus:border-primary"
							style="width: 420px"
							placeholder="Naziv, PIB, naziv/e-mail pravnog lica"
							v-on:keyup.enter="search"
							v-model="searchInput"
						/>
						<button title="Pretraži" @click="search()" style="width: 42px">
							<i class="fas fa-search" />
						</button>
						<button title="Poništi filter" class="cancel-filter-button" @click="cancelFilter()" style="width: 42px">
							<i class="fas fa-times" />
						</button>
					</div>
				</div>
			</div>
			<vuetable
				ref="vuetable"
				:api-mode="false"
				:fields="fields"
				:css="css.table"
				:per-page="perPage"
				noDataTemplate="Nisu pronađeni korisnici"
				:data-manager="dataManager"
				pagination-path="pagination"
				@vuetable:pagination-data="onPaginationData"
				@vuetable:loaded="onLoaded"
				class="monitor-table"
				track-by="index"
			>
				<div class="slot" slot="name-slot" slot-scope="props">
					{{ props.rowData.nameFormatted }}
				</div>
				<div class="slot" slot="user-type-slot" slot-scope="props">
					{{ props.rowData.typeFormatted }}
				</div>
				<div class="slot text-sm" slot="pricelist-prod-slot" slot-scope="props">
					<span v-if="props.rowData.pricelistProdName">{{ props.rowData.pricelistProdName }}</span>
					<span v-else>/</span>
				</div>
				<div class="slot text-sm" slot="pricelist-print-slot" slot-scope="props">
					<span v-if="props.rowData.pricelistPrintName">{{ props.rowData.pricelistPrintName }}</span>
					<span v-else>/</span>
				</div>
				<div class="slot text-sm" slot="pricelist-ips-slot" slot-scope="props">
					<span v-if="props.rowData.pricelistIpsName">{{ props.rowData.pricelistIpsName }}</span>
					<span v-else>/</span>
				</div>
				<div class="slot text-sm" slot="pricelist-eft-slot" slot-scope="props">
					<span v-if="props.rowData.pricelistEftName">{{ props.rowData.pricelistEftName }}</span>
					<span v-else>/</span>
				</div>

				<div class="slot" slot="legal-entity-name-slot" slot-scope="props">
					{{ props.rowData.legalEntityName ? props.rowData.legalEntityName : '/' }}
				</div>
				<div class="slot text-sm" slot="legal-entity-emails-slot" slot-scope="props">
					{{ props.rowData.legalEntityEmails ? props.rowData.legalEntityEmailsFormatted : '/' }}
				</div>
				<div class="slot text-sm" slot="legal-entity-address-slot" slot-scope="props">
					{{ props.rowData.legalEntityAddress ? props.rowData.legalEntityAddress : '/' }}
				</div>
				<div class="slot flex flex-row justify-center" slot="actions-slot" slot-scope="props">
					<button
						style="width: 26px; height: 26px; padding: 0"
						class="btn btn-sm"
						title="Izmeni podatke za fakturisanje"
						@click="editClient(props.rowData)"
					>
						<i class="fas fa-pen" />
					</button>
				</div>
			</vuetable>
			<div class="flex flex-row justify-end items-center">
				<div class="mr-4">{{ numOfDisplayed }} / {{ numOfTotal }}</div>
				<div class="flex flex-row per-page-div">
					<input type="radio" id="10-per-page-monitor" :value="10" v-model="perPage" /><label
						:class="{ active: perPage == 10 }"
						for="10-per-page-monitor"
						>10</label
					>
					<input type="radio" id="20-per-page-monitor" :value="20" v-model="perPage" /><label
						:class="{ active: perPage == 20 }"
						for="20-per-page-monitor"
						>20</label
					>
					<input type="radio" id="50-per-page-monitor" :value="50" v-model="perPage" /><label
						:class="{ active: perPage == 50 }"
						for="50-per-page-monitor"
						>50</label
					>
					<input type="radio" id="100-per-page-monitor" :value="100" v-model="perPage" /><label
						:class="{ active: perPage == 100 }"
						for="100-per-page-monitor"
						>100</label
					>
				</div>
				<vuetable-pagination
					ref="pagination"
					class="pull-right"
					@vuetable-pagination:change-page="onChangePage"
				></vuetable-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import Vuetable from 'vuetable-2';
import VuetablePagination from '../components/VuetablePaginationBootstrap';
import VuetableCssConfig from '@/VuetableCssConfig';
import ModalEditFinancialSettings from '../components/ModalEditFinancialSettings.vue';
import { mapState } from 'vuex';

export default {
	components: {
		Vuetable,
		VuetablePagination,
		ModalEditFinancialSettings
	},
	data() {
		return {
			localData: [],
			originalData: [],
			fields: [],
			perPage: 20,
			numberOfLoadedClients: 0,
			css: VuetableCssConfig,
			loading: true,
			searchInput: '',
			searchFor: '',
			filterType: null,
			selectedRow: null,
			numOfDisplayed: 0,
			numOfTotal: 0
		};
	},

	computed: {
		...mapState({
			user: state => {
				return {
					id: state.user.user.id,
					name: state.user.user.name,
					phone: state.user.user.phone,
					email: state.user.user.email,
					role: state.user.user.role,
					pib: state.user.user.pib
				};
			}
		}),

		role() {
			switch (this.user.role) {
				case 'SUPERADMIN':
					return 'SUPER ADMINISTRATOR';
				case 'ONPREMADMIN':
					return 'ADMINISTRATOR';
				case 'DISTRIBUTOR':
					return 'DISTRIBUTER';
				case 'PARTNER':
					return 'PARTNER';
				default:
					return 'Nepoznato';
			}
		}
	},

	methods: {
		editClient(row) {
			this.selectedRow = row;
			this.$modal.show('edit-financial-settings');
		},

		async getClients() {
			const response = await axios.get('/finances/clients');
			this.formatTableData(response.data);
		},

		closeModal(name) {
			this.$modal.hide(name);
		},

		async refreshData() {
			try {
				await this.getClients();
			} catch (error) {
			} finally {
				this.loading = false;
			}
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},

		formatTableData(data) {
			this.localData = data.map(row => {
				switch (row.type) {
					case 'DISTRIBUTOR':
						row.typeFormatted = 'Distributer';
						break;
					case 'PARTNER':
						row.typeFormatted = 'Partner';
						break;
					case 'CLIENT':
						row.typeFormatted = 'Klijent';
						break;
				}
				if (row.legalEntityEmails) {
					row.legalEntityEmailsFormatted = row.legalEntityEmails.split(',').join(', ');
					if (row.legalEntityEmailsFormatted.length > 32) {
						row.legalEntityEmailsFormatted = row.legalEntityEmailsFormatted.substr(0, 29) + '...';
					}
				}
				row.nameFormatted = row.name;
				if (row.name.length > 24) {
					row.nameFormatted = row.nameFormatted.substr(0, 21) + '...';
				}
				if (row.discount) {
					let discountParts = row.discount.split(',');
					for (let i = 0; i < discountParts.length; i++) {
						let discountAmounts = discountParts[i].split('-');
						for (let j = 1; j < discountAmounts.length; j = j + 2) {
							discountAmounts[j] = (parseFloat(discountAmounts[j]) * 100).toFixed(0);
							discountAmounts[j] = discountAmounts[j] + '%';
						}
						discountAmounts = discountAmounts.join(' - ');
						discountParts[i] = discountAmounts;
					}
					discountParts = discountParts.join(', ≥');
					row.discountFormatted = '≥' + discountParts;

					if (row.discountFormatted.length > 48) {
						row.discountFormatted = row.discountFormatted.substr(0, 45) + '...';
					}
				}

				return row;
			});

			this.originalData = this.localData;
			if (this.filterType) {
				this.localData = this.originalData.filter(client => {
					return client.type === this.filterType;
				});
				if (this.$refs.vuetable) this.$refs.vuetable.refresh();
			}
		},

		setupTableFields() {
			this.fields = [
				{
					name: 'name-slot',
					title: 'Naziv korisnika',
					sortField: 'name'
				},
				{
					name: 'user-type-slot',
					title: 'Tip korisnika',
					sortField: 'type'
				},
				{
					name: 'pib',
					title: 'PIB',
					sortField: 'pib'
				},
				{
					name: 'mb',
					title: 'MB',
					sortField: 'mb'
				},

				{
					name: 'legal-entity-name-slot',
					title: 'Naziv obveznika',
					sortField: 'legalEntityName'
				},

				{
					name: 'legal-entity-emails-slot',
					title: 'E-mail obveznika',
					sortField: 'legalEntityEmails'
				},
				{
					name: 'legal-entity-address-slot',
					title: 'Adresa obveznika',
					sortField: 'legalEntityAddress'
				},
				{
					name: 'pricelist-prod-slot',
					title: 'Cenovnik (PROD)',
					sortField: 'pricelistProdName'
				},
				{
					name: 'pricelist-print-slot',
					title: 'Cenovnik (PRINT)',
					sortField: 'pricelistPrintName'
				},
				{
					name: 'pricelist-ips-slot',
					title: 'Cenovnik (IPS)',
					sortField: 'pricelistIpsName'
				},
				{
					name: 'pricelist-eft-slot',
					title: 'Cenovnik (EFT)',
					sortField: 'pricelistEftName'
				},
				{
					name: 'actions-slot',
					title: 'Akcije',
					sortable: false
				}
			];
		},

		dataManager(sortOrder, pagination) {
			let data = this.localData;

			// account for search filter
			if (this.searchFor) {
				// the text should be case insensitive
				let txt = new RegExp(this.searchFor, 'i');

				// search on name, email, and nickname
				data = _.filter(data, function (item) {
					return (
						item.name.search(txt) >= 0 ||
						item.pib.search(txt) >= 0 ||
						(item.legalEntityName && item.legalEntityName.search(txt) >= 0) ||
						(item.legalEntityEmails && item.legalEntityEmails.search(txt) >= 0) ||
						(item.legalEntityAddress && item.legalEntityAddress.search(txt) >= 0)
					);
				});
			}

			// sortOrder can be empty, so we have to check for that as well
			if (sortOrder.length > 0) {
				data = _.orderBy(data, sortOrder[0].sortField, sortOrder[0].direction);
			}

			// since the filter might affect the total number of records
			// we can ask Vuetable to recalculate the pagination for us
			// by calling makePagination(). this will make VuetablePagination
			// work just like in API mode
			pagination = this.$refs.vuetable.makePagination(data.length); // if you don't want to use pagination component, you can just
			// return the data array
			return {
				pagination: pagination,
				data: _.slice(data, pagination.from - 1, pagination.to)
			};
		},

		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData);
		},
		onLoaded() {
			this.numOfDisplayed = this.$refs.vuetable.countTableData;
			this.numOfTotal = this.$refs.pagination.tablePagination.total;
		},
		onChangePage(page) {
			this.$refs.vuetable.changePage(page);
		},

		search() {
			this.searchFor = this.searchInput;
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		},

		cancelFilter() {
			this.searchFor = '';
			this.searchInput = '';
			this.filterType = null;
			this.localData = this.originalData;
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		}
	},

	async created() {
		this.loading = true;
		this.setupTableFields();
		try {
			await this.getClients();
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},

	watch: {
		localData() {
			if (this.$refs.vuetable) this.$refs.vuetable.reload();
		},

		filterType() {
			if (!this.filterType) return;
			this.localData = this.originalData.filter(client => {
				return client.type === this.filterType;
			});
			if (this.$refs.vuetable) this.$refs.vuetable.refresh();
		}
	}
};
</script>

<style></style>
