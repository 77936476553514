<template>
	<div class="modal modal-list-of-logs">
		<h2 class="text-2xl">Log</h2>
		<hr />
		<div v-if="loading" class="mt-10 mb-4 flex flex-row justify-center text-center">
			<spinner-component :size="50" />
		</div>
		<div v-else class="modal-content">
			<div v-if="logs && logs.length > 0">
				<div class="form-item" v-for="log in logs" :key="log.id">
					<h2>Opis</h2>
					<h1>{{ log.event }}</h1>
					<h2>Vreme</h2>
					<h1>{{ log.timestamp | formatDate }}</h1>
					<h2>Status</h2>
					<div class="status">
						<span v-if="log.status == 1">
							<img src="@/assets/Green.png" />
							<h1>Operativan</h1>
						</span>
						<span v-else-if="log.status == 2"
							><img src="@/assets/Orange.png" />
							<h1>Upozorenje</h1></span
						>
						<span v-else-if="log.status == 3"
							><img src="@/assets/Red.png" />
							<h1>Van funkcije</h1></span
						>
						<span v-else
							><img src="@/assets/Gray.png" />
							<h1>Nepoznat</h1></span
						>
					</div>
					<h2>Verzija</h2>
					<h1>{{ log.version }}</h1>
				</div>
			</div>
			<div v-else class="form-item">
				<h1>Nisu pronadjeni logovi.</h1>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	data() {
		return {
			loading: false,
			logs: null
		};
	},

	props: {
		lpfr: {
			type: Object,
			required: true
		}
	},

	async created() {
		await this.getLogs();
	},

	methods: {
		async getLogs() {
			this.loading = true;
			try {
				const { data } = await axios.get(`/client/lpfr/${this.lpfr.id}/notify-logs`);

				this.logs = data;
				this.logs.sort((a, b) => {
					return new Date(b.timestamp) - new Date(a.timestamp);
				});
			} catch (error) {
				console.log(error);
				this.$emit('close');
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.form-item {
	background-color: rgb(241, 241, 241);
	border-radius: 12px;
	padding: 12px;
	margin-right: 12px;
}

.status span {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.status span img {
	margin-bottom: 4px;
	margin-right: 6px;
}

.modal-content {
	max-height: 600px;
	overflow-y: auto;
}

h2 {
	color: rgb(90, 90, 90) !important;
}
h1 {
	font-size: 1.2rem;
}
</style>
