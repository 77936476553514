<template>
	<div>
		<h1 class="text-2xl">Dashboard</h1>
		<h2>(analitika / opšti pregled)</h2>

		<h3 class="font-bold text-3xl mt-24">CONTENT</h3>
		<h3 class="font-bold text-3xl">TO BE DETERMINED</h3>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
