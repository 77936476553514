<template>
	<div class="modal modal-add-client">
		<h1 class="text-2xl">Dodaj novi podnalog</h1>
		<hr />
		<div class="modal-content text-lg">
			<div class="form-item">
				<div class="flex">
					<h2 for="name">Naziv partnera</h2>
					<span class="ml-1 font-semibold text-red-600">*</span>
				</div>
				<input
					type="text"
					id="name"
					v-model="name"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<h2 for="email">E-mail</h2>

				<input
					type="text"
					id="email"
					v-model="email"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<h2 for="phone">Kontakt telefon</h2>

				<vue-phone-number-input
					id="phone"
					color="#005ca9"
					valid-color="#00796b"
					default-country-code="RS"
					v-model="phoneRaw"
					:translations="{
						countrySelectorLabel: 'Država',
						countrySelectorError: 'Greska',
						phoneNumberLabel: 'Broj telefona',
						example: 'Primer:'
					}"
					:no-example="true"
					:preferred-countries="['RS', 'ME', 'BA', 'HR', 'MK', 'HU', 'RO', 'BG']"
					:show-code-on-list="true"
					size="lg"
					:countries-height="25"
					:no-country-selector="false"
					@update="updatePhoneNumber($event)"
				/>
			</div>
			<div class="flex flex-row mt-3">
				<button class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Otkaži</button>
				<button
					:disabled="
						loading || name == '' || !this.email.match(this.emailRegex) || (this.phoneRaw && !this.phone.isValid)
					"
					class="flex-grow ml-2"
					@click="addAlternateLogin()"
				>
					Dodaj
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { mapState } from 'vuex';

export default {
	props: {
		userId: {
			type: Number,
			required: true
		}
	},
	components: {
		VuePhoneNumberInput
	},

	data() {
		return {
			loading: false,
			name: '',
			phoneRaw: null,
			phone: {
				isValid: null,
				number: null
			},
			email: '',
			emailRegex:
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		};
	},

	computed: {
		...mapState({
			user: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		})
	},
	methods: {
		updatePhoneNumber($event) {
			this.phone = {
				isValid: $event.isValid,
				number: $event.formattedNumber
			};
		},

		async addAlternateLogin() {
			this.loading = true;
			try {
				const requestBody = {
					email: this.email,
					name: this.name,
					userId: this.userId
				};

				if (this.phoneRaw && this.phone.isValid) {
					requestBody.phone = this.phone.number;
				}

				await axios.post('user/alternate-login', requestBody);
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					text: 'Podnalog je uspešno dodat.',
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				this.$emit('close');
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom dodavanja podnaloga.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style></style>
