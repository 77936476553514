<template>
	<div class="modal modal-list-of-logs">
		<h2 class="text-2xl">Detalji licence</h2>
		<hr />
		<div class="modal-content">
			<div class="form-item">
				<h2>Licenca</h2>
				<div class="flex flex-row items-center">
					<h1 style="padding-bottom: 2px">{{ licence.code }}</h1>
					<button @click="copyLicenceCode" class="ml-4" style="width: 34px; height: 34px; padding: 0">
						<i class="fas fa-copy" />
					</button>
				</div>

				<h2>Status</h2>
				<h1 class="font-bold">{{ licenceStatus }}</h1>
				<div class="flex flex-row justify-start">
					<div>
						<h2>Datum izdavanja</h2>
						<h1>{{ licence.issueDate | formatDateNoTime }}</h1>
					</div>
					<div class="ml-16">
						<h2>Datum isteka</h2>
						<h1>{{ licence.expirationDate | formatDateNoTime }}</h1>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: false
		};
	},

	props: {
		licence: {
			type: Object,
			required: true
		}
	},

	computed: {
		licenceStatus: {
			get() {
				if (this.licence.expirationDate) {
					return this.licence.expirationDate < new Date() ? 'Istekla' : 'Aktivna';
				}
				return 'Istekla';
			}
		}
	},
	methods: {
		copyLicenceCode() {
			navigator.clipboard.writeText(this.licence.code);
			this.$fire({
				title: 'Kod licence kopiran',
				text: `${this.licence.code}`,
				timer: 1000,
				showConfirmButton: false
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.form-item {
	background-color: rgb(241, 241, 241);
	border-radius: 12px;
	padding: 12px;
	margin-right: 12px;
}

.status span {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.status span img {
	margin-bottom: 4px;
	margin-right: 6px;
}

.modal-content {
	max-height: 600px;
	overflow-y: auto;
}

h2 {
	color: rgb(90, 90, 90) !important;
}
h1 {
	font-size: 1.2rem;
}
</style>
