<template>
	<div class="modal">
		<div>
			<modal :adaptive="true" height="auto" :width="640" name="e-invoice-statuses">
				<div class="flex flex-row justify-end m-2 absolute right-1 top-1">
					<button
						class="bg-secondary hover:bg-secondary-light"
						style="width: 34px; height: 34px; line-height: 0"
						@click="$modal.hide('e-invoice-statuses')"
					>
						<i class="fas fa-times" />
					</button>
				</div>
				<modal-invoice-e-faktura-status @close="closeModal('e-invoice-statuses')" :invoiceId="invoice.id">
				</modal-invoice-e-faktura-status>
			</modal>
		</div>
		<h1>Sistem za e-fakture</h1>
		<hr class="mb-4" />
		<div v-if="loading" class="mt-6 mb-6">
			<spinner-component :size="64" />
		</div>
		<div v-else class="flex flex-col">
			<div class="flex flex-row">
				<div class="left-column">
					<h2>Broj računa</h2>
					<h1>{{ invoice.number }}</h1>
				</div>
				<div class="left-column">
					<h2>Obračunski period</h2>
					<h1>{{ invoice.accountingPeriodFormattedShort }}</h1>
				</div>
				<div class="right-column">
					<h2>Ukupno (sa PDV)</h2>
					<h1>{{ invoice.totalWithVatFormatted }}</h1>
				</div>
			</div>
			<h2>Obveznik</h2>
			<h1>{{ invoice.obligorFormatted }}</h1>
			<div class="flex flex-row"></div>
			<hr class="mb-2 mt-2" />
			<div>
				<h2>Status eFakture</h2>
				<h1
					class="status mt-1"
					:class="{
						'red-border':
							eFakturaData.status == 'Send failed' ||
							eFakturaData.status == 'Rejected' ||
							eFakturaData.status == 'Mistake',
						'green-border': eFakturaData.status == 'Approved',
						'blue-border': eFakturaData.status == 'Sent successfully',
						'gray-border':
							eFakturaData.status == 'Not sent' ||
							!eFakturaData.status ||
							eFakturaData.status == 'Cancelled' ||
							eFakturaData.status == 'Storno'
					}"
				>
					{{ eFakturaData.statusTranslated }}
				</h1>
				<!-- split into 2 columns -->
				<div class="mt-4" v-if="eFakturaData.status == 'Sent successfully'">
					<div class="flex flex-row">
						<div class="left-column">
							<h2>Jedinstveni ID računa</h2>
							<h1>{{ eFakturaData.invoiceId }}</h1>
						</div>
						<div class="left-column">
							<h2>Jedinstveni ID zahteva</h2>
							<h1>{{ eFakturaData.uniqueRequestId }}</h1>
						</div>
						<div class="left-column">
							<h2>ID "sales" računa</h2>
							<h1>{{ eFakturaData.salesInvoiceId }}</h1>
						</div>
						<div class="right-column">
							<h2>ID "purchase" računa</h2>
							<h1>{{ eFakturaData.purchaseInvoiceId }}</h1>
						</div>
					</div>
					<div class="flex flex-row"></div>
				</div>
			</div>
			<hr class="mb-2 mt-2" />

			<div class="flex flex-row justify-end">
				<button
					v-if="
						!eFakturaData.status ||
						eFakturaData.status == 'Not sent' ||
						eFakturaData.status == 'Send failed' ||
						eFakturaData.status == 'Cancelled'
					"
					class="w-4/12"
					@click="sendToSystem"
				>
					Pošalji na sistem e-faktura
				</button>
				<button
					v-if="eFakturaData.status == 'Sent successfully' || eFakturaData.status == 'Rejected'"
					@click="cancelInvoice"
					class="w-4/12 bg-red-600 hover:bg-red-800"
				>
					Storniraj e-fakturu
				</button>
				<button
					v-if="eFakturaData.status && eFakturaData.status != 'Not sent' && eFakturaData.status != 'Send failed'"
					@click="checkStatus"
					class="ml-2"
				>
					Proveri statuse na sistemu eFaktura
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import ModalInvoiceEFakturaStatus from './ModalInvoiceEFakturaStatus.vue';
export default {
	components: { ModalInvoiceEFakturaStatus },
	data() {
		return {
			loading: false,
			eFakturaData: {
				status: null,
				statusTranslated: null,
				invoiceId: null,
				salesInvoiceId: null,
				purchaseInvoiceId: null,
				uniqueRequestId: null
			},
			errorTranslations: {
				'Invoice InvoiceInformation.InvoiceNumber contains duplicates':
					'Račun sa ovim brojem računa već postoji na eFaktura sistemu.',
				'Already uploaded ubl with this requestId': 'Već je poslat zahtev sa ovim jedinstvenim ID zahteva.',
				'Invoice for cancellation not found': 'Račun za storniranje nije pronađen na eFaktura sistemu.'
			}
		};
	},
	props: {
		invoice: {
			type: Object,
			required: true
		}
	},
	methods: {
		async sendToSystem() {
			this.$fire({
				title: 'Slanje na sistem e-faktura',
				html: `Da li ste sigurni da želite da pošaljete račun na sistem e-faktura?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Pošalji',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`efaktura/invoice`, {
							invoiceId: this.invoice.id
						})
						.then(() => {
							this.$fire({
								title: 'Uspešna akcija',
								html: `Faktura je uspešno poslata na eFaktura sistem.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.$emit('refresh');
						})
						.catch(error => {
							this.$fire({
								title: 'Greška',
								html: `Došlo je do greške prilikom slanja fakture na eFaktura sistem:<br/>
								<b>${
									error?.response?.data?.message
										? this.errorTranslations[error.response.data.message] || error.response.data.message
										: 'Nepoznata greška'
								}</b><br/><br/>
								Više informacija se potencijalno nalazi u bazi podataka.`,
								type: 'error',
								showConfirmButton: true
							});
						})
						.finally(() => {
							this.loading = false;
							this.loadInvoiceData();
						});
				}
			});
		},
		async loadInvoiceData() {
			this.loading = true;
			try {
				const response = await axios.get(`efaktura/invoice/${this.invoice.id}`);

				if (response.data.eFakturaData) this.eFakturaData = response.data.eFakturaData;
				this.eFakturaData.statusTranslated = this.translatedEFakturaStatus(this.eFakturaData.status);
			} catch (error) {
				console.log(error);
				this.$fire({
					title: 'Greška',
					text: 'Došlo je do greške prilikom učitavanja podataka o računu.',
					type: 'error',
					timer: 2500,
					showConfirmButton: false
				});
			} finally {
				this.loading = false;
			}
		},

		async cancelInvoice() {
			this.$fire({
				title: 'Storniranje računa na sistemu e-faktura',
				html: `Da li ste sigurni da želite da stornirate račun na sistemu e-faktura?`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Storniraj',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`efaktura/cancel-invoice`, {
							invoiceId: this.invoice.id
						})
						.then(() => {
							this.$fire({
								title: 'Uspešna akcija',
								html: `Faktura je uspešno stornirana na eFaktura sistemu.`,
								type: 'success',
								timer: 2500,
								showConfirmButton: false
							});
							this.loadInvoiceData();
							this.$emit('refresh');
						})
						.catch(error => {
							this.$fire({
								title: 'Greška',
								html: `Došlo je do greške prilikom storniranja fakture na eFaktura sistemu:<br/>
								<b>${
									error?.response?.data?.message
										? this.errorTranslations[error.response.data.message] || error.response.data.message
										: 'Nepoznata greška'
								}</b><br/><br/>
								Više informacija se potencijalno nalazi u bazi podataka.`,
								type: 'error',
								showConfirmButton: true
							});
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});
		},

		translatedEFakturaStatus(status) {
			switch (status) {
				case 'Not sent':
					return 'Nije poslato na eFaktura sistem';
				case 'Sent successfully':
					return 'Poslato na eFaktura sistem';
				case 'Send failed':
					return 'Greška prilikom slanja na eFaktura sistem';
				case 'Cancelled':
					return 'Otkazano na eFaktura sistemu';
				case 'Rejected':
					return 'Odbijeno na eFaktura sistemu';
				case 'Approved':
					return 'Prihvaćeno na eFaktura sistemu';
				case 'Storno':
					return 'Stornirano na eFaktura sistemu';
				case 'Mistake':
					return 'Greška na eFaktura sistemu';
				default:
					return 'Nije poslato na eFaktura sistem';
			}
		},

		closeModal(name) {
			this.$modal.hide(name);
		},
		openEInvoiceStatuses(name, data) {
			event.stopPropagation();
			this.$modal.show(name);
		},

		async checkStatus() {
			this.openEInvoiceStatuses('e-invoice-statuses');

			return;
		}
	},
	async created() {
		await this.loadInvoiceData();
	}
};
</script>

<style lang="scss" scoped>
.left-column {
	float: left;
	margin-right: 48px;
}
.right-column {
	float: right;
}

.status {
	padding: 4px 10px;
	display: inline-block !important;
	font-size: 1.3rem !important;
}

.red-border {
	background-color: rgb(173, 15, 10);
	color: white;
	border-radius: 12px;
}

.green-border {
	background-color: rgb(0, 90, 0);
	color: white;
	border-radius: 12px;
}
.gray-border {
	background-color: rgb(128, 128, 128);
	color: white;
	border-radius: 12px;
}

.blue-border {
	background-color: #0091cc;
	color: white;
	border-radius: 12px;
}
</style>
