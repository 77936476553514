<template>
	<div class="modal modal-user-details">
		<h1 class="text-2xl">Detalji o {{ userType }}</h1>
		<hr />
		<div class="flex flex-row justify-center mt-4" v-if="loading">
			<spinner-component :size="50" />
		</div>
		<div class="modal-content text-lg" v-if="!loading">
			<div class="form-item">
				<div class="flex">
					<h2 for="name">Naziv</h2>
					<span v-if="underEdit" class="ml-1 font-semibold text-red-600">*</span>
				</div>
				<div class="font-semibold text-lg" v-if="!underEdit">{{ name }}</div>
				<input
					v-if="underEdit"
					:disabled="!underEdit"
					type="text"
					id="name"
					v-model="name"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<div class="flex">
					<h2 for="pib">PIB</h2>
				</div>

				<div class="font-semibold text-lg">{{ pib }}</div>
			</div>
			<div class="form-item" v-if="user.productType">
				<div class="flex">
					<h2 for="pib">Tip proizvoda klijenta</h2>
				</div>

				<div class="font-semibold text-lg">{{ user.productType }}</div>
			</div>

			<div class="form-item" v-if="user.productTypes">
				<div class="flex">
					<h2 for="pib">Dozvoljeni tipovi proizvoda</h2>
				</div>

				<div class="flex flex-row">
					<div class="font-semibold text-lg mr-16" v-for="productType of user.productTypes" :key="productType">
						{{ productType }}
					</div>
				</div>
			</div>

			<div class="form-item">
				<div class="flex" v-if="email || underEdit">
					<h2 for="email">E-mail</h2>

					<span v-if="underEdit && user.role" class="ml-1 font-semibold text-red-600">*</span>
				</div>

				<a class="font-semibold text-lg hover:text-primary" v-if="!underEdit" :href="`mailto:${email}`">{{ email }}</a>

				<input
					v-if="underEdit"
					:disabled="!underEdit"
					type="text"
					id="email"
					v-model="email"
					class="focus:ring-primary focus:border-primary font-semibold text-lg"
				/>
			</div>
			<div class="form-item">
				<h2 v-if="phoneRaw || underEdit" for="phone">Kontakt telefon</h2>

				<a v-if="!underEdit" :href="`tel:${phoneRaw}`" class="font-semibold text-lg hover:text-primary">
					{{ phoneRaw }}
				</a>

				<vue-phone-number-input
					v-if="underEdit"
					:disabled="!underEdit"
					id="phone"
					color="#005ca9"
					valid-color="#00796b"
					default-country-code="RS"
					v-model="phoneRaw"
					:translations="{
						countrySelectorLabel: 'Država',
						countrySelectorError: 'Greska',
						phoneNumberLabel: 'Broj telefona',
						example: 'Primer:'
					}"
					:no-example="true"
					:preferred-countries="['RS', 'ME', 'BA', 'HR', 'MK', 'HU', 'RO', 'BG']"
					:show-code-on-list="true"
					size="lg"
					:countries-height="25"
					:no-country-selector="false"
					@update="updatePhoneNumber($event)"
				/>
			</div>
			<div
				class="form-item"
				v-if="
					!user.role &&
					loggedUser.role != 'ONPREMADMIN' &&
					underEdit &&
					(loggedUser.role == 'DISTRIBUTOR' || loggedUser.role == 'SUPERADMIN')
				"
			>
				<h2>Partner</h2>
				<v-select
					placeholder="Pretražite i izaberite partnera"
					class="select-style mb-1"
					v-model="selectedPartner"
					:options="partners"
				></v-select>
			</div>

			<div class="flex flex-row mt-3">
				<button v-if="!underEdit" class="w-4/12 bg-red-600 hover:bg-red-800" @click="$emit('close')">Izađi</button>
				<button v-if="underEdit" class="w-4/12 bg-red-600 hover:bg-red-800" @click="cancelEdit()">Otkaži</button>
				<button v-if="!underEdit" :disabled="loading" class="flex-grow ml-2" @click="startEdit()">
					Izmeni detalje
				</button>
				<button
					v-if="underEdit"
					:disabled="
						!isDifferent ||
						loading ||
						name == '' ||
						pib == '' ||
						pib.match(/^[0-9,A-Z,a-z]{9,13}$/) == null ||
						((this.user.role == 'DISTRIBUTOR' || this.user.role == 'PARTNER') && !this.email.match(this.emailRegex)) ||
						(this.email && !this.email.match(this.emailRegex)) ||
						(this.phoneRaw && !this.phone.isValid)
					"
					class="flex-grow ml-2"
					@click="editUser()"
				>
					Potvrdi izmene
				</button>
			</div>
			<div>
				<p
					class="float-left text-base underline text-gray-600 mt-2 text-left cursor-pointer hover:text-primary"
					v-if="loggedUser.role == 'SUPERADMIN'"
					@click="addAlternateLogin()"
				>
					Kreiraj podnalog
				</p>
				<p
					class="float-right text-base underline text-gray-600 mt-2 text-right cursor-pointer hover:text-primary"
					v-if="user.role == 'DISTRIBUTOR' || user.role == 'PARTNER'"
					@click="resetPassword()"
				>
					Resetuj šifru naloga
				</p>

				<modal :draggable="true" :adaptive="true" :height="'auto'" :focusTrap="true" name="add-alternate-login">
					<modal-add-alternate-login :userId="user.id" @close="closeModal('add-alternate-login')" />
				</modal>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { mapState } from 'vuex';
import ModalAddAlternateLogin from './ModalAddAlternateLogin.vue';

export default {
	props: {
		user: {
			type: Object,
			required: true
		}
	},

	components: {
		VuePhoneNumberInput,
		ModalAddAlternateLogin
	},

	computed: {
		...mapState({
			loggedUser: state => {
				return {
					role: state.user.user.role,
					id: state.user.user.id
				};
			}
		}),
		isDifferent() {
			return (
				this.user.name != this.name ||
				this.user.pib != this.pib ||
				(this.user.email && this.user.email != this.email) ||
				(!this.user.email && this.email != '') ||
				(this.user.phone && this.user.phone != this.phone.number) ||
				(!this.user.phone && this.phone.number != null) ||
				(!this.user.role && this.selectedPartner && this.selectedPartner.id != this.ownerId)
			);
		}
	},

	async created() {
		// console.log('user', this.user);
		this.loading = true;
		//if selected user is a client, find his owner
		if (!this.user.role) {
			this.ownerId = (await axios.get(`/client/${this.user.id}/owner`)).data;
		}
		// Filling data
		this.name = this.user.name;
		this.pib = this.user.pib;
		if (this.user.email) this.email = this.user.email;
		if (this.user.phone) this.phoneRaw = this.user.phone;

		if (this.user.role) {
			switch (this.user.role) {
				case 'SUPERADMIN':
					this.userType = 'administratoru';
					break;
				case 'ONPREMADMIN':
					this.userType = 'administratoru';
					break;
				case 'DISTRIBUTOR':
					this.userType = 'distributeru';
					break;
				case 'PARTNER':
					this.userType = 'partneru';
					break;
				default:
					this.userType = 'klijentu';
					break;
			}
		} else {
			this.userType = 'klijentu';
		}

		if (this.loggedUser.role == 'DISTRIBUTOR' || this.loggedUser.role == 'SUPERADMIN') {
			await this.getPartners();
		}
		this.loading = false;
	},

	data() {
		return {
			name: '',
			pib: '',
			loading: false,
			email: '',
			phoneRaw: null,
			phone: {
				isValid: null,
				number: null
			},
			userType: null,
			emailRegex:
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			underEdit: false,
			partners: [],
			selectedPartner: null,
			ownerId: null
		};
	},

	methods: {
		updatePhoneNumber($event) {
			this.phone = {
				isValid: $event.isValid,
				number: $event.formattedNumber
			};
		},

		startEdit() {
			this.underEdit = true;
		},

		cancelEdit() {
			this.underEdit = false;
			this.name = this.user.name;
			this.pib = this.user.pib;
			if (this.user.email) this.email = this.user.email;
			else {
				this.email = '';
			}
			if (this.user.phone) this.phoneRaw = this.user.phone;
			else {
				this.phoneRaw = null;
			}
		},

		async getPartners() {
			if (this.loggedUser.role == 'DISTRIBUTOR') {
				const response = await axios.get('user/distributor/partners', {
					params: {
						productType: this.user.productType
					}
				});
				this.partners = response.data.map(partner => {
					partner.label = `${partner.name} (PIB: ${partner.pib})`;
					return partner;
				});
			} else if (this.loggedUser.role == 'SUPERADMIN') {
				const response = await axios.get(`user/partners/all`, {
					params: {
						productType: this.user.productType
					}
				});
				this.partners = response.data.map(partner => {
					partner.label = `${partner.name} (PIB: ${partner.pib})`;
					return partner;
				});
			}

			const selfUser = (await axios.get(`user/get/${this.loggedUser.id}`)).data;
			selfUser.label = `${selfUser.name} (PIB: ${selfUser.pib})`;
			this.partners.unshift(selfUser);

			this.selectedPartner = this.partners.find(partner => partner.id == this.ownerId);
		},

		resetPassword() {
			this.$fire({
				title: 'Resetovanje šifre',
				html: `Da li ste sigurni da želite da resetujete šifru naloga <br>"${this.name}" ?<br><br> <span class="text-sm">Nova šifra će biti poslata na e-mail adresu korisnika.</span>`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Odustani',
				showConfirmButton: true,
				confirmButtonText: 'Resetuj šifru',
				confirmButtonColor: '#005ca9',
				reverseButtons: true
			}).then(result => {
				if (result.value) {
					this.loading = true;
					axios
						.post(`user/${this.user.id}/reset-password`, {
							id: this.user.id
						})
						.then(response => {
							if (response.data.message && response.data.message == 'Email not sent') {
								this.$fire({
									title: 'Uspešna akcija',
									html: `Šifra je uspešno resetovana. <br/> <br/> Nova lozinka: <b>${response.data.password}</b>`,
									type: 'success',
									showConfirmButton: true
								});
							} else {
								this.$fire({
									title: 'Uspešna akcija',
									html: `Šifra je uspešno resetovana. Podaci su poslati na sledeću e-mail adresu: <br><br> <span class="font-semibold">${this.user.email}</span>`,
									type: 'success',
									showConfirmButton: true
								});
							}
							this.loading = false;
							this.$emit('refresh');
						})
						.catch(error => {
							this.loading = false;
							this.$fire({
								title: 'Greška',
								text: 'Došlo je do greške prilikom resetovanja lozinke.',
								type: 'error',
								timer: 2500,
								showConfirmButton: false
							});
						});
				}
			});
		},

		addAlternateLogin() {
			this.$modal.show('add-alternate-login');
		},

		closeModal(name) {
			this.$modal.hide(name);
		},

		async editUser() {
			this.loading = true;
			try {
				const requestBody = {
					name: this.name,
					email: this.email ? this.email : null
				};

				if (this.phoneRaw && this.phone.isValid) {
					requestBody.phone = this.phone.number;
				} else {
					requestBody.phone = '';
				}

				if (!this.user.role && this.selectedPartner) {
					requestBody.partnerId = this.selectedPartner.id;
				}

				if (this.user.role == 'DISTRIBUTOR' || this.user.role == 'PARTNER') {
					await axios.post(`user/edit/${this.user.id}`, requestBody);
				} else {
					await axios.post(`client/edit/${this.user.id}`, requestBody);
				}
				this.$emit('close');
				this.$fire({
					title: 'Uspešna akcija',
					html: `Podaci o ${this.userType} su uspešno izmenjeni`,
					type: 'success',
					timer: 2500,
					showConfirmButton: false
				});
				this.$emit('refresh');
			} catch (error) {
				console.log(error);
				if (error.response.status == 409) {
					this.$fire({
						title: 'Neispravna e-mail adresa',
						text: 'Uneta e-mail adresa već postoji.',
						type: 'error',
						showConfirmButton: true
					});
				} else {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom izmene podataka.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
					this.$emit('close');
				}
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style></style>
