<template>
	<div class="modal modal-list-of-logs">
		<h2 class="text-2xl">{{ lpfrDescription }} - Log fajlova</h2>
		<hr />
		<div class="modal-content">
			<div class="form-item flex flex-row justify-between items-center" v-for="log in logs" :key="log.id">
				<div class="w-3/5">
					<h2>Naziv fajla</h2>
					<h1>{{ log.filename }}</h1>
					<h2>Datum</h2>
					<h1>{{ log.date | formatDateNoTime }}</h1>
				</div>
				<div class="w-2/5 flex flex-row justify-center">
					<button
						class="edit-nick-btn flex flex-row justify-around items-center text-sm"
						style="width: 150px; height: 48px"
						:disabled="downloadInProgress"
						@click="downloadLogFile(log)"
					>
						Preuzmi log fajl
						<i class="fas fa-download text-xl"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	data() {
		return {
			loading: false,
			logs: null,
			downloadInProgress: false
		};
	},

	props: {
		row: {
			type: Object,
			required: true
		}
	},

	computed: {
		lpfrDescription() {
			let description = this.row.jid;
			if (this.row.jidNickname) {
				description = `${description} (${this.row.jidNickname})`;
			}

			return description;
		}
	},

	created() {
		this.logs = this.row.logFiles;
	},

	methods: {
		async downloadLogFile(logfile) {
			this.downloadInProgress = true;

			axios({
				url: `lpfr/logfile/${logfile.id}`,
				method: 'GET',
				responseType: 'base64'
			})
				.then(response => {
					const b64toBlob = (base64, type = 'application/octet-stream') =>
						fetch(`data:${type};base64,${base64}`).then(res => res.blob());

					b64toBlob(response.data).then(blob => {
						const url = window.URL.createObjectURL(blob);
						const link = document.createElement('a');
						link.href = url;
						const filename = logfile.filename.substr(0, logfile.filename.lastIndexOf('.')) + '.zip';
						link.setAttribute('download', `${filename}`);
						document.body.appendChild(link);
						link.click();
					});
				})
				.catch(error => {
					this.$fire({
						title: 'Greška',
						text: 'Došlo je do greške prilikom skidanja fajla.',
						type: 'error',
						timer: 2500,
						showConfirmButton: false
					});
					console.log(error);
				})
				.finally(() => {
					this.downloadInProgress = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.form-item {
	background-color: rgb(241, 241, 241);
	border-radius: 12px;
	padding: 12px;
	margin-right: 12px;
	flex-direction: row;
}

.status span {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.status span img {
	margin-bottom: 4px;
	margin-right: 6px;
}

.modal-content {
	max-height: 600px;
	overflow-y: auto;
}

h2 {
	color: rgb(90, 90, 90) !important;
}
h1 {
	font-size: 1.2rem;
}
</style>
