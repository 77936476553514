<template>
	<div class="modal modal-list-of-logs">
		<h2 class="text-2xl">Istorijat akcija nad licencama</h2>
		<hr />
		<div v-if="loading" class="mt-10 mb-4 flex flex-row justify-center text-center">
			<spinner-component :size="50" />
		</div>
		<div v-if="!loading && !hasNoActions" class="modal-content">
			<div class="form-item" v-for="action in actions" :key="action.id">
				<h2>Opis akcije</h2>
				<h1 class="font-semibold">{{ action.eventName }}</h1>
				<div class="flex flex-row">
					<div class="w-1/2">
						<h2>Akciju izvršio</h2>
						<h1
							v-if="action.user && action.user.role != 'SUPERADMIN'"
							:title="action.titleComputed"
							class="cursor-help"
						>
							{{ action.user.name }}
						</h1>
						<h1
							v-else-if="action.user && action.user.role == 'SUPERADMIN'"
							:title="action.titleComputed"
							class="cursor-help"
						>
							Administrator
						</h1>
						<h1
							v-else
							:title="`Ova akcija je automatski izvršena prilikom registracije proizvoda, korišćenjem eFiskalizator 2022 softvera`"
							class="cursor-help"
						>
							Softver proizvoda
						</h1>
					</div>
					<div class="pl-4 mr-4 action-timestamp">
						<h2>Vreme izvršenja</h2>
						<h1>{{ action.timestamp | formatDate }}</h1>
					</div>
				</div>
				<div class="flex flex-row">
					<div class="w-1/2 mr-4" v-if="action.event != 'CreateNew' && action.event != 'TransferTo'">
						<h2>Stari datum isteka licence</h2>
						<h1>{{ action.oldExpiryDate | formatDateNoTime }}</h1>
					</div>
					<div class="mr-4">
						<h2>Novi datum isteka licence</h2>
						<h1>{{ action.newExpiryDate | formatDateNoTime }}</h1>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!loading && hasNoActions">
			<h2>Nisu pronađene izmene nad licencama za dati LPFR.</h2>
		</div>
	</div>
</template>

<script>
import axios from '@/axios/axios.js';
export default {
	data() {
		return {
			loading: false,
			actions: null,
			hasNoActions: false
		};
	},

	props: {
		lpfr: {
			type: Object,
			required: true
		}
	},

	async created() {
		await this.getActions();
		// this.actions.sort((a, b) => {
		// 	return new Date(b.timestamp) - new Date(a.timestamp);
		// });
	},

	methods: {
		async getActions() {
			this.loading = true;
			try {
				const { data } = await axios.get(`/client/lpfr/${this.lpfr.id}/licence-changelog`);

				this.actions = data;
				// console.log(this.actions);
				if (this.actions.length == 0) {
					this.actions = null;
					this.hasNoActions = true;
				} else {
					this.adjustEventNames();
				}
			} catch (error) {
				console.log(error);
				this.$emit('close');
			} finally {
				this.loading = false;
			}
		},
		adjustEventNames() {
			this.actions = this.actions.map(action => {
				switch (action.event) {
					case 'Renew':
						action.eventName = 'Obnovljena licenca';
						break;
					case 'RenewYearly':
						action.eventName = 'Obnovljena godišnja licenca';
						break;
					case 'CancelNow':
						action.eventName = 'Otkazana licenca';
						break;
					case 'CancelAtEndOfMonth':
						action.eventName = 'Zakazano otkazivanje licence na kraju meseca';
						break;
					case 'CreateNew':
						action.eventName = 'Registrovan proizvod (kreirana probna licenca)';
						break;
					case 'TransferFrom':
						action.eventName = 'Uklonjena licenca (prebacivanje)';
						break;
					case 'TransferTo':
						action.eventName = 'Dodeljena licenca (prebacivanje)';
						break;
					default:
						action.eventName = 'Nepoznato';
						break;
				}

				if (action.user && action.user.role == 'SUPERADMIN') {
					action.titleComputed = `Administrator\nE-mail: licenca@fiskalizacija.in.rs`;
				} else if (action.user) {
					action.titleComputed = `PIB: ${action.user.pib}\nE-mail: ${action.user.email}`;
				}

				return action;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.action-timestamp {
	white-space: nowrap;
}

.form-item {
	background-color: rgb(241, 241, 241);
	border-radius: 12px;
	padding: 12px;
	margin-right: 12px;
}

.status span {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.status span img {
	margin-bottom: 4px;
	margin-right: 6px;
}

.modal-content {
	max-height: 600px;
	overflow-y: auto;
}

h2 {
	color: rgb(90, 90, 90) !important;
}
h1 {
	font-size: 1.2rem;
}
</style>
